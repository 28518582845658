import { useSelector, useDispatch } from 'react-redux'
import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import { updateResult } from '../../../store/survey/surveySlice'
import { selectCurrentQuestion, selectCurrentQuestionResult } from '../../../store/survey/surveySlice'
import './Description.css'

type Props = {
    handleValueChanged?: (val: any, inputIndex: number | undefined) => void,
    index?: number,
    value?: number | string
}

export const Description = ({ handleValueChanged, index = 0, value }: Props) => {    
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const dispatch = useDispatch()

    const handleAnswerChange = (val: number | string, isValid: boolean) => {
        dispatch(updateResult({
            answer: val,
            isValid: isValid
        }))
    }

    const handleInputChange = (val: string) => {
        // this is a number from a formula or multiple question
        if (handleValueChanged) {
            handleValueChanged(val, index) 
            return
        }
        handleAnswerChange(val, val !== null && val !== "")
    }

    return (
        <div className="number-and-description-container">
            <div className="number-description">
                <div>
                    <label className="number-description-label">Description</label>
                    <input 
                        type="input" 
                        readOnly={question.locked} 
                        className="number-description-input" 
                        value={ (value || value === "") ? value : (result ? result.answer : "") } 
                        onChange={ e => handleInputChange(e.target.value) }  
                    />
                </div>
            </div>
        </div>
    )
}