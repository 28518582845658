import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { createInvitation } from "../../../api/OrganizationAPI"
import { AlertTypes, UserFormValues } from "../../../interfaces"
import { setAlert } from "../../../store/alert/alertSlice"
import { SuperAdminHeader } from '../header/SuperAdminHeader'
import { UserForm } from "../shared/UserForm/UserForm"


export const SuperAdminNewUser = () => {
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()

    const handleCreateUser = async (inputs: UserFormValues) => {
        setIsLoading(true)
        try {
            await createInvitation(inputs)
            dispatch(setAlert({
              text: "User has been created and an invite email has been sent to their email.",
              type: AlertTypes.Success
            }))
            history.push("/superadmin")
        } catch {
            setIsLoading(false)
            dispatch(setAlert({
              text: "User with this email already exists. Please try again.",
              type: AlertTypes.Error
            }))
        }
    }

    return (
        <div className="super-admin-page-background">
            <div className="super-admin-page-container">
                <SuperAdminHeader />
                <UserForm
                    isLoading={ isLoading }
                    formTitle="New User"
                    formActionButtonTitle="Create User"
                    formAction={handleCreateUser}
                />
            </div>
        </div>
    )
}