import { PropagateLoader } from 'react-spinners'
import './AuthSubmit.css'
import { css } from "@emotion/core";

type Props = {
    onSubmit: () => void,
    title?: string,
    isLoading: boolean
}

const override = css`
  display: block;
  margin: 20px auto;
  left: 50%;
`;

export const AuthSubmit = ({ onSubmit, isLoading, title = "CREATE ACCOUNT" }: Props) => {
    return (
        <div>
            { !isLoading && <button className="create-account-submit" onClick={e => onSubmit()}>{ title }</button> }
            { isLoading && <PropagateLoader css={override} /> }
        </div>
    )
}