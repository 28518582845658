/* Custom utilities to format numbers, date values, etc
*/

enum TimePeriod {
  Annual = "Annual",
  Monthly = "Monthly",
  BiWeekly = "Bi-Weekly"
}

export const getDivisor = (timePeriod: TimePeriod) => {
  var divisor = 1
  if (timePeriod === TimePeriod.Monthly) { divisor = 12 }
  if (timePeriod === TimePeriod.BiWeekly) { divisor = 26 }
  return divisor
}

// Formats number with commas. Inserts X's in thousands places if unsubscribed
export const formatNumbers = (num: number, isSubscribed: boolean = false) => {
  if (num === undefined || isNaN(num) || num === null) {
    num = 0
  }
  
  const formattedNumber = (num).toLocaleString(undefined, { maximumFractionDigits: 0 })
  if (isSubscribed) {
      return formattedNumber
  }

  const reformattedNumber = formattedNumber.split(',')
  reformattedNumber[0] = reformattedNumber[0].replace(/\d/g, "X")

  return reformattedNumber.join(",")
}

// return number to 2 decimal places if it is a true number
export const getNumberValue = (num: number) => {
  if (num === undefined || isNaN(num)) {
      return 0
  }
  return parseFloat(num.toFixed(2))
}

// Formats percentages to 2 decimal places. Inserts X's in thousands places if unsubscribed
export const getPercentage = (dividend: number, annualGross: number, isSubscribed: boolean = false) => {
  const percentage = (getNumberValue(dividend) / (annualGross) * 100).toFixed(2)

  if (isSubscribed) {
    return percentage
  }

  const reformattedPercentage = percentage.replace(/\d/g, "X")
  return reformattedPercentage
}

// Formats percentages to 2 decimal places. Inserts X's in thousands places if unsubscribed
export const formatPercentage = (num: number, isSubscribed: boolean = false) => {
  const percentage = (num).toFixed(2)

  if (isSubscribed) {
    return percentage
  }

  const reformattedPercentage = percentage.replace(/\d/g, "X")
  return reformattedPercentage
}

// Converts year and months calculation into decimalized year
export const calculateTime = (months: number, years: number) => {
  return years + months / 12;
};

// Converts from decimalized year to find year and months based on the
// @type argument provided
export const convertTime = (type: string, value: number) => {
  if (type === "years") return Math.trunc(value);
  return Math.round((value - Math.trunc(value)) * 12);
};