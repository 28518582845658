import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Grid } from "@mui/material";
import { TimerBar } from "../TimerBar";
import cx from "classnames";

import { formatNumbers } from "../../../../utils/numberDateFormatters";
import { Menu } from "../../menu/Menu";
import { Sliders } from "../../../svg";
import { ForecastApi } from "../../../../api/ForecastApi";
import EditMyData from "../edit-data/EditMyData";
import { AlertTypes, PensionDataTypes, ServiceStatusValues } from "../../../../interfaces";
import { setAlert } from "../../../../store/alert/alertSlice";
import { selectNumbersData, selectPensionType, selectUserStatus } from "../../../../store/profile/profileSlice";
import { selectCurrentUser, selectIsValidated, selectSubscriptionStatus } from "../../../../store/auth/authSlice";
import Loader, { LoaderType } from '../loader/Loader'
import {
  selectForecastId,
  selectForecastProfile,
} from "../../../../store/forecast/forecastSlice";
import { ConfirmAlert } from "../../../alerts/confirm-alert/ConfirmAlert";
import { Forecasting } from "../forecasting/Forecasting";
import { PageLoader } from "../loader/PageLoader";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import "./OverviewHeader.css";
import { UserPageRoute } from "../../UserPage";

const MOBILE_WIDTH = 900;

type Props = {
  activePage: UserPageRoute;
};

export const OverviewHeader = ({ activePage }: Props) => {
  const [showForecasting, setShowForecasting] = useState(false);
  const [showEditMyData, setShowEditMyData] = useState(false);
  const [confirmSaveForecastOpen, setConfirmSaveForecastOpen] = useState(false);
  const [confirmDisableForecastOpen, setConfirmDisableForecastOpen] =
    useState(false);
  const [loadingCompare, setLoadingCompare] = useState(false);
  const numbersData = useSelector(selectNumbersData);
  const isGrossNumber = useSelector(selectPensionType) === PensionDataTypes.Gross;
  const currentForecastId = useSelector(selectForecastId);
  const currentUser = useSelector(selectCurrentUser);
  const forecastingProfileData = useSelector(selectForecastProfile);
  const userServiceStatus = useSelector(selectUserStatus) as ServiceStatusValues;
  const history = useHistory();
  const isMobile = window && window.innerWidth < MOBILE_WIDTH;
  const dispatch = useDispatch();
  const isSubscribed = useSelector(selectSubscriptionStatus);
  const isValidated = useSelector(selectIsValidated);

  const retirementData = numbersData;

  const handleCompare = () => {
    setLoadingCompare(true);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (loadingCompare) {
      timeoutId = setTimeout(() => {
        history.push("/compare");
      }, 500);
    }

    if (timeoutId) clearTimeout(timeoutId);;
  }, [loadingCompare, history]);

  const handleSaveForecast = async () => {
    try {
      if (currentForecastId !== null) {
        await ForecastApi.saveForecast(currentForecastId);
      } else {
        throw new Error();
      }
    } catch {
      dispatch(
        setAlert({
          text: "Could not save your forecast. Please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setConfirmSaveForecastOpen(false);
    }
  };

  const handleDisableForecast = async () => {
    try {
      if (currentForecastId !== null && currentForecastId !== undefined) {
        await ForecastApi.deleteForecast(currentForecastId);
      }
    } catch {
      dispatch(
        setAlert({
          text: "Could not delete your forecast. Please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setConfirmDisableForecastOpen(false);
    }
  };

  if (userServiceStatus !== ServiceStatusValues.Loaded) {
    return (
        <Loader type={LoaderType.Scale} size={50} text="Loading your data..." />
    )
  }
  return (
    <>
      {isMobile && (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Menu activePage={activePage} />
          <span style={{ display: "flex", flexDirection: "row" }}>
          <button
            className={cx("overview-header-button", {
              "overview-header-button-mobile": isMobile,
            })}
            onClick={() => setShowEditMyData(true)}
            disabled={!isValidated}
          >
            <Sliders fill={isMobile ? "black" : "white"} />
          </button>
          </span>
        </div>
      )}
      <div className="overview-header-container">
        <div className="flex-default">
          <div className="overview-header-title-container">
            {/* placeholder for logo
                      { currentUser.organizations.id === 13 && <Grid className="overview-header-image-container">
                      <img style={{width: "100%", height:"100%"}}
                          src={Logo} alt = "" />
                          </Grid>} */}
            <Grid className="overview-header-text-container">
              <h1>Hello {currentUser.first_name ?? ""},</h1>
              <p className="widget-light-text" style={{ fontWeight: "bold" }}>
                <PaidOutlinedIcon />
                <span style={{ marginLeft: "0.5rem" }}>
                  {retirementData && (
                    <>
                      Estimated Pension:{" "}
                      <span style={{ fontWeight: "bold", color: "#5579e4" }}>
                        {isGrossNumber
                          ? formatNumbers(numbersData.yearly_gross_pension, isSubscribed)
                          : formatNumbers(numbersData.yearly_net_pension, isSubscribed)}
                      </span>
                    </>
                  )}
                </span>
              </p>
              <TimerBar />
            </Grid>
          </div>
        </div>
        <div className="overview-header-button-container">
          {!forecastingProfileData && !isMobile && (
            <>
              <button
                className={cx("overview-header-button", {
                  "overview-header-button-mobile": isMobile,
                })}
                onClick={() => setShowEditMyData(true)}
              >
                <span style={{ marginRight: "0.5rem" }}>
                  Customize my pension
                </span>
                <Sliders fill={isMobile ? "black" : "white"} />
              </button>
              {/* <button
                className={cx("overview-header-button", {
                  "overview-header-button-mobile": isMobile,
                })}
                onClick={() => setShowForecasting(true)}
              >
                <span style={{ marginRight: "0.5rem" }}>
                  Forecast my future
                </span>
                <LayersOutlinedIcon />
              </button> */}
            </>
          )}
          {forecastingProfileData !== null && (
            <>
              <button
                className="overview-header-button"
                onClick={() => setConfirmSaveForecastOpen(true)}
              >
                Save Forecast
              </button>
              <button
                className="overview-header-button"
                onClick={handleCompare}
              >
                Side by side compare
              </button>
              <button
                onClick={() => setShowForecasting(true)}
                className="overview-header-button"
              >
                Edit forecast
              </button>
              <button
                onClick={() => setConfirmDisableForecastOpen(true)}
                className="overview-header-button"
                id="background-red"
                style={{ background: "var(--red)" }}
              >
                Exit Forecasting
              </button>
            </>
          )}
        </div>
        <Forecasting
          open={showForecasting}
          onClose={() => setShowForecasting(false)}
        />
        {showEditMyData && (
          <EditMyData
            open={showEditMyData}
            onClose={() => setShowEditMyData(false)}
            isMobile={isMobile}
          />
        )}
        <ConfirmAlert
          open={confirmSaveForecastOpen}
          handleClose={() => setConfirmSaveForecastOpen(false)}
          dialogContent={`Are you sure you want to save your forecast and override your current data?`}
          dialogTitle="Save Forecast"
          actionTitle="Save"
          onAction={handleSaveForecast}
        />
        <ConfirmAlert
          open={confirmDisableForecastOpen}
          handleClose={() => setConfirmDisableForecastOpen(false)}
          dialogContent={`Are you sure you want to disable your forecast?`}
          dialogTitle="Disable Forecast"
          actionTitle="Disable"
          onAction={handleDisableForecast}
        />
        {loadingCompare && <PageLoader title="Loading Side By Side Compare" />}
      </div>
    </>
  );
};
