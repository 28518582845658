export enum AlertTypes {
    Error = "Error",
    Info = "Info",
    Success = "Success",
    Warning = "Warning"
}

export interface AlertValues {
    text: string,
    type: AlertTypes
}

export interface AlertState {
    alert?: AlertValues
}