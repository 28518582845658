import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ROUTE, getBackendUrlForPath } from "../helpers";
import { postCompletedSurvey } from "../api/SurveyAPI";

export const verifyAuthStatus = createAsyncThunk(
  "profile/getUser",
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(getBackendUrlForPath(API_ROUTE.GET_PROFILE_DATA), {withCredentials: true})
        return response.data
      } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
          return rejectWithValue(err.response ? err.response.data : err.message);
        } else {
          return rejectWithValue('An unexpected error occurred');
        }
      }
    }
);

export const completedSurvey = createAsyncThunk(
  "auth/completedSurvey",
    async (_, { rejectWithValue, dispatch}) => {
      try {
        const response = await postCompletedSurvey();
        dispatch(verifyAuthStatus())
        return response
      } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
          return rejectWithValue(err.response ? err.response.data : err.message);
        } else {
          return rejectWithValue('An unexpected error occurred');
        }
      }
    }
);