import { ForecastingFormData, ForecastingOption, ForecastingOptionType } from '../../../../../interfaces'
import { setForecastFormData } from '../../../../../store/forecast/forecastSlice'
import './ForecastingForm.css'
import { ForecastingPurchasedServiceForm } from './service-credit/ForecastingPurchasedServiceForm'
import { DefaultForm } from './DefaultForm'
import { useDispatch } from 'react-redux'

type Props = {
    data: ForecastingFormData,
    selectedForecast: ForecastingOption
}

export const ForecastingForm = ({ data, selectedForecast }: Props) => {
  const dispatch = useDispatch()
    const handleSaveChanges = () => {
        dispatch(setForecastFormData({
            ...data,
            [selectedForecast.dataKey]: {
                ...data[selectedForecast.dataKey],
                savedValue: data[selectedForecast.dataKey].textfieldValue
            }
        }))
    }

    const handleRevertChanges = () => {
        dispatch(setForecastFormData({
            ...data,
            [selectedForecast.dataKey]: {
                ...data[selectedForecast.dataKey],
                textfieldValue: null,
                savedValue: null
            }
        }))
    }

    const getForm = () => {
        const { option, dataKey, label, preFieldDecorator } = selectedForecast;
        switch (option) {
            case ForecastingOptionType.PurchasedService:
                return <ForecastingPurchasedServiceForm />
            default:
                return <DefaultForm
                            field={dataKey}
                            label={label}
                            adornment={preFieldDecorator}
                        />
        }
    }

    return (
        <div className="forecasting-form-container">
            {
                selectedForecast === null &&
                <em>
                    <p className="forecasting-no-option-selected">Please Select An Option</p>
                </em>
            }
            {
                selectedForecast !== null &&
                <div>
                    { data && getForm() }
                    { data && <div className="forecasting-sub-button-container">
                        {
                            data[selectedForecast?.dataKey]?.textfieldValue !== data[selectedForecast?.dataKey]?.profileValue &&
                            <button 
                                className="forecasting-sub-button"
                                onClick={handleSaveChanges}
                            >
                                Save Changes
                            </button>
                        }
                        {
                            data[selectedForecast?.dataKey]?.savedValue !== null &&
                            <button 
                                className="forecasting-sub-button forecasting-sub-button-outlined"
                                onClick={handleRevertChanges}
                            >
                                Revert Changes
                            </button>
                        }
                    </div>}
                </div>
            }
        </div>
    )
}