import { useEffect, useState } from 'react'
import { AdminTableError } from '../TableError/AdminTableError'
import { DataTableHeader } from '../header/DataTableHeader'
import { PaginationControls } from '../PaginationControls/PaginationControls'
import { DataGrid } from '@mui/x-data-grid';

export interface AddedUsers {
  interval: string,
  count: string,
}

type Props = {
  data: AddedUsers[],
  interval: string,
}

export const SuperAdminDataTable = ({ data, interval = 'Weekly' }: Props) => {
  const rowCount = 50
  const [rows, setRows] = useState([])
  const [paginationStartIndex, setPaginationStartIndex] = useState(0)

  const columns = [
    { field: 'interval', headerName: 'Interval', flex: 0.5 },
    { field: 'numUsers', headerName: `${interval} users`, flex: 1.5 },
    { field: 'count', headerName: 'Count', flex: 1.0 },
  ]

  useEffect(() => {
    let runningCount = 0;
      setRows(data.map((val) => {
      runningCount += +val.count;
        return {
          interval: val.interval,
          numUsers: runningCount,
          count: val.count,
        }
      }).reverse())
      setPaginationStartIndex(0)
  }, [data])

  const handlePaginationChange = (index: number) => {
      setPaginationStartIndex(index * rowCount)
  }

  if (rows.length === 0) {
      return (
          <div style={{ marginTop: '30px'}}>
              <DataTableHeader />
              <AdminTableError />
          </div>
      )
  }

  return (
    <div>
    <DataGrid
      getRowId={(row) => row.interval}
      autoHeight
      pageSize={100}
      rowsPerPageOptions={[50, 100, 200]}
      rows={rows}
      columns={columns}
      pagination
    />
    <PaginationControls
      count={Math.ceil(rows.length / rowCount)}
      handlePaginationChange={handlePaginationChange}
      pageIndex={paginationStartIndex / rowCount}
    />
    </div>
  )
}