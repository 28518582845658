import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, RouteComponentProps } from "react-router-dom";
import { Box } from "@mui/material";

import { useWindowSize } from "../../../hooks";
import { selectLoggedInStatus, validateUserEmail } from "../../../store/auth/authSlice";
import { OverviewHeader } from "./header/OverviewHeader";
import { UserPageRoute } from "../UserPage";
import { ResponsiveAppBar } from "../../home/homeLandingPage";
import Loader, { LoaderType } from "./loader/Loader"
import { useAppDispatch } from "../../../store/hooks";

type ValidateParams = {
  userId: string;
  token: string;
};

const EmailValidation: React.FC<RouteComponentProps<ValidateParams>> = () => {
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const { width } = useWindowSize();
  const isMobile = width < 900;
  const { userId, token } = useParams() as ValidateParams;
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(undefined);
  const dispatch = useAppDispatch();

  const validate = async () => {
    const resultAction = await dispatch(validateUserEmail({ userId, token }));
    setSuccess(resultAction.payload.success);
    setIsLoading(false);
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      {isLoggedIn && isMobile && (
        <OverviewHeader activePage={UserPageRoute.Settings} />
      )}
      {!isLoggedIn && <ResponsiveAppBar />}
      <Box
        sx={{
          backgroundColor: "var(--dashboard-light-purple)",
          padding: "var(--dashboard-widget-padding)",
          borderRadius: "var(--dashboard-border-radius)",
          textAlign: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {isLoading && <Loader text="Validating token..." type={LoaderType.Ring} size={25} />}
        {!isLoading && <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          {success ? (
            <>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                Success! Your email has been verified.
              </Box>
              <Box
                sx={{
                  fontWeight: "light",
                }}
              >
                <Link to="/login">Login</Link> to access your user features.
              </Box>
            </>
          ) : (
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              The token has already been used or does not exist.
            </Box>
          )}
        </Box>}
      </Box>
    </>
  );
};

export default EmailValidation;
