import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectHasTrialed } from "../../../store/auth/authSlice";

const SubscriptionAlert = () => {
  const hasTrialed = useSelector(selectHasTrialed);
  return (
    <Alert severity="warning" title={"Warning"}>
      <Link to="/subscribe">{hasTrialed ? "Subscribe now" : "Start your free trial subscription"}</Link>{" "}
      to see pension details and customized recommendations.
    </Alert>
  );
};

export default SubscriptionAlert;
