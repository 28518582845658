import { Field } from 'react-final-form';
import { SurveyQuestion } from '../../../interfaces';
import './Formula.css'

type Props = {
    question: SurveyQuestion,
}

export const Formula = ({ question }: Props) => {
    return (
        <Field
        component="input"
        name={question.variable.name}
        type="number"
        className="number-input-edit" 
        initialValue={question.prefill}
        readOnly={ question.locked }
        question={question}
         >
            {({input: {onChange, value}, question, ...rest}) => (
                <>
                <div>
                { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol-edit">#</span> }
                { question?.preFieldDecorator && <span className="number-symbol-edit number-symbol-pre-edit">{ question.preFieldDecorator.flat()[0] }</span> }
                <input
                    type={question.answerType[0]}
                    value={value[0] || 0}
                    onChange={e => onChange(value ? [e.target.value, value[1]] : [e.target.value, 0])}
                    {...rest}
                />
                { question.postFieldDecorator && <span className="number-symbol-edit number-symbol-post">{ question.postFieldDecorator.flat()[0] }</span> }
                </div>
                <div>
                { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol-edit">#</span> }
                { question?.preFieldDecorator && <span className="number-symbol-edit number-symbol-pre-edit">{ question.preFieldDecorator.flat()[1]}</span> }                    
                <input
                    type={question.answerType[1]}
                    value={value[1] || 0}
                    onChange={e => onChange(value ? [value[0], e.target.value] : [ 0, e.target.value])}
                    {...rest}
                />
                { question.postFieldDecorator && <span className="number-symbol-edit number-symbol-post">{ question.postFieldDecorator.flat()[1] }</span> }
                </div>
                </>
                )}
        </Field>
    )
}