import { PlanBenefit } from "../survey/SurveyAPITypes";

export enum UserStatus {
    Inactive = 0,
    Active = 1, 
    Pending = 2
}

export enum UserRole {
    User = "user",
    Super = "super"
}

export interface userVariable {
    id: number,
    value: string,
    variable: {
        [key: string]: any,
    }
}

export interface User {
    first_name: string,
    last_name: string,
    email: string,
    role: UserRole,
    inherited?: boolean,
    created_at: string,
    latest_terms_version?: number,
    status: UserStatus,
    id: number,
    isSubscribed: boolean,
    completedSurvey: boolean,
    acknowledgeTerms: boolean,
    completedDashboardIntro: boolean,
    organizations: NewOrganization,
    preSurveyProgress?: PreSurveyProgress,
    isTrialSubscription?: boolean,
    plan_benefit?: PlanBenefit,
    userVariables?: userVariable[],
    metadata?: {
      [key: string]: any,
    },
}

export interface PreSurveyProgress {
    hireDate?: Date,
    planBenefit?: number,
    rsbId?: number,
}

export enum BenefitFactorType {
    'age',
    'service',
}

export interface NewOrganization {
  id: number,
  display_name: string,
  unique_name: string,
  state_name: string,
  state_fips: string,
  org_type: string,
  lea_code: string | null,
  county_fips: string | null,
  active_users?: number,
  orgDetails?: {
    id: number,
    name: string,
    type: string,
    hireStart?: Date,
    hireEnd?: Date,
    value: string
  }[],
  plan_specific_benefits?: PlanBenefit[],
  users: User[]
}

export interface BasicOrganization {
    organization_id: number,
    coverageType_name: string,
    organization_name: string,
}