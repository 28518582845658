import { AutoCompleteEdit } from "../components/survey/autocomplete/AutoCompleteEdit"
import { CheckBox } from "../components/survey/checkbox/checkbox/CheckBoxEdit"
import { NewDatePicker } from "../components/survey/date/DatePickerEdit"
import { Description } from "../components/survey/description/Description"
import { Formula } from "../components/survey/formula/FormulaEdit"
import { AllowsMultipleEdit } from "../components/survey/multiple/AllowsMultipleEdit"
import { Number } from "../components/survey/number/NumberEdit"
import { WriteIn } from "../components/survey/write/WriteInEdit"
import { SurveyAnswerOption, SurveyQuestion, SurveyQuestionAnswerType } from "../interfaces"

export const getQuestionComponent = (question: SurveyQuestion, form: any) => {
    if (question.answerType.length > 1) {
        return <Formula question={question} />
    }
    if ((question.allowMultipleAnswers || question.answerType[0].length > 1)) {
        return <AllowsMultipleEdit question={question} form={form} />
    }

    return getComponentForAnswerType(question, question.answerType[0][0], undefined, undefined, question.answerOptions, undefined)
}

// todo: add more validation for social security and medicare taxes
export const getComponentForAnswerType = (
  question: SurveyQuestion,
  answerType: SurveyQuestionAnswerType, 
  handleValueChanged?: (val: any, inputIndex: number | undefined) => void,
  index?: number,
  answerOptions?: SurveyAnswerOption[],
  value?: any,
  ) => {
    switch (answerType) {
        // KEYS ARE JUST RANDOM ALGORITHM
        case SurveyQuestionAnswerType.Free:
          if (handleValueChanged) {
            return <Description handleValueChanged={ handleValueChanged } index={ index } value={ value } key={answerType.toString() + (index ? index.toString() : "0")}/>
          }
          return <WriteIn question={question} />
        case SurveyQuestionAnswerType.Date:
          return <NewDatePicker question={question}/>
        case SurveyQuestionAnswerType.Select:
          if (answerOptions && answerOptions.length < 6) {
            return <CheckBox question={question} />
          } else {
            return <AutoCompleteEdit question={question} />
          }
        case SurveyQuestionAnswerType.YesNo:
          return <CheckBox question={question} />
        case SurveyQuestionAnswerType.Number:
          return <Number question={question} />
        default:
          return <div></div>
    }
}