import { UserRole, UserStatus, NewOrganization } from "../user/User";

export interface UserFormValues {
    email: string,
    firstName: string,
    lastName: string,
    role: UserRole,
    status?: UserStatus,
    completedSurvey: boolean,
    organization?: NewOrganization,
    deletedVars: any[],
}

export enum UserFormStatus {
    InvalidEmail = "Email is invalid",
    InvalidFirstName = "First name is invalid",
    InvalidLastName = "Last name is invalid",
    NewUserRequestError = "There was an error creating a new user. Please try again.",
    EditUserRequestError = "There was an error updating the user. Please try again."
}