import {
  createSlice,
  Reducer,
} from "@reduxjs/toolkit";
import type { RootState } from "../storeTypes";
import {
  ProfileData,
  MonthlyData,
  ServiceStatusValues,
  TimePeriod,
  PensionDataTypes,
} from "../../interfaces";
import { verifyAuthStatus, completedSurvey } from "../thunks";

interface ProfileSlice {
  profileData: ProfileData | null;
  status: ServiceStatusValues;
  widgetData: {
    current_tax_state: string | null;
    timePeriod: TimePeriod;
    grossNet: PensionDataTypes;
    workingDate: string;
    numbersData: MonthlyData | null;
    sickLeaveData: {
      percentageGain: 0;
      monthlyIncrease: 0;
    };
  };
  error: string | null;
}

// Define the initial state using that type
const initialState: ProfileSlice = {
  profileData: null,
  status: ServiceStatusValues.Loading,
  widgetData: {
    current_tax_state: null,
    timePeriod: TimePeriod.Annual,
    grossNet: PensionDataTypes.Gross,
    workingDate: new Date().toISOString(),
    numbersData: null,
    sickLeaveData: {
      percentageGain: 0,
      monthlyIncrease: 0,
    },
  },
  error: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfile(state, action) {
      state.status = ServiceStatusValues.Loaded;
      state.profileData = action.payload;
    },
    editReciprocity(state, action) {},
    updateSickLeave(state, action) {
      state.widgetData.sickLeaveData = action.payload;
    },
    setStateTax(state, action) {
      state.widgetData.current_tax_state = action.payload;
    },
    setWorkingDate(state, action) {
      state.widgetData.workingDate = action.payload;
      state.widgetData.numbersData = state.profileData.monthly_data[action.payload];
    },
    toggleTimePeriod(state) {
      let newPeriod;
      switch(state.widgetData.timePeriod) {
        case (TimePeriod.Annual):
          newPeriod = TimePeriod.Monthly
          break;
        case (TimePeriod.Monthly):
          newPeriod = TimePeriod.BiWeekly
          break;
        default:
          newPeriod = TimePeriod.Annual;
      }
      state.widgetData.timePeriod = newPeriod;
    },
    toggleGrossNet(state) {
      let amountType;
      switch(state.widgetData.grossNet) {
        case (PensionDataTypes.Gross):
          amountType = PensionDataTypes.Net
          break;
        default:
          amountType = PensionDataTypes.Gross;
      }
      state.widgetData.grossNet = amountType;
    }
  },
  extraReducers: (builder) => {
    // subscribe to the Redux asyncThunk action types
    builder.addCase(verifyAuthStatus.pending, (state, action) => {
      state.status = ServiceStatusValues.Loading;
    });
    builder.addCase(verifyAuthStatus.fulfilled, (state, action) => {
      const calculation = action.payload.calculation?.pension;
      const retirementDate = calculation?.retirementKey || new Date().toString()
      state.status = ServiceStatusValues.Loaded;
      state.profileData = calculation;
      state.widgetData.workingDate = retirementDate;
      state.widgetData.numbersData = calculation?.monthly_data[retirementDate]
      state.widgetData.current_tax_state = calculation?.retireLocation || "California"
    });
    builder.addCase(verifyAuthStatus.rejected, (state, action) => {
      state.status = ServiceStatusValues.Loaded;
      state.profileData = null;
    });
    builder.addCase(completedSurvey.pending, (state, action) => {
      state.status = ServiceStatusValues.Loading;
    });
  },
});

export const { updateProfile, updateSickLeave, editReciprocity, setWorkingDate, setStateTax, toggleTimePeriod, toggleGrossNet } =
  profileSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectRetirementDate = (state: RootState) =>
  new Date(state.profile.profileData.desired_retirement_date);

export const selectProfileData = (state: RootState) =>
  state.profile.profileData;

export const selectRetirementSystemId = (state: RootState) =>
  state.profile.profileData?.retirementBenefit?.retirementSystemId;

export const selectTaxWithholdings = (state: RootState) =>
  state.profile.profileData?.tax_withholdings_percentage|| 0;

export const selectUserStatus = (state: RootState) => state.profile.status;

export const selectWorkingDate = (state: RootState) =>
  new Date(state.profile.widgetData.workingDate);

export const selectSickLeaveMonthlyIncrease = (state: RootState) =>
  state.profile.widgetData.sickLeaveData;

export const selectCurrentTaxState = (state: RootState) =>
  state.profile.widgetData.current_tax_state;

export const selectSickLeaveCredit = (state: RootState) =>
  state.profile.profileData.sick_leave_credit;

export const selectTimePeriod = (state: RootState) =>
  state.profile.widgetData.timePeriod;

export const selectPensionType = (state: RootState) =>
  state.profile.widgetData.grossNet;

export const selectMonthlyKeys = (state: RootState) =>
  state.profile.profileData?.monthly_data_keys || [];

export const selectNumbersData = (state: RootState) =>
  state.profile.widgetData.numbersData;

export default profileSlice.reducer as Reducer<typeof initialState>;
