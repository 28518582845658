import { InputAdornment, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { ForecastingFormData } from "../../../../../interfaces"
import { setForecastFormData, selectForecastFormData } from "../../../../../store/forecast/forecastSlice"

type Props = {
    field: keyof ForecastingFormData,
    label: string,
    adornment: string,
}

export const DefaultForm = ({ field, label, adornment }: Props) => {
    const data = useSelector(selectForecastFormData)
    const dispatch = useDispatch()
    const showAdornment = adornment
        ? {
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
          }
        : {};

    const setData = (data: ForecastingFormData) => {
        dispatch(setForecastFormData(data))
    }

    if (data === null || data === undefined) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <TextField
            fullWidth
            type="number"
            value={(data[field as keyof ForecastingFormData].textfieldValue !== null) ?
                data[field as keyof ForecastingFormData].textfieldValue :
                data[field as keyof ForecastingFormData].profileValue
            }
            onChange={ e => setData({
                ...data,
                [field]: { ...data[field], textfieldValue : parseInt(e.target.value) }
            })}
            label={label}
            InputProps={showAdornment}
            variant="outlined"
        />
    )
}