import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button } from '@mui/material/'
import { useState } from 'react'
import { deactivateInvitation } from '../../../../api/OrganizationAPI'
import { OrganizationInvitation } from '../../../../interfaces'
import { ConfirmAlert } from '../../../alerts/confirm-alert/ConfirmAlert'

type Props = {
    orgInvite: OrganizationInvitation,
    refreshInvites: () => void
}

export const SignUpLinkRow = ({ orgInvite, refreshInvites }: Props) => {
  const [open, setOpen] = useState(false)

  const handleDectivateInvitation = async () => {
    await deactivateInvitation()
    refreshInvites()
    setOpen(false)
  }

  return (
    <Box sx={{ marginTop: '3rem' }}>
      <div className="flex-space-between">
          <Button
            sx={{ color: 'red', borderColor: 'red' }}
            variant='outlined'
            onClick={ () => setOpen(true) }
          >
            Deactivate
            <DeleteIcon />
          </Button>
      </div>
      <ConfirmAlert
          open={open}
          handleClose={ () => setOpen(false) }
          onAction={ handleDectivateInvitation }
          actionTitle="Deactivate"
          dialogTitle="Deactivate Key?"
          dialogContent="Are you sure you want to deactivate this key?"
      />
    </Box>
  )
}