export const defaultResetPasswordData: ResetPasswordData = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
}
export interface ResetPasswordData {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

export interface ResetPasswordFormData {
    newPassword: string,
    confirmPassword: string
}