import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import IosShareIcon from '@mui/icons-material/IosShare'
import Logo from '../../assets/images/logo512.png'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  open: boolean;
  onClose: () => void;
}

export const MobileAddToHomeScreen = ({ open, onClose } : Props) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{textAlign: "center"}}
    >
      <DialogTitle>
        Install Advanced Pension
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      Want to add Advanced Pension to your home screen?
      <p>
      <img
        alt="Pension Logo"
        style={{width: "2rem", height: "2rem"}}
        src={Logo}
      />
      </p>
      Just tap
        <IosShareIcon
          sx={{margin: "0 0.25rem", verticalAlign: "bottom"}}
        />
      then "Add to home screen"
      </DialogContent>
    </Dialog>
  )
}