import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { SuperAdminApi } from '../../../api/SuperAdminAPI'
import { AlertTypes, UserFormValues, User } from '../../../interfaces'
import { setAlert } from '../../../store/alert/alertSlice'
import { UserForm } from '../shared/UserForm/UserForm'
import { SuperAdminHeader } from '../header/SuperAdminHeader'
import { useDispatch } from 'react-redux'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SuperAdminEdit = () => {
    const query = useQuery()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState<User>(null)
    const dispatch = useDispatch()

    const fetchUser = async () => {
        try {
            let id = parseInt(query.get("id"))
            if (!isNaN(id)) {
                let user = await SuperAdminApi.getUserById(id)
                setUser(user)
            }
            setIsLoading(false)
        } catch {
            dispatch(setAlert({
              text: "Could not get the user with that ID. Please try again.",
              type: AlertTypes.Error
            }))
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])

    const handleUpdateUser = async (values: UserFormValues) => {
      if (!user) { return }

      setUser(null)
      setIsLoading(true)
      try {
        await SuperAdminApi.editUser(user.id, values)
        dispatch(setAlert({
          text: "User was successfully updated.",
          type: AlertTypes.Success
        }))
        window.location.reload()
      } catch {
        setIsLoading(false)
        dispatch(setAlert({
          text: "Could not update user. Please try again.",
          type: AlertTypes.Error
        }))
      }
    }

    if (!user && !isLoading) {
        return (
            <div className="super-admin-page-background">
                <div className="super-admin-page-container">
                    <SuperAdminHeader />
                    <h1 className="org-edit-user-error-text">User Not Found</h1>
                    <button className="org-edit-back-button" onClick={e => history.push("/superadmin")}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="super-admin-page-background">
            <div className="super-admin-page-container">
                <SuperAdminHeader />
                <UserForm 
                    isLoading={ isLoading }
                    formTitle="Edit User" 
                    formActionButtonTitle="Update User" 
                    formAction={handleUpdateUser} 
                    user={ user || undefined}
                />
            </div>
        </div>
    )
}