import { Slider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import {getStore} from '../../../store';
import { updateResult } from '../../../store/survey/surveySlice';
import { selectCurrentQuestion, selectCurrentQuestionResult, selectFederalTaxes, selectGrossPay, selectNetPay } from '../../../store/survey/surveySlice';
import { getNumberValue } from '../number/Number';
import '../number/Number.css';

type Props = {
    index?: number
}

function valuetext(value: number) {
    return `$${value.toFixed(0)}`;
}

export const NumberTax = ({ index = 0 }: Props) => {    
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const [minValue, setMinValue] = useState<number>(0)
    const [maxValue, setMaxValue] = useState<number>(0)

    useEffect(() => {
        let grossPay = selectGrossPay(getStore().getState())
        let netPay = selectNetPay(getStore().getState())
        setMaxValue(grossPay - netPay)

        // for state taxes also subtract out federal tax
        if (question.text.toLowerCase().includes("state taxes")) {
            let federalTax = selectFederalTaxes(getStore().getState())
            setMaxValue(grossPay - netPay - federalTax)
        }

        setMinValue(0)
        // eslint-disable-next-line
    }, [question])

    useEffect(() => {
        if (result && result.answer) {
            handleInputChange(result.answer.toString())
        }
        // eslint-disable-next-line
    }, [maxValue])

    const handleAnswerChange = (val: number | string, isValid: boolean) => {
        getStore().dispatch(updateResult({
            answer: val,
            isValid: isValid
        }))
    }

    const handleInputChange = (val: string) => {
        let num = parseFloat(val)
        let valToSave = isNaN(num) ? "" : num
        handleAnswerChange(valToSave, 
            !isNaN(num) && 
            (minValue === null || (num >= minValue)) &&
            (maxValue === null || (num <= maxValue))
        )
    }

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        handleInputChange(newValue.toString());
    };

    const showMaxWarning = () => {
        return maxValue !== null && result && (getNumberValue(result.answer) > maxValue) && maxValue !== 0
    }

    const showMinWarning = () => {
        return minValue !== null && result && (getNumberValue(result.answer) < minValue)
    }

    return (
        <div className="number-container">
            <div className="number-input-container">
                { question.preFieldDecorator && <span className="number-symbol number-symbol-pre">{ question.preFieldDecorator.flat()[index] }</span> }
                { question.postFieldDecorator && <span className="number-symbol number-symbol-post">{ question.postFieldDecorator.flat()[index] }</span> }
                { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol">#</span> }
                <input 
                    type="number"
                    className="number-input" 
                    value={ (result ? result.answer : "") }
                    onChange={ e => handleInputChange(e.target.value) } 
                    readOnly={ question.locked }
                />
            </div>
            <Slider
                value={result ? result.answer : 0}
                className="number-input-slider"
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                onChange={handleSliderChange}
                marks
                min={0}
                max={maxValue}
            />
            {
                showMaxWarning() &&
                <p><em>You must enter a number lower than {maxValue}</em></p>
            }
            {
                showMinWarning() &&
                <p><em>You must enter a number higher than {minValue}</em></p>
            }
        </div>
    )
}