import axios from "axios"
import { BENEFIT_QUESTION_ID, PreSurveyData } from "../data/PreSurveyData"
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"
import { AlertTypes, SurveyAPIData, SurveyQuestion, PreSurveyProgress } from "../interfaces"
import {getStore} from "../store"
import { setAlert } from "../store/alert/alertSlice"
import { completedSurvey } from "../store/thunks"
import { updateSurvey, setSurveyLoadingAction } from "../store/survey/surveySlice"
import { setBenefitFormulas } from "../helpers/PreSurveyHelpers"

export const getSurvey = async (preSurveyProgress: PreSurveyProgress, startFromBeginning: boolean) => {
    try {
        const config = { withCredentials: true }
        const response = await axios.get(getBackendUrlForPath(API_ROUTE.GET_SURVEY), config)
        const mainSurvey = { ...response.data.finalSurvey } as SurveyAPIData

        mainSurvey.questions = PreSurveyData.questions.concat(mainSurvey.questions)

        const benefitIndex = mainSurvey.questions.findIndex(s => s.id === BENEFIT_QUESTION_ID);
        mainSurvey.questions[benefitIndex].answerOptions = setBenefitFormulas(response.data.configurations);

        // store in redux
        getStore().dispatch(updateSurvey({ mainSurvey, preSurveyProgress }))
        // return {mainSurvey, preSurveyProgress}
    } catch (error) {
        getStore().dispatch(setAlert(
          {text: "Could not load survey due to connection error. Please try refreshing the page.", 
          type: AlertTypes.Error
        }))
    }
}

export const getAllQuestions = async () => {
    try {
        const config = { withCredentials: true }
        const response = await axios.get(getBackendUrlForPath(API_ROUTE.GET_ALL_QUESTIONS), config)

        const mainSurvey = { ...response.data }

        return mainSurvey
    } catch (error) {
        getStore().dispatch(setAlert({
          text: "Could not load form due to connection error. Please try refreshing the page.",
          type: AlertTypes.Error
        }))
    }
}

export const handleFinalQuestion = async (dispatch: any, history: any, isSubscribed: boolean) => {
    try {
        dispatch(completedSurvey())
        if (isSubscribed) {
          history.push("overview")
        } else {
          history.push("/subscribe")
        }
    } catch {
        dispatch(setAlert({
          text: "Could not complete survey. Please try again",
          type: AlertTypes.Error
        }))
    }
}

export const postAnswer = async (questionId: number, answer: any, question?: SurveyQuestion, setLoading: boolean = true) => {
    if (setLoading) { getStore().dispatch(setSurveyLoadingAction(true)) }
    const body = {
        questionId: questionId,
        answer: answer
    }

    try {
        let response = await axios.post(getBackendUrlForPath(API_ROUTE.SET_SURVEY_ANSWER), body, apiDefaultOptions)
        if (setLoading) { getStore().dispatch(setSurveyLoadingAction(false)) }
        return response
    } catch (e) {
        if (setLoading) { getStore().dispatch(setSurveyLoadingAction(false)) }
        throw new Error(e)
    }
}

export const postAnswers = async (payload : {[key: string]: any}) => {
    const body = payload

    try {
        let response = await axios.post(getBackendUrlForPath(API_ROUTE.SET_VARIABLES), body, apiDefaultOptions)
        return response
    } catch (e) {
        throw new Error(e)
    }
}

export const postNullAnswers = async (questionIds: number[]) => {
    for (const id of questionIds) {
        // don't set loading as can't call dispatch from reducer and this is being called on next quesiton in the suvey reducer
        await postAnswer(id, null, undefined, false)
    }
}

export const postCompletedSurvey = async () => {
    try {
        let response = await axios.post(getBackendUrlForPath(API_ROUTE.COMPLETE_SURVEY), {}, apiDefaultOptions)
        return response.data
    } catch {
        throw new Error("Could not complete the survey. Please try again.")
    }
}