import { User } from "../user/User";

export enum AuthErrors {
    InvalidFirstName = "Please enter your first name.",
    InvalidTerms = "You must read and accept the terms of conditions in order to proceed.",
    InvalidLastName = "Please enter your last name.",
    InvalidPassword = "Please enter your password.",
    InvalidConfirmPassword = "Please confirm your password.",
    PasswordMatching = "Your passwords do not match.",
    ConsumeError = "There was an error processing your data. Please try again.",
    InvalidEmail = "Please enter your email.",
    InvalidOrganization = "Please select a valid organization.",
    LoginError = "There was an error logging in due to invalid username/password. Please try again.",
    ResetError = "There was an error in resetting your password. It is likely that we don't have an account under this email. Please try again."
}

interface AuthStateLoggedOut {
    isLoggedIn: false
}

interface AuthStateLoggedIn {
    isLoggedIn: true,
    currentUser: User
}

export type AuthState = 
    | AuthStateLoggedIn
    | AuthStateLoggedOut