import Box from "@mui/material/Box";
import dayjs from "dayjs";

const SubscribedUser = ({
  ongoingSub,
  expiresAt,
  isTrialing,
}: {
  ongoingSub: boolean;
  expiresAt: Date;
  isTrialing: boolean;
}) => (
  <>
    {ongoingSub ? (
      <Box
        sx={{
          fontWeight: "bolder",
        }}
      >
        <p>Congratulations! 🎉</p>
        <p>You are subscribed.</p>
        <Box>
          {`Your ${isTrialing ? "trial" : ""} subscription `}
          <Box
            sx={{
              display: "inline-block",
              color: "var(--dashboard-blue)",
            }}
          >
            renews on {dayjs(expiresAt).format("MMMM D, YYYY")}
          </Box>
          .
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          fontWeight: "bolder",
        }}
      >
        <p>We're sorry to see you go.</p>
        <Box>
          Your subscription{" "}
          <Box
            sx={{
              display: "inline-block",
              color: "var(--red)",
            }}
          >
            ends on {dayjs(expiresAt).format("MMMM D, YYYY")}
          </Box>
          .
        </Box>
      </Box>
    )}
  </>
);

export default SubscribedUser;
