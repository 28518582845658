import React from "react";
import { Redirect } from "react-router-dom";
import { InvitePage } from "../components/auth/register/InvitePage";
import { ResetPage } from "../components/auth/reset/ResetPage";
import { NotFound } from "../components/errors/NotFound";
import { SuperAdminApprove } from "../components/super-admin/approve/SuperAdminApprove";
import { SuperAdminOnboard } from "../components/super-admin/onboard/SuperAdminOnboard";
import { SuperAdminOrgEdit } from "../components/super-admin/org-edit/SuperAdminOrgEdit";
import { SuperAdmin } from "../components/super-admin/SuperAdmin";
import { Survey } from "../components/survey/Survey";
import { UserPage, UserPageRoute } from "../components/user/UserPage";
import { User, UserRole, UserStatus } from "../interfaces";

export enum RoutePages {
    Admin,
    AdminNew,
    AdminEdit,
    Survey,
    Overview,
    SuperAdminApprove,
    SuperAdminOnboard,
    SuperAdminOrgEdit,
}

export const getSuperAdminRouteComponent = (page: RoutePages, loggedInUser?: User) => {
    if (!loggedInUser) {
        return <Redirect to="/login" />
    } else if (loggedInUser.role !== UserRole.Super || loggedInUser.status !== UserStatus.Active) {
        return <NotFound errorMessage="No access to this page." />
    } else {
        switch (page) {
            case RoutePages.SuperAdminApprove:
                return <SuperAdminApprove />
            case RoutePages.SuperAdminOnboard:
                return <SuperAdminOnboard />
            case RoutePages.SuperAdminOrgEdit:
                return <SuperAdminOrgEdit />
            default:
                return <SuperAdmin />
        }
    }
}

export const getSurveyRouteComponent = (loggedInUser?: User) => {
    if (!loggedInUser) {
        return (<Redirect to="/login" />)
    } else if (loggedInUser.status === UserStatus.Active && loggedInUser.role === UserRole.User && (!loggedInUser.completedSurvey)) {
        return <Survey preSurveyProgress={loggedInUser.preSurveyProgress} />
    } else if (loggedInUser.status === UserStatus.Active && loggedInUser.role === UserRole.User && loggedInUser.completedSurvey) {
        return (<Redirect to="/overview" />)
    } else {
        return (<NotFound />)
    }
}

export const getRestartSurveyRouteComponent = (loggedInUser?: User) => {
    if (!loggedInUser) {
        return (<Redirect to="/login" />)
    } else if (loggedInUser.status === UserStatus.Active && loggedInUser.role === UserRole.User && (loggedInUser.completedSurvey)) {
        return <Survey preSurveyProgress={loggedInUser.preSurveyProgress} startFromBeginning />
    } else if (loggedInUser.status === UserStatus.Active && loggedInUser.role === UserRole.User && !loggedInUser.completedSurvey) {
        return (<Redirect to="/survey" />)
    } else {
        return (<NotFound />)
    }
}

export const getUserPage = (userPageRoute: UserPageRoute, loggedInUser?: User) => {
    if (!loggedInUser) {
        return (<Redirect to="/login" />)
    } else if (loggedInUser.role === UserRole.User && !loggedInUser.completedSurvey) {
        return (<Redirect to="/survey" />)
    } else if (loggedInUser.role === UserRole.User && loggedInUser.completedSurvey) {
        return (
            <React.Fragment>
                <UserPage page={userPageRoute} />
            </React.Fragment>
        )
    } else {
        return (<NotFound />)
    }
}

export const getInviteRouteComponent = (loggedInUser?: User) => {
    if (!loggedInUser) {
        return <InvitePage />
    } else {
        return (<NotFound errorMessage="Error. Can't access invite as you are already logged in as a user. Please log out first." />)
    }
}

export const getOrgInviteRouteComponent = (loggedInUser?: User) => {
    if (!loggedInUser) {
        return <InvitePage isOrgInvite={true} />
    } else {
        return (<NotFound errorMessage="Error. Can't access invite as you are already logged in as a user. Please log out first." />)
    }
}

export const getResetRouteComponent = (loggedInUser?: User) => {
    if (!loggedInUser) {
        return <ResetPage />
    } else {
        return (<NotFound errorMessage="Error. Can't access invite as you are already logged in as a user. Please log out first." />)
    }
}