export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister().then((boolean) => {
        console.log('Service Worker unregistered:', boolean);
      });
    }).catch((error) => {
      console.error('Service Worker unregistration failed:', error);
    });

    // Optionally clear caches manually in case the service worker misses any
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName).then((success) => {
            if (success) {
              console.log(`Cache '${cacheName}' deleted.`);
            }
          });
        });
      }).catch((error) => {
        console.error('Cache deletion failed:', error);
      });
    }
  }
}

// Optionally, call unregister() on window load to ensure it's executed
window.addEventListener('load', () => {
  unregister();
});
