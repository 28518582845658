import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";

import { validateLoginForm } from '../../../../helpers/FormHelpers'
import { AuthErrors, LoginFormData } from '../../../../interfaces'
import { AuthSubmit } from '../../shared/submit/AuthSubmit'
import { authenticationRequest } from "../../../../store/auth/authSlice";
import { verifyAuthStatus } from '../../../../store/thunks';
import { AuthTextfield, AuthTextfieldTypes } from '../../shared/textfield/AuthTextfield'
import { checkMobileIosBrowser } from '../../../../utils/mobileConfigurations'
import { MobileAddToHomeScreen } from '../../../shared/iOSMobileAdd'

export const LoginForm = () => {
    const [loginFormData, setLoginFormData] = useState<LoginFormData>({
        email: "",
        password: "",
    })
    const [formErrors, setErrors] = useState(null)
    const [formErrorMessages, setErrorMessages] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [mobileIos, setMobileIos] = useState(false)
    const formDataRef = useRef(loginFormData)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        setMobileIos(checkMobileIosBrowser());
        // free ones need diff logic to handle pressing enter on autocomplete
        const onKeyDown = (ev: KeyboardEvent) => {
            // arrow keys should only navigate questions if input isn't active or input value is empty
            if(ev.key === "Enter") {
                handleSubmit()
            }
        }

        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [])

    const handleErrors = (errors: AuthErrors[]) => {
        setErrors(errors)
        setErrorMessages(errors.map((e) => <h1 key={e} className="auth-form-error">* {e}</h1>))
    }

    const handleSubmit = () => {
        const errors = validateLoginForm(formDataRef.current)
        if (errors !== null) {
            handleErrors(errors)
            return
        }

        handleLoginRequest()
    }

    const handleForgotPassword = () => {
        history.push("/forgot")
    }

    const handleLoginRequest = async () => {
        setIsLoading(true)
        try {
            await dispatch(authenticationRequest({
              email: formDataRef.current.email,
              password: formDataRef.current.password
            }))
        } catch {
            setIsLoading(false)
            handleErrors([AuthErrors.LoginError])
        }
    }

    const handleInputChange = (type: AuthTextfieldTypes, val: string) => {
        var tempData = { ...loginFormData }
        if (type === AuthTextfieldTypes.Email) {
            tempData.email = val
        } else {
            tempData.password = val
        }

        formDataRef.current = tempData
        setLoginFormData(tempData)
    }

    return (
        <div className="auth-form-container">
            <MobileAddToHomeScreen
                open={mobileIos}
                onClose={() => setMobileIos(false)}
            />
            <div className="auth-form-content">
                <div>
                    <h1 className="auth-form-title">Login</h1>
                    <div className="auth-form-title-underline"></div>
                </div>
                { formErrors && formErrorMessages }
                <div>
                    <AuthTextfield
                        key="email"
                        type={ AuthTextfieldTypes.Email }
                        formErrors={ formErrors }
                        value={ loginFormData.email }
                        onChange={ handleInputChange }
                    />
                    <AuthTextfield
                        key="password"
                        type={ AuthTextfieldTypes.Password }
                        formErrors={ formErrors }
                        value={ loginFormData.password }
                        onChange={ handleInputChange }
                    />
                </div>
                <AuthSubmit isLoading={ isLoading } onSubmit={handleSubmit} title="LOGIN" />
                <div
                    className="forgot-password-button"
                    onClick={handleForgotPassword}
                >
                    Forgot Password?
                </div>
            </div>
        </div>
    )
}