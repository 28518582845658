import { createSlice, Reducer } from "@reduxjs/toolkit";
import { AlertTypes } from "../../interfaces";
import type { RootState } from "../storeTypes"

interface AlertSlice {
  text: string | null;
  type: AlertTypes | null;
}

// Define the initial state using that type
const initialState: AlertSlice = {
  text: null,
  type: null,
};

export const alertSlice = createSlice({
  name: "alert",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAlert(state, action) {
      state = action.payload;
    },
    removeAlert(state) {
      state.text = null;
      state.type = null;
    }
  },
  extraReducers: (builder) => {
  },
});

export const { setAlert, removeAlert } = alertSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAlert = (state: RootState) =>
  state.alert;

export default alertSlice.reducer as Reducer<typeof initialState>;
