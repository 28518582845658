import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeid } from "../../../helpers/OverviewHelpers";
import { selectSubscriptionStatus } from "../../../store/auth/authSlice";
import { selectForecastProfile } from "../../../store/forecast/forecastSlice";
import { selectProfileData, setWorkingDate } from "../../../store/profile/profileSlice";
import { OverviewTaxDeferred } from "../overview/deferred/OverviewTaxDeferred";
import { OverviewGraph } from "../overview/graph/OverviewGraph";
import { OverviewRaiseWidget } from "../overview/raises/OverviewRaiseWidget";
import { OverviewSickLeave } from "../overview/sick/OverviewSickLeave";
import { OverviewStatus } from "../overview/status/OverviewStatus";
import { OverviewTaxWidget } from "../overview/tax/OverviewTaxWidget";
import { CompareCard } from "./card/CompareCard";
import "./Compare.css";
import { CompareHeader } from "./header/CompareHeader";
import { OverviewDaily } from "../overview/daily/OverviewDaily";

const MOBILE_WIDTH = 900;

export const Compare = () => {
  const [forecastChecked, setForecastChecked] = useState(false);
  const forecastData = useSelector(selectForecastProfile);
  const profileData = useSelector(selectProfileData);
  const isSubscribed = useSelector(selectSubscriptionStatus);
  const history = useHistory();
  const isMobile = window && window.innerWidth < MOBILE_WIDTH;
  const hasTaxImpact = profileData?.tax_impact_on_pay.length > 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forecastData) {
      return history.push("/overview");
    }

    setForecastChecked(true);
  }, [forecastData, history]);

  if (!forecastChecked || !forecastData) {
    return <div></div>;
  }

  const handleDateChange = (newIndex: number) => {
    dispatch(
      setWorkingDate(profileData.monthly_data_keys[newIndex])
    );
  };

  return (
    <div>
      <CompareHeader />
      <div className="compare-container">
        <CompareCard
          profileWidget={
            <OverviewStatus
              isMobile={isMobile}
              profileData={profileData}
              onChange={handleDateChange}
            />
          }
          forecastWidget={
            <OverviewStatus
              isMobile={isMobile}
              profileData={forecastData}
              isCurrent={false}
              onChange={handleDateChange}
            />
          }
        />
        {profileData.sick_leave_credit && (
          <CompareCard
            profileWidget={<OverviewSickLeave profileData={profileData} />}
            forecastWidget={<OverviewSickLeave profileData={forecastData} />}
          />
        )}
        {profileData.monthly_data && isSubscribed && (
          <CompareCard
            profileWidget={
              <OverviewDaily
                profileData={profileData}
                onChange={handleDateChange}
              />
            }
            forecastWidget={
              <OverviewDaily
                profileData={forecastData}
                onChange={handleDateChange}
              />
            }
            sideBySide={false}
          />
        )}
        {profileData.monthly_data && isSubscribed && (
          <CompareCard
            profileWidget={
              <OverviewGraph graphId={makeid(10)} />
            }
            forecastWidget={
              <OverviewGraph graphId={makeid(20)} />
            }
            sideBySide={false}
          />
        )}
        {profileData.annual_raise_percentage && (
          <CompareCard
            profileWidget={<OverviewRaiseWidget profileData={profileData} />}
            forecastWidget={<OverviewRaiseWidget profileData={forecastData} />}
          />
        )}
        <CompareCard
          profileWidget={
            <OverviewTaxWidget
              profileData={profileData}
              graphId={makeid(10)}
              hasTaxImpact={hasTaxImpact}
              isMobile={isMobile}
            />
          }
          forecastWidget={
            <OverviewTaxWidget
              profileData={forecastData}
              graphId={makeid(20)}
              hasTaxImpact={hasTaxImpact}
              isMobile={isMobile}
            />
          }
        />
        {profileData.tax_impact_on_pay &&
          !isNaN(profileData.pension_contribution_percentage) &&
          profileData.pension_contribution_percentage > 0 &&
          isSubscribed && (
            <CompareCard
              profileWidget={
                <OverviewTaxDeferred
                  profileData={profileData}
                  graphId={makeid(20)}
                />
              }
              forecastWidget={
                <OverviewTaxDeferred
                  profileData={forecastData}
                  graphId={makeid(20)}
                />
              }
            />
          )}
      </div>
    </div>
  );
};
