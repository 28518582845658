import FormTextField from "./FormTextField";

const AddSituations = ({ newLocation }: { newLocation: string }) => {
  return (
    <div
      className="widget-container summary-widget-container"
      style={{ textAlign: "left" }}
    >
      I would like to move to{" "}
      <FormTextField inputName="retireLocation" currentValue={newLocation} />{" "}
      to retire.
    </div>
  );
};

export default AddSituations;
