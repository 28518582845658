import { Field } from "react-final-form";
import { SurveyQuestion } from '../../../../interfaces';
import './CheckBox.css';

type Props = {
    question: SurveyQuestion,
}

export const CheckBox = ({ question }: Props) => {

    const findInitialValue = () => {
        if (question.variable.type !== "boolean") {
            return question.prefill || ""
        }

        if (question.prefill) return "Yes"

        return "No"
    }

    return (
        <div className="checkbox-content">
            <Field
                name={question.variable.name}
                component="select"
                initialValue={findInitialValue()}
                className="select-edit"
            >
                {question?.answerOptions?.map((answer) => 
                    <option key={answer.text} value={answer.text}>
                        {answer.text}
                    </option>
                )}
            </Field>
        </div>
    )
}