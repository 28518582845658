import { ToggleButton, ToggleButtonGroup, Autocomplete, TextField } from '@mui/material';
import { Fragment, useEffect, useState } from 'react'
import { SuperAdminApi } from '../../api/SuperAdminAPI'
import { getAllNewOrganizations } from '../../api/OrganizationAPI'
import { AlertTypes, NewOrganization, User } from '../../interfaces'
import { setAlert } from '../../store/alert/alertSlice'
import { SuperAdminHeader } from './header/SuperAdminHeader'
import './SuperAdmin.css'
import { SuperAdminOrgTable } from './table/SuperAdminOrgTable'
import { SuperAdminUserTable } from './table/SuperAdminUserTable'
import { SuperAdminDataTable } from './table/SuperAdminDataTable';
import { useDispatch } from 'react-redux';

export const SuperAdmin = () => {
  const [loading, setLoading] = useState(true)
  const [organizations, setOrganizations] = useState<NewOrganization[]>([])
  const [allOrgs, setAllOrgs] = useState<{[key: string]: NewOrganization[]}>({})
  const [availableOrgs, setAvailableOrgs] = useState([])
  const [users, setUsers] = useState<User[]>([])
  const [recentUsers, setRecentUsers] = useState<User[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [tabValue, setTabValue] = useState(0)
  const [addedUsersByWeek, setAddedUsersByWeek] = useState< any[]>([])
  const [addedUsersByMonth, setAddedUsersByMonth] = useState< any[]>([])
  const dispatch = useDispatch()

  const tabInfo = [
    {num: 0, label: 'Recent Users',
      component: <SuperAdminUserTable loading={loading} users={recentUsers} isSuperUserDisplay={false} />},
    {num: 1, label: 'All Users',
      component: <SuperAdminUserTable loading={loading} users={allUsers} isSuperUserDisplay={false} />},
    {num: 2, label: 'Organizations with users',
      component: <SuperAdminOrgTable organizations={organizations} />},
    {num: 3, label: 'All Organizations',
      component: <SuperAdminOrgTable organizations={availableOrgs} />},
    {num: 4, label: 'Added Users by Week',
      component: <SuperAdminDataTable data={addedUsersByWeek} interval={'Weekly'} />},
    {num: 5, label: 'Added Users by Month',
      component: <SuperAdminDataTable data={addedUsersByMonth} interval={'Monthly'} />},
    {num: 6, label: 'Super User',
      component: <SuperAdminUserTable loading={loading} users={users} isSuperUserDisplay={true} />},
  ]

  const fetchAll = async () => {
    try {
      const allInfo = await SuperAdminApi.loadDashboard()
      setLoading(false)

      setRecentUsers(allInfo.recentUsers);
      setAddedUsersByMonth(allInfo.addedUsersByMonth);
      setAddedUsersByWeek(allInfo.addedUsersByWeek);
      setAllUsers(allInfo.allUsers);
      setOrganizations(allInfo.organizations);
      setUsers(allInfo.superUsers);
    } catch {
      dispatch(setAlert({
        text: 'Could not fetch recent users. Please try again.',
        type: AlertTypes.Error
      }))
    }
  }

  const fetchAllOrganizations = async () => {
    try {
      const orgs = await getAllNewOrganizations()
      setAllOrgs(orgs)
    } catch {
      dispatch(setAlert({
        text: 'Could not fetch organizations. Please try again.',
        type: AlertTypes.Error
      }))
    }
  }

  const handleClick = (value: any) => {
    if (value === null) {
      setAvailableOrgs([])
      return;
    }
    setAvailableOrgs(allOrgs[value])
  }

    useEffect(() => {
      // org API
      fetchAllOrganizations()

      fetchAll()
    }, [])

    return (
        <div className='super-admin-page-background'>
            <div className='super-admin-page-container'>
                <SuperAdminHeader withBackButton={false} />
                <ToggleButtonGroup
                    value={tabValue}
                    exclusive
                    onChange={(e, value) => setTabValue(value)}
                    aria-label='view type'
                    sx={{ marginBottom: '0.5rem' }}
                >
                  {tabInfo.map((tab) =>
                    <ToggleButton key={tab.num} value={tab.num} aria-label={tab.label}>
                      {tab.label}
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
                {
                    tabValue === 3 &&
                    <div style={{margin: '1rem 0', alignItems: 'center'}}>
                      <Autocomplete
                      disablePortal
                      sx={{ marginTop: '0.75rem' }}
                      options={Object.keys(allOrgs).sort()}
                      onChange={(e, newValue) => handleClick(newValue)}
                      renderInput={(params) => <TextField label='State' {...params} />}
                      />
                  </div>
                }
                {
                    tabValue === 1 &&
                    <div style={{margin: '1rem 0', alignItems: 'center'}}>
                        <h3>Total Active Users: { allUsers?.length || 'Loading...' }</h3>
                    </div>
                }
                {tabInfo.map((tab) =>
                    <Fragment key={tab.num}>
                      {tab.num === tabValue && tab.component}
                    </Fragment>
                )}
            </div>
        </div>
    )
}