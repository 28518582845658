import "./Settings.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../../api/UserAPI";
import {
  AlertTypes,
  ProfileData,
  ServiceStatusValues,
  User,
  defaultResetPasswordData,
  ResetPasswordData,
} from "../../../interfaces";
import { setAlert } from "../../../store/alert/alertSlice";
import { selectCurrentUser, selectIsValidated } from "../../../store/auth/authSlice";
import { updateProfileData } from "../../../api/UserAPI";
import {
  selectProfileData,
  selectUserStatus,
} from "../../../store/profile/profileSlice";
import Loader, { LoaderType } from "../overview/loader/Loader";
import { OverviewHeader } from "../overview/header/OverviewHeader";
import { UserPageRoute } from "../UserPage";
import PleaseValidate from "../overview/PleaseValidate";

type UserInfo = {
  first_name: string;
  last_name: string;
};

export const Settings = () => {
  const [resetPasswordData, setResetPasswordData] = useState<ResetPasswordData>(
    defaultResetPasswordData
  );
  const userData = useSelector(selectCurrentUser) as User;
  const [userInfo, setUserInfo] = useState<UserInfo>({
    first_name: userData.first_name || "",
    last_name: userData.last_name || "",
  });
  const profileData = useSelector(selectProfileData) as ProfileData;
  const userServiceStatus = useSelector(
    selectUserStatus
  ) as ServiceStatusValues;
  const currentUser = useSelector(selectCurrentUser);
  const isValidated = useSelector(selectIsValidated);
  const dispatch = useDispatch();

  if (userServiceStatus !== ServiceStatusValues.Loaded) {
    return (
      <Loader type={LoaderType.Scale} size={50} text="Loading your data..." />
    );
  }

  if (!profileData || !currentUser) {
    return <div></div>;
  }

  const updateUserInfo = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await updateProfileData(userInfo);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });

  const handlePasswordReset = () => {
    resetPassword(resetPasswordData)
      .then(() => {
        setResetPasswordData(defaultResetPasswordData);
        dispatch(
          setAlert({
            text: "Password has been succesfully changed.",
            type: AlertTypes.Success
          })
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            text: "Could not update your password. Please make sure the new passwords match.",
            type: AlertTypes.Error
          })
        );
      });
  };

  return (
    <div>
      <OverviewHeader activePage={UserPageRoute.Settings} />
      {!isValidated && <PleaseValidate />}
      <h1 className="user-page-title">Settings</h1>
      <div className="settings-content">
        <form className="settings-left" onSubmit={updateUserInfo} noValidate>
          <div className="settings-field">
            <h1>First name</h1>
            <input
              onChange={onChange}
              name="first_name"
              value={`${userInfo.first_name}`}
            />
          </div>
          <div className="settings-field">
            <h1>Last name</h1>
            <input
              onChange={onChange}
              name="last_name"
              value={`${userInfo.last_name}`}
            />
          </div>
          <div className="settings-field">
            <h1>Employer Name</h1>
            <input
              disabled={true}
              value={`${userData.organizations.display_name}`}
            />
          </div>
          <div className="settings-field">
            <h1>Email</h1>
            <input disabled={true} value={`${currentUser.email}`} />
          </div>
          <div className="settings-field">
            <h1>Retirement System</h1>
            <input disabled={true} value={`${profileData.retirement_system}`} />
          </div>
          <button type="submit">Update User Information</button>
        </form>
        <div className="change-password-form">
          <h1>Change Password</h1>
          <input
            value={resetPasswordData.currentPassword}
            type="password"
            placeholder="Current Password"
            onChange={(e) =>
              setResetPasswordData({
                ...resetPasswordData,
                currentPassword: e.target.value,
              })
            }
          />
          <input
            value={resetPasswordData.newPassword}
            type="password"
            placeholder="New Password"
            onChange={(e) =>
              setResetPasswordData({
                ...resetPasswordData,
                newPassword: e.target.value,
              })
            }
          />
          <input
            value={resetPasswordData.confirmPassword}
            type="password"
            placeholder="Confirm New Password"
            onChange={(e) =>
              setResetPasswordData({
                ...resetPasswordData,
                confirmPassword: e.target.value,
              })
            }
          />
          <button onClick={handlePasswordReset}>Update Password</button>
        </div>
      </div>
    </div>
  );
};
