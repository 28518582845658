import axios from "axios"
import {getStore} from "../store"
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"
import { ResetPasswordData, ProfileData } from "../interfaces"
import { updateProfile } from "../store/profile/profileSlice"

export const updateProfileData = async (values: {[key: string]: any}) => {
  const body = values;
  try {
      await axios.post(getBackendUrlForPath(API_ROUTE.SET_PROFILE_DATA), body, apiDefaultOptions)
  } catch {
      throw new Error("Could not save your user data. Please try again.")
  }
}

export const getProfileData = (): Promise<ProfileData> => {
  const PROFILE_DATA_URL = getBackendUrlForPath(API_ROUTE.GET_PROFILE_DATA)
  return new Promise((resolve, reject) => {
    axios.get(PROFILE_DATA_URL, {
      withCredentials: true
    })
    .then((response) => {
      resolve(response.data as ProfileData)
    }, (error) => {
      reject(error)
    })
  })
}

export const postCompletedDashboardIntro = async () => {
    const PROFILE_DATA_URL = getBackendUrlForPath(API_ROUTE.SET_PROFILE_INTRO)
    try {
		let response = await axios.post(PROFILE_DATA_URL, {}, apiDefaultOptions) as ProfileData
		return response.payday_count
    } catch (error) {
		throw error
    }
}

export const saveVariable = async (name: string, value: string) => {
  const body = { name, value }
  try {
      let response = await axios.post(getBackendUrlForPath(API_ROUTE.SET_VARIABLE), body, apiDefaultOptions)
      let result = await getProfileData()
      getStore().dispatch(updateProfile(result as ProfileData))
      return response.data
  } catch {
      throw new Error("Could not save your variable. Please try again.")
  }
}

export const resetPassword = (resetPasswordData: ResetPasswordData) => {
  const PROFILE_DATA_URL = getBackendUrlForPath(API_ROUTE.ACCOUNT_CHANGE_PASS)
  const body = {
    oldPassword: resetPasswordData.currentPassword,
    newPassword: resetPasswordData.newPassword,
    newPassword2: resetPasswordData.confirmPassword
  }
  return new Promise((resolve, reject) => {
    axios.post(PROFILE_DATA_URL, body, {
      withCredentials: true
    })
    .then((response) => {
      resolve(response.data)
    }, (error) => {
      reject(error)
    })
  })
}