import { Button, Autocomplete, TextField, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AllPlans, ProfileData, NewOrganization } from "../../../../interfaces";
import { selectRetirementSystemId } from "../../../../store/profile/profileSlice";
import { calculateTime } from "../../../../utils/numberDateFormatters";

type Props = {
  profileData: ProfileData;
  stateOrgs: NewOrganization[];
  allPlans: AllPlans;
  handleAddSubmit: (values: { [key: string]: any }) => void;
};

export const AddNewPension = ({
  profileData,
  stateOrgs,
  allPlans,
  handleAddSubmit,
}: Props) => {
  const mainSystemId = useSelector(selectRetirementSystemId);
  const [add, setAdd] = useState(false);
  const [currentSystem, setCurrentSystem] = useState("");
  const [hasReciprocity, setHasReciprocity] = useState(false);
  const [planForm, setPlanForm] = useState({
    retirementBenefit: null,
    orgId: null,
    serviceYears: 0,
    serviceMonths: 0,
    socialSecurity: null,
    reciprocity: false,
  })

  const reciprocalSystems = profileData?.peerSystemIds || [];
  useEffect(() => {
    const { retirementBenefit, serviceMonths, serviceYears } = planForm;

    if (retirementBenefit?.rsbId && (serviceMonths || serviceYears)) {
      handleAddSubmit({
        orgId: planForm.orgId,
        retirementBenefitId: retirementBenefit.rsbId,
        serviceYears: calculateTime(serviceMonths, serviceYears),
        ...(planForm.socialSecurity !== null ? { socialSecurity: planForm.socialSecurity } : {}),
        reciprocity: planForm.reciprocity,
      })
    }
  }, [planForm])

  return (
    <div style={{ margin: "1.5rem 0", fontWeight: 500 }}>
      {add ? (
        <div style={{ display: "flex", flexDirection: "column", margin: "1rem 0" }}>
          Add previous employer information:
          <Autocomplete
            disablePortal
            placeholder="Select second pension system..."
            id="pension"
            freeSolo={false}
            options={Object.keys(allPlans).sort()}
            sx={{ margin: "0.5rem 0" }}
            onChange={(e, newValue) => {
              setCurrentSystem(newValue)

              // corner case. make sure to reset plan form every time we
              // select a new pension system
              const currentSystemId = allPlans[newValue]?.systemId;

              const isEligibleReciprocal = reciprocalSystems
                .some(s => s === currentSystemId);
              // we assume reciprocity if it is same as the current system
              const isCurrentSystem = currentSystemId === mainSystemId;

              setPlanForm({
                ...planForm,
                retirementBenefit: null,
                socialSecurity: null,
                reciprocity: isEligibleReciprocal || isCurrentSystem,
              })

              setHasReciprocity(isEligibleReciprocal);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select secondary pension system.."
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="configuration"
            freeSolo={false}
            value={planForm.retirementBenefit}
            options={allPlans[currentSystem]?.plans || []}
            onChange={(e, newValue) => setPlanForm({
              ...planForm,
              retirementBenefit: newValue,
              socialSecurity: newValue?.socialSecurity || null,
            })}
            getOptionLabel={(option) => option ? `${option.name}, ${option.type}, ${option.level}` : ''}
            sx={{ margin: "0.5rem 0" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select benefit formula.."
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="employer"
            freeSolo={false}
            options={stateOrgs}
            onChange={(e, newValue) => setPlanForm({...planForm, orgId: newValue.id})}
            getOptionLabel={(option) => option.display_name}
            sx={{ margin: "0.5rem 0" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="(Optional) Select employer.."
              />
            )}
          />
          <TextField
            label="Years"
            type="number"
            sx={{ margin: "0.5rem 0" }}
            onChange={(e) => setPlanForm({...planForm, serviceYears: +e.target.value})}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Months"
            type="number"
            sx={{ margin: "0.5rem 0" }}
            onChange={(e) => setPlanForm({...planForm, serviceMonths: +e.target.value})}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div>
          <Checkbox
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
            checked={planForm.socialSecurity || false}
            onChange={(e) => setPlanForm({...planForm, socialSecurity: e.target.checked})}
          />
          Social Security
          </div>
          { hasReciprocity && <div>
          <Checkbox
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
            checked={planForm.reciprocity || false}
            onChange={(e) => setPlanForm({...planForm, reciprocity: e.target.checked})}
          />
          Reciprocity
          </div>}
        </div>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setAdd(true)}
        >
          Add
        </Button>
      )}
    </div>
  );
};
