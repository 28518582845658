import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import {getStore} from '../../../store';
import { updateResult } from '../../../store/survey/surveySlice';
import { selectCurrentQuestion, selectCurrentQuestionResult, selectGrossPay, selectNetPay } from '../../../store/survey/surveySlice';
import { NumberDate } from '../number-date/NumberDate';
import { NumberTax } from '../number-tax/NumberTax';
import { Button, Dialog } from '@mui/material';
import './Number.css';

export const getNumberValue = (num: number) => {
    if (num === undefined || num === null || isNaN(parseFloat(num.toString()))) {
        return 0
    }
    return parseFloat(num.toString())
}

type Props = {
    handleValueChanged?: (val: any, inputIndex: number | undefined) => void,
    index?: number,
    value?: number | string | null
}

export const Number = ({ handleValueChanged, index = 0, value }: Props) => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const [minValue, setMinValue] = useState<number>(null)
    const [maxValue, setMaxValue] = useState<number>(null)
    const [showPaycheck, setShowPaycheck] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        // add validators
        // no negative numbers
        setMinValue(0)
        if (question.text.toLowerCase().includes("gross pay")) {
            setMinValue(1000)
            setMaxValue(80000)
        }

        if (question.text.toLowerCase().includes("net pay")) {
            const grossPay = selectGrossPay(getStore().getState())
            if (grossPay) { setMaxValue(grossPay) }
        }

        if (question.text.toLowerCase().includes("percentage") && question.category.text.toLowerCase() === "divorce") {
            setMaxValue(50)
        }

        if (question.text.toLowerCase().includes("tax deferred")) {
            const grossPay = selectGrossPay(getStore().getState())
            const netPay = selectNetPay(getStore().getState())
            setMaxValue(grossPay - netPay)
        }
    }, [question])

    const handleAnswerChange = (val: number | string, isValid: boolean) => {
        dispatch(updateResult({
            answer: val,
            isValid: isValid
        }))
    }

    const handleInputChange = (val: string) => {
        let num = parseFloat(val)
        const asString = num.toString();
        const split = asString.split(".");
        const decimals = split[1]?.length || 0;
        if (decimals > 2) num = parseFloat(num.toFixed(2))
        let valToSave = isNaN(num) ? "" : num
        // this is a number from a formula or multiple question
        if (handleValueChanged) {
            return handleValueChanged(valToSave, index)
        }

        // valid if
        // it is a number
        // if it is greater than min value if there is a min value
        // if it is less than max value if there is a max value
        handleAnswerChange(valToSave,
            !isNaN(num) &&
            (minValue === null || (num >= minValue)) &&
            (maxValue === null || (num <= maxValue))
        )
    }

    const showGrossPayExample = question.text.toLowerCase().includes('gross pay')

    const showMaxWarning = () => {
        return maxValue !== null && result && (getNumberValue(result.answer) > maxValue)
    }

    const showMinWarning = () => {
        return minValue !== null && result && (getNumberValue(result.answer) < minValue)
    }

    if (question.text.toLowerCase().includes("federal") || question.text.toLowerCase().includes("state tax")) {
        return <NumberTax />
    }

    if (question.text.toLowerCase().includes("retirement age")) {
        return <NumberDate />
    }

    return (
        <div className="number-container">
            <div className="number-input-container">
                <div>
                    { question.preFieldDecorator && <span className="number-symbol number-symbol-pre">{ question.preFieldDecorator.flat()[index] }</span> }
                    { question.postFieldDecorator && <span className="number-symbol number-symbol-post">{ question.postFieldDecorator.flat()[index] }</span> }
                    { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol">#</span> }
                    <input
                        type="number"
                        className="number-input"
                        value={ value !== undefined ? value : ((result && result.answer !== null) ? result.answer : "") }
                        onChange={ e => handleInputChange(e.target.value) }
                        readOnly={ question.locked }
                    />
                </div>
            </div>
            <div>
                { showGrossPayExample &&
                    <Button
                        className='number-input-button'
                        onClick={() => setShowPaycheck(true)}
                    >
                        Show example of gross pay
                    </Button>}
                <Dialog
                    open={showPaycheck}
                    onClose={() => setShowPaycheck(false)}
                >
                    <img src="https://pensionapp-assets.s3.us-west-2.amazonaws.com/paycheck_grossPay.PNG" aria-label="paycheck"></img>
                </Dialog>
            </div>
            {
                showMaxWarning() &&
                <p><em>You must enter a number lower than {maxValue}</em></p>
            }
            {
                showMinWarning() &&
                <p><em>You must enter a number higher than {minValue}</em></p>
            }
        </div>
    )
}