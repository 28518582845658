import { Autocomplete, TextField } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SurveyAnswerOption, SurveyQuestion, SurveyResult } from "../../../interfaces";
import { updateResult } from "../../../store/survey/surveySlice";
import { selectCurrentQuestion, selectCurrentQuestionResult } from "../../../store/survey/surveySlice";

const filter = createFilterOptions<any>();

export const BenefitAutoComplete = () => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult
    const [optionValue, setOptionValue] = useState(null)
    const [altValue, setAltValue]=useState(null)
    const [otherPlans, setOtherPlans] = useState(false)
    const dispatch = useDispatch()

    const isOnboardedState = question.answerOptions.length > 0;
    const establishedPlans = question.answerOptions.filter((o) => o.planId !== null)
    const additionalPlans = question.answerOptions.filter((o) => o.planId === null)

    useEffect(() => {
        const currentPlan = result && result.rsbId;
        if (currentPlan) {
            const currentValue = establishedPlans.find((option) => option.rsbId === currentPlan)
            setOptionValue(currentValue);
        }
    }, [])
    

    const handleInputChange = (option: SurveyAnswerOption, alt: boolean = false) => {
      if (!option) {
          dispatch(updateResult({
              answer: "",
              isValid: false
          }))
          setOptionValue(null)
          setAltValue(null)
          return
      }

      dispatch(updateResult({
          answer: option.text,
          planId: option.planId,
          rsbId: option.rsbId,
          isValid: option && option.text !== ""
      }))

      if (alt) {
        setOptionValue(null)
        setAltValue(option)
      } else {
        setOptionValue(option)
        setAltValue(null)
      }
    }

    const handleOtherInputChange = (text: string) => {
      const answer = {
        answer: text,
        text: text,
        planId: null as null,
        rsbId: null as null,
        isValid: text && text !== ""
      }
      dispatch(updateResult(answer))

      setAltValue(answer)
      setOptionValue(null)
    }

    return (
        <div>
            {!isOnboardedState &&
              <div style={{ marginBottom: "1rem" }}>
                Unfortunately, we don't have any plans available for your state. You can
                add your pension details and we will work on onboarding it ASAP.
              </div>
            }
            {isOnboardedState && 
            <>
              <Autocomplete
                fullWidth
                options={ establishedPlans}
                value={optionValue}
                onChange={ (e, newValue: SurveyAnswerOption) => handleInputChange(newValue) }
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField
                    {...params}
                    label={question.placeholderText}
                    variant="outlined"
                />}
            />
            <div style={{ marginTop: '0.75rem', marginBottom: '0.75rem', fontWeight: 'bolder', cursor: 'pointer' }}
                onClick={() => setOtherPlans(!otherPlans)}
            >
                Can't find your plan?
                {' '}
                <span className='progress-bar-link'>Click here</span>
                {' '}
                to see other options.
            </div>
            </>}
            {(otherPlans || !isOnboardedState) &&
                <Autocomplete
                    options={additionalPlans}
                    value={altValue}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        handleOtherInputChange(newValue.inputValue);
                      } else {
                        // null cases
                        handleInputChange(newValue, true);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          text: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => option.text}
                    freeSolo
                    renderInput={(params) => <TextField
                        {...params}
                        label={question.placeholderText}
                        variant="outlined"
                    />}
                />
            }
        </div>
    )
}