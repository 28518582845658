export const CalendarIcon = () => (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14.25 3H13.5V1.5H12V3H6V1.5H4.5V3H3.75C2.9175 3 2.25 3.675 2.25 4.5V15C2.25 15.825
2.9175 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM14.25 15H3.75V6.75H14.25V15ZM4.875 9.75C4.875 8.715 5.715 7.875 6.75 7.875C7.785 7.875 8.625 8.715 8.625 9.75C8.625 10.785 7.785 11.625 6.75 11.625C5.715 11.625 4.875 10.785 4.875 9.75Z" fill="#1F1F1F"/>
</svg>);

export const Sliders = ({ fill }) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 20C7.11287 20.0004 6.25076 19.706 5.54929 19.1629C4.84782 18.6198 4.34678 17.859 4.125 17H2V15H4.126C4.31302 14.2757 4.69942 13.6183 5.24131 13.1025C5.78321 12.5868 6.45887 12.2333 7.19156 12.0823C7.92425 11.9313 8.68461 11.9888 9.38627 12.2483C10.0879 12.5077 10.7028 12.9587 11.1609 13.5501C11.6191 14.1415 11.9023 14.8495 11.9783 15.5937C12.0543 16.3379 11.92 17.0885 11.5908 17.7603C11.2616 18.432 10.7506 18.998 10.1159 19.3939C9.48114 19.7898 8.74809 19.9998 8 20ZM8 14C7.60836 14.0004 7.22545 14.1158 6.89878 14.3318C6.57211 14.5479 6.31606 14.855 6.1624 15.2153C6.00874 15.5755 5.96422 15.973 6.03438 16.3583C6.10453 16.7436 6.28627 17.0998 6.55704 17.3828C6.82782 17.6657 7.17571 17.863 7.55756 17.95C7.93941 18.037 8.33841 18.01 8.70506 17.8724C9.07171 17.7347 9.38987 17.4924 9.62007 17.1756C9.85027 16.8587 9.98238 16.4812 10 16.09V16.49V16C10 15.4696 9.78929 14.9609 9.41421 14.5858C9.03914 14.2107 8.53043 14 8 14ZM22 17H13V15H22V17ZM13 12C12.113 12.0002 11.2512 11.7056 10.5499 11.1626C9.84863 10.6195 9.34774 9.85879 9.126 9H2V7H9.126C9.31302 6.27566 9.69942 5.61829 10.2413 5.10254C10.7832 4.5868 11.4589 4.23335 12.1916 4.08234C12.9243 3.93133 13.6846 3.98882 14.3863 4.24828C15.0879 4.50773 15.7028 4.95875 16.1609 5.55011C16.6191 6.14147 16.9023 6.84947 16.9783 7.59369C17.0543 8.33791 16.92 9.08853 16.5908 9.76029C16.2616 10.432 15.7506 10.998 15.1159 11.3939C14.4811 11.7898 13.7481 11.9998 13 12ZM13 6C12.6084 6.0004 12.2254 6.11577 11.8988 6.33181C11.5721 6.54785 11.3161 6.85505 11.1624 7.21528C11.0087 7.57552 10.9642 7.97295 11.0344 8.35826C11.1045 8.74357 11.2863 9.0998 11.557 9.38276C11.8278 9.66572 12.1757 9.86295 12.5576 9.94999C12.9394 10.037 13.3384 10.01 13.7051 9.87236C14.0717 9.73469 14.3899 9.49241 14.6201 9.17556C14.8503 8.85871 14.9824 8.48125 15 8.09V8.49V8C15 7.46957 14.7893 6.96086 14.4142 6.58579C14.0391 6.21071 13.5304 6 13 6ZM22 9H18V7H22V9Z" fill={fill}/>
</svg>

export const Bars = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66699 22.6665L17.3337 22.6665" stroke="#1A1C1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 16H25.3337" stroke="#1A1C1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.66699 9.3335L17.3337 9.3335" stroke="#1A1C1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
