import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { selectPensionType, toggleGrossNet } from "../../../store/profile/profileSlice";

export const GrossNetToggle = () => {
  const amountType = useSelector(selectPensionType);
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(toggleGrossNet())}
      endIcon={<ArrowDropDownIcon />}
      className="widget-configuration-button"
    >
      {amountType}
    </Button>
  );
};
