import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus } from '../../store/auth/authSlice';
import { UserPage, UserPageRoute } from '../user/UserPage';
import EmailRemoval from '../user/overview/EmailRemoval';
import EmailValidation from '../user/overview/EmailValidation';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const isAdmin = useSelector(selectIsAdmin);
  const hasCompletedSurvey = useSelector(selectCompleteSurvey);
  const handleRedirect = () => {
    if (isAdmin) {
      return "/superadmin";
    } else if (hasCompletedSurvey) {
      return "/overview";
    }

    return "/survey";
  };

  // redirect if main path and it's going to /home page
  if (!isLoggedIn && path === "/") {
    window.location.href = 'https://www.advancedpension.com/home';
    return null;
  }

  // todo: need to fix how we render paths
  if (isLoggedIn && path === "/subscribe") {
    return <UserPage page={UserPageRoute.Subscribe} />
  } else if (isLoggedIn && path === "/success") {
    return <UserPage page={UserPageRoute.Subscribe} />
  } else if (isLoggedIn && path.includes("/remove")) {
    return (
      <Route
        path="/remove/:userId/:token"
        render={(props) => <EmailRemoval {...props} />}
      />
    );
  } else if (isLoggedIn && path.includes("/validate")) {
    return (
      <Route
        path="/validate/:userId/:token"
        render={(props) => <EmailValidation {...props} />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: handleRedirect(),
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
