import { useState } from "react";
import { Box, Typography, Tabs, Tab, Button } from "@mui/material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";

const TabNames = {
  INCOME: "Retirement Income",
  LIFE: "Life Changes",
  ACCRUALS: "Accruals",
  TAXES: "Taxes",
  CASHOUTS: "Cashouts",
  RECIPROCITY: "Reciprocity",
  INVESTMENTS: "Investments",
};

const InfoTabs = [
  {
    name: TabNames.INCOME,
    icon: <MonetizationOnOutlinedIcon />,
    title: "Your retirement paycheck",
    flavorText: `Wondering how much you'll earn when you retire?
      Use our simple calculator to see your future retirement income.
      Make sure you're on track to live the way you want.`,
  },
  {
    name: TabNames.LIFE,
    icon: <HouseOutlinedIcon />,
    title: "Roll with life's punches",
    flavorText: `Stuff happens—divorce, getting remarried, you name it.
      We help you figure out what these big life changes mean for your retirement
      money, so you can focus on the important stuff.`,
  },
  {
    name: TabNames.ACCRUALS,
    icon: <AccountBalanceOutlinedIcon />,
    title: "Climb the ladder",
    flavorText: `Want to know where you stand with your retirement benefits?
      We break down all the fancy terms and show you how to make the most of
      your working years for a better retirement.`,
  },
  {
    name: TabNames.TAXES,
    icon: <ReceiptLongOutlinedIcon />,
    title: "Keep more of your money",
    flavorText: `Taxes can take a bite out of your retirement money.
      We help you find places to live where your pension will go further
      and to keep more of your cash.`,
  },
  {
    name: TabNames.CASHOUTS,
    icon: <PointOfSaleOutlinedIcon />,
    title: "Turn time off into time well spent",
    flavorText: `Got extra vacation, holiday, or sick days? Learn
      how you can use them to boost your retirement benefits and
      make your future even better.`,
  },
  {
    name: TabNames.RECIPROCITY, icon: <CompareArrowsOutlinedIcon />,
    title: "Combine service for bigger benefits",
    flavorText: `Worked in multiple jobs across the state? Our calculator stacks
      your years of service to help you unlock better retirement benefits and a
      larger pension paycheck.`,
  },
  {
    name: TabNames.INVESTMENTS, icon: <TrendingUpOutlinedIcon />,
    title: "Grow your future",
    flavorText: `Curious how your savings could grow over time? Use our tool to
      see how your money might grow to help you get closer to your retirement goals.`,
  },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProductsBar() {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Box sx={{ bgcolor: "#ede7f6" }}>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        aria-label="tab options"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {InfoTabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.name}
            value={index}
            {...a11yProps(index)}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ my: 10, mx: 5, flex: "2 1" }}>
          <Typography variant="h2" sx={{ fontWeight: 600 }}>
            {InfoTabs[currentTab].title}
          </Typography>
          <Typography variant="h4" sx={{ color: "grey" }}>
            {InfoTabs[currentTab].flavorText}
          </Typography>
          <Button
            href="/register"
            sx={{ my: 2, display: "inline-block" }}
            variant="contained"
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ProductsBar;
