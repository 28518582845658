import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SubscribeSuccessModal = ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose: () => void;
  message: {
    title: string,
    successMessage: string,
    body: string,
  };
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={matches}
      sx={{
        fontWeight: "bolder",
        textAlign: "center",
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" fontWeight="bold">
          <Box flexGrow={1}>{message.title}</Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Box
        sx={{
          padding: "1rem",
        }}
      >
        {message.successMessage}
      </Box>
      <Box
        sx={{
          display: "block",
          padding: "1rem",
        }}
      >
        {message.body}
      </Box>
    </Dialog>
  );
};

export default SubscribeSuccessModal;
