import axios from "axios";
import {
  apiDefaultOptions,
  API_ROUTE,
  getBackendUrlForPath,
} from "../helpers/APIHelpers";
import { generateForecastingDto } from "../helpers/ForecastingHelpers";
import {
  ForecastDtoWithId,
  ForecastingFormData,
} from "../interfaces";
import {getStore} from "../store";
import {
  setForecastFormData,
  setForecastProfile,
} from "../store/forecast/forecastSlice";

export class ForecastApi {
  static async getForecasts() {
    try {
      const response = await axios.get(
        getBackendUrlForPath(API_ROUTE.FORECAST),
        apiDefaultOptions
      );
      return response.data as ForecastDtoWithId[];
    } catch {
      throw new Error();
    }
  }

  static async postForecasts(data: ForecastingFormData) {
    try {
      const response = await axios.post(
        getBackendUrlForPath(API_ROUTE.FORECAST),
        generateForecastingDto(data),
        apiDefaultOptions
      );
      return response.data as ForecastDtoWithId;
    } catch (e) {
      throw new Error(e);
    }
  }

  static async postCalculatorData(data: ForecastingFormData) {
    try {
      const response = await axios.post(
        getBackendUrlForPath(API_ROUTE.FORECAST_CALCULATE),
        data,
        apiDefaultOptions
      );
      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  static async saveCalculatorData(data: {[key: string]: any}) {
    try {
      const response = await axios.post(
        getBackendUrlForPath(API_ROUTE.FORECAST_CALCULATE_SAVE),
        data,
        apiDefaultOptions
      );
      return response.status;
    } catch (e) {
      throw new Error(e);
    }
  }

  static editData(data: ForecastingFormData) {
    try {
      return axios.post(
        getBackendUrlForPath(API_ROUTE.FORECAST_EDIT),
        generateForecastingDto(data),
        apiDefaultOptions
      );
    } catch (e) {
      throw new Error(e);
    }
  }

  static async getForecastProfileData(forecastId: number) {
    let apiRoute = `${getBackendUrlForPath(
      API_ROUTE.FORECAST_PROFILE
    )}/${forecastId}`;
    try {
      const response = await axios.get(apiRoute, apiDefaultOptions);
      getStore().dispatch(setForecastProfile(response.data));
    } catch {
      throw new Error();
    }
  }

  static async saveForecast(forecastId: number) {
    let apiRoute = `${getBackendUrlForPath(
      API_ROUTE.FORECAST_SAVE
    )}/${forecastId}`;
    try {
      await axios.post(apiRoute, {}, apiDefaultOptions);
      window.location.reload();
    } catch {
      throw new Error();
    }
  }

  static async deleteForecast(forecastId: number) {
    let apiRoute = `${getBackendUrlForPath(API_ROUTE.FORECAST)}/${forecastId}`;
    try {
      const response = await axios.delete(apiRoute, apiDefaultOptions);
      getStore().dispatch(setForecastFormData(null));
      getStore().dispatch(setForecastProfile(null));
      return response.data;
    } catch {
      throw new Error();
    }
  }
}
