import './NotFound.css'

type Props = {
    errorMessage?: string
}

const DEFAULT_MESSAGE = "The page you were looking for could not be found"

export const NotFound = ({ errorMessage = DEFAULT_MESSAGE }: Props) => {
    return (
        <div className="not-found-container">
            <h1>404</h1>
            <p>{ errorMessage }</p>
            <a href="/login">Return</a>
        </div>
    )
}