import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

const SubscriptionPricing = ({
  annual,
  toggleSub,
}: {
  annual: boolean;
  toggleSub: () => void;
}) => {
  return (
    <>
      <Box>
        <Switch checked={annual} onClick={toggleSub}></Switch>
        {annual ? "Annual" : "Monthly"}
      </Box>
      <Box>
        {!annual && (
          <>
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >
              $14.99 / month
            </Box>
          </>
        )}
        {annual && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                fontWeight: "bold",
              }}
            >
              <Box
                sx={{
                  textDecoration: "line-through",
                  fontWeight: "light",
                }}
              >
                $14.99 / month
              </Box>
              <Box>$8.25 / month</Box>
              <Box
                sx={{
                  color: "var(--dashboard-blue-purple)",
                }}
              >
                Save $80!
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPricing;