import { BasicOrganization } from "../user/User";
import { NewOrganization } from "../user/User";

export interface SignUpFormData {
    firstName: string,
    lastName: string,
    email: string,
    setPass: string,
    confirmPass: string,
    acceptedTerms: boolean,
    marketingEmails: boolean,
    organization?: BasicOrganization,
}

export interface NewSignUpFormData {
    firstName: string,
    lastName: string,
    email: string,
    setPass: string,
    confirmPass: string,
    acceptedTerms: boolean,
    marketingEmails: boolean,
    organization?: NewOrganization,
    other_classification: string,
}

export enum SignUpFormError {
    InvalidFirstName = "Please enter your first name.",
    InvalidLastName = "Please enter your last name.",
    InvalidPassword = "Please enter your password.",
    InvalidConfirmPassword = "Please confirm your password.",
    PasswordMatching = "Your passwords do not match.",
    ConsumeError = "There was an error processing your data. Please try again.",
    TermsError = "You must accept the terms of service in order to create an account.",
    OrganizationError = "Please specify a relevant organization."
}