import {
  faCog,
  faEye,
  faSignOutAlt,
  faThLarge,
  faUser,
  faBookReader,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { selectForecastProfile } from "../../../store/forecast/forecastSlice";
import { UserPageRoute } from "../UserPage";
import "./MenuButton.css";
import cx from "classnames";
import { Button } from "@mui/material";

type Props = {
  isMobile: boolean;
  type: UserPageRoute;
  isActive: boolean;
  onClick: any;
  isDisabled: boolean;
};

export const MenuButton = ({
  isMobile,
  type,
  isActive,
  onClick,
  isDisabled = false,
}: Props) => {
  const forecastData = useSelector(selectForecastProfile);

  const getIcon = (type: any) => {
    const iconClass = cx("menu-button-icon", {
      "menu-button-icon-active": isActive,
      "menu-button-icon-disabled": isDisabled,
    });

    switch (type) {
      case UserPageRoute.Overview:
        return (
          <FontAwesomeIcon className={iconClass} icon={faThLarge} fixedWidth />
        );
      case UserPageRoute.Account:
        return (
          <FontAwesomeIcon className={iconClass} icon={faUser} fixedWidth />
        );
      case UserPageRoute.Settings:
        return (
          <FontAwesomeIcon className={iconClass} icon={faCog} fixedWidth />
        );
      case UserPageRoute.Compare:
        return (
          <FontAwesomeIcon className={iconClass} icon={faEye} fixedWidth />
        );
      case UserPageRoute.Subscribe:
        return <CurrencyExchangeIcon className={iconClass} />;
      case UserPageRoute.Success:
        return <CurrencyExchangeIcon className={iconClass} />;
      case UserPageRoute.Tutorial:
        return (
          <FontAwesomeIcon
            className={iconClass}
            icon={faBookReader}
            fixedWidth
          />
        );
      case UserPageRoute.Help:
        return (
          <FontAwesomeIcon className={iconClass} icon={faEnvelope} fixedWidth />
        );
      default:
        return (
          <FontAwesomeIcon
            className={iconClass}
            icon={faSignOutAlt}
            fixedWidth
          />
        );
    }
  };

  const getButtonClassName = () => {
    var cn = "menu-icon-container";
    if (isActive) {
      cn += " menu-icon-active";
    }
    if (
      isActive &&
      forecastData !== null &&
      (type === UserPageRoute.Overview || type === UserPageRoute.Compare)
    ) {
      cn += " menu-icon-active-yellow";
    }

    return cn;
  };

  if (isMobile) {
    return (
      <div className="menu-button-container">
        <Button className={getButtonClassName()} onClick={onClick}>
          {type}
        </Button>
      </div>
    );
  }

  return (
    <div className="menu-button-container">
      <Tooltip
        title={type.toString()}
        placement="right"
        aria-label={type.toString()}
        disableHoverListener={isDisabled}
      >
        <span style={{ display: "inline-block" }}>
        <Button
          className={getButtonClassName()}
          onClick={onClick}
          disabled={isDisabled}
        >
          <div className="menu-button">{getIcon(type)}</div>
        </Button>
      </span>
      </Tooltip>
    </div>
  );
};
