import { motion, useAnimation } from "framer-motion";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getSurvey } from '../../api/SurveyAPI';
import { useAppDispatch } from "../../store/hooks";
import { SurveyQuestion, ServiceStatusValues, PreSurveyProgress } from '../../interfaces';
import { selectCurrentQuestion, selectSurveyStatus, loadSurveyData } from '../../store/survey/surveySlice';
import { AnimationContext } from "../App";
import { SurveyError } from './error/SurveyError';
import { SurveyLoader } from "./loading/SurveyLoader";
import { ProgressBar } from './progress/ProgressBar';
import Question from './question/Question';
import './Survey.css';

type Props = {
	preSurveyProgress?: PreSurveyProgress,
	startFromBeginning?: boolean
}

export const Survey = ({ preSurveyProgress, startFromBeginning = false }: Props) => {
	const currentQuestion = useSelector(selectCurrentQuestion) as SurveyQuestion
	const surveyStatus = useSelector(selectSurveyStatus) as ServiceStatusValues
	const history = useHistory()
	const controls = useAnimation()
  const dispatch = useDispatch()

	useEffect(() => {
    dispatch(loadSurveyData({ preSurveyProgress, startFromBeginning}))
	}, [])

	if (surveyStatus !== ServiceStatusValues.Loaded || !currentQuestion) {
		return (
			<div className="Survey">
				<SurveyError />
			</div>
		)
	}

	// not transition
	return (
		<div className="Survey blue-bg">
			{ startFromBeginning &&
				<div 
					className="survey-overview-return-button"
					onClick={() => history.push("/overview")}
				>
					Back To Dashboard
				</div>
			}
			<h1 className="page-title">{ currentQuestion.category.text }</h1>
			<SurveyLoader />
			<AnimationContext.Provider value={ controls }>
				<motion.div animate={controls} >
					<Question key={ currentQuestion.questionId.toString()} /> 
				</motion.div>
				<ProgressBar />
			</AnimationContext.Provider>
		</div>
	);
}
