import Fuse from "fuse.js";
import { KeyboardEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { handleNextPress } from "../../../api/AnimationAPI";
import { SurveyAnswerOption, SurveyQuestion, SurveyResult } from "../../../interfaces";
import { selectCurrentQuestionIndex, selectSurveyData, updateResult } from "../../../store/survey/surveySlice";
import { selectCurrentQuestion, selectCurrentQuestionResult } from "../../../store/survey/surveySlice";
import { AnimationContext } from "../../App";
import AutoCompleteOption from './AutoCompleteOption';
import './WriteIn.css';

export const WriteIn = () => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const currentQuestionIndex = useSelector(selectCurrentQuestionIndex)
    const surveyData = useSelector(selectSurveyData)
    const [queriedList, setQueriedList] = useState<SurveyAnswerOption[]>([])
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
    const [autoCompleteActiveIndex, setActiveIndex] = useState(0)
    const animationControls = useContext(AnimationContext)
    const dispatch = useDispatch()
    const history = useHistory;

    const handleInputChange = (val: string) => {
        var answer: (string | number) = val
        // if it is a number input parse the value as an integer
        if (answer !== "") {
            answer = question.inputType === "number" ? parseFloat(answer) : answer
        }
        dispatch(updateResult({ 
            answer: answer,
            isValid: val !== ""
        }))
        setActiveIndex(0)
    }

    useEffect(() => {
        if (!result || !question.answerOptions || !result.answer) { return }

        const fuse = new Fuse([...question.answerOptions!], { keys: ['text'] })
        var filteredList = fuse.search(result.answer).map(x => x.item) as SurveyAnswerOption[]

        // if result is empty or user selected an option don't show any autocomplete options
        if (result.answer === "" || (filteredList.length > 0 && filteredList[0].text === result.answer)) { filteredList = [] }

        setQueriedList(filteredList)

        // create AutoCompleteOption elements from query
        if (filteredList && filteredList.length > 0) {
            let options = filteredList.map((x, index) => {
                return <AutoCompleteOption 
                    answerOption={x} 
                    onClick={(text: string) => handleInputChange(text)}
                    key={x.id ? x.id.toString() : x.text}
                    isActive={index === autoCompleteActiveIndex} 
                />
            }).slice(0, 4)
            setAutoCompleteOptions(options)
        } else {
            setAutoCompleteOptions([])
        }

        // eslint-disable-next-line
    }, [result, autoCompleteActiveIndex, question])

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            handleEnterPress()
        } else if (e.code === "ArrowDown") {
            handleDownArrowPress()
        } else if (e.code === "ArrowUp") {
            // without prevent default the input cursor moves to the beginning of the text
            e.preventDefault()
            handleUpArrowPress()
        }
    }

    function handleEnterPress() {
        if (autoCompleteOptions.length === 0) {
            handleNextPress(question, result, currentQuestionIndex, surveyData, animationControls, dispatch, history)
            return
        }
            
        if (queriedList[autoCompleteActiveIndex]) {
            handleInputChange(queriedList[autoCompleteActiveIndex].text)
        }
    }

    const handleDownArrowPress = () => {
        if (autoCompleteActiveIndex < autoCompleteOptions.length - 1) {
            setActiveIndex(autoCompleteActiveIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const handleUpArrowPress = () => {
        if (autoCompleteActiveIndex > 0) {
            setActiveIndex(autoCompleteActiveIndex - 1)
        } else {
            setActiveIndex(autoCompleteOptions.length - 1)
        }
    }

    return (
        <div>
            <input type={ question.inputType } 
                placeholder={ question.placeholderText } 
                value={result ? result.answer : ""} 
                onChange={ e => handleInputChange(e.target.value) } 
                className="write-in-input" 
                onKeyDown={handleKeyDown}
                readOnly={ question.locked }
            />
            { (question.answerOptions && autoCompleteOptions.length > 0) && <div className="write-in-options-container">
                    { autoCompleteOptions }
                </div>
            }
        </div>
    )
}