import './Loader.css'
import RingLoader from 'react-spinners/RingLoader'
import { css } from "@emotion/core";
import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 20px auto;
  border-color: red;
`;

export enum LoaderType {
  Ring,
  Scale
}

type Props = {
  size: number,
  text?: string,
  type: LoaderType
}

const Loader = ({ size, text, type }: Props) => {
    return (
        <div className="loader-container">
          { type === LoaderType.Ring && <RingLoader css={override} size={size} color={"#5579e4"} loading={true} /> }
          { type === LoaderType.Scale && <ScaleLoader css={override} width={size} height={size} color={"#5579e4"} loading={true} /> }
          { text && <p>{ text }</p> }
        </div>
    )
}

export default Loader