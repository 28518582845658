import { useEffect } from 'react'
import { ProfileData } from '../../../../interfaces'
import './OverviewRaiseWidget.css'

type Props = {
    profileData: ProfileData
}

export const OverviewRaiseWidget = ({ profileData }: Props) => {
    useEffect(() => {
        if (!profileData) { return }
    }, [profileData])

    return (
        <div className="widget-container">
            <div className="raise-widget-container">
                <h1 className="widget-title">Future Raises</h1>
                <div className="widget-light-text">annual raise</div>
                <div className="dashboard-number-and-text">
                    <span>{ profileData.annual_raise_percentage.toLocaleString(undefined, {maximumFractionDigits: 2}) }%</span>
                    Your Current Annual Raise Projection
                </div>
            </div>
        </div>
    )
}