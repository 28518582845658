import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleNextPress } from '../../../api/AnimationAPI'
import { SurveyResult } from '../../../interfaces'
import { useAppDispatch } from '../../../store/hooks'
import { selectCurrentQuestionResult,
  selectCurrentQuestion, 
  selectCurrentQuestionIndex,
  selectSurveyData} from '../../../store/survey/surveySlice'
import { AnimationContext } from '../../App'
import { useHistory } from 'react-router-dom'
import './SubmitButton.css'
import { selectSubscriptionStatus } from '../../../store/auth/authSlice'

type Props = {
    buttonText?: string
}

const SubmitButton = ({ buttonText = "Submit" }: Props) => {
    const animationControls = useContext(AnimationContext)
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const question = useSelector(selectCurrentQuestion)
    const questionIndex = useSelector(selectCurrentQuestionIndex)
    const surveyData = useSelector(selectSurveyData)
    const isSubscribed = useSelector(selectSubscriptionStatus)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
          if (event.key === 'Enter') {
              event.preventDefault();
              handleNextPress(question, result, questionIndex, surveyData, animationControls, dispatch, history, isSubscribed);
          }
      };

      document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [question, result, questionIndex, surveyData, animationControls, dispatch, history]);


    return (
        <div>
            <button className="submit-button" type="button" onClick={ e => handleNextPress(
              question, result, questionIndex, surveyData, animationControls, dispatch, history, isSubscribed) } >
                { (result && result.isValid) ? buttonText : "Skip" }
            </button>
            <p className="submit-button-text">press <strong>Enter ↵</strong></p>
        </div>
    )
}

export default SubmitButton