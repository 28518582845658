import { createSlice, createAsyncThunk, Reducer } from "@reduxjs/toolkit";
import type { RootState } from "../storeTypes"
import { ForecastingFormData, ServiceStatusValues } from "../../interfaces";
import { ForecastApi } from "../../api/ForecastApi";

interface ForecastSlice {
  forecastFormData: ForecastingFormData | null,
  forecastProfile: null,
  currentForecastId: number,
  status: ServiceStatusValues,
}

// Define the initial state using that type
const initialState: ForecastSlice = {
  forecastFormData: null,
  forecastProfile: null,
  currentForecastId: null,
  status: ServiceStatusValues.Loading,
};

export const postForecast = createAsyncThunk(
  "forecast/postForecast",
    async (data: ForecastingFormData) => {
      try {
        const response = await ForecastApi.postForecasts(data);
        return response
      } catch (err) {

      }
    }
);

export const forecastSlice = createSlice({
  name: "forecast",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setForecastFormData(state, action) {
      state.forecastFormData = action.payload;
    },
    setForecastProfile(state, action) {
      state.forecastProfile = action.payload;
    },
    setForecastId(state, action) {
      state.currentForecastId = action.payload;
    }
  },
  extraReducers: (builder) => {
  },
});

export const { setForecastFormData, setForecastProfile, setForecastId } =
  forecastSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectForecastFormData = (state: RootState) => state.forecast.forecastFormData;

export const selectForecastProfile = (state: RootState) => state.forecast.forecastProfile;

export const selectForecastId = (state: RootState) => state.forecast.currentForecastId;

export default forecastSlice.reducer as Reducer<typeof initialState>;
