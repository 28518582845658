import CloseIcon from "@mui/icons-material/Close";
import { TextField, Autocomplete, Drawer, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ForecastApi } from "../../../../api/ForecastApi";
import { getInitialForecastFormData } from "../../../../helpers/ForecastingHelpers";
import { AlertTypes, ForecastingOption,
  FORECASTING_OPTIONS, ProfileData } from "../../../../interfaces";
import { setAlert } from "../../../../store/alert/alertSlice";
import {
  setForecastFormData,
  setForecastId,
} from "../../../../store/forecast/forecastSlice";
import {
  selectForecastFormData,
  selectForecastId,
} from "../../../../store/forecast/forecastSlice";
import { selectProfileData } from "../../../../store/profile/profileSlice";
import { ForecastingChanges } from "./changes/ForecastingChanges";
import "./Forecasting.css";
import { ForecastingForm } from "./form/ForecastingForm";
import { ForecastingLoader } from "./loader/ForecastingLoader";

type Props = {
  onClose: () => void;
  editMode?: boolean;
  open: boolean;
};

export const Forecasting = ({
  open = false,
  onClose,
  editMode = false,
}: Props) => {
  const formData = useSelector(selectForecastFormData);
  const currentForecastId = useSelector(selectForecastId);
  const [isLoading, setLoading] = useState(false);
  const profileData = useSelector(selectProfileData) as ProfileData;
  const [selectedForecast, setSelectedForecast] =
    useState<ForecastingOption>(null);
  const [options, setOptions] = useState(FORECASTING_OPTIONS);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profileData) return;

    const options = FORECASTING_OPTIONS.filter((option) => {
      if (
        option.dataKey !== "final_overtime_salary" ||
        profileData?.retirementBenefit?.hasOvertime
      ) {
        return true;
      }
      return false;
    });

    setOptions(options);
  }, []);

  const setInitialData = async () => {
    if (formData) {
      return;
    }
    const initialData = await getInitialForecastFormData(profileData);
    dispatch(setForecastFormData(initialData));
  };

  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line
  }, [formData, profileData]);

  const handleActivate = async () => {
    setLoading(true);
    try {
      // post the forecast
      const createdForecast = await ForecastApi.postForecasts(formData);
      const forecastId = createdForecast.id;
      dispatch(setForecastId(forecastId));

      // get the forecast profile
      await ForecastApi.getForecastProfileData(forecastId);

      history.push("/compare");
      onClose();
    } catch {
      dispatch(
        setAlert({
          text: "Could not save your forecasts, please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEditData = async () => {
    setLoading(true);
    try {
      // post the forecast
      await ForecastApi.editData(formData);
      window.location.reload();
    } catch {
      dispatch(
        setAlert({
          text: "Could not save your forecasts, please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDisable = async () => {
    try {
      if (currentForecastId !== null && currentForecastId !== undefined) {
        await ForecastApi.deleteForecast(currentForecastId);
      }
      onClose();
    } catch {
      dispatch(
        setAlert({
          text: "Could not delete your forecast. Please try again.",
          type: AlertTypes.Error
        })
      );
    }
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      className="forecasting-background-container"
      onClose={onClose}
      keepMounted
    >
      <Box
        px={3}
        py={2} 
        className="forecasting-container"
        sx={{
          width: { xs: '100vw', sm: '40vw' }
        }}
      >
        <div className="forecasting-header">
          <h1>{editMode ? "Customize my pension" : "Forecasting"}</h1>
          <CloseIcon onClick={onClose} />
        </div>
        <Autocomplete
          options={options}
          getOptionLabel={(option) =>
            (option as ForecastingOption).option.toString()
          }
          value={selectedForecast}
          onChange={(e, o) => {
            if (typeof o === "string") {
              return;
            }
            setSelectedForecast(o);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Forecast changes to my...`}
              variant="outlined"
            />
          )}
        />
        <ForecastingForm data={formData} selectedForecast={selectedForecast} />
        <ForecastingChanges
          data={formData}
          handleActivate={!editMode ? handleActivate : handleEditData}
          handleDisable={handleDisable}
        />
      </Box>
      {isLoading && <ForecastingLoader />}
    </Drawer>
  );
};
