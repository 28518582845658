import { useSelector } from 'react-redux'
import { SurveyQuestion } from '../../../interfaces';
import { Field } from 'react-final-form';
import { selectProfileData } from '../../../store/profile/profileSlice'
import { mustBeNumber, minValue, maxValue, composeValidators } from '../../../helpers/ValidationHelpers';
import '../number/Number.css';

type Props = {
    question: SurveyQuestion,
    index?: number,
    survey?: boolean,
}

export const NumberTax = ({ question, index = 0 }: Props) => {
    const profileData = useSelector(selectProfileData)

    return (
        <div className="number-container">
            <div className="number-input-container">
                { question.preFieldDecorator && <span className="number-symbol-edit number-symbol-pre">{ question.preFieldDecorator.flat()[index] }</span> }
                <Field
                    name={question.variable.name}
                    type="number"
                    className="number-input-edit" 
                    component="input"
                    initialValue={question.prefill || 0}
                    readOnly={question.locked}
                    validate={composeValidators(mustBeNumber, minValue(0), maxValue(profileData.gross_pay))}
                >
                {({ input, meta }) => (
                    <>
                        { question.postFieldDecorator && <span className="number-symbol-edit number-symbol-post">{ question.postFieldDecorator.flat()[index] }</span> }
                        { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol-edit">#</span> }
                        <input
                            className="number-input-edit"
                            {...input}
                            type="number"
                            placeholder={input.value}
                        />
                        { question.postFieldDecorator && <span className="number-symbol-edit number-symbol-post">{ question.postFieldDecorator.flat() }</span> }
                        {meta.error && meta.touched &&
                            <span className="number-input-edit error">{meta.error}</span>
                        }
                        </>
                    )}
                </Field>
            </div>
        </div>
    )
}