import { Button } from '@mui/material'
import './InviteWelcome.css'

type Props = {
    orgName?: string
}

export const InviteWelcome = ({ orgName }: Props) => {
    return (
        <div className="invite-welcome-container">
            <div className="invite-welcome-content">
                <div>
                    <h1>Welcome to Advanced Pension</h1>
                    <h3>Your One-Stop Pension Planner.</h3>
                </div>
                <div>
                 &larr; <Button onClick={() => window.location.href="https://www.advancedpension.com/home"} style={{ cursor: "pointer", color: "white", textDecoration: 'none' }}>Back to homepage</Button>
                </div>
                { orgName && <h3 className="invite-welcome-org-name">{ orgName }</h3> }
            </div>
        </div>
    )
}