import React, { SetStateAction } from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";

import { Field, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { formatNumbers } from "../../../utils/numberDateFormatters";
import { AdditionalConfigurationData } from "../../../interfaces";
import {
  composeValidators,
  mustBeNumber,
  minValue,
  required,
} from "../../../helpers/ValidationHelpers";
import { Plans } from "./OverviewReciprocity";
import { selectSubscriptionStatus } from "../../../store/auth/authSlice";

type PlanProps = {
  title: string;
  plans: AdditionalConfigurationData[];
  form: any;
  planType: keyof Plans;
  setRemovedItems: React.Dispatch<SetStateAction<AdditionalConfigurationData[]>>;
};

const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    style={{
      color: "red",
      fontWeight: "bolder",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
    }}
  >
    {children}
  </Box>
);

const Condition = ({
  when,
  is,
  children,
}: {
  when: string;
  is: any;
  children: React.ReactNode;
}) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
};

const FormCheckbox = ({ form, planName }: { form: any; planName: string }) => {
  const currentField = useField(`${planName}`).input.value;
  const isSubscribed = useSelector(selectSubscriptionStatus);

  // if same retirement systemm as current system, don't allow
  // changes to reciprocity
  if (currentField.overrideReciprocity) {
    return (
    <Box
      sx={{
        marginTop: "0.5rem"
      }}
    >
      <Box
        sx={{
          fontWeight: "bolder",
          color: "var(--dashboard-blue-purple)",
        }}
      >
        <li>
          {currentField?.retirementBenefit?.retirement_system?.name}
        </li>
      </Box>
      <Box sx={{ marginLeft: "0.25rem", marginBottom: "0.5rem" }}>
        {`${currentField.org?.display_name || "No organization specified"}: ${currentField.retirementBenefit?.name}`}
      </Box>
    </Box>
    )
  }

  return (
    <>
      <Field name={`${planName}.reciprocity`} type="checkbox">
        {({ input }) => (
          <>
            <FormControlLabel
              sx={{ width: "100%" }}
              componentsProps={{
                typography: {
                  sx: {
                    fontWeight: "bolder",
                    color: "var(--dashboard-blue-purple)",
                  },
                },
              }}
              control={
                <Checkbox
                  {...input}
                  onChange={(event, checked) => {
                    input.onChange(checked);
                    if (checked) {
                      form.change(`${planName}.additionalReciprocity`, null);
                    }
                  }}
                />
              }
              label={currentField?.retirementBenefit?.retirement_system?.name}
            />
          </>
        )}
      </Field>
      <Box sx={{ marginLeft: "0.25rem", marginBottom: "0.5rem" }}>
        {`${currentField.org?.display_name || "No organization specified"}: ${currentField.retirementBenefit?.name}`}
      </Box>
      <Condition when={`${planName}.reciprocity`} is={false}>
        <Field
          name={`${planName}.additionalReciprocity`}
          type="select"
          validate={!currentField.reciprocity ? required : undefined}
        >
          {({ input, meta }) => (
            <FormControl fullWidth>
              <Select
                {...input}
                onChange={(e) => {
                  if (e.target.value === "newplan") {
                    input.onChange({ id: "newplan", salary: 0 });
                  } else {
                    const selectedPlan = currentField.eligiblePlans?.find(
                      (a: any) => a.id === e.target.value
                    );
                    input.onChange({
                      id: selectedPlan.id,
                      salary: selectedPlan.salary,
                    });
                  }
                }}
                displayEmpty
                value={input.value.id || ""}
              >
                <MenuItem value={""} disabled>
                  Change reciprocity
                </MenuItem>
                <MenuItem value={"newplan"}>
                  {`Add a Plan: (Click to enter salary)`}
                </MenuItem>
                {currentField.eligiblePlans?.map((option: any) => (
                  <MenuItem value={option.id} key={option.id}>
                    {`Plan ${option.displayIndex}: $${formatNumbers(
                      option.salary,
                      isSubscribed
                    )}`}
                  </MenuItem>
                ))}
              </Select>
              {/* will proc on salary validation, allow that to flow through to below */}
              {typeof meta.error === "string" && meta.touched && (
                <Text>{meta.error}</Text>
              )}
            </FormControl>
          )}
        </Field>
      </Condition>
      <Condition when={`${planName}.additionalReciprocity.id`} is="newplan">
        <Field
          name={`${planName}.additionalReciprocity.salary`}
          type="input"
          validate={composeValidators(mustBeNumber, minValue(1), required)}
        >
          {({ input, meta }) => (
            <>
              <TextField
                inputProps={{ type: "number" }}
                style={{ marginTop: "0.25rem" }}
                placeholder="Enter salary"
                onChange={(e) => input.onChange(e.target.value)}
                fullWidth
              />
              {typeof meta.error === "string" && meta.touched && (
                <Text>{meta.error}</Text>
              )}
            </>
          )}
        </Field>
      </Condition>
    </>
  );
};

export const PlanList = ({ title, plans, form, planType, setRemovedItems }: PlanProps) => {
  // if no plans, don't display this section
  if (plans?.length === 0 || !plans) return null;

  return (
    <Box
      style={{
        textAlign: "left",
        border: "1px var(--dashboard-blue) solid",
        borderRadius: "5px",
        padding: "0.75rem",
        marginTop: "0.5rem",
      }}
    >
      <Box
        style={{
          fontWeight: "bolder",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          fontSize: "1.25rem",
        }}
      >
        {title}
      </Box>
      <FieldArray name={planType}>
        {({ fields }) =>
          fields.map((name, index) => (
            <Field
              name={name}
              key={index}
              subscription={{}}
              render={({ input, meta }) => (
                <>
                <FormCheckbox {...input} form={form} planName={name} />
                  {/* <span
                    onClick={(e) => {
                      const removedItem: AdditionalConfigurationData = form.getFieldState(`${name}`).value;
                      fields.remove(index)
                      setRemovedItems((prev: AdditionalConfigurationData[]) => {
                        const alreadyRemoved = prev.find(item => item.id === removedItem.id);
                        return alreadyRemoved ? prev : [...prev, removedItem];
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                  ❌
                  </span> */}
                </>
              )}
            />
          ))
        }
      </FieldArray>
    </Box>
  );
};
