import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "../../errors/NotFound";
import { InviteWelcome } from "../shared/welcome/InviteWelcome"
import { authenticateResetPassword } from "../../../api/AuthAPI";
import { ResetForm } from "./form/ResetForm";
import '../shared/styles/AuthStyles.css'

type ResetParams = {
  userId: string,
  token: string
}

export const ResetPage = () => {
    const [resetError, setTokenError] = useState(false)
    const { userId, token } = useParams() as ResetParams
    const [email, setEmail] = useState("")

    const authenticate = async (token: string, userId: string) => {
        try {
            const userEmail = await authenticateResetPassword(userId, token)
            setEmail(userEmail)
            setTokenError(false)
        } catch {
            setTokenError(true)
        }
    }

    useEffect(() => {
        if (!token || !userId) { return setTokenError(true) }
        authenticate(token, userId)
    }, [token, userId])

    if (resetError) {
        return <NotFound errorMessage="Page not found due to invalid/expired reset token." />
    }

    return (
        <div className="auth-page-container">
            <InviteWelcome orgName={ "" } />
            <ResetForm email={ email } token={token} userId={userId} />
        </div>
    )
}