import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Divider, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { federal_tax_data, state_tax_data } from "../../../../data/tax_data";
import { TimePeriod, ProfileData, User } from '../../../../interfaces';
import { selectCurrentTaxState, selectTimePeriod, selectNumbersData } from "../../../../store/profile/profileSlice";
import { selectSubscriptionStatus } from "../../../../store/auth/authSlice";

import { selectCurrentUser } from '../../../../store/auth/authSlice';
import { getNumberValue, getDivisor, formatNumbers, getPercentage } from '../../../../utils/numberDateFormatters';
import { StateMapWidget } from '../map/StateMapWidget'
import { DateSelect } from '../DateSelect';
import { StateAutocomplete } from "./StateAutocomplete";
import { TimePeriodToggle } from '../TimePeriodToggle';
import './OverviewTaxWidget.css';

interface GraphDataValue {
    "tax": string,
    "amount": number
}

interface TaxWidgetValues {
    federal: number,
    state: number,
    medicare: number,
    social: number
}

type Props = {
    graphId?: string
    profileData: ProfileData
    hasTaxImpact: boolean
    isMobile: boolean
}

export const OverviewTaxWidget = ({ profileData, graphId = "", hasTaxImpact, isMobile }: Props) => {
    const currentTaxState = useSelector(selectCurrentTaxState)
    const userData = useSelector(selectCurrentUser) as User
    const timePeriod = useSelector(selectTimePeriod)
    const numbersData = useSelector(selectNumbersData);
    const [stateName, setStateName] = useState<string>(userData.organizations?.state_name || "California")
    const [graphData, setGraphData] = useState<GraphDataValue[]>([])
    const [values, setValues] = useState<TaxWidgetValues>({ federal: 0, state: 0, medicare: 0, social: 0 })
    const isSubscribed = useSelector(selectSubscriptionStatus);
    const annualGross = numbersData?.yearly_gross;

    const getFederalTax = (annualGrossPay: number) => {
        let taxData = federal_tax_data
        const taxableGrossPay = annualGrossPay - 24000
        var projected_tax = 0
        taxData.forEach((d) => {
            if ((taxableGrossPay) > d.single.brackets) {
                const topValue = Math.min(d.single.total, taxableGrossPay)
                projected_tax += (topValue - d.single.brackets) * (d.single.rates / 100)
            }
        })

        return projected_tax
    }

    const getStateTax = (annualGrossPay: number) => {
        let taxData = state_tax_data[stateName]
        const taxableGrossPay = annualGrossPay - 24000
        var projected_tax = 0
        taxData?.forEach((d) => {
            if ((taxableGrossPay) > d.single.brackets) {
                const topValue = Math.min(d.single.total, taxableGrossPay)
                projected_tax += (topValue - d.single.brackets) * (d.single.rates / 100)
            }
        })
        return projected_tax
    }

    useEffect(() => {
        if (!profileData) { return }
        const taxValues: TaxWidgetValues = {
            federal: getFederalTax(annualGross),
            state: getStateTax(annualGross),
            medicare: (profileData.medicare_tax_payment / profileData.pensionable_pay) * annualGross || 0,
            social: (profileData.social_security_tax_payment / profileData.pensionable_pay) * annualGross || 0
        }

        const divisor = getDivisor(timePeriod);

        setGraphData([
            { tax: "Federal", amount: getNumberValue(taxValues.federal / divisor) },
            { tax: "State", amount: getNumberValue(taxValues.state / divisor) },
            { tax: "Medicare", amount: getNumberValue(taxValues.medicare / divisor) },
            { tax: "Social Security", amount: getNumberValue(taxValues.social / divisor) },
        ])
        setValues(taxValues)
    }, [profileData, stateName, timePeriod])

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create(`taxdiv${graphId}`, am4charts.XYChart);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "tax";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "amount";
        series.dataFields.categoryX = "tax";
        series.name = "Taxes";
        // eslint-disable-next-line
        series.columns.template.tooltipText = "{categoryX}: [bold]${valueY}[/]";
        series.columns.template.fillOpacity = .8;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#5579e4")
        columnTemplate.stroke = am4core.color("#4543FA")

        chart.data = graphData
    }, [graphData, graphId])

    const getAnnualNumber = (num: number) => {
        var divisor = 1
        if (timePeriod === TimePeriod.Monthly) { divisor = 12 }
        if (timePeriod === TimePeriod.BiWeekly) { divisor = 26 }
        const parsedNum = getNumberValue(num)
        return formatNumbers(parsedNum / divisor, isSubscribed);
    }

    useEffect(() => {
        if (currentTaxState) {
            setStateName(currentTaxState)
        }
    }, [currentTaxState])

    return (
        <>
        <div className="widget-container">
            <div className="widget-configuration-button-container">
                <Select
                    value={stateName}
                    variant="standard"
                    onChange={ e => setStateName(e.target.value as string) }
                    className="widget-configuration-button"
                >
                    {
                        Object.keys(state_tax_data).map((k, index) => (
                            <MenuItem
                                className="widget-header-select"
                                key={k}
                                value={k}>
                                { k }
                            </MenuItem>
                        ))
                    }
                </Select>
                <DateSelect />
                <TimePeriodToggle />
            </div>
            <div className="tax-section-container">
                <div className="tax-total-container">
                    <h1 className="tax-item-number">${getAnnualNumber(values.social + values.state + values.federal + values.medicare)}</h1>
                    <div className="tax-total-description">
                        <p>Total Tax Withholdings (percentage applied to pension)</p>
                        <p>{getPercentage(
                            values.social +
                            values.state +
                            values.federal +
                            values.medicare,
                            annualGross,
                            isSubscribed
                        )}%</p>
                    </div>
                </div>
                <Divider />
                <div className="tax-item-container">
                    <div className="tax-item">
                        <div className="tax-item-row">
                            <div className="tax-blue-bar"></div>
                            <p className="tax-item-number">${getAnnualNumber(values.federal)}</p>
                        </div>
                        <div className="tax-item-row">
                            <div className="tax-gray-bar"></div>
                            <div>
                                <p className="tax-text">{ timePeriod } Federal Taxes</p>
                                <p className="tax-text">{ getPercentage(values.federal, annualGross, isSubscribed) }% Of Gross Pay</p>
                            </div>
                        </div>
                    </div>
                    <div className="tax-item">
                        <div className="tax-item-row">
                            <div className="tax-blue-bar"></div>
                            <p className="tax-item-number">${getAnnualNumber(values.state)}</p>
                        </div>
                        <div className="tax-item-row">
                            <div className="tax-gray-bar"></div>
                            <div>
                                <p className="tax-text">{ timePeriod } State Taxes</p>
                                <p className="tax-text">{ getPercentage(values.state, annualGross, isSubscribed) }% Of Gross Pay</p>
                            </div>
                        </div>
                    </div>
                    <div className="tax-item">
                        <div className="tax-item-row">
                            <div className="tax-blue-bar"></div>
                            <p className="tax-item-number">${getAnnualNumber(values.medicare)}</p>
                        </div>
                        <div className="tax-item-row">
                            <div className="tax-gray-bar"></div>
                            <div>
                                <p className="tax-text">{ timePeriod } Medicare Taxes</p>
                                <p className="tax-text">{ getPercentage(values.medicare, annualGross, isSubscribed) }% Of Gross Pay</p>
                            </div>
                        </div>
                    </div>
                    <div className="tax-item">
                        <div className="tax-item-row">
                            <div className="tax-blue-bar"></div>
                            <p className="tax-item-number">${getAnnualNumber(values.social)}</p>
                        </div>
                        <div className="tax-item-row">
                            <div className="tax-gray-bar"></div>
                            <div>
                                <p className="tax-text">{ timePeriod} Social Security Taxes</p>
                                <p className="tax-text">{ getPercentage(values.social, annualGross, isSubscribed) }% Of Gross Pay</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {(!isMobile && hasTaxImpact) &&
                <StateMapWidget profileData={profileData} />
            }
            <StateAutocomplete
                profileData={profileData}
                stateName={stateName}
            />
        </>
    )
}