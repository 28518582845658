import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { signupNewsletter } from "../../../api/MailerAPI";

const SIGNUP_KEY = "newsletter_signup";
const SUBMITTED_KEY = "newsletter_subscribed";
const DELAY_MS = 7 * 24 * 60 * 60 * 1000; // once a week
const INITIAL_DELAY_MS = 2 * 1000;

export const NewsletterPopup = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorInfo, setErrorInfo] = useState({
    success: true,
    error: ""
  });

  useEffect(() => {
    const lastShown = localStorage.getItem(SIGNUP_KEY);
    const hasSubscribed = localStorage.getItem(SUBMITTED_KEY);
    const shouldShowPopup =
      !lastShown ||
      (!hasSubscribed && parseInt(lastShown, 10) + DELAY_MS < Date.now());

    if (shouldShowPopup) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, INITIAL_DELAY_MS);

      localStorage.setItem(SIGNUP_KEY, Date.now().toString());
      return () => clearTimeout(timer);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const result = await signupNewsletter(email);
      if (result.success) {
        localStorage.setItem(SUBMITTED_KEY, "true");
        setSubmitted(true);
      } else {
        setIsError(true);
        setErrorInfo(result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setErrorInfo(error as { success: boolean, error: string });
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: "center",
          color: "var(--dashboard-blue-purple)",
          fontWeight: "bolder",
        }}
      >
        Embark on your financial journey
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        {submitted
          ? `Success! You're now subscribed. Look out for our weekly insights on
            financial planning and exclusive offers, delivered straight to your inbox.`
          : `Ready to take control of your future? Sign up now to unlock our free
            personalized newsletter for a secure and prosperous retirement.`}
        {!isLoading && !submitted && (
          <>
            <TextField
              value={email}
              onChange={handleChange}
              placeholder="Email"
              sx={{
                width: "75%",
              }}
            />
            {isError && <Alert severity="error">{errorInfo.error}</Alert>}
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "75%",
                alignItems: "center",
              }}
            >
              Yes! Please subscribe me
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                marginTop: "0.5rem",
              }}
            >
              No thanks
            </Button>
          </>
        )}
        {isLoading ? (
          <CircularProgress />
        ) : (
          submitted && (
            <>
              <CheckCircleIcon
                sx={{ color: "var(--emerald-green)", fontSize: 40 }}
              />
            </>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
