import { SurveyQuestion } from '../../../interfaces'
import './QuestionHeader.css'

type Props = {
    question: SurveyQuestion,
    number: number
}

export const QuestionHeader = ({ question, number}: Props) => {
    return (
        <label className="question-label">
            <span className="question-number">{ number }</span>
            <div style={{ maxWidth: "80%" }}>
                { question.text }
                { question.description && <span className="question-description">{question.description}</span> }
                { question.locked && <span className="question-description">* Question is Locked *</span> }
            </div>
        </label>
    )
}