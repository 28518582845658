import {useState} from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Typography, Box } from '@mui/material';
import EditMyData from './edit-data/EditMyData';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant='determinate' {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='caption'
            component='div'
            color='text.secondary'
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
}

type Props = {
    progressValue: number,
    isMobile: boolean
}

export const ProgressBar = ({ progressValue, isMobile }: Props) => {
    const [showEditMyData, setShowEditMyData] = useState(false)
    return (
        <>
            {
                showEditMyData &&
                <EditMyData
                    open={showEditMyData}
                    onClose={() => setShowEditMyData(false)}
                    isMobile={isMobile}
                />
            }
            <Box
                className='progress-bar'
                onClick={() => setShowEditMyData(true)}
            >
                <CircularProgressWithLabel value={progressValue} />
                <Box
                    sx={{marginLeft: '1rem'}}
                >
                    Profile progress:
                    {` ${Math.round(progressValue)}% `}
                    complete.
                    {' '}
                    <span className='progress-bar-link'>Click here</span>
                    {' '}
                    to customize your profile.
                </Box>
            </Box>
        </>
    )
}