interface SurveyCategory {
    id: number,
    text: string
}

export interface SurveyAnswerOption {
    id: number,
    text: string,
    planId?: number,
    rsbId?: number,
    shortcutKey?: string,
    system_name?: string,
    hire_date?: {
        start: string,
        end: string,
    }
}

export enum SurveyYesNoAnswers {
    Yes = "Yes",
    No = "No / Skip"
}

export interface Variable {
    id: number,
    name: string,
    description: string,
    type: string,
}

export enum SurveyQuestionAnswerType {
    Free = "free",
    Number = "number",
    Date = "date",
    YesNo = "yesNo",
    Select = "select",
}

export interface Answer {
  id : number,
  value: any,
}

export interface SurveyQuestion {
    id: number,
    order: number,
    prefill?: any,
    hidden: boolean,
    locked: boolean,
    text: string, 
    description?: string,
    previousAnswer: Answer[],
    preFieldDecorator?: string[][],
    postFieldDecorator?: string[][],
    allowMultipleAnswers?: boolean,
    placeholderText?: string,
    buttonText?: string,
    answerType: SurveyQuestionAnswerType[][],
    allowOther?: boolean,
    inputType?: string,
    projectDate?: boolean,
    category?: SurveyCategory,
    answerOptions?: any[],
    questionId: number,
    required?: boolean,
    postApiRoute?: string,
    isPreSurveyQuestion?: boolean,
    variable?: Variable,
}

// todo: check if needed
export interface ComponentLocation {
    row: number,
    column: number
}