import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button} from '@mui/material';
import { generateOrganizationInvitation, getInvitationByOrg } from "../../../api/OrganizationAPI";
import { AlertTypes, OrganizationInvitation } from "../../../interfaces";
import { setAlert } from "../../../store/alert/alertSlice";
import { SignUpLinkHeader } from "./header/SignUpLinkHeader";
import { SignUpLinkRow } from "./row/SignUpLinkRow";
import { useDispatch } from "react-redux";

type Params = {
  id: string,
}

export const SignUpLink = () => {
  const [invite, setInvite] = useState<OrganizationInvitation>(null)
  const [key, setKey] = useState<string>(null)

  const { id: orgId } = useParams<Params>()

  const dispatch = useDispatch()

  const refreshInvites = async () => {
    try {
        const currentInvite = await getInvitationByOrg(orgId) as OrganizationInvitation
        if (currentInvite) {
          setInvite(currentInvite)
          setKey(currentInvite.key)
        }
    } catch {
        dispatch(setAlert({
          text: "Could not get sign up link. Please try refreshing.",
          type: AlertTypes.Error
        }))
    }
  }

  useEffect(() => {
      refreshInvites()
  }, [])

  const handleGenerateLink = async () => {
      try {
          await generateOrganizationInvitation()
          await refreshInvites()
          dispatch(setAlert({
            text: "New organization invite code has been created and updated below.",
            type: AlertTypes.Success}))
      } catch {
          dispatch(setAlert({
            text: "Could not generate. Please try again.",
            type: AlertTypes.Error
          }))
      }
  }

  return (
    <Box sx={{ px: 3, py: 3, mb: 2 }}>
      {
        key &&
        <>
          <SignUpLinkHeader signUpKey={key} />
          <SignUpLinkRow key={key} orgInvite={invite} refreshInvites={refreshInvites} />
        </>
      }
      {
        !key &&
        <p>
          This organization has no invites yet. Click below to generate a unique invite code.
        <Button
          onClick={handleGenerateLink}
          variant="contained"
        >
          Generate New Link
        </Button>
        </p>
      }
    </Box>
  )
}