import { Box } from "@mui/material";
import Card from "@mui/material/Card";

import { useWindowSize } from "../../../hooks";
import { OverviewHeader } from "./header/OverviewHeader";
import { UserPageRoute } from "../UserPage";
import PleaseValidate from "./PleaseValidate"

export interface SuccessMessage {
  title: string,
  successMessage: string,
  body: string,
}

const VerifyEmail: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < 900;

  return (
    <>
      {isMobile && (
        <OverviewHeader activePage={UserPageRoute.Verify} />
      )}
      <Box
        sx={{
          backgroundColor: "var(--dashboard-light-purple)",
          padding: "var(--dashboard-widget-padding)",
          borderRadius: "var(--dashboard-border-radius)",
          textAlign: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Email Validation Needed
          </Box>
        </Box>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            alignSelf: "center",
            padding: "1rem",
          }}
        >
            <PleaseValidate />
        </Card>
      </Box>
    </>
  );
};

export default VerifyEmail;
