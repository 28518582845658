import { Dispatch, SetStateAction } from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { StateMapTooltipContent } from "../../../../interfaces";
import { setStateTax } from "../../../../store/profile/profileSlice";
import { no_tax } from "../../../../data/tax_data";
import { useDispatch } from "react-redux";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

type Props = {
  setTooltipContent: Dispatch<SetStateAction<StateMapTooltipContent>>
}

export const MapChart = ({ setTooltipContent }: Props) => {
  const dispatch = useDispatch();

  return (
    <ComposableMap data-tip="" projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            <>
              { geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  stroke="#FFF"
                  geography={geo}
                  onClick={() => dispatch(setStateTax(geo.properties.name))}
                  onMouseEnter={() => {
                    setTooltipContent({ stateName: geo.properties.name });
                  }}
                  onMouseLeave={() => {
                    setTooltipContent(null);
                  }}
                  style={{
                    hover: {
                      fill: "#808080",
                      outline: "none",
                      cursor: "pointer"
                    }
                  }}
                  className={no_tax[geo.properties.name] || 'default'}
                />
              ))}
            </>
          }
        </Geographies>
    </ComposableMap>
  );
};