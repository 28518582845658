import { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { useParams, useHistory } from "react-router-dom"
import { SuperAdminApi } from "../../../api/SuperAdminAPI"
import { SuperAdminHeader } from "../header/SuperAdminHeader"
import './SuperAdminApprove.css'
import '../shared/UserForm/UserForm.css'
import { NotFound } from "../../errors/NotFound";
import { AlertTypes, UserStatus, InviteData } from "../../../interfaces"
import { setAlert } from "../../../store/alert/alertSlice"
import { PropagateLoader } from "react-spinners"
import { useDispatch } from "react-redux"

type ApproveParam = {
    key: string,
}

const override = css`
  margin: 50px auto;
`;


export const SuperAdminApprove = () => {
    const [error, setError] = useState(true)
    const history = useHistory()
    const { key } = useParams() as ApproveParam
    const [userData, setUserData] = useState<InviteData>(null)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const getInviteData = async () => {
        try {
            let res = await SuperAdminApi.getSignupInvitation(key)
            setUserData(res)
            setError(false)
        } catch {
            setError(true)
        }
    }

    const approveSignup = async () => {
        try {
            setIsLoading(true);
            await SuperAdminApi.approveSignup(key);
            setIsLoading(false);
            dispatch(setAlert({
              text: `Signup for user ${userData?.email} approved. An invitation email has been sent.`,
              type: AlertTypes.Success
            }))
            history.push("/superadmin")
        } catch {
            dispatch(setAlert({
              text: "Failed to approve signup. Please try again.",
              type: AlertTypes.Error}))
        }
    }

    const rejectSignup = async () => {
        try {
            setIsLoading(true);
            await SuperAdminApi.declineSignup(key);
            setIsLoading(false);
            dispatch(setAlert({
              text: `Signup for user ${userData?.email} rejected and the account has been disabled.`,
              type: AlertTypes.Success
            }))
            history.push("/superadmin")
        } catch {
            dispatch(setAlert({
              text: "Failed to reject signup. Please try again.",
              type: AlertTypes.Error
            }))
        }
    }

    useEffect(() => {
        if (!key) { return setError(true) }
        getInviteData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key])

    if (error || !userData) {
        return <NotFound errorMessage="Page not found due to invalid invite key." />
    }

    if (userData.status !== UserStatus.Pending) {
        return <NotFound errorMessage="User is not pending approval." />
    }

    return (
        <div className="super-admin-page-background">
            <div className="super-admin-page-container">
                <SuperAdminHeader />
                <div className="org-user-form">
                    <h1 className="org-user-form-title">New User Signup Request</h1>
                    <div className="org-user-form-underline"></div>
                    <p>
                        <b>{ userData.first_name } { userData.last_name }</b> (<b>{ userData.email }</b>)
                    </p>
                    <p>
                        wants to join
                    </p>
                    <h4>
                        { userData.organizations?.display_name }
                    </h4>
                    <div className="approve-reject-buttons">
                        <button className="org-user-form-action-button approve-button" onClick={() => approveSignup()}>
                            Approve Signup
                        </button>
                        <button className="org-user-form-action-button reject-button" onClick={() => rejectSignup()}>
                            Reject Signup
                        </button>
                    </div>
                    { isLoading && <PropagateLoader css={override} /> }
                </div>
            </div>
        </div>
    )
}