import { CreateForecastDto, ForecastingFormData, ProfileData } from "../interfaces";
import dayjs from "dayjs";

export const getInitialForecastFormData = async (profileData: ProfileData): Promise<ForecastingFormData> => {
    // only check if they have updated gross pay from the original survey
    const {
        date_of_birth,
        gross_pay,
        annual_raise_percentage,
        deferred_investment_deduction,
        desired_retirement_date,
        divorce_percentage,
        purchased_service_time,
        retirementKey,
        monthly_data,
        payday_count,
    } = profileData

    const retireAge = Math.round(dayjs(desired_retirement_date).diff(new Date(date_of_birth), "year", true) * 1000) / 1000;

    const return_data = {
        annual_raise_percentage: {
            profileValue: annual_raise_percentage || 0,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Annual Raise"
        },
        deferred_investment_deduction: {
            profileValue: deferred_investment_deduction || 0,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Deferred Investment Deduction"
        },
        desired_retirement_age: {
            profileValue: retireAge || 55,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Desired Retirement Age"
        },
        divorce_percentage: {
            profileValue: divorce_percentage || 0,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Divorce Percentage"
        },
        purchased_service_time: {
            profileValue: purchased_service_time || [0,0],
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Purchased Service Time"
        },
        gross_pay: {
            profileValue: gross_pay,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Gross Pay"
        },
        final_overtime_salary: {
            profileValue: Math.floor(100 * monthly_data[retirementKey]?.yearly_gross / payday_count) / 100,
            savedValue: null,
            textfieldValue: null,
            changeTitle: "Final Salary"
        }
    } as ForecastingFormData

    return return_data
}

export const generateForecastingDto = (formData: ForecastingFormData): CreateForecastDto => {
    const { final_overtime_salary,
            gross_pay,
            annual_raise_percentage,
            desired_retirement_age,
            divorce_percentage,
            deferred_investment_deduction,
            purchased_service_time
        } = formData;

    return {
        final_overtime_salary: final_overtime_salary.savedValue || null,
        gross_pay: gross_pay.savedValue || null,
        annual_raise_percentage: annual_raise_percentage.savedValue || null,
        desired_retirement_age: desired_retirement_age.savedValue || null,
        divorce_percentage: divorce_percentage.savedValue || null,
        deferred_investment_deduction: deferred_investment_deduction.savedValue || null,
        purchased_service_time: purchased_service_time.savedValue || null,
    } as CreateForecastDto
}