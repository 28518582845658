import axios from "axios";
import {
  apiDefaultOptions,
  API_ROUTE,
  getBackendUrlForPath,
} from "../helpers/APIHelpers";
import {
  AlertTypes,
  LegalTerms,
  newAccountToken,
  ResetPasswordFormData,
  NewSignUpFormData,
  User,
} from "../interfaces";
import { getStore } from "../store";
import { setAlert } from "../store/alert/alertSlice";

export const authLogin = async (email: string, password: string) => {
  const body = {
    email: email,
    password: password,
  };

  try {
    let res = await axios.post(
      getBackendUrlForPath(API_ROUTE.ACCOUNT_LOGIN),
      body,
      apiDefaultOptions
    );

    return res.data;
  } catch {
    throw new Error();
  }
};

export const getLatestTerms = async (): Promise<LegalTerms> => {
  try {
    let res = await axios.get(
      getBackendUrlForPath(API_ROUTE.ACCOUNT_GET_TERMS)
    );
    return res.data as LegalTerms;
  } catch {
    throw new Error();
  }
};

export const acceptTerms = async () => {
  try {
    await axios.post(
      getBackendUrlForPath(API_ROUTE.ACCOUNT_ACCEPT_TERMS),
      {},
      apiDefaultOptions
    );
  } catch {
    const thisStore = getStore();
    thisStore.dispatch(
      setAlert({
        text: "Could not accept the terms of conditions. Please try again.",
        type: AlertTypes.Error,
      })
    );
  }
};

export const authLogout = async () => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(API_ROUTE.ACCOUNT_LOGOUT),
      {},
      {
        withCredentials: true,
      }
    );
    return response.status;
  } catch (err) {
    throw new Error();
  }
};

export const authenticateAccountToken = async (
  token: string,
  userId: string
) => {
  try {
    const response = await axios.get(
      getBackendUrlForPath(
        `${API_ROUTE.AUTHENTICATE_ACCOUNT_TOKEN}/${userId}/${token}`
      ),
      {
        withCredentials: true,
      }
    );
    return response.data as newAccountToken;
  } catch {
    throw new Error();
  }
};

export const createNewSubscriber = async (
  body: NewSignUpFormData,
  userId: string,
  token: string
) => {
  try {
    const response = await axios
      .post(
        getBackendUrlForPath(`${API_ROUTE.CREATE_SUBSCRIBER}/${userId}/${token}`),
        body,
        apiDefaultOptions
    )
    return response.data as User;
  } catch (err) {
    throw new Error();
  }
};

export const sendResetPassword = async (email: string) => {
  const body = { email: email };
  try {
    await axios.post(getBackendUrlForPath(API_ROUTE.SEND_RESET_PASS), body, {
      withCredentials: true,
    });
  } catch {
    throw new Error();
  }
};

export const consumeResetPassword = async (
  formData: ResetPasswordFormData,
  token: string,
  userId: string
) => {
  const body = { ...formData, token: token };
  try {
    await axios.post(getBackendUrlForPath(`${API_ROUTE.CONSUME_RESET_PASS}/${userId}/${token}`), body, {
      withCredentials: true,
    });
  } catch {
    throw new Error();
  }
};

export const authenticateResetPassword = async (userId: string, token: string) => {
  try {
    const response = await axios.get(
      getBackendUrlForPath(`${API_ROUTE.AUTHENTICATE_PASS_RESET}/${userId}/${token}`),
      {
        withCredentials: true,
      }
    );
    return response.data as string;
  } catch {
    throw new Error();
  }
};

export const validateEmail = async (userId: string, token: string) => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(`${API_ROUTE.ACCOUNT_VALIDATE}/${userId}/${token}`), {},
      {
        withCredentials: true,
      }
    );
    return response.data as { success: boolean };
  } catch {
    throw new Error();
  }
};

export const removeEmail = async (userId: string, token: string) => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(`${API_ROUTE.ACCOUNT_REMOVE}/${userId}/${token}`),
      {},
      {
        withCredentials: true,
      }
    );
    return response.data as { success: boolean };
  } catch {
    throw new Error();
  }
};

export const resendValidation = async () => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(`${API_ROUTE.ACCOUNT_REVALIDATE}`),
      {},
      {
        withCredentials: true,
      }
    );
    return response.data as { success: boolean };
  } catch {
    throw new Error();
  }
};
