import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import './PaginationControls.css'

type Props = {
    count: number,
    handlePaginationChange: (index: number) => void,
    pageIndex: number
}

export const PaginationControls = ({ count, handlePaginationChange, pageIndex }: Props) => {
    const [buttons, setButtons] = useState([])

    const getPageButton = (index: number) => {
        return <button 
                className={"admin-pagination-controls-button" + (pageIndex === index ? " admin-pagination-controls-button-active" : "")}
                key={index.toString()}
                onClick={ () => handlePaginationChange(index) }>
            {index + 1}
        </button>
    }

    const getPrevPageButton = () => {
        return <button 
                className="admin-pagination-controls-button-arrow"
                key="prev_page"
                onClick={ () => handlePaginationChange(pageIndex - 1) }>
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    }

    const getNextPageButton = () => {
        return <button 
                className="admin-pagination-controls-button-arrow"
                key="next_page"
                onClick={ () => handlePaginationChange(pageIndex + 1) }>
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    }

    useEffect(() => {
        if (!count) { return }

        var btns = Array.from(Array(count).keys()).map((val, index) => {
            return getPageButton(index)
        })

        if (pageIndex !== 0) { btns.splice(0, 0, getPrevPageButton()) }
        if (pageIndex < count - 1) { btns.push(getNextPageButton()) }

        setButtons(btns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, pageIndex])

    return (
        <div className="admin-pagination-controls-container">
            { buttons }
        </div>
    )
}