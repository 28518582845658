import { BarLoader } from 'react-spinners'
import './ForecastingLoader.css'

export const ForecastingLoader = () => {
    return (
        <div className="forecasting-loader-background" onClick={ e => e.stopPropagation() }>
            <div className="forecasting-loader-container">
                <h1>Fetching Updated Data</h1>
                <p className="forecasting-loader-text">One moment while we calculate your new forecasts based on the changes you just entered...</p>
                <BarLoader color="var(--blue-purple)" height="10px" width="100%"/>
            </div>
        </div>
    )
}