import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrganizationInvitation } from "../../../api/OrganizationAPI";
import { authenticateAccountToken } from "../../../api/AuthAPI";
import {
  newAccountToken,
  NewSignUpFormData,
  OrganizationInvitationData,
} from "../../../interfaces";
import { NotFound } from "../../errors/NotFound";
import { InviteWelcome } from "../shared/welcome/InviteWelcome";
import { SignUpFormNew } from "./SignUpFormNew";
import "../shared/styles/AuthStyles.css";
import { useDispatch } from "react-redux";
import { createSubscriber } from "../../../store/auth/authSlice";

type InviteParam = {
  token: string;
  userId: string;
};

type Props = {
  isOrgInvite?: boolean;
};

export const InvitePage = ({ isOrgInvite }: Props) => {
  const [loading, setLoading] = useState(true);
  const [inviteError, setInviteError] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  const [orgInviteData, setOrgInviteData] =
    useState<OrganizationInvitationData>(null);
  const { token, userId } = useParams() as InviteParam;
  const dispatch = useDispatch();

  const onSubmit = async (signUpForm: NewSignUpFormData) => {
    await dispatch(createSubscriber({ signUpForm, userId, token}));
  };

  const getInviteData = async () => {
    try {
      if (!isOrgInvite) {
        let res: newAccountToken = await authenticateAccountToken(
          token,
          userId
        );
        console.log(res);
        if (res.used) {
        }
        setInviteData(res);
        setLoading(false);
      } else {
        let res = await getOrganizationInvitation(token);
        setOrgInviteData(res as OrganizationInvitationData);
        setLoading(false);
      }
    } catch {
      setLoading(false);
      setInviteError(true);
    }
  };

  useEffect(() => {
    if (!token || !userId) {
      return setInviteError(true);
    }
    getInviteData();
  }, [token, userId]);

  if (loading) return <div></div>;

  if (inviteError || (!inviteData && !orgInviteData)) {
    return (
      <NotFound errorMessage="Page not found due to invalid invite key." />
    );
  }

  return (
    <div className="auth-page-container">
      <InviteWelcome orgName={""} />
      <SignUpFormNew inviteData={inviteData} onSubmit={onSubmit} />
    </div>
  );
};
