import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Button } from '@mui/material'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { NewOrganization } from "../../../interfaces";

type Props = {
    organizations: NewOrganization[]
}

export const SuperAdminOrgTable = ({ organizations }: Props) => {
  const [pageSize, setPageSize] = useState<number>(100);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={`/superadmin/org-edit/${(params.row as NewOrganization)?.id}`}>
          <Button>
            {params.id}
          </Button>
        </Link>
      )
    },
    { field: 'name', headerName: 'Organization', flex: 1.8 },
    { field: 'count', headerName: 'Users', flex: 0.7 },
  ];

  const rows = organizations.map((org) => ({
      id: org.id,
      name: org.display_name,
      count: org.active_users ?? 0
  }))

  if (!organizations) {
    return;
  }

  return (
    <div>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[50, 100, 200]}
          rows={rows}
          columns={columns}
          pagination
        />
      </div>
    </div>
  )
}