import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Slider, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ProfileData } from '../../../../interfaces'
import { updateSickLeave } from '../../../../store/profile/profileSlice'
import './OverviewSickLeave.css'

type Props = {
    profileData: ProfileData
}

export const OverviewSickLeave = ({ profileData }: Props) => {
    const [monthlyIncrease, setMonthlyIncrease] = useState("0")
    const [pensionPercentageIncrease, setPensionPercentageIncrease] = useState("0")
    const [balance, setBalance] = useState(profileData.sick_leave_converted_hours_count ?? 0)
    const [sickLeaveBalance, setSickLeaveBalance] = useState("0")
    const [sliderMarks, setSliderMarks] = useState(null)
    const [sliderValue, setSliderValue] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!profileData) { return }

        var marks = [
            {
                value: 0,
                label: "0"
            },
            {
                value: getHardCap(),
                label: getHardCap().toString(),
            }
        ]

        let initialMonthlyIncrease = setData()
        setSliderMarks(marks)
        setSliderValue(profileData.sick_leave_converted_hours_count ?? 0)

        setTimeout(() => {
            dispatch(updateSickLeave({ monthlyIncrease: initialMonthlyIncrease[0], percentageGain: initialMonthlyIncrease[1] }))
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])


    const getStringDollarNumber = (num: number) => {
        return parseFloat(num.toFixed(0)).toLocaleString()
    }

    const getHardCap = () => {
        if (profileData.org_sick_leave_hard_cap && profileData.org_sick_leave_hard_cap > 0) {
            return profileData.org_sick_leave_hard_cap;
        } else {
            return 5000;
        }
    }

    const setData = () => {
        // Calculating monthly data every month, out to retirement plus 10 years, so 120 months prior is the retirement date
        const retirementData = profileData.monthly_data_keys.length > 120 ?
            profileData.monthly_data[profileData.monthly_data_keys[profileData.monthly_data_keys.length - 120]] :
            profileData.monthly_data[profileData.monthly_data_keys[profileData.monthly_data_keys.length - 1]]

        const total_sick_time_retired = Math.min(balance ?? 0, getHardCap());
        const sick_time_in_years = (total_sick_time_retired / 8 * 0.004)
        // not accurate
        const time_to_earn_back_value = sick_time_in_years * (2 / 100)

        const monthly_increase = time_to_earn_back_value * retirementData.yearly_gross / 12;

        const pension_percentage_increase = (sick_time_in_years * 2)

        setMonthlyIncrease(getStringDollarNumber(monthly_increase))
        setSickLeaveBalance(balance ? balance.toString() : "0")
        setPensionPercentageIncrease(pension_percentage_increase.toFixed(1))

        return [monthly_increase, pension_percentage_increase]
    }

    const handleChange = (e: Event, v: number | number[]) => {
        setSliderValue(v as number)
        setBalance(v as number)
    }

    const moveSlider = (value: number) => {
        var newValue = sliderValue + value
        newValue = newValue >= 0 && newValue <= profileData.org_sick_leave_hard_cap ? newValue : sliderValue
        setSliderValue(newValue)
        setBalance(newValue)
    }

    const updateSickLeaveInStore = () => {
        dispatch(updateSickLeave({ monthlyIncrease: parseFloat(monthlyIncrease), percentageGain: parseFloat(pensionPercentageIncrease) }))
    }

    useEffect(() => {
        setData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance])

    return (
        <div className="widget-container">
            <div className="widget-header-container">
                <div className="widget-title">Sick Leave Conversion Analysis</div>
                <div className="widget-configuration-button-container">
                    <Tooltip
                        placement="left"
                        title="Not all employers and/or pension systems allow members to convert sick time into service credit. This chart allows members to estimate the value/cost of converting sick time to service credit and the increase to your pension vs value of taking the time in cash."
                    >
                        <button 
                            className="widget-configuration-button widget-configuration-button-info" 
                        >
                            <InfoOutlinedIcon />
                        </button>
                    </Tooltip>
                </div>
            </div>
            <div className="sick-leave-section-container">
                <div className="dashboard-number-and-text">
                    <span>{ sickLeaveBalance }</span>
                    Sick Leave Hours To Convert
                </div>
            </div>
            <div className="overview-slider">
                <ArrowBackIcon onClick={ () => moveSlider(-1) } />
                <Slider 
                    min={ 0 } 
                    max={ getHardCap() } 
                    defaultValue={ profileData.sick_leave_converted_hours_count } 
                    valueLabelDisplay='auto'
                    marks={ sliderMarks }
                    onChange={handleChange}
                    onChangeCommitted={updateSickLeaveInStore}
                    value={sliderValue}
                />
                <ArrowForwardIcon onClick={ () => moveSlider(1) } />
            </div>
            <div className="overview-slider-label">Sick Leave Conversion Slider</div>
        </div>
    )
}