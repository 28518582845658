import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AlertTypes } from '../../../interfaces'
import { selectAlert, removeAlert } from '../../../store/alert/alertSlice'
import './AppAlert.css'

export const AppAlert = () => {
    const dispatch = useDispatch();
    const alert = useSelector(selectAlert)

    useEffect(() => {
        var timeout: NodeJS.Timeout = null
        if (alert) {
            timeout = setTimeout(() => {
                handleRemoveAlert()
            }, 4000)
        }

        return () => clearInterval(timeout)
    }, [alert])

    const handleRemoveAlert = () => {
        dispatch(removeAlert())
    }

    const getIconForAlertType = (type: AlertTypes): IconProp => {
        switch (type) {
            case AlertTypes.Success:
                return faCheck 
            default:
                return faTimes
        }
    }

    if (alert?.type) {
        return (
            <div className={ (`app-alert-container app-alert-${alert?.type?.toLowerCase()}`) } onClick={ e => handleRemoveAlert() }>
                <FontAwesomeIcon className="app-alert-icon" size={"2x"} icon={getIconForAlertType(alert.type)} />
                <div className="app-alert-text-container">
                    <h1 className="app-alert-title">{ alert.type }</h1>
                    <h1 className="app-alert-text">{ alert.text }</h1>
                </div>
            </div>
        )
    }

    return null
}