import { css } from '@emotion/core'
import { Autocomplete } from '@mui/material';
import { FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridActionsCellItem, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PropagateLoader } from 'react-spinners';
import { getAllNewOrganizations } from '../../../../api/OrganizationAPI';
import { validateOrgForm } from '../../../../helpers/FormHelpers'
import { UserFormStatus, UserFormValues, NewOrganization, User, UserRole, UserStatus } from '../../../../interfaces'
import './UserForm.css'

const override = css`
  margin: 50px auto;
`;

type CurrentState = {
    [key: string]: [],
}

export interface UserFormProps {
    formTitle: string,
    formActionButtonTitle: string,
    formAction: (inputs: UserFormValues) => void,
    values?: UserFormValues,
    isLoading: boolean,
    user?: User | undefined,
}

export const defaultFormValues: UserFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    role: UserRole.User,
    status: UserStatus.Active,
    completedSurvey: false,
    organization: null,
    deletedVars: [],
}

export const UserForm = ({ isLoading, formTitle, formActionButtonTitle, formAction, user = undefined }: UserFormProps) => {
  const [loading, setLoading] = useState(isLoading)
  const [organizations, setOrganizations] = useState<{[key: string]: any}>({})
  const [availableOrgs, setAvailableOrgs] = useState([])
  const [inputValues, setInputValues] = useState(defaultFormValues)
  const [formErrors, setFormErrors] = useState<UserFormStatus[]>(null)
  const [rows, setRows] = useState([])

  const handleDeleteClick = (id: GridRowId) => () => {
    setInputValues({...inputValues, deletedVars: [...inputValues.deletedVars, id]})
    setRows(rows.filter((row: any) => row.id !== id));
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isUpdated: true };
    setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const retrieveOrganizations = async () => {
    try {
        setLoading(true)
        const orgs = await getAllNewOrganizations()
        setOrganizations(orgs)
    } catch {
        setLoading(false)
    }
  }

  useEffect(() => {
    if (!user || rows.length > 0) return

    setInputValues({
      firstName: user.first_name,
      lastName: user.last_name,
      organization: user.organizations,
      deletedVars: [],
      ...user,
    })

    let userRows: {id: string, value: any, type: string}[] = []
    
    Object.keys(user.metadata).forEach((m: string, index) => {
      if (m === 'totalProgress' || m === 'userProgress') return;
      userRows.push({
        id: m,
        value: user[m as keyof User],
        type: user.metadata[m]
      })
    });

    userRows.sort((a, b) => a.type > b.type ? -1 : 0)

    setRows(userRows)
  }, [user])

  useEffect(() => {
    if (!user?.organizations || !Object.keys(organizations).length) return

    const { state_name, id } = user.organizations;

    const currentState = organizations[state_name];
    const currentOrg = currentState.filter((org: NewOrganization) => org.id === id)[0];

    setInputValues({...inputValues, organization: currentOrg})
  }, [organizations])

  const columns = [
    { field: 'id', headerName: 'Var name', flex: 1.5 },
    { field: 'type', headerName: 'Type', flex: 0.5 },
    { field: 'value', headerName: 'Value', flex: 1.8, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Delete',
      cellClassName: 'actions',
      getActions: ({ id, row }: { id: string, row: GridRowModel }) => {
        if (row.type !== 'user') return [];

        return [<GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />];
      }
    },
  ]

  const handleInputChange = (key: keyof UserFormValues, value: any) =>
    setInputValues({...inputValues, [key]: value});

  const handleClick = (value: string) => {
    if (value === null) {
      setAvailableOrgs([])
        return;
    }
    setAvailableOrgs(organizations[value])
    setInputValues({...inputValues, organization: null})
  }

  const handleAction = () => {
    const status = validateOrgForm(inputValues)
    if (!status) {
        formAction(inputValues)
        return
    }

    setFormErrors(status)
  }

  const formContainsError = (err: UserFormStatus) => {
      return formErrors && formErrors.includes(err)
  }

  useEffect(() => {
      retrieveOrganizations();
      setLoading(false);
  }, [])

  if (isLoading) return <></>

  return (
    <div className='org-user-form'>
      <h1 className='org-user-form-title'>{ formTitle }</h1>
      <div className='org-user-form-underline'></div>
      <div className='org-user-form-input'>
          <TextField
              fullWidth
              label='Email'
              error={formContainsError(UserFormStatus.InvalidEmail)}
              value={ inputValues.email }
              onChange={ e => handleInputChange('email', e.target.value) }
          />
          { formContainsError(UserFormStatus.InvalidEmail) &&
          <div className='org-user-form-error-message'>{ UserFormStatus.InvalidEmail }</div>
          }
      </div>
      <div className='org-user-form-input'>
          <TextField
              fullWidth
              label='First Name'
              error={formContainsError(UserFormStatus.InvalidFirstName)}
              value={ inputValues.firstName }
              onChange={ e => handleInputChange('firstName', e.target.value) }
          />
          { formContainsError(UserFormStatus.InvalidFirstName) &&
          <div className='org-user-form-error-message'>{ UserFormStatus.InvalidFirstName }</div>
          }
      </div>
      <div className='org-user-form-input'>
          <TextField
              fullWidth
              label='Last Name'
              error={formContainsError(UserFormStatus.InvalidLastName)}
              value={ inputValues.lastName }
              onChange={ e => handleInputChange('lastName', e.target.value) }
          />
          { formContainsError(UserFormStatus.InvalidLastName) &&
          <div className='org-user-form-error-message'>{ UserFormStatus.InvalidLastName }</div>
          }
      </div>
      <div className='org-user-form-input'>
        <FormControl fullWidth>
          <InputLabel id='account-input'>Account Type</InputLabel>
          <Select
              fullWidth
              labelId='account-input'
              label='Account Type'
              style={{ textAlign: 'left'}}
              value={ inputValues.role }
              onChange={(event: any) => {
                  handleInputChange('role', event.target.value)
              }}
          >
            <MenuItem value={UserRole.Super}>SuperAdmin</MenuItem>
            <MenuItem value={UserRole.User}>User</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='org-user-form-input'>
        <FormControl fullWidth>
          <InputLabel id='completed-survey'>Completed Survey</InputLabel>
          <Select
              fullWidth
              labelId='completed-survey'
              label='Completed Survey'
              style={{ textAlign: 'left'}}
              value={ inputValues.completedSurvey ? 1 : 0 }
              onChange={(event: any) => {
                  handleInputChange('completedSurvey', Boolean(event.target.value))
              }}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </Select>
        </FormControl>
      </div>
      {(inputValues.role === UserRole.User) && <div className='org-user-form-input'>
          Specify an organization
          <Autocomplete
              fullWidth
              disablePortal
              sx={{ marginTop: '0.75rem' }}
              options={Object.keys(organizations).sort()}
              onChange={(e, newValue) => handleClick(newValue)}
              renderInput={(params) => <TextField label='State' {...params} />}
          />
          <Autocomplete
            options={availableOrgs || []}
            sx={{ marginTop: '0.75rem' }}
            value={inputValues.organization}
            getOptionLabel={(option) => `${option.display_name}`}
            onChange={(e, newValue) => setInputValues({...inputValues, organization: newValue})}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) =>
                option.display_name.toLowerCase().includes(inputValue.toLowerCase())
              );
            }}
            renderInput={(Props) => <TextField
                label='Organization Name'
                {...Props}
                variant="outlined"
            />}
          />
        </div>}
      <>
          <Box className='org-user-form-input'>
          Variables
          </Box>
          <Stack
            className='org-user-form-input'
            spacing={1}
          >
          <DataGrid
            autoHeight
            pageSize={100}
            rows={rows}
            columns={columns}
            pagination
            processRowUpdate={processRowUpdate}
          />
          </Stack>
      </>
      {
        inputValues !== undefined && inputValues.role === UserRole.Super &&
        <div className='org-user-form-input'>
          <FormControl fullWidth>
            <InputLabel id='account-input'>Account Status</InputLabel>
            <Select
                fullWidth
                labelId='account-input'
                label='Account Status'
                style={{ textAlign: 'left'}}
                value={ inputValues.status ?? 1 }
                onChange={(event: any) => {
                    handleInputChange('status', event.target.value)
                }}
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
      }
      { !isLoading &&
          <button className='org-user-form-action-button' onClick={e => handleAction()}>
              { formActionButtonTitle }
          </button>
      }
      { isLoading && <PropagateLoader css={override} /> }
    </div>
  )
}