  import { SurveyQuestion } from "./SurveyQuestion";

  export interface SurveyAPIData {
      questions: SurveyQuestion[],
  }

  export enum SurveyApiActions {
      Error = "error",
      Continue = "continue",
      Ask = "ask",
      Skip = "skip",
  }

  interface PreSurveyAPIDataError {
      action: SurveyApiActions.Error
  }

  interface PreSurveyAPIDataContinue {
      action: SurveyApiActions.Continue
  }

  interface PreSurveyAPIDataSkip {
      action: SurveyApiActions.Skip
  }

  interface Plan {
    rsbId: number,
    level: string,
    name: string,
    planCompPeriod: number,
    planId: number,
    retirementBenefitCompPeriod: number,
    state_codes: string,
    systemLongName: string,
    system_name: string,
    type: string,
    socialSecurity: boolean,
  }

  export interface AllPlans {
    [key: string]: {
      systemId: number,
      plans: Plan[]
    };
  }


  export interface Reciprocity {
    id: number,
    parentSystemId: number,
    peerSystemId: number,
  }

  export interface RetirementSystem {
      id: number,
      name: string,
      short_name: string,
  }

  export interface RetirementBenefit {
      id: number,
      name: string,
      type: string,
      level: string,
      max_percent: string,
      scaled: boolean,
      early_reduction: boolean,
      hire_eligibility: {
          start: null | string,
          end: null | string,
      }
      age_service_eligibility: {
          [key: string]: any,
      }
      min_service_years: string,
      calculation: {
          [key: string]: any,
      },
      hasOvertime: boolean,
      benefit_type: string,
      final_comp_months: string | null,
      final_comp_adj: number,
      retirementSystemId: number,
      retirement_system: RetirementSystem,
      social_security: boolean,
  }

  export interface PlanBenefit {
      id: number,
      retirementBenefit: RetirementBenefit,
      retirementBenefitId: number,
      sick_leave: boolean,
      final_comp_months: number,
      holiday_leave: boolean,
      vacation_leave: boolean,
  }

  interface PreSurveyAPIDataAsk {
      action: SurveyApiActions.Ask,
      choices?: PlanBenefit[],
      name?: string
  }

  export type PreSurveyAPIData =
      | PreSurveyAPIDataError
      | PreSurveyAPIDataAsk
      | PreSurveyAPIDataContinue
      | PreSurveyAPIDataSkip