import { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ConfettiComponent = ({ showConfetti }: { showConfetti: boolean }) => {
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showConfetti) {
      setIsConfettiVisible(true);
      timer = setTimeout(() => setIsConfettiVisible(false), 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showConfetti]);

  return <div>{isConfettiVisible && <Confetti />}</div>;
};

export default ConfettiComponent;
