import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { useEffect } from 'react';
import { SurveyQuestion } from '../../../interfaces';
import { Field } from 'react-final-form';
import {getStore} from '../../../store';
import { selectHireDate } from '../../../store/survey/surveySlice';
import './DatePicker.css';

type Props = {
    question: SurveyQuestion,
}

//todo: normalize dates

export const NewDatePicker = ({ question }: Props) => {
  const placeholderDate = "01/01/1960"

  useEffect(() => {
    const hireDate = selectHireDate(getStore().getState())
    if (question.text.toLowerCase().includes("date of birth") && hireDate) {
      // Date 15 years before hire
      const tempDate = new Date(hireDate)
      tempDate.setFullYear(tempDate.getFullYear() - 15)

      // Date required for user to be 18 years old
      const eighteenDate = new Date()
      eighteenDate.setFullYear(eighteenDate.getFullYear() - 18)

      // Get the lowest of these two dates to set it as the max
      const dates = [tempDate, eighteenDate]
      console.log(dates)
      const min = dates.reduce(function (a, b) { return a < b ? a : b; }); 
    }

  }, [])

  return (
      <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Field
            type="date"
            component="input"
            name={question.variable.name}
            value={ parseISO(question.prefill) || new Date(placeholderDate) }
          >
          {({input: {onChange, value}}) => (
          <DatePicker
              format="MM/dd/yyyy"
              value={new Date(value) || new Date()}
              onChange={onChange}
              onAccept={onChange}
              disabled={question.locked}
          />)}
          </Field>
          </LocalizationProvider>
          {/* {
            isValid( currentAnswer ) &&
            isBefore( currentAnswer, minDate) &&
            <p>Please enter a date after { minDate.toDateString() }</p>
          }
          {
            isValid( currentAnswer ) &&
            isAfter( currentAnswer, maxDate ) &&
            (((((new Date()).getFullYear() - (new Date(currentAnswer)).getFullYear()) >= 18) || !question.text.toLowerCase().includes("date of birth")) ?
            <p>Please enter a date before { maxDate.toDateString() }</p>
            : <p>You must be at least eighteen years old.</p>)
          } */}
      </div>
  )
}