export const required: any = (value: any) => ((value !== null && value !== undefined && ((typeof value !== 'string') || !!(value.trim()))) ? undefined : "Field is required.");
export const mustBeNumber: any = (value: any) => ((value && isNaN(value)) ? "Must be a number" : undefined);
export const minValue: any = (min: number) => (value: any) =>
  isNaN(value) || value >= min ? undefined : `Should be at least ${min}`;
export const maxValue: any = (max: number) => (value: any) =>
  isNaN(value) || value <= max ? undefined : `Should not be more than ${max}`;
export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error: string | undefined, validator: any) => error || validator(value), undefined);

export const fieldParseInt = (value: any) => {
    const parsed = parseInt(value)
    return (typeof parsed === 'number' && !isNaN(parsed)) ? parsed : undefined
}

export const fieldParseFloat  = (value: any) => {
    const parsed = parseFloat(value)
    return (typeof parsed === 'number' && !isNaN(parsed)) ? parsed : undefined
}