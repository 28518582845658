import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, CardMedia, Button, useMediaQuery, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Loader, { LoaderType } from '../overview/loader/Loader'

const VideoText = () => (
  <div>
    Welcome to the future of pension tracking!
    <p>
      On your profile page, you will find several easy-to-use customizable options, such as our “Income Forecast Slider” where you will have the ability to adjust your pensionable income, retirement date, years of service and estimated pension.
    </p>
    <p>
      You can also change the way you view your income, switching from annual to monthly to biweekly or even gross and net pay.
    </p>
    <p>
      You can further customize your pension information by selecting the “Customize my data” menu where you will have the ability to account for things such as annual raises, special pay, pension contributions, divorce and even additional retirements.
    </p>
    <p>
      With a simple click of a button, you can move between several tabs that provide detailed information and unique outlooks.
    </p>
    <p>
      Click on the “Tax” tab where you can view your state and federal taxes.
    </p>
    <p>
      Ever consider moving out of state? Well, we give you the option to select any state and find out how much you would save in state taxes.
    </p>
    <p>
      If you click on the “Growth” tab, you can view the growth of your retirement and even more importantly you can determine the best time to retire.
    </p>
    <p>
      Next, is the “Breakdown” tab where you can view all of your retirement data in one place to ensure the information being provided is accurate.
    </p>
    <p>
      Select the tab labeled, “Calculator” and create retirement scenarios where you can include such things as  investments and personal expenditures, merging the real-life information necessary to make financial decisions to retire.
    </p>
    <p>
      Click on the “Forecasting” menu and you will have the ability to forecast the impact of decisions such as, divorce, change in pay, change in retirement date and give you the ability to view the information side by side, where you can compare the results.
    </p>
    <p>
      Have question? Click on the message box to reach our 24/7 help desk.
    </p>
  </div>
)

export const Tutorial = ({ open = false, onClose }: { open : boolean, onClose : () => void }) => {
    const isMobile = useMediaQuery('(max-width:600px)')
    const [showText, setShowText] = useState(false)
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)

    const handleVideoLoad = () => {
      setIsVideoLoaded(true)
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={isMobile}
      >
        <DialogTitle>
          Welcome Tutorial
          <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CardMedia
            component='video'
            autoPlay
            src='https://pensionapp-assets.s3.us-west-2.amazonaws.com/tutorial.mov'
            controls
            onLoadedData={handleVideoLoad}
          />
            <Button
              variant='contained'
              onClick={() => setShowText(!showText)}
              sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            >
              {showText ? 'Hide' : 'See'} Video Text
            </Button>
            {showText && <VideoText />}
        </DialogContent>
      </Dialog>
    )
}