export enum ChartTypes {
    Bar,
    Line
}

export enum PensionDataTypes {
    Gross = "Gross",
    Net = "Net"
}

export type CalculatorDataPoint = {
  name: string,
  amount: number,
  amount1?: number,
}

export type GraphDataPoint = {
    name: string,
    gross: number,
    early: number,
    net: number,
    date: Date
}

export enum TimePeriod {
    Annual = "Annual",
    Monthly = "Monthly",
    BiWeekly = "Bi-Weekly"
}