import './Option.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { SurveyAnswerOption } from '../../../../interfaces'

type Props = {
    isActive: boolean,
    answerOption: SurveyAnswerOption,
    handleSelection: (answerOption: SurveyAnswerOption) => void
}

export const Option = ({ isActive, answerOption, handleSelection }: Props) => {
    function handleOptionClicked() {
        handleSelection(answerOption)
    }

    return (
        <div className={"option" + (isActive ? " option-active" : "")} onClick={handleOptionClicked}>
            <div className="flex-default">
                <span className={"option-letter" + (isActive ? " option-letter-active" : "")}>
                    { answerOption.shortcutKey }
                </span>
                { answerOption.text }
            </div>
            <div>
                { isActive && <FontAwesomeIcon className={"option-check"} icon={faCheck} />}
            </div>
        </div>
    )
}