import './MultipleField.css'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SurveyQuestion } from '../../../interfaces'
import { useSelector } from 'react-redux'
import { selectCurrentQuestion } from '../../../store/survey/surveySlice'
import { getComponentForAnswerType } from '../../../helpers/QuestionHelpers'

type Props = {
    userInput: any[],
    showMinus: boolean,
    showPlus: boolean,
    index: number,
    handleAdd: () => void,
    handleRemove: (index: number) => void,
    handleInputChange: (val: (string | number)[], index: number) => void
}

export const MultipleField = ({ userInput, showMinus, showPlus, index, handleAdd, handleRemove, handleInputChange}: Props) => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion

    const handleValueChanged = (val: any, inputIndex?: number) => {
        const temp = [...userInput]
        temp[inputIndex] = val
        handleInputChange(temp, index)
    }

    const getFieldComponents = () => {
        var comps: JSX.Element[] = []
        question.answerType[0].forEach((a, answerIndex) => {
            if (answerIndex > 0) {
                comps.push(
                    <div className="multiple-field-left-spacing" key={answerIndex.toString()}>
                        { getComponentForAnswerType(a, handleValueChanged, answerIndex, undefined, userInput[answerIndex]) }
                    </div>
                )
            } else {
                comps.push(getComponentForAnswerType(a, handleValueChanged, answerIndex, undefined, userInput[answerIndex]))
            }
        })

        return comps
    }

    return (
        <div className="multiple-field-container">
            { getFieldComponents() }
            { showMinus && <FontAwesomeIcon className="number-toggle-button" icon={faMinusCircle} onClick={ e => handleRemove(index) } /> }
            { showPlus && <FontAwesomeIcon className="number-toggle-button" icon={faPlusCircle} onClick={ e => handleAdd() } /> }
        </div>
    )
}