import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { SurveyQuestion, SurveyResult, ProfileData } from '../../../interfaces';
import {getStore} from '../../../store';
import { selectProfileData } from '../../../store/profile/profileSlice'
import { selectCurrentQuestionResult, selectGrossPay, selectNetPay } from '../../../store/survey/surveySlice';
import { NumberDateEdit } from '../number-date/NumberDateEdit';
import { NumberTax } from '../number-tax/NumberTaxEdit';
import { mustBeNumber, minValue, composeValidators } from '../../../helpers/ValidationHelpers';
import './Number.css';

export const getNumberValue = (num: number) => {
    if (num === undefined || num === null || isNaN(parseFloat(num.toString()))) {
        return 0
    }
    return parseFloat(num.toString())
}

type Props = {
    question: SurveyQuestion,
}

export const Number = ({ question }: Props) => {
    const profileData = useSelector(selectProfileData) as ProfileData
    const [estimate, setEstimate] = useState<string>('default')

    useEffect(() => {
        // add validators
        // no negative numbers
        if (question.text.toLowerCase().includes("hourly cashout")) {
          const varName = question.variable.name as keyof ProfileData;
          question.prefill = Math.floor(profileData[varName] as number * 100) / 100;
          setEstimate(profileData?.metadata?.[varName] || "default")
        }
    }, [question])

    if (question.text.toLowerCase().includes("federal") || question.text.toLowerCase().includes("state tax")) {
        return <NumberTax question={question} />
    }

    if (question.text.toLowerCase().includes("retirement age")) {
        return <NumberDateEdit question={question} />
    }

    return (
        <div className="number-container">
            <div className="number-input-container">
                {/* todo: fix styling on pre and post field decorators. some of these classes are unused */}
                { question.preFieldDecorator && <span className="number-symbol-edit number-symbol-pre-edit">{ question.preFieldDecorator.flat() }</span> }
                <Field
                    component="input"
                    name={question.variable.name}
                    type="number"
                    initialValue={ Math.max(question.prefill, 0) || 0}
                    readOnly={ question.locked }
                    validate={composeValidators(mustBeNumber, minValue(0))}
                    >
                    {({ input, meta }) => (
                        <>
                        { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol-edit">#</span> }
                        <input
                            className="number-input-edit"
                            {...input}
                            type="number"
                        />
                        { question.postFieldDecorator && <span className="number-symbol-edit number-symbol-post">{ question.postFieldDecorator.flat() }</span> }
                        {meta.error && meta.touched &&
                            <span className="number-input-edit error">{meta.error}</span>
                        }
                        </>
                    )}
                </Field>
                {question.text.includes("hourly cashout") && estimate === "default"
                    && <div style={{color: "red"}}>*estimated calculated rate</div>
                }
            </div>
        </div>
    )
}