import { useEffect, useState } from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import { Box } from "@mui/material";

import { removeEmail } from "../../../api/AuthAPI";
import { ResponsiveAppBar } from "../../home/homeLandingPage";
import Loader, { LoaderType } from "./loader/Loader"

type ValidateParams = {
  userId: string;
  token: string;
};

const EmailRemoval: React.FC<RouteComponentProps<ValidateParams>> = () => {
  const { userId, token } = useParams() as ValidateParams;
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(undefined);

  const validate = async () => {
    const result = await removeEmail(userId, token);
    setSuccess(result.success);
    setIsLoading(false);
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Box
        sx={{
          backgroundColor: "var(--dashboard-light-purple)",
          padding: "var(--dashboard-widget-padding)",
          borderRadius: "var(--dashboard-border-radius)",
          textAlign: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {isLoading && <Loader text="Validating token..." type={LoaderType.Ring} size={25} />}
        {!isLoading && <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          {success ? (
            <>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                Success! Your email has been removed.
              </Box>
            </>
          ) : (
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              The token has already been used or does not exist.
            </Box>
          )}
        </Box>}
      </Box>
    </>
  );
};

export default EmailRemoval;
