import { useEffect, useState } from "react";
import { Field, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { TextField, Box, Button } from "@mui/material";

import { SurveyQuestion } from "../../../interfaces";

import SubmitButton from "../submit/SubmitButton";
import {
  composeValidators,
  mustBeNumber,
  minValue,
  required,
} from "../../../helpers/ValidationHelpers";
import "./AllowsMultiple.css";

type Props = {
  question: SurveyQuestion;
  form: any;
};

const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    style={{
      color: "red",
      fontWeight: "bolder",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
    }}
  >
    {children}
  </Box>
);

export const AllowsMultipleEdit = ({ question, form }: Props) => {
  return (
    <div className="allow-multiple-container">
      <FieldArray name={`${question.variable.name}`}>
        {({ fields }) =>
          fields.map((name, index) => (
            <Box
              key={name}
              sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
            >
              <Field name={`${name}[0]`} type="input">
                {({ input, meta }) => (
                  <>
                    <TextField
                      inputProps={{ type: "number" }}
                      style={{ marginTop: "0.25rem" }}
                      placeholder="Enter amount"
                      value={input.value}
                      onChange={(e) => input.onChange(e.target.value)}
                      fullWidth
                    />
                    {meta.error && meta.touched && <Text>{meta.error}</Text>}
                  </>
                )}
              </Field>
              <Field name={`${name}[1]`} type="input">
                {({ input, meta }) => (
                  <>
                    <TextField
                      inputProps={{ type: "string" }}
                      style={{ marginTop: "0.25rem" }}
                      placeholder="Enter description"
                      value={input.value}
                      onChange={(e) => input.onChange(e.target.value)}
                      fullWidth
                    />
                    {meta.error && meta.touched && <Text>{meta.error}</Text>}
                  </>
                )}
              </Field>
              <Button
                type="button"
                variant="outlined"
                sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                onClick={() => fields.remove(index)}
              >
                Remove
              </Button>
            </Box>
          ))
        }
      </FieldArray>
      <div>
        <Button
          type="button"
          variant="outlined"
          onClick={() =>
            form.mutators.push(`${question.variable.name}`, [])
          }
        >
          Add
        </Button>
      </div>
    </div>
  );
};
