import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProfileData } from "../../../../interfaces";
import { getTickerDate } from "./OverviewStatus";

type Props = {
    profileData: ProfileData,
    moveSlider: (num: number) => void,
    sliderMarks: any,
    sliderValue: number,
    handleChange: (e: Event, v: number | number[]) => void,
    currentDate: Date
}

export const OverviewStatusSlider = ({ 
    profileData, 
    moveSlider, 
    sliderMarks, 
    sliderValue, 
    handleChange, 
    currentDate 
    }: Props) => {
        return (
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <ArrowBackIcon onClick={ () => moveSlider(-1) } />
                <Slider 
                    min={ sliderMarks[0].value } 
                    max={ sliderMarks[1].value } 
                    defaultValue={ 0 }
                    value={sliderValue}
                    marks={ sliderMarks }
                    valueLabelDisplay="auto"
                    getAriaValueText={(x) => `${getTickerDate(new Date(profileData.monthly_data_keys[x]))}` }
                    valueLabelFormat={(x) => `${getTickerDate(new Date(profileData.monthly_data_keys[x]))}` }
                    onChange={handleChange}
                />
                <ArrowForwardIcon onClick={ () => moveSlider(1) } />
            </Stack>
        )
}