import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { selectSubscriptionStatus } from '../../../../store/auth/authSlice';
import { state_tax_data } from "../../../../data/tax_data";
import { ProfileData } from '../../../../interfaces'
import { StateMapTooltipContent, StateTaxBracket } from '../../../../interfaces';
import { formatNumbers, formatPercentage } from '../../../../utils/numberDateFormatters';
import { MapChart } from './MapChart';
import './StateMapWidget.css';

type Props = {
    profileData: ProfileData
}

export const StateMapWidget = ({ profileData }: Props) => {
    const [yearlyStateTax, setYearlyStateTax] = useState<string>(null)
    const [taxRate, setTaxRate] = useState<string>(null)
    const [content, setContent] = useState<StateMapTooltipContent>(null)
    const isSubscribed = useSelector(selectSubscriptionStatus);

    const getTaxRate = (state_tax_data: StateTaxBracket[]) => {
        const yearlyGross = profileData.monthly_data[profileData.monthly_data_keys[0]].yearly_gross - 24000
        var taxRate = 0
        state_tax_data.forEach((d) => {
            if (yearlyGross > d.single.brackets && yearlyGross < d.single.total) {
                taxRate = d.single.rates
            }
        })

        return taxRate
    }

    const getProjectedYearlyStateTax = (state_tax_data: StateTaxBracket[]) => {
        const yearlyGross = profileData.monthly_data[profileData.monthly_data_keys[0]].yearly_gross - 24000
        var projected_tax = 0
        state_tax_data?.forEach((d) => {
            if (yearlyGross > d.single.brackets) {
                const topValue = Math.min(d.single.total, yearlyGross)
                projected_tax += (topValue - d.single.brackets) * (d.single.rates / 100)
            }
        })

        return projected_tax
    }

    useEffect(() => {
        if (!content || !profileData) { return }

        var hover_tax_rate = 0
        var projectedYearlyStateTax = 0
        if (content.stateName in state_tax_data) {
            hover_tax_rate = getTaxRate(state_tax_data[content.stateName])
            projectedYearlyStateTax = getProjectedYearlyStateTax(state_tax_data[content.stateName])
        }

        setYearlyStateTax(formatNumbers(projectedYearlyStateTax, isSubscribed))
        setTaxRate(formatPercentage(hover_tax_rate, isSubscribed))
        // eslint-disable-next-line
    }, [content, profileData])

    return (
        <div
            className="widget-container widget-container-full"
        >
            <div className="state-section-container">
                <div className="state-left-section">
                    <div>
                        <h1 className="widget-title">Marginal State Tax Map</h1>
                        <p className="widget-light-text">What could i save if i moved out of state?</p>
                    </div>
                </div>
                <div className="state-map-chart-container">
                    <MapChart setTooltipContent={setContent} />
                    { content && <ReactTooltip>
                        <div className="map-tooltip-section">
                            <h1>{ content ? content.stateName : "" }</h1>
                        </div>
                        <div className="map-tooltip-section">
                            Marginal State Tax Rate: { taxRate }%
                        </div>
                        <div className="map-tooltip-section">
                            Projected Yearly State Tax: ${ yearlyStateTax }
                        </div>
                    </ReactTooltip> }
                </div>
            <div className="state-item-container">
                <div className="state-item">
                    <div className="state-item-row">
                        <div className="state-blue-bar"></div>
                        <p className="state-item-number">No State Income Tax</p>
                    </div>
                    <div className="state-item-row">
                        <div className="state-gray-bar"></div>
                        <p className="state-text">AK, FL, TN, WY, NH, SD, TX, WA, and NV</p>
                    </div>
                </div>
                <Divider />
                <div className="state-item">
                    <div className="state-item-row">
                        <div className="state-blue-bar"></div>
                        <p className="state-item-number">No Pension Tax</p>
                    </div>
                    <div className="state-item-row">
                        <div className="state-gray-bar"></div>
                        <p className="state-text">AL, AK, FL, IL, IA, HI, MS, NV, NH, PA, SD, TN, TX, WA, and WY</p>
                    </div>
                </div>
                <Divider />
            </div>
            </div>
        </div>
    )
}