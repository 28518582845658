import { AuthErrors, LoginFormData, ResetPasswordFormData, SignUpFormData, NewSignUpFormData, UserFormStatus, UserFormValues } from "../interfaces"

export const validateSignUpForm = (form: SignUpFormData | NewSignUpFormData, newForm = false) => {
    var errors = []
    if (form.firstName.trim() === "") {
        errors.push(AuthErrors.InvalidFirstName)
    }
    if (form.email.trim() === "") {
        errors.push(AuthErrors.InvalidEmail)
    }
    if (form.lastName.trim() === "") {
        errors.push(AuthErrors.InvalidLastName)
    }
    if (form.setPass.trim() === "") {
        errors.push(AuthErrors.InvalidPassword)
    }
    if (form.confirmPass.trim() === "") {
        errors.push(AuthErrors.InvalidConfirmPassword)
    }
    if (form.confirmPass !== form.setPass) {
        errors.push(AuthErrors.PasswordMatching)
    }
    if (!form.acceptedTerms) {
        errors.push(AuthErrors.InvalidTerms)
    }
    if (form.organization === null && newForm) {
        errors.push(AuthErrors.InvalidOrganization)
    }

    return errors.length > 0 ? errors : null
}

export const validateResetForm = (form: ResetPasswordFormData) => {
    var errors = []
    if (form.newPassword.trim() === "") {
        errors.push(AuthErrors.InvalidPassword)
    }
    if (form.confirmPassword.trim() === "") {
        errors.push(AuthErrors.InvalidConfirmPassword)
    }
    if (form.newPassword !== form.confirmPassword) {
        errors.push(AuthErrors.PasswordMatching)
    }

    return errors.length > 0 ? errors : null
}

export const validateLoginForm = (form: LoginFormData) => {
    var errors = []
    if (form.email.trim() === "") {
        errors.push(AuthErrors.InvalidEmail)
    }
    if (form.password.trim() === "") {
        errors.push(AuthErrors.InvalidPassword)
    }

    return errors.length > 0 ? errors : null
}

export const validateForgotForm = (email: string) => {
    var errors = []
    if (email.trim() === "") {
        errors.push(AuthErrors.InvalidEmail)
    }
    return errors.length > 0 ? errors : null
}

export const validateOrgForm = (form: UserFormValues): UserFormStatus[] => {
    var formErrors = []
    if (form.firstName.trim() === "") {
        formErrors.push(UserFormStatus.InvalidFirstName)
    }
    if (form.lastName.trim() === "") {
        formErrors.push(UserFormStatus.InvalidLastName)
    }
    if (form.email.trim() === "" || !validateEmail(form.email)) {
        formErrors.push(UserFormStatus.InvalidEmail)
    }

    return formErrors.length > 0 ? formErrors : null
}

function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}