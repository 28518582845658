import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import cx from "classnames";

import { Bars } from "../../svg";
import {
  authLogoutUser,
  selectFirstName,
  selectIsValidated,
  selectLastName,
  selectUserEmail,
} from "../../../store/auth/authSlice";
import { selectForecastProfile } from "../../../store/forecast/forecastSlice";
import { useWindowSize } from "../../../hooks";
import { ConfirmAlert } from "../../alerts/confirm-alert/ConfirmAlert";
import { UserPageRoute } from "../UserPage";
import { MenuButton } from "./MenuButton";
import { Tutorial } from "../tutorial/Tutorial";
import "./Menu.css";
import { useAppDispatch } from "../../../store/hooks";

const drawerWidth = 240;

type Props = {
  activePage: UserPageRoute;
};

export const openZendesk = (userEmail: string, fullName: string) => {
  window.zE("webWidget", "show");
  window.zE("webWidget", "prefill", {
    name: {
      value: fullName,
      readOnly: true,
    },
    email: {
      value: userEmail,
      readOnly: true,
    },
  });
  window.zE("webWidget", "open");
};

export const Menu = ({ activePage }: Props) => {
  const [buttons, setButtons] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const forecastData = useSelector(selectForecastProfile);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const userEmail = useSelector(selectUserEmail);
  const fullName = `${firstName} ${lastName}`;
  const windowSize = useWindowSize();
  const isVerified = useSelector(selectIsValidated);

  useEffect(() => {
    if (windowSize && windowSize.width < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  const handleMenuClick = (newPage: UserPageRoute) => {
    if (newPage === UserPageRoute.Logout) {
      setShowLogoutConfirmation(true);
      return;
    } else if (newPage === UserPageRoute.Tutorial) {
      // placeholder here for the new video tutorial
      setShowTutorial(true);
    } else if (newPage === UserPageRoute.Help) {
      openZendesk(userEmail, fullName);
    } else {
      history.push(`/${newPage}`);
    }
    setMobileOpen(false);
  };

  const handleLogout = async () => {
    await dispatch(authLogoutUser());
  };

  const toggleDrawer = () => setMobileOpen(!mobileOpen);

  const renderButtons = (pages: UserPageRoute[]) =>
    pages.map((page) => {
      const isPageEnabled = activePage !== "Verify" ||
      (activePage === "Verify"
      && (page === "Logout" || page === "Help"))
      return (
      <MenuButton
        isMobile={isMobile}
        onClick={() => handleMenuClick(page)}
        type={page}
        isActive={activePage === page}
        key={page}
        isDisabled={!isPageEnabled}
      />
    )}
    );

  useEffect(() => {
    const Buttons = [
      UserPageRoute.Overview,
      ...(forecastData ? [UserPageRoute.Compare] : []),
      UserPageRoute.Settings,
      UserPageRoute.Tutorial,
      UserPageRoute.Subscribe,
      UserPageRoute.Help,
      UserPageRoute.Logout,
    ];

    const menuButtons = renderButtons(Buttons);

    setButtons(menuButtons);
  }, [activePage, forecastData, windowSize, isMobile]);

  return (
    <>
      {(!isMobile || mobileOpen) && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "&.MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          open={!isMobile || mobileOpen}
          onClose={toggleDrawer}
        >
          {buttons}
          <ConfirmAlert
            open={showLogoutConfirmation}
            handleClose={() => setShowLogoutConfirmation(false)}
            onAction={handleLogout}
            actionTitle="Logout"
            dialogContent="Are you sure you want to logout?"
            dialogTitle="Logout?"
          />
        </Drawer>
      )}
      {isMobile && (
        <button
          className={cx(
            "menu-button-mobile",
            "overview-header-button",
            "overview-header-button-mobile"
          )}
          onClick={() => setMobileOpen(true)}
          type="button"
        >
          <Bars />
        </button>
      )}
      {showTutorial && (
        <Tutorial open={showTutorial} onClose={() => setShowTutorial(false)} />
      )}
    </>
  );
};
