import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTimePeriod,
  toggleTimePeriod,
} from "../../../store/profile/profileSlice";

export const TimePeriodToggle = () => {
  const timePeriod = useSelector(selectTimePeriod);
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => dispatch(toggleTimePeriod())}
      endIcon={<ArrowDropDownIcon />}
      className="widget-configuration-button"
    >
      {timePeriod}
    </Button>
  );
};
