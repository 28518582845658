import axios from "axios"
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"
import { InviteData, UserFormValues, OnboardingForm, BasicForm, NewOrganization, User, UserStatus } from "../interfaces"

export class SuperAdminApi {

  static async getUserById(id: number): Promise<User> {
      try {
          const response = await axios.get(`${getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_GET_USER_BY_ID)}${id}`, apiDefaultOptions)
          return response.data as User
      } catch {
          throw new Error()
      }
  }

  static async loadDashboard(): Promise<{ [key: string ] : any}> {
      try {
          const response = await axios.get(`${getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_LOAD_DASHBOARD)}`, apiDefaultOptions)
          return response.data as User[]
      } catch {
          throw new Error()
      }
  }

  static editUser(userId: number, values: UserFormValues) {
      let data = {
          userId: userId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          status: values.status | UserStatus.Active,
          completedSurvey: values.completedSurvey,
          deletedVars: values.deletedVars,
      }

      try {
          return axios.post(getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_EDIT_USER), data, apiDefaultOptions)
      } catch {
          throw new Error()
      }
  }

  static async getSignupInvitation(key: string) {
      try {
          const response = await axios.get(getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_GET_SIGNUP_INVITATION), {
              params: {
                  key
              },
              ...apiDefaultOptions
          })
          return response.data as InviteData
      } catch {
          throw new Error()
      }
  }

  static approveSignup(invitationKey: string) {
      let data = { invitationKey }
      try {
          return axios.post(getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_APPROVE_SIGNUP), data, apiDefaultOptions)
      } catch {
          throw new Error()
      }
  }

  static declineSignup(invitationKey: string) {
      let data = { invitationKey }
      try {
          return axios.post(getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_DECLINE_SIGNUP), data, apiDefaultOptions)
      } catch {
          throw new Error()
      }
  }

  static async getAllVariables(): Promise<{ id: number, name: string, type: string, description: string }[]> {
      try {
          const response = await axios.get(getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_GET_VARIABLES), apiDefaultOptions)
          return response.data
      } catch {
          throw new Error()
      }
  }

  static async createOrganization(data: OnboardingForm): Promise<NewOrganization> {
      try {
          const response = await axios.post(`${getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_CREATE_ORGANIZATION)}`, data, apiDefaultOptions)
          return response.data
      } catch {
          throw new Error()
      }
  }

  static async editOrganizationData(id: number, data: BasicForm) {
      try {
          return axios.post(`${getBackendUrlForPath(API_ROUTE.SUPER_ADMIN_EDIT_ORGANIZATION)}/${id}`, data, apiDefaultOptions)
      } catch {
          throw new Error()
      }
  }
}