import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CalculatorDataPoint, ProfileData } from "../../../interfaces";
import { selectSubscriptionStatus } from "../../../store/auth/authSlice";
import { formatNumbers } from '../../../utils/numberDateFormatters';

type Props = {
  profileData: ProfileData;
  projection: {
    finalTargetExpense: number;
    finalPension: number;
    finalAdditionalRetirement: number;
    isRelocate: boolean;
    relocationChange: number;
    suggestedHours?: {
      [key: string]: number,
    }
  };
  graphId?: string;
};

export const CalculatorGraph = ({
  profileData,
  projection,
  graphId = "",
}: Props) => {
  const [graphData, setGraphData] = useState<CalculatorDataPoint[]>([]);
  const [hasInvestments, setHasInvestments] = useState(false);
  const isSubscribed = useSelector(selectSubscriptionStatus);

  useEffect(() => {
    am4core.addLicense("CH272102831");
    let chart = am4core.create(`calculatorGraph${graphId}`, am4charts.XYChart);

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "$#,###";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "amount";
    series.dataFields.categoryX = "name";
    series.name = "Type";
    series.columns.template.fillOpacity = 0.8;
    series.stacked = true;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "amount1";
    series1.dataFields.categoryX = "name";
    series1.columns.template.fillOpacity = 0.8;
    series1.stacked = true;

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    chart.data = graphData;
  }, [graphData, profileData, graphId]);

  useEffect(() => {
    if (!profileData || !projection) {
      return;
    }
    const additionalAmounts = projection.finalAdditionalRetirement > 0 ||
      profileData.otherRetirementAccounts ||
      profileData.otherRetirementInvestments;
    if (additionalAmounts) {
      setHasInvestments(true)
    } else {
      setHasInvestments(false)
    }

    setGraphData([
      {
        name: "Income",
        amount: projection.finalPension,
        ...additionalAmounts ? {amount1: projection.finalAdditionalRetirement} : {},
      },
      {
        name: "Expenses",
        amount: projection.finalTargetExpense,
      },
    ]);
  }, [profileData, projection]);

  if (!graphData) return;

  return (
    <div className="widget-container widget-container-full">
      <div className="widget-header-container">
        <div className="widget-title">Your Results</div>
      </div>
      { projection.finalPension >= projection.finalTargetExpense
        ? <>
          Based on the information provided, your pension{" "}
          <strong>will be able to cover</strong>{" "}
          your projected expenses after taxes.
        </>
        : <>
          Based on the information provided, your pension{" "}
          <strong  style={{ color: 'red' }}>will not be able to cover</strong>{" "}
          your projected expenses. Here are the number of hours to cash out to reach your
          pension goal:
          {projection?.suggestedHours &&
            Object.entries(projection?.suggestedHours).map(([key,value]) =>
              <div style={{ margin: '0.25rem 0'}} key={key}>
                {key}: {value} hours
              </div>)}
        </>
      }
      { projection.isRelocate &&
        <div style={{ padding: '0.5rem '}}>
          You are expected to {`${projection.relocationChange > 0 ? 'save ' : 'lose '}`}
          {`$${formatNumbers(Math.abs(projection.relocationChange), isSubscribed)}`} per paycheck by relocating.
        </div>
      }
      <div style={{ display: 'flex', padding: '0.5rem 1rem' }}>
        <span style={{ flex: '1 1' }}>You may earn:{" "}
          <strong style={{ color: "#5579e4" }}>${formatNumbers(projection.finalPension, isSubscribed)}{hasInvestments && "*"}</strong></span>
        <span style={{ flex: '1 1' }}>You may need:{" "}
          <strong style={{ color: "red" }}>${formatNumbers(projection.finalTargetExpense, isSubscribed)}</strong></span>
      </div>
      <div id={`calculatorGraph${graphId}`} style={{ height: "500px" }}></div>
      {hasInvestments &&
        <div style={{ textAlign: "left", fontSize: "0.75rem" }}>
          <em>
          **Includes ${formatNumbers(projection.finalPension, isSubscribed)} of pension
          and ${formatNumbers(projection.finalAdditionalRetirement, isSubscribed)} of
          retirement savings assuming standard 4% drawdown
          </em>
        </div>
      }
    </div>
  );
};
