import RingLoader from 'react-spinners/RingLoader'
import { css } from "@emotion/core";
import './SurveyError.css'

const override = css`
  display: block;
  margin: 0px auto;
  margin-top: 50px;
`;

export const SurveyError = () => {
    return (
        <div>
            <RingLoader css={override} size={100} color={"#3498db"} loading={true} />
            <h1 className="survey-error-text">Fetching your survey now...</h1>
        </div>
    )
}