import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Button, Alert, Dialog } from "@mui/material";
import dayjs from "dayjs";

import AddInvestments from "./AddInvestments";
import AddSituations from "./AddSituations";
import { ProfileData } from "../../../interfaces";
import { CalculatorGraph } from "./CalculatorGraph";
import { ForecastApi } from "../../../api/ForecastApi";
import { Form } from "react-final-form";
import FormField from "./FormField";
import { formatNumbers } from "../../../utils/numberDateFormatters";
import { selectSubscriptionStatus } from "../../../store/auth/authSlice";

type Props = {
  profileData: ProfileData;
  isMobile: boolean;
};

export const OverviewCalculator = ({ profileData }: Props) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [projection, setProjection] = useState(null);
  const [isOpen, setIsOpen] = useState({
    addInvestments: false,
    addSituations: false,
  })
  const ageRef = useRef(null);
  const buttonRef = useRef(null);
  const hasOvertime = profileData?.retirementBenefit?.hasOvertime;
  const paydayCount = profileData?.payday_count || 26;
  const isSubscribed = useSelector(selectSubscriptionStatus);

  const executeScroll = () => isSubmitted
    ? ageRef.current.scrollIntoView()
    : buttonRef.current.scrollIntoView();

  const onRecalculate = () => {
    setIsSubmitted(false);
    executeScroll();
  }

  const onSubmit = async (values: any) => {
    setIsError(false);
    // saving the form will save the user variables
    if (values.save) return await onSave(values);

    try {
      const calculatedValues = await ForecastApi.postCalculatorData(values);
      setProjection(calculatedValues);
      setIsSubmitted(true);
      executeScroll();
    } catch {
      setIsSaved(true);
      setIsError(true);
    }
  };

  const getRetirementDate = (date_of_birth: Date, targetAge: number) => {
    const years = Math.floor(targetAge);
    const days = (targetAge % 1) * 365;
    return dayjs(date_of_birth).add(years, 'year').add(days, 'days').toDate();
  }

  const onSave = async (values: any) => {
    let dirtyValues: {[key: string] : any} = {};
    Object.entries(values).forEach(([key, value]) => {
      if (initialValues[key] === value || key === 'save') return;
      if (key === "desired_retirement_age") {
        const retireDate = getRetirementDate(new Date(profileData.date_of_birth), value as number)
        dirtyValues["desired_retirement_date"] = new Date(retireDate).toString();
        return;
      }
      dirtyValues[key] = value;
    })
    try {
      await ForecastApi.saveCalculatorData(dirtyValues);

      setIsSaved(true);

      setTimeout(
        () => window.location.reload(),
        3000
      );
    } catch {
      setIsSaved(true);
      setIsError(true);
    }
  }

  useEffect(() => {
    if (!profileData) {
      return;
    }

    const key = profileData.monthly_data_keys[0];
    const data = profileData.monthly_data[key];

    setInitialValues({
      age: data.age,
      replacementIncome: profileData.replacementIncome || 100,
      retireLocation: profileData.retireLocation,
      annual_raise_percentage: profileData.annual_raise_percentage,
      desired_retirement_age: Math.round(profileData.desired_retirement_age * 1000) / 1000,
      gross_pay: profileData.gross_pay,
      holiday_hours: profileData.holiday_hours || 0,
      holidayCashoutRate: profileData.holidayCashoutRate,
      vacation_hours: profileData.vacation_hours || 0,
      vacationCashoutRate: profileData.vacationCashoutRate,
      adjustedFlsaPay: profileData.adjustedFlsaPay,
      otherRetirementAccounts: profileData.otherRetirementAccounts || 0,
      otherRetirementGrowthRate: profileData.otherRetirementGrowthRate || 0,
      otherRetirementInvestments: profileData.otherRetirementInvestments || 0,
      ...(hasOvertime
          ? {overtimeHours: profileData.overtimeHours || 0,
            overtimeRate: profileData.overtimeRate}
          : {}
          ),
      save: false,
    });
  }, [profileData]);

  if (!profileData || !initialValues) {
    return <div></div>;
  }

  return (
    <div className="widget-container summary-widget-container" style={{ textAlign: 'left' }}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
          <form id="calculator" onSubmit={handleSubmit} noValidate>
            <Box>
              <span ref={ageRef}>I am currently {parseInt(initialValues.age)} </span>
              years old and I plan to retire at age{" "}
              <FormField inputName="desired_retirement_age" varType="number" />
              . I make $
              <FormField inputName="gross_pay" varType="number" />
              per paycheck in gross pay and I need{" "}
              <FormField inputName="replacementIncome" varType="percent" />
              of my income to retire. I will receive a raise of{" "}
              <FormField inputName="annual_raise_percentage" varType="percent" />
              per year until retirement.
              <p />I plan to contribute the following <strong>total yearly</strong> hours at current cashout
              rates:
              <p />
              {hasOvertime &&
                <>
                Overtime: <FormField inputName="overtimeHours" varType="number" /> x{" "}
                <FormField inputName="overtimeRate" varType="dollar" />/hour{" "}
                <span>= ${formatNumbers((values.overtimeHours || 0) * (values.overtimeRate || 0), isSubscribed)} or</span>{" "}
                <span>{((values.overtimeHours || 0) / paydayCount).toFixed(2)} hours per paycheck</span>
                <p />
                </>
              }
              Vacation: <FormField inputName="vacation_hours" varType="number" /> x{" "}
              <FormField inputName="vacationCashoutRate" varType="dollar" />/hour{" "}
              <span>= ${formatNumbers((values.vacation_hours || 0) * (values.vacationCashoutRate || 0), isSubscribed)} or</span>{" "}
              <span>{((values.vacation_hours || 0) / paydayCount).toFixed(2)} hours per paycheck</span>
              <p />
              Holiday: <FormField inputName="holiday_hours" varType="number" /> x{" "}
              <FormField inputName="holidayCashoutRate" varType="dollar" />/hour{" "}
              <span>= ${formatNumbers((values.holiday_hours || 0) * (values.holidayCashoutRate || 0), isSubscribed)} or</span>{" "}
              <span>{((values.holiday_hours || 0) / paydayCount).toFixed(2)} hours per paycheck</span>
              <p />
              <div style={{ margin: "1.5rem 0 0 0", borderTop: "1px black solid", borderBottom: "1px black solid" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "0.5rem 0" }}
                    onClick={() => setIsOpen({...isOpen, addInvestments: !isOpen.addInvestments})}
                  >
                    Additional Investments
                    {isOpen.addInvestments && <ExpandMoreIcon />}
                    {!isOpen.addInvestments && <ExpandLessIcon />}
                  </div>
                  <div style={{ margin: "0.5rem 0" }}>
                    {isOpen.addInvestments && <AddInvestments />}
                  </div>
              </div>
              <div style={{ marginBottom: "1.5rem", borderBottom: "1px black solid" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "0.5rem 0" }}
                    onClick={() => setIsOpen({...isOpen, addSituations: !isOpen.addSituations})}
                  >
                    Additional Situations
                    {isOpen.addSituations && <ExpandMoreIcon />}
                    {!isOpen.addSituations && <ExpandLessIcon />}
                  </div>
                  <div style={{ margin: "0.5rem 0" }}>
                    {isOpen.addSituations && <AddSituations newLocation={initialValues.retireLocation} />}
                  </div>
              </div>
              <strong>Will I be able to reach my goal?</strong>
              <p />
            </Box>
            <div>
              <Button
                ref={buttonRef}
                type="submit"
                variant="contained"
              >
                {isSubmitted ? 'Recalculate' : 'Calculate'}
              </Button>
              <span style={{ padding: '0.5rem', color: 'grey', display: 'inline-block' }}>
                {isSubmitted && `To see different results, scroll up and
                  edit desired fields, then click recalculate. Otherwise, scroll
                  down to save calculation`}
              </span>
            </div>
            {isSubmitted &&
              <>
                <p />
                <CalculatorGraph
                  profileData={profileData}
                  projection={projection}
                />
                <p />
                <Button
                  type="submit"
                  variant="contained"
                  disabled={pristine || submitting}
                  onClick={() => {
                    form.change("save", true);
                  }}
                >
                  Save Calculation
                </Button>
                <span style={{ padding: '0.5rem', color: 'grey', display: 'inline-block' }}>
                {submitting && `Please wait, saving your calculation...`}
              </span>
                <p />
                <Button
                  onClick={onRecalculate}
                >
                  Want to change your retirement story? Click here to recalculate.
                </Button>
              </>
            }
          </form>
        )}}
        />
      <Dialog
        open={isSaved}
        onClose={() => {setIsSaved(false)}}
      >
        {isError
          ? <Alert severity="error">
            There was an error with your submission. Please try again.
          </Alert>
          : <Alert severity="success">
            Calculation successfully saved. You will now be redirected.
          </Alert>}
      </Dialog>
    </div>
  );
};
