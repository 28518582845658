import { faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

import { getDivisor, formatNumbers } from '../../../../utils/numberDateFormatters';
import { PensionDataTypes, ProfileData, SickLeaveData } from '../../../../interfaces';
import { selectCurrentUser, selectSubscriptionStatus } from '../../../../store/auth/authSlice';
import { selectPensionType, selectSickLeaveMonthlyIncrease, selectTimePeriod, selectWorkingDate } from '../../../../store/profile/profileSlice';
import { DateSelect } from '../DateSelect';
import { GrossNetToggle } from '../GrossNetToggle';
import { TimePeriodToggle } from '../TimePeriodToggle';
import './OverviewStatus.css';
import { OverviewStatusSlider } from './OverviewStatusSlider';
import { memoize } from 'lodash';

interface StatusValues {
    yearValue: string,
    pensionPercentage: number,
    maxPercentage: number,
    currentPensionPercentage: number,
    divorcePercentage: number,
    previousPensionPercentage: number,
    yearlyPay: string,
    pensionablePay: string,
    age: number,
    benefit_percentage: number,
    grossPension: string,
    grossPensionNumber: number,
}

type Props = {
    profileData: ProfileData
    isCurrent?: boolean
    onChange: (inputs: number) => void
    isMobile: boolean
}

interface Sections {
    SERVICE_CREDIT: boolean,
    PERCENTAGE: boolean,
}

const InitialSections: Sections = {
    SERVICE_CREDIT: false,
    PERCENTAGE: false,
}

// todo: remove getTickerDate and change DateSelect to use state from redux
export const getTickerDate = memoize((workingDate: Date) => {
    return `${workingDate.toLocaleString('default', { month: 'short' })} ${workingDate.getFullYear()}`
}, (d) => d.getTime())

export const OverviewStatus = ({ profileData, isCurrent = true, onChange, isMobile }: Props) => {
    const userData = useSelector(selectCurrentUser)
    const workingDate = useSelector(selectWorkingDate)
    const timePeriod = useSelector(selectTimePeriod)
    const currentDate = new Date()
    const [sliderMarks, setSliderMarks] = useState(null)
    const [sliderValue, setSliderValue] = useState(0)
    const [statusValues, setStatusValues] = useState<StatusValues>(null)
    const [openSections, setOpenSections] = useState<Sections>(InitialSections)
    const sickLeaveIncrease = useSelector(selectSickLeaveMonthlyIncrease) as SickLeaveData
    const hasPercentageDetails = statusValues?.previousPensionPercentage > 0
        || sickLeaveIncrease?.percentageGain > 0
        || (statusValues?.divorcePercentage !== null && statusValues?.divorcePercentage > 0);
    const hasServiceCreditDetails = profileData.additional_configurations.length
        || profileData.purchased_service_years > 0;
    const isSubscribed = useSelector(selectSubscriptionStatus)
    const pensionType = useSelector(selectPensionType)

    useEffect(() => {
        if (!profileData) { return }
        const lastKey = profileData.monthly_data_keys[profileData.monthly_data_keys.length - 1]

        const marks = [
            {
                value: 0,
                label: "Today"
            },
            {
                value: profileData.monthly_data_keys.length - 1,
                label: getTickerDate(new Date(lastKey))
            }
        ]
        setData(0)
        setSliderMarks(marks)
    }, [profileData])

    useEffect(() => {
        setData(sliderValue ? sliderValue : 0)
    }, [sliderValue, timePeriod, pensionType])

    useEffect(() => {
        if (workingDate === null) { return }
        let tempDataIndex = profileData.monthly_data_keys.findIndex((v) => getTickerDate(new Date(v)) === getTickerDate(workingDate))
        if (tempDataIndex > 0) {
            setSliderValue(tempDataIndex)
        }
    }, [workingDate])

    const handleChange = (e: Event, v: number | number[]) => {
        setSliderValue(v as number)
    }

    const setData = (v: number) => {
        let key = profileData.monthly_data_keys[v]
        let data = profileData.monthly_data[key]
        const isGrossNumber = pensionType === PensionDataTypes.Gross
        const divisor = getDivisor(timePeriod)

        setStatusValues({
            age: data.age,
            benefit_percentage: data.benefit_percentage,
            maxPercentage: data.max_percentage,
            yearlyPay: isGrossNumber ? formatNumbers(data.yearly_gross / divisor, isSubscribed) : formatNumbers(data.yearly_net / divisor, isSubscribed),
            pensionablePay: formatNumbers(data.yearly_pensionable / divisor, isSubscribed),
            pensionPercentage: data.pension_percentage,
            divorcePercentage: data.divorce_percent,
            currentPensionPercentage: data.current_pension_percentage,
            previousPensionPercentage: data.previous_pension_percentage,
            yearValue: (data.service_time.toLocaleString(undefined, { minimumFractionDigits: 2 })),
            grossPension: isGrossNumber ? formatNumbers(data.yearly_gross_pension / divisor, isSubscribed) : formatNumbers(data.yearly_net_pension / divisor, isSubscribed),
            grossPensionNumber: isGrossNumber ? (data.yearly_gross_pension / divisor) : (data.yearly_net_pension / divisor),
        })
    }

    const moveSlider = (value: number) => {
        var newValue = sliderValue + value
        newValue = newValue >= 0 && newValue < profileData.monthly_data_keys.length ? newValue : sliderValue
        onChange(newValue)
    }

    if (!profileData || !sliderMarks) {
        return (
            <div></div>
        )
    }

    const toggleSections = (section: keyof Sections) => {
        setOpenSections({...openSections,
            [section]: !openSections[section]
        })
    }

    return (
        <div
            className="widget-container summary-widget-container"
        >
            <div className="widget-header-container">
                <div className="flex-default">
               {!isMobile && <div className="widget-title">
                    { isCurrent ? "Pension Summary" : "Forecasted Summary" }
                </div>}
                    <div className="widget-bold-small flex-default">
                        {!isMobile && <div className="vertical-divider">
                            <FontAwesomeIcon icon={faGripLinesVertical} />
                        </div>}
                        { statusValues.age } years old
                        <div className="vertical-divider">
                            <FontAwesomeIcon icon={faGripLinesVertical} />
                        </div>
                        { new Date(profileData.monthly_data_keys[sliderValue]).toLocaleDateString() }
                    </div>
                </div>
                <div className="widget-configuration-button-container">
                    <DateSelect />
                    <GrossNetToggle />
                    <TimePeriodToggle />
                </div>
            </div>
            <div className="summary-section-container">
                <div className="summary-section">
                    <div className="widget-light-text">
                        <span>
                            Pensionable Earnings
                        </span>
                    </div>
                        <div className="overview-status-number overview-status-total">
                            <span>${statusValues.pensionablePay}</span> {timePeriod} pensionable income
                            (prior to retirement)
                        </div>
                </div>
                <div className="summary-section">
                    <div className="flex-space-between">
                        <div className="widget-light-text">
                            <span>Pension Percentage</span>
                            {hasPercentageDetails &&
                                <IconButton
                                    className="widget-icon-button"
                                    onClick={() => toggleSections("PERCENTAGE")}
                                >
                                    {!openSections.PERCENTAGE && <ExpandMoreIcon />}
                                    {openSections.PERCENTAGE && <ExpandLessIcon />}
                                </IconButton>}
                        </div>
                    </div>
                    {openSections.PERCENTAGE &&
                        <>
                        { statusValues.currentPensionPercentage > 0 &&
                        statusValues.previousPensionPercentage > 0 &&
                            <div className="flex-space-between">
                                <div className="overview-status-number">
                                    <span>{Math.abs(statusValues.currentPensionPercentage).toFixed(2)}%</span> percentage earned in current pension
                                </div>
                            </div>
                        }
                        { statusValues.previousPensionPercentage > 0 &&
                            <div className="overview-status-number">
                                <span>{Math.abs(statusValues.previousPensionPercentage).toFixed(2)}%</span> percentage earned in previous pension(s)
                            </div>
                        }
                        { (sickLeaveIncrease.percentageGain > 0) &&
                            <div className="overview-status-number">
                                <span>{sickLeaveIncrease.percentageGain.toFixed(2)}%</span> earned from sick leave
                            </div>
                        }
                        { (statusValues.divorcePercentage !== null && statusValues.divorcePercentage > 0) &&
                            <div className="overview-status-number">
                                <span>(-{statusValues.divorcePercentage.toFixed(0)})%</span> percentage lost due to divorce
                            </div>
                        }
                    </>
                    }
                    <div className="overview-status-number overview-status-total">
                        <span>{statusValues.pensionPercentage.toFixed(2)}
                            %
                        </span>
                        total pension percentage earned
                    </div>
                </div>
                <div className="widget-light-text">
                    <span>
                        Unmodified Allowance
                    </span>
                </div>
                <div className="overview-status-number overview-status-total">
                    <span>${
                        formatNumbers(statusValues.grossPensionNumber + (sickLeaveIncrease.monthlyIncrease * 12), isSubscribed)
                    }</span>
                    { timePeriod } {pensionType} pension (unmodified allowance)
                </div>
                <div className="summary-section">
                    <div className="widget-light-text">
                        <span>Service Credit</span>
                        {hasServiceCreditDetails &&
                            <IconButton
                                className="widget-icon-button"
                                onClick={() => toggleSections("SERVICE_CREDIT")}
                            >
                            {!openSections.SERVICE_CREDIT && <ExpandMoreIcon />}
                            {openSections.SERVICE_CREDIT && <ExpandLessIcon />}
                            </IconButton>
                        }
                    </div>
                    { openSections.SERVICE_CREDIT &&
                        <>
                        <div className="overview-status-number">
                            <span>{profileData.purchased_service_years.toFixed(2)}</span> years of purchased service credit
                        </div>
                            { profileData.additional_configurations.map((ac, index) => (
                                <div className="overview-status-number" key={index}>
                                    <span>{ac.serviceYears.toFixed(2)}</span>
                                    <div>
                                        years of prior service at {`${ac.plan_benefit.org.display_name}`}
                                        { `${ac.plan_benefit.retirementBenefit.name} (${ac.plan_benefit.retirementBenefit.retirement_system.short_name})` }
                                    </div>
                                </div>
                            ))}
                            { profileData?.additional_configurations.length > 0 &&
                            <div className="overview-status-number">
                                <span>{(parseFloat(statusValues.yearValue) - (profileData.purchased_service_years ?? 0) - (profileData.additional_service_years ?? 0)).toFixed(2)}</span>
                                <div>
                                    years at { userData.organizations ? `${userData.organizations.display_name}` : "your organization" }
                                    { ` (${profileData.retirementBenefitName})` }
                                </div>
                            </div> }
                        </>
                    }
                    <div className="overview-status-number overview-status-total">
                        <span>{parseFloat(statusValues.yearValue).toFixed(2)}</span> total years of service credit
                        { ` (${profileData.retirementBenefitName})` }
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '0.75rem'}}>
                <OverviewStatusSlider
                    profileData={profileData}
                    moveSlider={moveSlider}
                    sliderMarks={sliderMarks}
                    sliderValue={sliderValue}
                    handleChange={handleChange}
                    currentDate={currentDate}
                />
                <div className="overview-slider-label">Income Forecast Slider</div>
            </div>
        </div>
    )
}