import { useEffect, useState } from "react"
import { User } from "../../../interfaces"
import { PaginationControls } from "../PaginationControls/PaginationControls"
import { Button } from '@mui/material'
import { DataGrid,  GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Link } from 'react-router-dom'

type Props = {
  loading: boolean,
  isSuperUserDisplay: boolean,
  users: User[]
}

export const CustomToolbar = () =>
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>


export const SuperAdminUserTable = ({ loading, users }: Props) => {
  const rowCount = 50
  const [rows, setRows] = useState([])
  const [paginationStartIndex, setPaginationStartIndex] = useState(0)

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5,
      renderCell: (params: any) => {
      return <Link to={`/superadmin/edit?id=${params.id}`}>
                <Button>
                  {params.id}
                </Button>
              </Link>;
      }, 
    },
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'organization', headerName: 'Organization', flex: 1.0 },
    { field: 'email', headerName: 'Email', flex: 1.5 },
    { field: 'created', headerName: 'Date Created', flex: 0.7 },
  ]

  useEffect(() => {
    setRows(users.map((u) => ({
      id: u.id,
      name: `${u.first_name} ${u.last_name}`,
      organization: u.organizations?.display_name || '',
      email: u.email,
      created: new Date(u.created_at).toLocaleDateString('en-us'),
    })))
    setPaginationStartIndex(0)
  }, [users])

  const handlePaginationChange = (index: number) => {
      setPaginationStartIndex(index * rowCount)
  }

  return (
    <div>
      <DataGrid
        autoHeight
        pageSize={100}
        rowsPerPageOptions={[50, 100, 200]}
        rows={rows}
        columns={columns}
        pagination
        loading={loading}
      />
      <PaginationControls
        count={Math.ceil(rows.length / rowCount)}
        handlePaginationChange={handlePaginationChange}
        pageIndex={paginationStartIndex / rowCount}
      />
    </div>
  )
}