import { render } from "react-dom";
import "./index.css";
import App from "./components/App";
import { Provider } from "react-redux";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { startStore } from "./store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bc4f6bc4db0e455182a327ec8e61784e@o849231.ingest.sentry.io/5816097",
  integrations: [new Integrations.BrowserTracing()],
  release: "advanced-pension@1.0",
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

const Root = () => (
  <Provider store={startStore()}>
      <App />
  </Provider>
);

render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
