import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { getLatestTerms } from '../../../../api/AuthAPI'
import { LegalTerms } from '../../../../interfaces'
import './TermsOfService.css'

type Props = {
    onClose: () => void,
    onAccept: () => void,
    showClose?: boolean
}

export const TermsOfService = ({ onClose, onAccept, showClose = true }: Props) => {
    const [isLoading, setLoading] = useState(true)
    const [terms, setTerms] = useState<LegalTerms>(null)

    useEffect(() => {
        getLatestTerms().then((terms) => {
            let parsedText = terms.text.replace(/ +(?= )/g,'')
            setTerms({ ...terms, text: parsedText })
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className="terms-of-service-background">
            {
                !isLoading && terms &&
                <div className="terms-of-service-container">
                    <div className="terms-of-service-header">
                        <h1>Terms and Conditions</h1>
                        { showClose && <FontAwesomeIcon icon={faTimes} onClick={onClose} /> }
                    </div>
                    <div className="terms-of-service-text">
                        {
                            terms.text.split("\n ").map((t, i) => (
                                <p key={i}>{ t }</p>
                            ))
                        }
                    </div>
                    <button className="terms-accept-button" onClick={onAccept}>
                        I Accept The Terms and Conditions
                    </button>
                </div>
            }
        </div>
    )
}