import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ForecastApi } from "../../../../api/ForecastApi";
import { AlertTypes } from "../../../../interfaces";
import { setAlert } from "../../../../store/alert/alertSlice";
import { selectForecastId } from "../../../../store/forecast/forecastSlice";
import { ConfirmAlert } from "../../../alerts/confirm-alert/ConfirmAlert";
import { Forecasting } from "../../overview/forecasting/Forecasting";
import { PageLoader } from "../../overview/loader/PageLoader";
import "./CompareHeader.css";

export const CompareHeader = () => {
  const [showForecasting, setShowForecasting] = useState(false);
  const currentForecastId = useSelector(selectForecastId);
  const [confirmSaveForecastOpen, setConfirmSaveForecastOpen] = useState(false);
  const [confirmDisableForecastOpen, setConfirmDisableForecastOpen] =
    useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDashboard = () => {
    setLoadingDashboard(true);
  };

  useEffect(() => {
    if (loadingDashboard) {
      setTimeout(() => {
        history.push("/overview");
      }, 500);
    }
  }, [loadingDashboard, history]);

  const handleSaveForecast = async () => {
    try {
      if (currentForecastId !== null) {
        await ForecastApi.saveForecast(currentForecastId);
      } else {
        throw new Error();
      }
    } catch {
      dispatch(
        setAlert({
          text: "Could not save your forecast. Please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setConfirmSaveForecastOpen(false);
    }
  };

  const handleDisableForecast = async () => {
    try {
      if (currentForecastId !== null && currentForecastId !== undefined) {
        await ForecastApi.deleteForecast(currentForecastId);
      }
    } catch {
      dispatch(
        setAlert({
          text: "Could not delete your forecast. Please try again.",
          type: AlertTypes.Error
        })
      );
    } finally {
      setConfirmDisableForecastOpen(false);
    }
  };

  return (
    <>
      <div className="compare-header-container">
        <h1 className="user-page-title">Forecast Compare</h1>
        <div className="overview-header-button-container">
          <button
            className="overview-header-button overview-header-mode-button"
            onClick={handleDashboard}
          >
            Return To Dashboard
          </button>
          <button
            className="overview-header-button"
            onClick={() => setConfirmSaveForecastOpen(true)}
          >
            Save Forecast
          </button>
          <button
            onClick={() => setShowForecasting(true)}
            className="overview-header-button"
          >
            Edit Forecast
          </button>
          <button
            onClick={() => setConfirmDisableForecastOpen(true)}
            className="overview-header-button"
            id="background-red"
            style={{ background: "var(--red)" }}
          >
            Exit Forecasting
          </button>
        </div>
        <Forecasting
          open={showForecasting}
          onClose={() => setShowForecasting(false)}
        />
        <ConfirmAlert
          open={confirmSaveForecastOpen}
          handleClose={() => setConfirmSaveForecastOpen(false)}
          dialogContent={`Are you sure you want to save your forecast and override your current data?`}
          dialogTitle="Save Forecast"
          actionTitle="Save"
          onAction={handleSaveForecast}
        />
        <ConfirmAlert
          open={confirmDisableForecastOpen}
          handleClose={() => setConfirmDisableForecastOpen(false)}
          dialogContent={`Are you sure you want to disable your forecast?`}
          dialogTitle="Disable Forecast"
          actionTitle="Disable"
          onAction={handleDisableForecast}
        />
        {loadingDashboard && <PageLoader title="Returning To Dashboard" />}
      </div>
    </>
  );
};
