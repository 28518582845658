import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux"
import { ForecastingFormData } from "../../../../../../interfaces"
import { setForecastFormData, selectForecastFormData } from "../../../../../../store/forecast/forecastSlice"

export const ForecastingPurchasedServiceForm = () => {
  const dispatch = useDispatch()
    const data = useSelector(selectForecastFormData)

    const setData = (data: ForecastingFormData) => {
        dispatch(setForecastFormData(data))
    }

    const handleChange = (value: string, index: number) => {
      let textfieldValue: [number, number];

      if (data.purchased_service_time.textfieldValue &&
          data.purchased_service_time.textfieldValue.length === 2 &&
          typeof data.purchased_service_time.textfieldValue[0] === 'number' &&
          typeof data.purchased_service_time.textfieldValue[1] === 'number') {
          textfieldValue = [...data.purchased_service_time.textfieldValue] as [number, number];
      } else if (data.purchased_service_time.profileValue &&
                 data.purchased_service_time.profileValue.length === 2 &&
                 typeof data.purchased_service_time.profileValue[0] === 'number' &&
                 typeof data.purchased_service_time.profileValue[1] === 'number') {
          textfieldValue = [...data.purchased_service_time.profileValue] as [number, number];
      } else {
          // Provide a default value
          textfieldValue = [0, 0];
      }
      
        textfieldValue[index] = parseInt(value)
        setData({ 
            ...data, 
            purchased_service_time: { ...data.purchased_service_time, textfieldValue : textfieldValue }
        })
    }

    if (data === null || data === undefined) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div>
            <TextField
                fullWidth
                value={data.purchased_service_time.textfieldValue !== null ? 
                    data.purchased_service_time.textfieldValue[0] :
                    data.purchased_service_time.profileValue[0]
                }
                onChange={ e => handleChange(e.target.value, 0) }
                label={"Months"}
                type="number"
                variant="outlined"
            />
            <div style={{ margin: "20px 0px"}}></div>
            <TextField
                fullWidth
                value={data.purchased_service_time.textfieldValue !== null ? 
                    data.purchased_service_time.textfieldValue[1] :
                    data.purchased_service_time.profileValue[1]
                }
                onChange={ e => handleChange(e.target.value, 1) }
                label={"Years"}
                type="number"
                variant="outlined"
            />
        </div>
    )
}