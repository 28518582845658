import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useState } from "react";

import {
  createSub,
  cancelSub,
  renewSub,
  addPromo,
} from "../../../store/auth/authSlice";
import { SubType, SuccessMessage } from "./Subscription";
import { useAppDispatch } from "../../../store/hooks";

export const SuccessMessages = {
  SUCCESS: {
    title: "Congratulations! 🎉",
    successMessage: `You have successfully subscribed!`,
    body: `We have sent your unique
    registration link to the email on your Stripe payment.`,
  },
  CANCEL: {
    title: `Subscription cancelled`,
    successMessage: `Your subscription has been successfully cancelled.`,
    body: `You will have premium access to the end of your subscription.`,
  },
  RENEW: {
    title: "Subscription renewed! 🎉",
    successMessage: "Your subscription has been successfully renewed.",
    body: "Continue to enjoy full access to our offerings.",
  },
  PROMO: {
    title: "Promo code applied",
    successMessage: `Your promo code has been applied.`,
    body: `You now have access through your organization.`,
  },
  INVALID_PROMO: {
    title: "Invalid promo code",
    successMessage: `Your promo code does not work.`,
    body: `Please check your promo code.`,
  },
  ERROR: {
    title: "ERROR",
    successMessage: `Your requested action could not be completed.`,
    body: `Please try again or contact contact@advancedpension.com.`,
  },
};

const SubscriptionCtaButton = ({
  isLoggedIn,
  isSubscribed,
  isWhitelisted,
  endingSub,
  hasTrialed,
  onClick,
  subType,
}: {
  isLoggedIn: boolean;
  isSubscribed: boolean;
  isWhitelisted: boolean;
  endingSub: boolean;
  hasTrialed: boolean;
  onClick: (showMessage: boolean, message: SuccessMessage) => void;
  subType: SubType;
}) => {
  const [promoCode, setPromoCode] = useState("");
  let buttonText = "";
  let ctaAction;
  let disclaimer = "";
  const dispatch = useAppDispatch();

  const subscribe = async (currentType: SubType) => {
    const { PREMIUM_MONTHLY } = currentType;
    let membership = "PREMIUM_ANNUAL";

    if (PREMIUM_MONTHLY) membership = "PREMIUM_MONTHLY";

    try {
      let response = await dispatch(createSub(membership)).unwrap();
      if (response && response.url) {
        window.location.href = response.url;
      } else {
        onClick(true, SuccessMessages.ERROR);
      }
    } catch {
      onClick(true, SuccessMessages.ERROR);
    }
  };

  const cancel = async () => {
    try {
      let response = await dispatch(cancelSub()).unwrap();
      onClick(response.success, SuccessMessages.CANCEL);
    } catch {
      onClick(true, SuccessMessages.ERROR);
    }
  };

  const renew = async (currentType: SubType) => {
    const { PREMIUM_MONTHLY } = currentType;
    let membership = "PREMIUM_ANNUAL";

    if (PREMIUM_MONTHLY) membership = "PREMIUM_MONTHLY";

    try {
      let response = await dispatch(renewSub(membership)).unwrap();
      onClick(response.success, SuccessMessages.RENEW);
    } catch {
      onClick(true, SuccessMessages.ERROR);
    }
  };

  const addPromoCode = async (promoCode: string) => {
    try {
      let response = await dispatch(addPromo(promoCode)).unwrap();
      let message = SuccessMessages.INVALID_PROMO;
      if (response.isPromoValid) message = SuccessMessages.PROMO;

      setPromoCode("");
      onClick(response.success, message);
    } catch {
      onClick(true, SuccessMessages.ERROR);
    }
  };

  if (isSubscribed) {
    if (!isWhitelisted && !endingSub) {
      buttonText = "Cancel my subscription";
      ctaAction = cancel;
    }

    if (endingSub) {
      buttonText = "Renew Now";
      ctaAction = () => renew(subType);
    }
  } else {
    if (hasTrialed) {
      buttonText = "Subscribe Now";
      ctaAction = () => subscribe(subType);
      disclaimer = "Subscribe now, cancel anytime";
    } else {
      buttonText = "Start my free trial";
      ctaAction = () => subscribe(subType);
      disclaimer = "Trial for free, cancel anytime";
    }
  }

  if (buttonText === "") {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        onClick={ctaAction}
        aria-label="subscription button"
      >
        {buttonText}
      </Button>
      {disclaimer && (
        <Box
          sx={{
            margin: "0.5rem",
            fontSize: "0.75rem",
            color: "gray",
          }}
        >
          {disclaimer}
        </Box>
      )}
      {isLoggedIn && !isSubscribed && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "0.5rem",
          }}
        >
          <TextField
            placeholder="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          ></TextField>
          <Button
            variant="contained"
            onClick={() => addPromoCode(promoCode)}
            aria-label="promo code button"
          >
            Apply
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionCtaButton;
