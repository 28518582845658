import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";

import { editReciprocity, selectProfileData } from '../../../store/profile/profileSlice'
import { ProfileData, AdditionalConfigurationData, EditReciprocityData } from "../../../interfaces";
import { PensionApi } from "../../../api/PensionAPI";

import { Form } from "react-final-form";
import { Button, Alert, Dialog } from "@mui/material";
import { PlanList } from "./PlanList";

const PlansTitle = {
  reciprocalPlans: `Established reciprocity with your current system:`,
  nonReciprocalPlans: `Eligible for but not established with your current system:`,
  standAlonePlans: `Plans not reciprocal with your current system:`,
}

export interface Plans {
  reciprocalPlans: AdditionalConfigurationData[],
  standAlonePlans: AdditionalConfigurationData[],
  nonReciprocalPlans: AdditionalConfigurationData[],
}

export const OverviewReciprocity = () => {
  const profileData = useSelector(selectProfileData) as ProfileData
  const [displayValues, setDisplayValues] = useState<Plans>(null);
  const [removedItems, setRemovedItems] = useState<AdditionalConfigurationData[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values: any, api: any) => {
    let dirtyValues: {[key: string] : any} = {};
    const finalSubmission: any = [];
    const formState = api.getState();
    const dirtyFields = formState.dirtyFields;

    Object.keys(dirtyFields).forEach(key => {
      if (key === 'reciprocalPlans' ||
        key === 'standAlonePlans' ||
        key === 'nonReciprocalPlans') return;
      const dynamicKey = key.split('.');
      const fieldState = api.getFieldState(key).value;

      dynamicKey.reduce((obj, prop, index) => {
        if (index === dynamicKey.length - 1) {
          obj[prop] = fieldState; // Set the value at the last key
        } else {
          obj[prop] = obj[prop] || {}; // Create an empty object if it doesn't exist
        }
        return obj[prop];
      }, dirtyValues);
    });

    Object.entries(dirtyValues).forEach(([key, value]) => {
      if (removedItems.find(item => item.id === value.id)) {
        value.removed = true;
      };

      if (value.additionalReciprocity) {
        value.additionalReciprocityId = value.additionalReciprocity.id;
      }

      // override if reciprocal
      if (value.reciprocity) {
        value.additionalReciprocity = null;
        value.additionalReciprocityId = null;
      }

      finalSubmission.push(value);
    })

    try {
      // if reciprocity is false, we need a plan salary
      const updatedData: EditReciprocityData = await PensionApi.editReciprocity(finalSubmission);
      dispatch(editReciprocity(updatedData))
      setIsSaved(true);
      api.reset();
    } catch {
      setIsSaved(true);
      setIsError(true);
    }
  }

  useEffect(() => {
    if (!profileData) {
      return;
    }

    /*
      group by reciprocal plans
      plans can either be:
        1. registered and eligible to be reciprocal with current plan
        2. not registered as reciprocal but eligible for reciprocity
        3. not eligible for reciprocity (completely stand alone)
    */
    const parentSystemId = profileData?.retirementBenefit.retirementSystemId;
    const peerSystems = profileData?.peerSystemIds || [];
    const alternativeSalaries = profileData?.reciprocalSalaries || [];
    const reciprocalPlans: AdditionalConfigurationData[] = [];
    const nonReciprocalPlans: AdditionalConfigurationData[] = [];
    const standAlonePlans: AdditionalConfigurationData[] = [];

    profileData.additional_configurations.forEach((config) => {
      const retirementSystem = config?.retirementBenefit?.retirement_system?.id;
      const overrideReciprocity = retirementSystem === parentSystemId;
      const isEligibleReciprocity = peerSystems.includes(retirementSystem) || overrideReciprocity;
      let newEligiblePlans = [...(config.eligiblePlans ?? [])];

      // find additional reciprocal systems for which current configuration
      // has eligible reciprocity. todo: maybe move this to server for performance
      alternativeSalaries?.forEach((a, index) => {
        // add to eligible plans for current config if a peer/parent system or already
        // in the list AND does not already exist as an option
        if ((a?.peerSystemIds?.find(id => id === retirementSystem ||
          a.id === config.additionalReciprocity?.id ||
          a.parentSystemId === retirementSystem) &&
            !config.eligiblePlans?.some(plan => plan.id === a.id))) {
          const updatedA = {
            ...a,
            displayIndex: index + 1,
          }
          newEligiblePlans.push(updatedA);
        }
      });

      const updatedConfig = {
        ...config,
        eligiblePlans: newEligiblePlans,
        overrideReciprocity,
      };
      if (!isEligibleReciprocity) {
        standAlonePlans.push(updatedConfig);
      } else if (!updatedConfig.reciprocity && !overrideReciprocity) {
        nonReciprocalPlans.push(updatedConfig)
      } else {
        reciprocalPlans.push(updatedConfig)
      }
    })

    standAlonePlans.sort((a, b) => (b?.additionalReciprocity?.id || 0) - (a?.additionalReciprocity?.id || 0));
    nonReciprocalPlans.sort((a, b) => (b?.additionalReciprocity?.id || 0) - (a?.additionalReciprocity?.id || 0));

    setDisplayValues({
      reciprocalPlans,
      nonReciprocalPlans,
      standAlonePlans,
    });

  }, [profileData]);

  if (!profileData || !displayValues) {
    return <div></div>;
  }

  return (
    <div className="widget-container summary-widget-container" style={{ textAlign: 'left' }}>
      <Form
        onSubmit={onSubmit}
        initialValues={displayValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, form, submitting, pristine, values, }) => {
          return (
          <form id="reciprocity" onSubmit={handleSubmit} noValidate>
            {Object.entries(displayValues).map(([planType, plans]) =>
              <PlanList
                key={planType}
                planType={planType as keyof Plans}
                title={PlansTitle[planType as keyof Plans]}
                plans={plans}
                form={form}
                setRemovedItems={setRemovedItems}
              />
            )}
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: "0.5rem" }}
              disabled={submitting}
            >
              Save Reciprocity
            </Button>
          </form>
        )}}
        />
      <Dialog
        open={isSaved}
        onClose={() => {setIsSaved(false)}}
      >
        {isError
          ? <Alert severity="error">
            There was an error with your submission. Please try again.
          </Alert>
          : <Alert severity="success">
            Reciprocities successfully saved.
          </Alert>}
      </Dialog>
    </div>
  );
};
