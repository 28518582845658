import { API_ROUTE } from "../helpers/APIHelpers";
import { SurveyQuestionAnswerType, SurveyAPIData } from "../interfaces";

const PRE_SURVEY_QUESTION_INCREMENT = 10000

export const HIRE_DATE_ID = PRE_SURVEY_QUESTION_INCREMENT + 2
export const BENEFIT_QUESTION_ID = PRE_SURVEY_QUESTION_INCREMENT + 3

export const PreSurveyData: SurveyAPIData = {
    questions: [
        {
            id: HIRE_DATE_ID,
            order: 20,
            hidden: false,
            locked: false,
            text: "First up, what is your hire date for your current employer?",
            answerType: [
                [
                    SurveyQuestionAnswerType.Date
                ]
            ],
            allowMultipleAnswers: false,
            projectDate: false,
            category: {
                id: 2,
                text: "User Information"
            },
            questionId: HIRE_DATE_ID,
            postApiRoute: API_ROUTE.SET_HIRE_DATE,
            isPreSurveyQuestion: true,
            previousAnswer: []
        },
        {
            id: BENEFIT_QUESTION_ID,
            order: 30,
            hidden: false,
            locked: false,
            text: "Select an available tier associated with your organization.",
            answerType: [
                [
                    SurveyQuestionAnswerType.Select
                ]
            ],
            allowMultipleAnswers: false,
            projectDate: false,
            category: {
                id: 2,
                text: "User Information"
            },
            questionId: BENEFIT_QUESTION_ID,
            postApiRoute: API_ROUTE.SET_PLAN_BENEFIT,
            isPreSurveyQuestion: true,
            previousAnswer: []
        },
    ],
}