import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SurveyResult, SurveyAnswerOption, SurveyQuestion, SurveyQuestionAnswerType, SurveyYesNoAnswers } from '../../../../interfaces';
import { updateResult } from '../../../../store/survey/surveySlice';
import { selectCurrentQuestion, selectCurrentQuestionResult } from '../../../../store/survey/surveySlice';
import './CheckBox.css';
import { Option } from '../option/Option';
import { CAPITAL_ALPHABET } from "../../../../data/Constants";
import { isQuestionType, yesNoAnswerOptions } from "../../../../helpers/SurveyHelpers";

export const CheckBox = () => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult
    const [optionItems, setOptionItems] = useState<JSX.Element[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (!question.answerOptions) { return setOptionItems([]) }
        setOptionItems(question.answerOptions.map((o, i) => {
            var isActive = result && o.text === result.answer
            if (question.answerType[0].includes(SurveyQuestionAnswerType.YesNo)) {
                if (o.text === SurveyYesNoAnswers.No) {
                    isActive = result && result.answer === false
                } else {
                    isActive = result && result.answer === true
                }
            }

            const optionWithShortcut = { ...o, shortcutKey: CAPITAL_ALPHABET[i] };

            return <Option
                isActive={ isActive }
                answerOption={ optionWithShortcut }
                handleSelection={(o: SurveyAnswerOption) => selectOption(o)}
                key={o.id ? o.id.toString() : o.text}
            />
        }))

        if (isQuestionType(question, SurveyQuestionAnswerType.YesNo) && (result && result.answer === null)) {
            selectOption(yesNoAnswerOptions[1])
        }
        // eslint-disable-next-line
    }, [question, result])

    const getAnswerToSave = (answerOption: SurveyAnswerOption) => {
        if (isQuestionType(question, SurveyQuestionAnswerType.YesNo)) {
            if (answerOption.text === SurveyYesNoAnswers.Yes) {
                return true
            } else {
                return false
            }
        } else {
            return answerOption.text
        }
    }

    const selectOption = (answerOption: SurveyAnswerOption) => {
        if (question.locked) { return }
        // update the answer in the survey store
        dispatch(updateResult({
            answer: getAnswerToSave(answerOption),
            isValid: true
        }))

        // set the new options with new active option
        setOptionItems(question.answerOptions!.map((o, i) => {

          const uo = {
            ...o,
            shortcutKey: CAPITAL_ALPHABET[i]
          };
          return <Option
              isActive={uo === answerOption}
              answerOption={uo}
              handleSelection={(o: SurveyAnswerOption) => selectOption(o)}
              key={uo.id ? uo.id.toString() : uo.text}
          />
        }))
    }

    type KeyListener = { key: string }
    function onKeyDown({key}: KeyListener) {
        if (!question.answerOptions) { return }
        question.answerOptions.forEach((item, index) => {
            if (!item.shortcutKey) { return }
            if (key === CAPITAL_ALPHABET[index] || key === CAPITAL_ALPHABET[index].toLowerCase()) {
                selectOption(question.answerOptions[index])
            }
        })
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    })

    return (
        <div className="checkbox-content">
            <div className="option-items">{ optionItems }</div>
        </div>
    )
}