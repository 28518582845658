import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DataGrid, GridAddIcon, GridCellEditCommitParams, GridColDef, GridEditRowsModel, GridRenderCellParams, GridRenderEditCellParams } from "@mui/x-data-grid"
import { useCallback, useEffect, useMemo, useState } from "react";
import { useField, useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { SuperAdminApi } from "../../../api/SuperAdminAPI";
import { AlertTypes, Default } from "../../../interfaces";
import { setAlert } from "../../../store/alert/alertSlice";

interface MyGridRowData {
    id: number
    title: string
    type: string
  }  

const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

const formatPercent = (n: Number) => n.toFixed(2) + "%"

const formatVariableName = (v: string): string => {
    if (!v) return v
    const words = v.split("_")

    for (let i = 0; i < words.length; i++) {
        if (words[i].length === 0) continue;
        words[i] = (words?.[i]?.[0]?.toUpperCase?.() ?? "") + words[i].substr(1)
    }

    return words.join(" ")
}

const backToVariable = (v: string): string => (v && v.toLowerCase().replaceAll(' ', '_'))

export interface Props {
    titleIndex: number
}

export const OrgDefaultsEditor = () => {
    const [variables, setVariables] = useState<{
        id: number, name: string, type: string, description: string
    }[]>([])

    const form = useForm()

    const defaults = useField('defaults')
    const dispatch = useDispatch()

    

    useEffect(() => {
        const fetchVariables = async () => {
            try {
                const vars = await SuperAdminApi.getAllVariables()
                setVariables(vars)
            } catch {
                dispatch(setAlert({
                  text: "Could not fetch variables. Please try again.",
                  type: AlertTypes.Error
                }))
            }
        }
        fetchVariables()
    }, [])


    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Variable Name',
            type: 'string',
            flex: 2,
            editable: true,
            cellClassName: 'cell-editable',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <b>{ formatVariableName(params.value as string) }</b>
            ),
            renderEditCell: ({ id, value, api, field }: GridRenderEditCellParams) => {

                // this /is/ a react render function - the linter just can't tell
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [autocompleteOpen, setAutocompleteOpen] = useState(false)

                return (
                    <Box sx={{ p: 1, width: "100%" }}>
                    <Autocomplete
                        size="small"
                        style={{ height: "56px" }}
                        freeSolo
                        fullWidth
                        options={variables.map(v => formatVariableName(v.name))}
                        value={formatVariableName(value as string)}
                        onChange={(event, value) => {
                            api.setEditCellValue({ id, field, value: backToVariable(value) }, event);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && autocompleteOpen) event.stopPropagation()
                        }}
                        onOpen={() => setAutocompleteOpen(true)}
                        onClose={() => setAutocompleteOpen(false)}
                        renderInput={(params) => 
                        <TextField {...params}
                            onChange={(event) => {
                                api.setEditCellValue({ id, field, value: backToVariable(event.target.value) }, event);
                            }}
                        variant="outlined" />
                        }
                    />
                    </Box>
                )
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            sortable: false,
            flex: 3,
            renderCell: ({ row }) => {
                const matchingVariable = variables.find(v => v.name === row.name)
                if (matchingVariable && matchingVariable.description.trim()) return <Typography variant="body2" color="textSecondary">{matchingVariable.description}</Typography>
                else return <Typography variant="body2" color="textSecondary" style={{ fontStyle: "italic" }}>No description</Typography>
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            editable: true,
            flex: 1,
            renderEditCell: ({ id, value, api, field }: GridRenderEditCellParams) => {

                return (
                    <Box sx={{ p: 1, width: "100%" }}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                label="Type"
                                value={value}
                                onChange={(e) => {
                                    api.setEditCellValue({ id, field, value: e.target.value }, e);
                                    // api.setCellMode(id, field, GridCellModes.View)
                                }}
                            >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                                <MenuItem value="percent">Percent</MenuItem>
                                <MenuItem value="currency">Currency</MenuItem>
                                <MenuItem value="boolean">True/False</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    )
            }
        },
        {
            field: 'value',
            headerName: 'Value',
            editable: true,
            cellClassName: 'cell-editable',
            sortable: false,
            flex: 2,
            valueFormatter: (params) => {
                const value = params.value.toString();
                const row = params.value as MyGridRowData
                const type = row.type;

                switch (type) {
                    case "text":
                        return value;
                    case "number":
                        return value;
                    case "percent":
                        return formatPercent(parseFloat(value));
                    case "currency":
                        return currency.format(parseFloat(value));
                    case "boolean":
                        return value === "true" ? "True" : "False";
                    default:
                        return value;
                }

            },
        }
    ], [variables]);

    const [rows, setRows] = useState([])

    useEffect(() => {

        if (!defaults?.input?.value) return

        setRows(
            defaults.input.value.map((d: Default, i: number) => ({
                index: i,
                ...d
            }))
        )

        
    }, [defaults.input.value])

    const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});
    const handleEditRowsModelChange = useCallback((newModel: GridEditRowsModel) => {
        const updatedModel = { ...newModel };
        // Object.keys(updatedModel).forEach((id) => {
        //     if (updatedModel[id]?.value ) {
        //         const value = updatedModel[id]?.value.value as number
        //         const isValid = value && !isNaN(value) && value > 0
        //         updatedModel[id].value = { ...updatedModel[id].value, error: !isValid };
        //     }
        // });
        setEditRowsModel(updatedModel);
      }, [])

    const handleCommit = useCallback((params: GridCellEditCommitParams) => {
        const index = rows.find(r => r.id === params.id).index
        form.mutators.setValue(`defaults[${index}][${params.field}]`, params.value)

        if (params.field === 'name') {
            const variable = variables.find(v => v.name === params.value)
            if (variable) {
                const { type } = variable
                const allowed = ["text", "number", "percent", "currency", "boolean"]
                if (allowed.includes(type)) {
                    form.mutators.setValue(`defaults[${index}].type`, type)
                } else {
                    form.mutators.setValue(`defaults[${index}].type`, 'text')
                }
            }
        }

    }, [form.mutators, rows, variables])

    const newDefault: () => Default = useCallback(() => ({
        id: (rows.length + 1) * -1,
        name: null,
        type: "text",
        value: "",
    }), [rows])

    return (
        <Box sx={{ p: 3, height: "100%" }}>
            <DataGrid
                rowHeight={60}
                columns={columns}
                rows={rows}
                hideFooterPagination
                pageSize={100}
                disableColumnMenu
                disableSelectionOnClick
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleEditRowsModelChange}
                onCellEditCommit={handleCommit}
                components={{
                    Footer: () => (
                        <Box sx={{ p: 1 }}>
                            <Button size="large" variant="contained" disableElevation fullWidth color="primary"
                                onClick={() => form.mutators.push(`defaults`, newDefault())}
                            >
                                <GridAddIcon fontSize="small" />
                                Add
                            </Button>
                        </Box>
                    )
                }}
            />
        </Box>
    )
}