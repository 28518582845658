import { AutoComplete } from "../components/survey/autocomplete/AutoComplete"
import { BenefitAutoComplete } from "../components/survey/autocomplete/BenefitAutocomplete"
import { BENEFIT_QUESTION_ID } from "../data/PreSurveyData"
import { CheckBox } from "../components/survey/checkbox/checkbox/CheckBox"
import { NewDatePicker } from "../components/survey/date/DatePicker"
import { Description } from "../components/survey/description/Description"
import { Formula } from "../components/survey/formula/Formula"
import { AllowsMultiple } from "../components/survey/multiple/AllowsMultiple"
import { Number } from "../components/survey/number/Number"
import { WriteIn } from "../components/survey/write/WriteIn"
import { SurveyAnswerOption, SurveyQuestion, SurveyQuestionAnswerType } from "../interfaces"
import { NumberDate } from "../components/survey/number-date/NumberDate"

export const getQuestionComponent = (question: SurveyQuestion) => {
    if (question.answerType.length > 1) {
        return <Formula />
    }
    if ((question.allowMultipleAnswers || question.answerType[0].length > 1)) {
        return <AllowsMultiple />
    }

    // if it's the desired retirement age/date question
    if (question.id === 24) {
      return <NumberDate />
    }

    if (question.id === BENEFIT_QUESTION_ID) {
        return <BenefitAutoComplete key={question.answerType[0][0].toString() + "0"} />
    }

    return getComponentForAnswerType(question.answerType[0][0], undefined, undefined, question.answerOptions, undefined)
}

export const getComponentForAnswerType = (
  answerType: SurveyQuestionAnswerType, 
  handleValueChanged?: (val: any, inputIndex: number | undefined) => void,
  index?: number,
  answerOptions?: SurveyAnswerOption[],
  value?: any
  ) => {
    switch (answerType) {
        // KEYS ARE JUST RANDOM ALGORITHM
        case SurveyQuestionAnswerType.Free:
          if (handleValueChanged) {
            return <Description handleValueChanged={ handleValueChanged } index={ index } value={ value } key={answerType.toString() + (index ? index.toString() : "0")}/>
          }
          return <WriteIn key={answerType.toString() + (index ? index.toString() : "0")}/>
        case SurveyQuestionAnswerType.Date:
          return <NewDatePicker handleValueChanged={ handleValueChanged } index={ index } value={ value } key={answerType.toString() + (index ? index.toString() : "0")}/>
        case SurveyQuestionAnswerType.Select:
          if (answerOptions && answerOptions.length < 6) {
            return <CheckBox key={answerType.toString() + (index ? index.toString() : "0")}/>
          } else {
            return <AutoComplete key={answerType.toString() + (index ? index.toString() : "0")}/>
          }
        case SurveyQuestionAnswerType.YesNo:
          return <CheckBox key={answerType.toString() + (index ? index.toString() : "0")}/>
        case SurveyQuestionAnswerType.Number:
          return <Number handleValueChanged={ handleValueChanged } index={ index } value={ value } key={answerType.toString() + (index ? index.toString() : "0")}/>
        default:
          return <div></div>
    }
}