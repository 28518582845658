import { getTickerDate } from "../components/user/overview/status/OverviewStatus"
import { GraphDataPoint, TimePeriod, ProfileData, SickLeaveData } from "../interfaces"

// Function to convert a date string to a Date object for comparison
function parseDate(dateString: string): Date {
  const [month, day, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
}

export const getMonthlyPensionData = (
    profileData: ProfileData, 
    addSickLeave: boolean = false, 
    sickLeaveIncrease: SickLeaveData, 
    timePeriood: TimePeriod = TimePeriod.Annual
    ): GraphDataPoint[] => {
    var tempData = []

    const keys = Object.keys(profileData.monthly_data);
    keys.sort((a, b) => parseDate(a).getTime() - parseDate(b).getTime());

    for (const key of keys) {
      const value = profileData.monthly_data[key]
        var netValue = (addSickLeave && sickLeaveIncrease) ? value.yearly_net_pension + (sickLeaveIncrease.monthlyIncrease * 12) : value.yearly_net_pension
        var grossValue = ((addSickLeave && sickLeaveIncrease)
          ? value.yearly_gross_pension + (sickLeaveIncrease.monthlyIncrease * 12)
          : value.yearly_gross_pension)
        var earlyReductionAmount = (1 - value.earlyReductionFactor) * grossValue;

        if (timePeriood === TimePeriod.Monthly) {
            grossValue = grossValue / 12
            netValue = netValue / 12
            earlyReductionAmount /= 12
        } else if (timePeriood === TimePeriod.BiWeekly) {
            grossValue = grossValue / 26
            netValue = netValue / 26
            earlyReductionAmount /= 12
        }

        var name = ""
        let dateObject = new Date(key)
        if (dateObject.getFullYear() === (new Date()).getFullYear() && dateObject.getMonth() === (new Date()).getMonth()) {
            // if year is the same as this year set name as today
            name = "Today"
        } else {
            // calculate age of user at that year
            name = getTickerDate(dateObject)
        }

        const dataValue: GraphDataPoint = { 
            "gross": parseFloat(grossValue.toFixed(0)), 
            "net": parseFloat(netValue.toFixed(0)),
            "early": parseFloat(earlyReductionAmount.toFixed(0)),
            name: name,
            date: dateObject
        }

        tempData.push(dataValue)
    }

    return tempData
}

export const getInitialTimePeriod = (paycheckCount: number) => {
    if (paycheckCount === 26) {
        return TimePeriod.BiWeekly
    } else if (paycheckCount === 1) {
        return TimePeriod.Annual
    } else {
        return TimePeriod.Monthly
    }
}

export const makeid = (length: number) => {
    var result           = []
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
 charactersLength)));
   }
   return result.join('');
}

export const getRetireAgeFromDateIndex = (index: number, profileData: ProfileData) => {
    let key = profileData.monthly_data_keys[index]
    const age = (new Date(key).getTime() - new Date(profileData.date_of_birth).getTime()) / (1000 * 3600 * 24 * 365.25);
    let roundedAge = (Math.floor(age * 4) / 4);
    return roundedAge
}