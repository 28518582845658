import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProfileData } from '../../../../interfaces'
import { selectWorkingDate } from '../../../../store/profile/profileSlice'
import { getTickerDate } from '../status/OverviewStatus'
import { DateSelect } from '../DateSelect'
import './OverviewDaily.css'

type Props = {
    profileData: ProfileData
    onChange: (inputs: number) => void
}

export const OverviewDaily = ({ profileData, onChange }: Props) => {
    const [unitIndex, setUnitIndex] = useState(0)
    const [dataIndex, setDataIndex] = useState(0)
    const [dailyValue, setDailyValue] = useState(0)
    const workingDate = useSelector(selectWorkingDate)

    useEffect(() => {
        setDailyValue(profileData.monthly_data[profileData.monthly_data_keys[dataIndex]].daily_impact_value)
    }, [dataIndex, profileData])

    useEffect(() => {
        if (workingDate === null) { return }
        let tempDataIndex = profileData.monthly_data_keys.findIndex((v) => getTickerDate(new Date(v)) === getTickerDate(workingDate))
        if (tempDataIndex > 0) {
            setDataIndex(tempDataIndex)
        }
        // eslint-disable-next-line
    }, [workingDate])

    const UNIT_CONVERTER = [
        {
            multiplier: 1,
            buttonLabel: "Daily",
            descLabel: "everyday"
        },
        {
            multiplier: 7,
            buttonLabel: "Weekly",
            descLabel: "every week"
        },
        {
            multiplier: 30,
            buttonLabel: "Monthly",
            descLabel: "every month"
        },
        {
            multiplier: 365,
            buttonLabel: "Yearly",
            descLabel: "every year"
        }
    ]

    const handleUp = () => {
        if (unitIndex < UNIT_CONVERTER.length - 1) {
            setUnitIndex(unitIndex + 1)
            return
        }

        setUnitIndex(0)
    }

    return (
        <div className="widget-container">
            <div className="widget-header-container">
                <div className="widget-title">Tracking Your Pension</div>
                <div className="widget-configuration-button-container">
                    <DateSelect />
                    <button 
                        className="widget-configuration-button" 
                        onClick={ handleUp }
                    >
                        { UNIT_CONVERTER[unitIndex].buttonLabel }
                    </button>
                </div>
            </div>
            <h1 className="overview-daily-number">${Math.abs((dailyValue * UNIT_CONVERTER[unitIndex].multiplier)).toFixed(2)}</h1>
            <h1 className="overview-daily-description">Amount earned toward <em>monthly</em> pensionable income for&nbsp; 
                <span className="overview-daily-unit">{UNIT_CONVERTER[unitIndex].descLabel}</span> 
                &nbsp;of continued employment.
            </h1>
        </div>
    )
}