export enum ForecastingOptionType {
    AnnualRaise = "Annual Raise",
    DesiredRetirementAge = "Desired Retirement Age",
    Divorce = "Divorce Percentage",
    DeferredComp = "Deferred Investment Deduction",
    PurchasedService = "Purchased Service Credit",
    GrossPay = "Gross Pay",
    FinalSalary = "Final Salary"
}

export interface CreateForecastDto {
    annual_raise_percentage: number | null,
    desired_retirement_age: number | null,
    divorce_percentage: number | null,
    purchased_service_time: [number, number] | null,
    deferred_investment_deduction: number | null,
    gross_pay: number | null,
    final_overtime_salary: number | null,
}

export interface ForecastingDataValue<T> {
    profileValue: T,
    textfieldValue: T | null,
    savedValue: T | null,
    changeTitle: string,
}

export interface ForecastingFormData {
    annual_raise_percentage: ForecastingDataValue<number>,
    desired_retirement_age: ForecastingDataValue<number>,
    divorce_percentage: ForecastingDataValue<number>,
    purchased_service_time: ForecastingDataValue<[number, number]>,
    deferred_investment_deduction: ForecastingDataValue<number>,
    gross_pay: ForecastingDataValue<number>,
    final_overtime_salary: ForecastingDataValue<number>,
}

export interface CalculatorFormData {
  annual_raise_percentage: number,
  true_gross_pay: number,
  replacementIncome: number,
  vacation_hours: number,
  holiday_hours: number,
  vacationCashoutRate: number,
  holidayCashoutRate: number,
  sick_leave_hours: number,
  overtimeRate?: number,
}

export interface ForecastingOption {
    option: ForecastingOptionType,
    preFieldDecorator?: string,
    dataKey: keyof ForecastingFormData
    label: string,
}

export const FORECASTING_OPTIONS: ForecastingOption[] = [
    {
        option: ForecastingOptionType.AnnualRaise,
        preFieldDecorator: "%",
        dataKey: "annual_raise_percentage",
        label: "Annual Raise",
    },
    {
        option: ForecastingOptionType.DesiredRetirementAge,
        dataKey: "desired_retirement_age",
        label: "Desired Retirement Age"
    },
    {
        option: ForecastingOptionType.DeferredComp,
        preFieldDecorator: "$",
        dataKey: "deferred_investment_deduction",
        label: "Deferred Invesment Deduction"
    },
    {
        option: ForecastingOptionType.Divorce,
        preFieldDecorator: "%",
        dataKey: "divorce_percentage",
        label: "Divorce Percentage",
    },
    {
        option: ForecastingOptionType.PurchasedService,
        dataKey: "purchased_service_time",
        label: "Purchased Service Time",
    },
    {
        option: ForecastingOptionType.GrossPay,
        preFieldDecorator: "$",
        dataKey: "gross_pay",
        label: "Gross Salary (per pay period)",
    },
    {
        option: ForecastingOptionType.FinalSalary,
        preFieldDecorator: "$",
        dataKey: "final_overtime_salary",
        label: "Target Salary (per pay period)",
    }
]

export interface ForecastChange {
    title: string,
    beforeValue: any,
    afterValue: any
    dataKey: keyof ForecastingFormData,
    decorator: string
}

export interface ForecastDto {
    annual_raise_percentage: number,
    desired_retirement_age: number,
    divorce_percentage: number,
    purchased_service_time: [number, number],
    deferred_investment_deduction: number,
    gross_pay_before: number,
    gross_pay_after: number,
    final_overtime_salary_before: number,
    final_overtime_salary_after: number,
}

export interface ForecastDtoWithId {
    id: number,
    annual_raise_percentage: number,
    desired_retirement_age: number,
    divorce_percentage: number,
    purchased_service_time: string,
    deferred_investment_deduction: number,
    gross_pay: number,
    final_overtime_salary: number,
}