import {
  createSlice,
  createAsyncThunk,
  Reducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../storeTypes";
import {
  authLogin,
  authLogout,
  acceptTerms,
  createNewSubscriber,
  validateEmail,
} from "../../api/AuthAPI";
import {
  createSubscription,
  renewSubscription,
  cancelSubscription,
  addWhitelistPromo,
} from "../../api/SubscriptionAPI";
import { createNewAccount } from "../../api/OrganizationAPI";
import {
  NewSignUpFormData,
  ServiceStatusValues,
  User,
  UserRole,
  UserStatus,
} from "../../interfaces";
import { verifyAuthStatus, completedSurvey } from "../thunks";

interface AuthSlice {
  status: ServiceStatusValues;
  currentUser: User | null;
  isLoggedIn: boolean;
  isSubscribed: boolean;
  error: string | null;
}

// Define the initial state using that type
const initialState: AuthSlice = {
  status: ServiceStatusValues.Loading,
  currentUser: null,
  isLoggedIn: false,
  isSubscribed: false,
  error: null,
};

export const createAccount = createAsyncThunk(
  "auth/createAccount",
  async (signUpForm: NewSignUpFormData) => {
    try {
      const response = await createNewAccount(signUpForm);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const createSubscriber = createAsyncThunk(
  "auth/createSubscriber",
  async ({
    signUpForm,
    userId,
    token,
  }: {
    signUpForm: NewSignUpFormData;
    userId: string;
    token: string;
  }) => {
    try {
      const response = await createNewSubscriber(signUpForm, userId, token);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const authenticationRequest = createAsyncThunk(
  "auth/authenticate",
  async (
    { email, password }: { email: string; password: string },
    { dispatch }
  ) => {
    try {
      const response = await authLogin(email, password);
      dispatch(verifyAuthStatus());
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const authAcceptTerms = createAsyncThunk(
  "auth/acceptTerms",
  async (_, thunkAPI) => {
    try {
      const response = await acceptTerms();
      return response;
    } catch (err) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(
          err.response ? err.response.data : err.message
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const validateUserEmail = createAsyncThunk<
  { success: boolean },
  { userId: string; token: string }
>("user/validateEmail", async ({ userId, token }) => {
  const result = await validateEmail(userId, token);
  return result;
});

export const createSub = createAsyncThunk(
  "auth/createSubscription",
  async (membership: string) => {
    return await createSubscription(membership);
  }
);

export const cancelSub = createAsyncThunk(
  "auth/cancelSubscription",
  async () => {
    return await cancelSubscription();
  }
);

export const renewSub = createAsyncThunk(
  "auth/renewSubscription",
  async (membership: string) => {
    return await renewSubscription(membership);
  }
);

export const addPromo = createAsyncThunk(
  "auth/addWhitelistPromo",
  async (promoCode: string) => {
    return await addWhitelistPromo(promoCode);
  }
);

export const authLogoutUser = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await authLogout();
    return response;
  } catch (err) {
    return err;
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticatedUser(state, action) {
      state.currentUser = action.payload;
    },
    logoutUser(state) {
      state.currentUser = null;
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.pending, (state, action) => {
        state.status = ServiceStatusValues.Loading;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
      })
      .addCase(authenticationRequest.pending, (state) => {
        state.status = ServiceStatusValues.Loading;
      })
      .addCase(authenticationRequest.fulfilled, (state, action) => {
        const { payload } = action;
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = payload;
        state.isLoggedIn = payload ? true : false;
        state.isSubscribed = payload.isSubscribed;
      })
      .addCase(authenticationRequest.rejected, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = null;
        state.isLoggedIn = false;
        state.isSubscribed = false;
      })
      .addCase(verifyAuthStatus.pending, (state) => {
        state.status = ServiceStatusValues.Loading;
      })
      .addCase(verifyAuthStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = payload;
        state.isLoggedIn = payload ? true : false;
        state.isSubscribed = payload.isSubscribed;
      })
      .addCase(authAcceptTerms.fulfilled, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
        state.currentUser.acknowledgeTerms = true;
      })
      .addCase(authAcceptTerms.rejected, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
        state.currentUser.acknowledgeTerms = false;
      })
      .addCase(verifyAuthStatus.rejected, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = null;
        state.isLoggedIn = false;
        state.isSubscribed = false;
      })
      .addCase(completedSurvey.fulfilled, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
      })
      .addCase(completedSurvey.rejected, (state, action) => {
        state.status = ServiceStatusValues.Loaded;
      })
      .addCase(
        validateUserEmail.fulfilled,
        (state, action: PayloadAction<{ success: boolean }>) => {
          if (action.payload.success && state.isLoggedIn) {
            state.currentUser.status = UserStatus.Active;
          }
        }
      )
      .addCase(authLogoutUser.fulfilled, (state) => {
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = null;
        state.isLoggedIn = false;
        state.isSubscribed = false;
      })
      .addCase(createSub.rejected, (state, action) => {
        state.status = ServiceStatusValues.Error;
        state.error = action.error.message;
      })
      .addCase(cancelSub.fulfilled, 
        (state, action: PayloadAction<{ success: boolean, user: User }>) => {
        const { payload } = action;
        state.status = ServiceStatusValues.Loaded;
        state.currentUser = payload.user;
      })
      .addCase(cancelSub.rejected, (state, action) => {
        state.status = ServiceStatusValues.Error;
        state.error = action.error.message;
      })
      .addCase(
        renewSub.fulfilled,
        (state, action: PayloadAction<{ user: User }>) => {
          const { payload } = action;
          state.status = ServiceStatusValues.Loaded;
          state.currentUser = payload.user;
          state.isLoggedIn = !!payload;
        }
      )
      .addCase(renewSub.rejected, (state, action) => {
        state.status = ServiceStatusValues.Error;
        state.error = action.error.message;
      })
      .addCase(
        addPromo.fulfilled,
        (state, action: PayloadAction<{ user: User, success: boolean, isPromoValid: boolean }>) => {
          const { payload } = action;
          state.status = ServiceStatusValues.Loaded;
          state.currentUser = payload.user;
          state.isLoggedIn = !!payload;
          state.isSubscribed = payload.user.isSubscribed;
        }
      )
      .addCase(addPromo.rejected, (state, action) => {
        state.status = ServiceStatusValues.Error;
        state.error = action.error.message;
      });
  },
});

export const { authenticatedUser, logoutUser } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export const selectAuthChecked = (state: RootState) => state.auth.status;

export const selectIsValidated = (state: RootState) =>
  state.auth.currentUser?.status === UserStatus.Active || false;

export const selectFirstName = (state: RootState) =>
  state.auth.currentUser?.first_name || "";

export const selectLastName = (state: RootState) =>
  state.auth.currentUser?.last_name || "";

export const selectUserEmail = (state: RootState) =>
  state.auth.currentUser?.email;

export const selectLoggedInStatus = (state: RootState) => state.auth.isLoggedIn;

export const selectSubscriptionStatus = (state: RootState) =>
  state.auth.isSubscribed;

export const selectSubscriptionInfo = (state: RootState) =>
  state.auth.currentUser?.subscription;

export const selectHasTrialed = (state: RootState) =>
  state.auth.currentUser?.hasTrialed || false;

export const selectWhitelistStatus = (state: RootState) =>
  state.auth.currentUser?.whitelistInfo;

export const selectCompletedDashboardIntro = (state: RootState) =>
  state.auth.currentUser?.completedDashboardIntro;

export const selectCompleteSurvey = (state: RootState) =>
  state.auth.currentUser?.completedSurvey || false;

export const selectAcknowledgeTerms = (state: RootState) =>
  state.auth.currentUser?.acknowledge_terms || false;

export const selectIsAdmin = (state: RootState) =>
  state.auth.currentUser?.role === UserRole.Super || false;

export default authSlice.reducer as Reducer<typeof initialState>;
