import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { sendResetPassword } from '../../../../api/AuthAPI'
import { validateForgotForm } from '../../../../helpers/FormHelpers'
import { AuthErrors } from '../../../../interfaces'
import { AuthSubmit } from '../../shared/submit/AuthSubmit'
import { AuthTextfield, AuthTextfieldTypes } from '../../shared/textfield/AuthTextfield'

export const ForgotForm = () => {
    const [email, setEmail] = useState<string>("")
    const [formErrors, setErrors] = useState(null)
    const [formErrorMessages, setErrorMessages] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState<string>(null)
    const formDataRef = useRef(email)
    const history = useHistory()

    useEffect(() => {
        // free ones need diff logic to handle pressing enter on autocomplete
        const onKeyDown = (ev: KeyboardEvent) => {
            // arrow keys should only navigate questions if input isn't active or input value is empty
            if(ev.key === "Enter") {
                handleSubmit()
            }
        }

        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    const handleErrors = (errors: AuthErrors[]) => {
        setErrors(errors)
        setErrorMessages(errors.map((e) => <h1 key={e} className="auth-form-error">* {e}</h1>))
    }

    const handleSubmit = () => {
        const errors = validateForgotForm(formDataRef.current)
        if (errors !== null) {
            handleErrors(errors)
            return
        }

        handleResetRequest()
    }

    const handleBack = () => {
        history.push("/login")
    }

    const handleResetRequest = async () => {
        setIsLoading(true)
        try {
            await sendResetPassword(formDataRef.current)
            setIsLoading(false)
            setEmail("")
            setSuccessMessage("Success! Please check your email for a link to reset your password.")
        } catch {
            setIsLoading(false)            
            handleErrors([AuthErrors.ResetError])
        }
    }

    const handleInputChange = (type: AuthTextfieldTypes, val: string) => {
        formDataRef.current = val
        setEmail(val)
    }

    return (
        <div className="auth-form-container">
            <div className="auth-form-content">
                <div>
                    <h1 className="auth-form-title">Forgot Password?</h1>
                    <div className="auth-form-title-underline"></div>
                </div>
                { formErrors && formErrorMessages }
                <strong style={{ color: "var(--emerald-green)"}}>
                    { successMessage }
                </strong>
                <div>
                    <AuthTextfield 
                        key="email"
                        type={ AuthTextfieldTypes.Email } 
                        formErrors={ formErrors } 
                        value={ email } 
                        onChange={ handleInputChange } 
                    />
                </div>
                <AuthSubmit isLoading={ isLoading } onSubmit={handleSubmit} title="SEND RESET LINK" />
                <div 
                    className="forgot-password-button"
                    onClick={handleBack}
                >
                    Back To Login
                </div>
            </div>
        </div>
    )
}