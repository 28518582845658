import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field, useField } from "react-final-form";
import { SurveyQuestion } from "../../../interfaces";
import { selectProfileData } from "../../../store/profile/profileSlice";
import {
  required,
} from "../../../helpers/ValidationHelpers";
import "../number/Number.css";

type Props = {
  question: SurveyQuestion;
};

export const NumberDateEdit = ({ question }: Props) => {
  const profileData = useSelector(selectProfileData);
  const [currentMinValue, setCurrentMinValue] = useState<number>(null);
  const [retireAge, setRetireAge] = useState<number | string>(60);
  const [dateValue, setDateValue] = useState<Date>(new Date());

  const dateOfBirth = profileData?.date_of_birth;
  const currentField = useField(question.variable.name);

  const calculateAge = (dateOfBirth: string, date: Date) => {
    return Math.round(dayjs(date).diff(new Date(dateOfBirth), "year", true) * 1000) / 1000;
  }

  useEffect(() => {
    if (!profileData) return;
    if (dateOfBirth && question.text.toLowerCase().includes("retirement age")) {
      let dob = new Date(dateOfBirth);
      let currentDate = new Date();
      const age = dayjs(currentDate).diff(dob, "year", true);
      let minimumRetirementAge = Math.ceil(Math.max(18, age) * 100) / 100;
      setCurrentMinValue(minimumRetirementAge);
      if (question.prefill) {
        setDateValue(new Date(question.prefill));
        const projectedAge = calculateAge(dateOfBirth, question.prefill);
        setRetireAge(projectedAge);
      }
    }
  }, [question, profileData, dateOfBirth]);

  const handleInputChange = (val: string) => {
    const num = parseFloat(val)

    // corner case: if val not a number, immediately return
    if (isNaN(num)) {
      setDateValue(null);
      setRetireAge("");
    }

    const yearsToAdd = Math.floor(num);
    const daysToAdd = (num - yearsToAdd) * 365;

    const futureDate = dayjs(new Date(dateOfBirth))
        .add(yearsToAdd, "years")
        .add(daysToAdd, "days")
        .toDate();
    currentField.input.onChange(futureDate);

    setDateValue(futureDate);

    setRetireAge(num);
  };

  const updateNumberInputWithDate = (date: Date | null) => {
    setDateValue(date);
    currentField.input.onChange(date);
    const age = calculateAge(dateOfBirth, date)
    if (age >= currentMinValue) {
      setRetireAge(age)
    } else {
      setRetireAge("")
    }
  };

  return (
    <>
      <div
        className="number-input-container"
        style={{
          flexDirection: "column",
          height: "auto",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Field
            component="input"
            name={question.variable.name}
            type="date"
            className="number-input-edit"
            validate={required}
            readOnly={question.locked}
          >
            {({ input, meta }) => (
              <>
                {!question.postFieldDecorator &&
                  !question.preFieldDecorator && (
                    <span className="number-symbol-edit">#</span>
                  )}
                <input
                  className="number-input-edit"
                  {...input}
                  type="number"
                  value={ retireAge }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value)
                  }
                />
                {question.postFieldDecorator && (
                  <span className="number-symbol-edit number-symbol-post">
                    {question.postFieldDecorator.flat()}
                  </span>
                )}
                {meta.error && meta.touched && (
                  <span className="number-input-edit error">{meta.error}</span>
                )}
                <span>OR ENTER DATE</span>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={ dateValue }
                      onChange={updateNumberInputWithDate}
                      onAccept={updateNumberInputWithDate}
                      disabled={question.locked}
                      minDate={new Date()}
                    />
                  </LocalizationProvider>
                </div>
              </>
            )}
          </Field>
        </div>
        {currentMinValue && currentField.input.value < currentMinValue && (
          <em>
            <p>
              You must enter a number higher than{" "}
              {Math.ceil(currentMinValue * 1000) / 1000}
            </p>
          </em>
        )}
      </div>
    </>
  );
};
