import { Autocomplete, TextField } from '@mui/material';
import { Field } from 'react-final-form';
import { SurveyQuestion } from "../../../interfaces";
import './WriteIn.css';

type Props = {
    question: SurveyQuestion,
}

export const WriteIn = ({ question }: Props) => {
    return (
        <Field
            type="string"
            component="input"
            name={question.variable.name}
            initialValue={ question.prefill ? question.prefill : "" }
            question={question}
        >
            {({input: {onChange, value}, question, ...rest}) => (
            <Autocomplete
            id="grouped-demo"
                options={question.answerOptions}
                getOptionLabel={(option) => option.text ? option.text : option}
                onChange={(e, newValue) => onChange(newValue ? newValue.text : null)}
                value={(value !== "0" && value) ? value : null}
                isOptionEqualToValue={(option) => option.text === value}
                {...rest}
                renderInput={(params) =>
                    <TextField
                     {...rest}
                     {...params}
                     label={value ? "" : question.placeholderText}
                    />}
            />
            )}
        </Field>
    )
}