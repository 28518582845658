import { Box, ListItemButton, Link } from '@mui/material';
import dayjs from 'dayjs';
import { User } from "../../../interfaces";

export const AllUsers = ({ users } : { users: User[]}) => {
  return (
    <Box sx={{ px: 3, py: 3, mb: 2 }}>
      <Box sx={{ display: "flex", paddingLeft: "1rem", textAlign: "left" }}>
        <span style={{ flex: "1 1 33%" }}>Date created</span>
        <span style={{ flex: "1 1 33%" }}>Name</span>
        <span style={{ flex: "1 1 33%" }}>Email</span>
      </Box>
      {users.map((user) => {
        const dateObj = new Date(user.created_at);
        const formattedDate = dayjs(dateObj).format('MM-DD-YYYY');
        return (
        <Link
          key={user.id}
          href={`/superadmin/edit?id=${user.id}`}
          underline="none"
        >
          <ListItemButton
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <span style={{ flex: "1 1 33%" }}>{formattedDate}</span>
            <span style={{ flex: "1 1 33%" }}>{`${user.first_name} ${user.last_name}`}</span>
            <span style={{ flex: "1 1 33%" }}>{user.email}</span>
          </ListItemButton>
        </Link>
        )}
      )}
    </Box>
  )
}