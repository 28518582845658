import React from 'react'
;import { useSelector } from 'react-redux';
import { PensionDataTypes, PreviousValues } from '../../../interfaces';
import { selectPensionType } from '../../../store/profile/profileSlice';
import { formatNumbers } from '../../../utils/numberDateFormatters';

type Props = {
    isMobile: boolean,
    values: PreviousValues,
    isSubscribed: boolean,
    taxWithholdings: number,
}


const Detail = {
    SYSTEM: 'Retirement system',
    FORMULA: 'Benefit formula',
    ALLOWANCE: 'Unmodified pension allowance',
}

interface Details {
    [key: string]: string,
}

type DetailProps = {
    details: Details,
}

export const DetailsHeader = ({ details }: DetailProps) => {
    return (
        <div className="details-header">
                {Object.keys(details).map((detail: string, i: number) =>
                    <React.Fragment key={i}>
                        <span style={{fontWeight: 500}}>
                            {detail}
                            {': '}
                            </span>
                        <span>
                            {details[detail]}
                        </span>
                        <p />
                    </React.Fragment>
                )}
        </div>
    )
}

export const PreviousOrgDetails = ({ isMobile = true, values, isSubscribed = false, taxWithholdings }: Props) => {
    const pensionType = useSelector(selectPensionType);
    const isGrossNumber = pensionType === PensionDataTypes.Gross;
    const taxAdjustment = isGrossNumber ? 1 : (1 - taxWithholdings / 100)
    const formattedEarlyFactor = Math.round(taxAdjustment * values.earlyFactor * 1000) / 10;
    const formattedServiceYears = Math.round(taxAdjustment * values.serviceYears * 100) / 100;
    const formattedPensionAmt = `$${formatNumbers(taxAdjustment * values.effectivePensionAmount, isSubscribed)}`;

    const DetailsInfo = {
        [Detail.SYSTEM]: values.systemName,
        [Detail.FORMULA]: values.retirementBenefitName,
        [Detail.ALLOWANCE]: formattedPensionAmt,
    }

    if (!values) {
      return (
          <div></div>
      )
    }

    return (
        <div
            className="widget-container summary-widget-container"
        >
            <div className="widget-header-container">
               <div className="widget-title">
                    {values.orgName}
                </div>
                <div
                    style={ isMobile ? {marginTop: '0.25rem'} : {}}
                    className="widget-light-text"
                >
                    Previous employer
                </div>
            </div>
            <DetailsHeader details={DetailsInfo} />
            <div style={{textAlign: 'left'}}>
                Final compensation period: {values.finalCompensationPeriod} months
                <p />
                Final average salary: ${formatNumbers(values.finalCompensation, isSubscribed)}
                <p />
                Benefit factor: {Math.round(values.benefitFactor * 1000) / 10}%
                <p />
                {values.isEarlyReduction && (values.earlyFactor < 1) &&
                    <>
                        Early reduction factor: {formattedEarlyFactor}%
                        <p />
                    </>
                }
                Years of service: {formattedServiceYears}
                <p />
                {`Unmodified pension allowance = ${formatNumbers(values.finalCompensation, isSubscribed)}
                    * ${Math.round(values.benefitFactor * 1000) / 10}%
                    ${values.isEarlyReduction && (values.earlyFactor < 1) ? `* ${formattedEarlyFactor}%` : ''}
                    * ${formattedServiceYears} years
                    = ${formattedPensionAmt}`}
                <p />
                {`Integrated with Social Security: ${values.isSocialSecurity ? 'Yes' : 'No'}`}
                <p />
                {`Has reciprocity: ${values.reciprocity ? 'Yes' : 'No'}`}
            </div>
        </div>
    )
}