import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus } from '../../store/auth/authSlice';

interface AdminRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const AdminRoute: React.FC<AdminRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAdmin = useSelector(selectIsAdmin);
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const hasCompletedSurvey = useSelector(selectCompleteSurvey);
  const handleRedirect = () => {
      if (isLoggedIn) {
        if (hasCompletedSurvey) return "/overview"
        else return "/survey"
      } else {
        return "/login"
      }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: handleRedirect(),
            }}
          />
        )
      }
    />
  );
}

export default AdminRoute;
