import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleBackPress, handleNextPress } from '../../../api/AnimationAPI'
import { canProceedToNextQuestion } from '../../../helpers/SurveyHelpers'
import { selectSubscriptionStatus } from '../../../store/auth/authSlice'
import { selectCurrentQuestion, selectCurrentQuestionIndex, selectCurrentQuestionResult, selectSurveyData } from '../../../store/survey/surveySlice'
import { AnimationContext } from '../../App'
import './ProgressBar.css'

export const ProgressBar = () => {
    const animationControls = useContext(AnimationContext)
    const surveyResults = useSelector(selectCurrentQuestionResult)
    const currentQuestionIndex = useSelector(selectCurrentQuestionIndex)
    const currentQuestion = useSelector(selectCurrentQuestion)
    const surveyData = (useSelector(selectSurveyData))
    const dispatch = useDispatch();
    const history = useHistory();
    const isSubscribed = useSelector(selectSubscriptionStatus)

    const [showBack, setShowBack] = useState(true)
    const [showNext, setShowNext] = useState(true)
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        setShowNext(canProceedToNextQuestion(surveyResults))
        setShowBack(currentQuestionIndex !== 0)
        setPercent(Math.round((currentQuestionIndex) / (surveyData.length) * 100))
    }, [surveyResults, currentQuestionIndex, surveyData])

    return (
        <div>
            <div className={"progress-container"}>
                <div className={"progress-control-container"}>
                    <div>
                        <h1 className="completed-text">{Math.min(percent, 100)}% Completed</h1>
                        <div className="bar-background">
                            <div className="bar-foreground" style={{width: Math.min(percent, 100) + '%'}}></div>
                        </div>
                    </div>
                    { showBack && 
                        <button className="arrow arrow-left" onClick={ e => handleBackPress(
                          currentQuestionIndex,
                          animationControls, dispatch) }>
                            <FontAwesomeIcon className="arrow-icon" icon={faArrowLeft} />
                        </button>
                    }
                    { showNext && 
                        <button className="arrow" onClick={ e=> handleNextPress(
                            currentQuestion,
                            surveyResults,
                            currentQuestionIndex,
                            surveyData,
                            animationControls,
                            dispatch,
                            history,
                            isSubscribed,
                            ) }>
                            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
                        </button> 
                    }
                </div>
            </div>
        </div>
    )
}