import { BarLoader } from 'react-spinners'
import './PageLoader.css'

type Props = {
    title: string
}

export const PageLoader = ({ title }: Props) => {
    return (
        <div className="page-loader-background-container">
            <div className="page-loader-container">
                <p>{ title }</p>
                <BarLoader color="var(--dashboard-blue-purple)" height="10px" width="100%"/>
            </div>
        </div>
    )
}