import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PageContainer from "./user/PageContainer";
import PrivateRoute from './shared/PrivateRoute';
import { Survey } from './survey/Survey';
import Login from "../components/auth/login/Login";
import { getLatestTerms } from '../api/AuthAPI';
import {
    getInviteRouteComponent,
    getOrgInviteRouteComponent,
    getResetRouteComponent,
    getSuperAdminRouteComponent,
    RoutePages } from '../helpers/RouteHelpers';
import { User, UserRole } from '../interfaces';
import { UserPage, UserPageRoute } from "./user/UserPage";
import { authLogoutUser } from '../store/auth/authSlice';
import { selectAuthChecked, selectCurrentUser, authAcceptTerms } from '../store/auth/authSlice';
import { verifyAuthStatus } from '../store/thunks';
import { AppAlert } from './alerts/app-alert/AppAlert';
import './App.css';
import { TermsOfService } from './auth/shared/terms/TermsOfService';
import { NotFound } from './errors/NotFound';
import * as Sentry from "@sentry/react";
import AdminRoute from './shared/AdminRoute';
import PublicRoute from './shared/PublicRoute';
import { SuperAdmin } from './super-admin/SuperAdmin';
import { SuperAdminEdit } from './super-admin/edit/SuperAdminEdit';
import { SuperAdminNewUser } from './super-admin/newUser/SuperAdminNewUser';
import Forgot from './auth/forgot/Forgot';
import { RegistrationPage } from "../components/auth/register/RegistrationPage";
import HomeLandingPage from './home/homeLandingPage';
import { InvitePage } from './auth/register/InvitePage';
import { Subscription } from './user/overview/Subscription';
import EmailValidation from './user/overview/EmailValidation';
import EmailRemoval from './user/overview/EmailRemoval';

export const AnimationContext = React.createContext(null)

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
          color: "#1976d2",
        }
      }
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: '1.2em'
            }
        }
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(',')
  }
});

function App() {
    const [showTerms, setShowTerms] = useState(false)
    const loggedInUser = useSelector(selectCurrentUser) as User
    const isAuthChecked = useSelector(selectAuthChecked)
    const dispatch = useDispatch();

    const checkAuthStatus = async () => {
        try {
          dispatch(verifyAuthStatus())
        } catch {
          dispatch(authLogoutUser())
        }
    }

    useEffect(() => {
      checkAuthStatus()
    }, [])

    useEffect(() => {
        if (!loggedInUser) { return }
        getLatestTerms().then((terms) => {
            if (loggedInUser.role === UserRole.User && (!loggedInUser.acknowledgeTerms || loggedInUser.latest_terms_version < terms.version)) {
                setShowTerms(true)
            }
        })
    }, [loggedInUser])

    useEffect(() => {
        if (!loggedInUser || !loggedInUser.id) { return }
        Sentry.setUser({
            id: loggedInUser.id.toString(),
            email: loggedInUser.email,
        })
    }, [loggedInUser])

    
    const handleTermsAccepted = async () => {
      if (loggedInUser) {
        dispatch(authAcceptTerms())
        setShowTerms(false)
      }
    }

    if (!isAuthChecked) {
        return (
            <div></div>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <AppAlert />
            {
                showTerms &&
                <TermsOfService
                    onClose={() => setShowTerms(false)}
                    onAccept={handleTermsAccepted}
                    showClose={false}
                />
            }
            <Router>
                <Switch>
                    <PublicRoute
                      exact path="/"
                      component={HomeLandingPage}
                    />
                    <Route path="/superadmin/org-edit/:id">
                        { getSuperAdminRouteComponent(RoutePages.SuperAdminOrgEdit, loggedInUser) }
                    </Route>
                    <Route path="/superadmin/onboard">
                        { getSuperAdminRouteComponent(RoutePages.SuperAdminOnboard, loggedInUser) }
                    </Route>
                    <Route path="/superadmin/approve/:key">
                        { getSuperAdminRouteComponent(RoutePages.SuperAdminApprove, loggedInUser) }
                    </Route>
                    <AdminRoute
                      path="/superadmin/newUser"
                      component={SuperAdminNewUser}
                    />
                    <AdminRoute
                      path="/superadmin/edit"
                      component={SuperAdminEdit}
                    />
                    <AdminRoute
                      path="/superadmin"
                      component={SuperAdmin}
                    />
                    <PublicRoute
                      path="/login"
                      component={Login}
                    />
                    <PublicRoute
                      path="/forgot"
                      component={Forgot}
                    />
                    <PrivateRoute
                      path="/survey">
                      {props => <Survey preSurveyProgress={loggedInUser?.preSurveyProgress} {...props} />}
                    </PrivateRoute>
                    <PublicRoute
                      path="/subscribe"
                      component={Subscription}
                    />
                    <PublicRoute
                      path="/success"
                      component={Subscription}
                    />
                    <PublicRoute
                      path="/about"
                      component={Subscription}
                    />
                    <PublicRoute
                      path="/about"
                      component={Subscription}
                    />
                    <PrivateRoute
                      path="/overview">
                      {props => <UserPage page={UserPageRoute.Overview} {...props} />}
                    </PrivateRoute>
                    <PrivateRoute
                      path="/verify">
                      {props => <UserPage page={UserPageRoute.Verify} {...props} />}
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                      {props => <UserPage page={UserPageRoute.Settings} {...props} />}
                    </PrivateRoute>
                    <PrivateRoute path="/compare">
                      {props => <UserPage page={UserPageRoute.Compare} {...props} />}
                    </PrivateRoute>
                    <PublicRoute
                      path="/register"
                      component={RegistrationPage}
                    />
                    <PublicRoute
                      path="/invite/:userId/:token"
                      component={InvitePage}
                    />
                    <PublicRoute
                      path="/validate/:userId/:token"
                      component={EmailValidation}
                    />
                    <PublicRoute
                      path="/remove/:userId/:token"
                      component={EmailRemoval}
                    />
                    <Route path="/autojoin/:key">
                        { getOrgInviteRouteComponent(loggedInUser) }
                    </Route>
                    <Route path="/reset/:userId/:token">
                        { getResetRouteComponent(loggedInUser) }
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App;