import { postNullAnswers, postCompletedSurvey } from "../api/SurveyAPI";
import { BENEFIT_QUESTION_ID, HIRE_DATE_ID } from "../data/PreSurveyData";
import {
  AlertTypes,
  Service,
  ServiceStatusValues,
  SurveyAPIData,
  SurveyResult,
  SurveyResults,
  SurveySlice,
  PreSurveyProgress,
  ComponentLocation,
  SurveyAnswerOption,
  SurveyQuestion,
  SurveyQuestionAnswerType,
  SurveyYesNoAnswers,
} from "../interfaces";

import {getStore} from "../store";
import { setAlert } from "../store/alert/alertSlice";
import { completedSurvey } from "../store/thunks";
import { selectCurrentQuestionResult } from "../store/survey/surveySlice";

export const yesNoAnswerOptions: SurveyAnswerOption[] = [
  {
    id: 1,
    text: SurveyYesNoAnswers.Yes.toString(),
    shortcutKey: "Y",
  },
  {
    id: 2,
    text: SurveyYesNoAnswers.No.toString(),
    shortcutKey: "N",
  },
];

export const sortSurveyQuestions = (questions: SurveyQuestion[]) => {
  var tempQuestions = [...questions];
  // need to add answer options to proceed quesitons
  tempQuestions.forEach((q) => {
    if (q.answerType[0].includes(SurveyQuestionAnswerType.YesNo)) {
      q.answerOptions = yesNoAnswerOptions;
    }
  });

  return tempQuestions;
};

export const getPreviousSurveyResults = (
  surveyData: SurveyAPIData,
  preSurveyProgress?: PreSurveyProgress
) => {
  var surveyResults: SurveyResults = {};
  for (const question of surveyData.questions) {
    if (question.previousAnswer.length) {
      surveyResults = {
        ...surveyResults,
        [question.id]: {
          answer: question.previousAnswer[0].value,
          isValid: true,
        },
      };
    } else {
      surveyResults = {
        ...surveyResults,
        [question.id]: {
          answer: "",
          isValid: false,
        },
      };
    }
  }

  // autofill pre survey data
  if (preSurveyProgress && preSurveyProgress.hireDate) {
    surveyResults = {
      ...surveyResults,
      [HIRE_DATE_ID]: { answer: preSurveyProgress.hireDate, isValid: true },
    };
  }
  if (preSurveyProgress && preSurveyProgress.planBenefit) {
    surveyResults = {
      ...surveyResults,
      [BENEFIT_QUESTION_ID]: {
        answer: "",
        planId: preSurveyProgress.planBenefit,
        rsbId: preSurveyProgress.rsbId,
        isValid: true,
      },
    };
  }

  return surveyResults;
};

export const getInitialCurrentQuestion = (
  surveyData: SurveyAPIData,
  preSurveyProgress?: PreSurveyProgress
) => {
  var currentQuestionIndex = 0;
  if (preSurveyProgress && !preSurveyProgress.hireDate) {
    currentQuestionIndex = 0;
  } else if (preSurveyProgress && !preSurveyProgress.planBenefit) {
    currentQuestionIndex = surveyData.questions.findIndex(
      (val) => val.questionId === HIRE_DATE_ID
    );
  }

  return {
    index: currentQuestionIndex,
    question: surveyData.questions[currentQuestionIndex],
  };
};

export const getQuestionWithId = (
  questions: SurveyQuestion[],
  questionId: number
) => {
  var currentQuestionIndex = questions.findIndex(
    (val) => val.questionId === questionId
  );
  return {
    index: currentQuestionIndex,
    question: questions[currentQuestionIndex],
  };
};

export const getInitialQuestionNumber = (
  surveyData: SurveyAPIData,
  preSurveyProgress?: PreSurveyProgress
) => {
  if (preSurveyProgress && !preSurveyProgress.hireDate) {
    return 1;
  } else if (preSurveyProgress && !preSurveyProgress.planBenefit) {
    return 1;
  }

  return 1;
};

export const getNextQuestionIndex = (state: SurveySlice) => {
  if (state.status !== ServiceStatusValues.Loaded) {
    return 0;
  }

  const currentIndex = state.currentQuestionData.index;
  const currentQuestion = state.currentQuestionData.question;

  if (!isQuestionType(currentQuestion, SurveyQuestionAnswerType.YesNo)) {
    return currentIndex + 1;
  }

  // if it is a yes no question, check whether to skip to next category
  const proceedResult = state.surveyResults[
    currentQuestion.questionId
  ] as SurveyResult;
  if (proceedResult && proceedResult.answer === false) {
    const nextQuestionIndex = state.surveyData.findIndex(
      (q, i) =>
        i > currentIndex &&
        q.category &&
        q.category.id !== currentQuestion.category.id
    );
    const skippedQuestionIds = state.surveyData
      .slice(currentIndex + 1, nextQuestionIndex)
      .map((q) => q.questionId);
    postNullAnswers(skippedQuestionIds);
    return nextQuestionIndex !== -1 ? nextQuestionIndex : currentIndex + 2;
  } else {
    return currentIndex + 1;
  }
};

export const canProceedToNextQuestion = (answer: SurveyResult) => {
  return answer && answer.isValid;
};

export const getPrevQuestionIndex = (state: SurveySlice) => {
  if (state.status !== ServiceStatusValues.Loaded) {
    return 0;
  }
  var currentIndex = state.currentQuestionData.index;
  const surveyQuestions = state.surveyData;
  const results = state.surveyResults;

  currentIndex = currentIndex > 0 ? currentIndex - 1 : 0;

  const prevQuestion = surveyQuestions[currentIndex];

  if (prevQuestion?.isPreSurveyQuestion) {
    currentIndex = state.surveyData.findIndex(
      (q) => q.questionId === HIRE_DATE_ID
    );
  }

  // handle going back and a skipped question (skipped due to answer of no on yes no question)
  if (!(prevQuestion.questionId in results)) {
    currentIndex = surveyQuestions.findIndex((q, i) => {
      return (
        i < currentIndex &&
        q.category &&
        q.category.id === surveyQuestions[currentIndex].category.id &&
        isQuestionType(q, SurveyQuestionAnswerType.YesNo)
      );
    });
  }

  return currentIndex;
};

export const generateLocationKey = (location: ComponentLocation) => {
  return (location.row + 5 * location.column + 4 * Math.random()).toString();
};

export const isQuestionType = (
  surveyQuestion: SurveyQuestion,
  type: SurveyQuestionAnswerType
) => {
  return (
    surveyQuestion !== undefined && surveyQuestion.answerType[0].includes(type)
  );
};

export const handleFinalQuestion = async () => {
  try {
    await postCompletedSurvey();
    getStore().dispatch(completedSurvey());
    window.location.href = window.location.origin + "/overview";
  } catch {
    getStore().dispatch(
      setAlert({
        text: "Could not complete survey. Please try again",
        type: AlertTypes.Error
      })
    );
  }
};
