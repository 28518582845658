import { InviteWelcome } from '../shared/welcome/InviteWelcome'
import { LoginForm } from './form/LoginForm'
import '../shared/styles/AuthStyles.css'

const Login = () => {
    return (
        <div className="auth-page-container">
            <InviteWelcome />
            <LoginForm />
        </div>
    )
}

export default Login;
