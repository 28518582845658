import axios, { AxiosError, AxiosResponse } from "axios";
import {
  apiDefaultOptions,
  API_ROUTE,
  getBackendUrlForPath,
} from "../helpers/APIHelpers";
import {
  AlertTypes,
  ConsumeInvitationBody,
  ConsumeOrganizationInvitationBody,
  NewSignUpFormData,
  SignUpFormData,
  UserFormValues,
  NewOrganization,
} from "../interfaces";
import { getStore } from "../store";
import { setAlert } from "../store/alert/alertSlice";

export const createInvitation = (values: UserFormValues) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(API_ROUTE.CREATE_INVITATION),
        values,
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const generateOrganizationInvitation = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(API_ROUTE.GENERATE_ORGANIZATION_INVITATION),
        {},
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const resendInvitation = (email: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(API_ROUTE.RESEND_INVITATION),
        { email: email },
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const getInvitation = (key: string) => {
  return new Promise((resolve, reject) => {
    axios.get(getBackendUrlForPath(`${API_ROUTE.GET_INVITATION}${key}`)).then(
      (response: AxiosResponse) => {
        resolve(response.data);
      },
      (error: AxiosError) => {
        reject(error);
      }
    );
  });
};

export const getOrganizationInvitation = (key: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        getBackendUrlForPath(`${API_ROUTE.GET_ORGANIZATION_INVITATION}${key}`)
      )
      .then(
        (response: AxiosResponse) => {
          resolve(response.data);
        },
        (error: AxiosError) => {
          reject(error);
        }
      );
  });
};

export const getInvitationByOrg = (orgId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        getBackendUrlForPath(`${API_ROUTE.GET_INVITATION_BY_ORG}${orgId}`),
        apiDefaultOptions
      )
      .then(
        (response: AxiosResponse) => {
          resolve(response.data);
        },
        (error: AxiosError) => {
          reject(error);
        }
      );
  });
};

export const activateInvitation = (key: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(`${API_ROUTE.ACTIVATE_ORGANIZATION_INVITATION}`),
        { key: key },
        apiDefaultOptions
      )
      .then(
        (response: AxiosResponse) => {
          getStore().dispatch(
            setAlert({
              text: "Organization invite code has been activated and updated below.",
              type: AlertTypes.Success,
            })
          );
          resolve(response.data);
        },
        (error: AxiosError) => {
          getStore().dispatch(
            setAlert({
              text: "Could not activate your invitation. Please try again.",
              type: AlertTypes.Error,
            })
          );
          reject(error);
        }
      );
  });
};

export const deactivateInvitation = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(`${API_ROUTE.DEACTIVATE_ORGANIZATION_INVITATION}`),
        {},
        apiDefaultOptions
      )
      .then(
        (response: AxiosResponse) => {
          getStore().dispatch(
            setAlert({
              text: "Organization invite code has been deactivated and updated below.",
              type: AlertTypes.Success,
            })
          );
          resolve(response.data);
        },
        (error: AxiosError) => {
          getStore().dispatch(
            setAlert({
              text: "Could not deactivate your invitation. Please try again.",
              type: AlertTypes.Error,
            })
          );
          reject(error);
        }
      );
  });
};

export const getOrganizationInvitationKey = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        getBackendUrlForPath(`${API_ROUTE.GET_ORGANIZATION_INVITATION_KEY}`),
        apiDefaultOptions
      )
      .then(
        (response: AxiosResponse) => {
          resolve(response);
        },
        (error: AxiosError) => {
          reject(error);
        }
      );
  });
};

export const createNewAccount = async (body: NewSignUpFormData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(API_ROUTE.CREATE_ACCOUNT),
        body,
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

//todo: why are we renaming keys in consumeInvitation?
export const consumeInvitation = (key: string, values: SignUpFormData) => {
  const body: ConsumeInvitationBody = {
    key: key,
    firstName: values.firstName,
    lastName: values.lastName,
    password: values.setPass,
    password2: values.confirmPass,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath(API_ROUTE.CONSUME_INVITATION),
        body,
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const editUserInDatabase = (userId: number, data: UserFormValues) => {
  const body = {
    userId: userId,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    role: data.role,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(getBackendUrlForPath(API_ROUTE.EDIT_USER), body, apiDefaultOptions)
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const disableUserInDatabase = (userId: number) => {
  const body = { userId: userId };

  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath("/organization/disableUser"),
        body,
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const enableUserInDatabase = (userId: number) => {
  const body = { userId: userId };

  return new Promise((resolve, reject) => {
    axios
      .post(
        getBackendUrlForPath("/organization/enableUser"),
        body,
        apiDefaultOptions
      )
      .then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const getOrganization = (id: number): Promise<NewOrganization> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${getBackendUrlForPath(API_ROUTE.GET_ORGANIZATION)}/${id}`,
        apiDefaultOptions
      )
      .then(
        (response: AxiosResponse) => {
          resolve(response.data);
        },
        (error: AxiosError) => {
          reject(error);
        }
      );
  });
};

export const getAllNewOrganizations = (
  stateName: string = 'all'
): Promise<{[key: string]: NewOrganization[]}> => {
  const params = {
    stateName,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`${getBackendUrlForPath(API_ROUTE.GET_NEW_ORGANIZATIONS)}/`, {
        params,
        ...apiDefaultOptions,
      })
      .then(
        (response: AxiosResponse) => {
          resolve(response.data);
        },
        (error: AxiosError) => {
          reject(error);
        }
      );
  });
};
