import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompleteSurvey, selectIsAdmin, selectLoggedInStatus, selectIsValidated } from "../../store/auth/authSlice";

interface PrivateRouteProps extends RouteProps {
  children: (props: RouteComponentProps) => React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  path,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectLoggedInStatus)
  const isAdmin = useSelector(selectIsAdmin)
  const hasCompletedSurvey = useSelector(selectCompleteSurvey)
  const hasVerifiedEmail = useSelector(selectIsValidated)

  const handleRedirect = () => {
    if (!isLoggedIn) {
      window.location.href = "https://www.advancedpension.com/login";
      return;
    } else if (isAdmin) {
      return "/superadmin"
    } else if (!hasVerifiedEmail) {
      return "/verify"
    } else if (!hasCompletedSurvey) {
      return "/survey"
    }
    window.location.href = "https://www.advancedpension.com/home";
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn || isAdmin) {
          return <Redirect to={{ pathname: handleRedirect() }} />;
        }

        if (path === "/verify") {
          return !hasVerifiedEmail ? children(props) : <Redirect to={{ pathname: handleRedirect() }} />;
        }

        return hasVerifiedEmail ? children(props) : <Redirect to={{ pathname: handleRedirect() }} />;
      }}
    />
  );
}

export default PrivateRoute;
