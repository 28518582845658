import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import { ProfileData } from '../../../../interfaces'
import './OverviewTaxDeferred.css';

interface GraphDataValue {
    "Investment $": number,
    "Impact $": number
}

type Props = {
    graphId?: string
    profileData: ProfileData
}

export const OverviewTaxDeferred = ({ profileData, graphId = "" }: Props) => {
    const [graphData, setGraphData] = useState<GraphDataValue[]>([])

    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(`taxdeferreddiv${graphId}`, am4charts.XYChart);

    useEffect(() => {
        if (!profileData) { return }
        setGraphData(profileData.tax_impact_on_pay.map((x, index) => {
            return {
                "Investment $": profileData.deferred_investment_deduction + 200 * index,
                "Impact $": x
            }
        }))
    }, [profileData])

    useEffect(() => {
        // Create chart instance
        // Create axes
        chart.xAxes.push(new am4charts.ValueAxis());

        // Create value axis
        chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = "Impact $";
        lineSeries.dataFields.valueX = "Investment $";
        lineSeries.name = "Tax Deferred Investment Contribution";
        lineSeries.strokeWidth = 3;
        lineSeries.stroke = am4core.color("#5579e4");
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color("#5579e4");

        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;

        // eslint-disable-next-line
        bullet.tooltipText = "If you stop making your ${valueX} investment, \nyou would receive ${valueY} a paycheck."

        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        chart.data = graphData
    }, [graphData, chart])

    return (
        <div className="widget-container">
            <div className="widget-header-container">
                <div className="widget-title">Tax Deferred Investment Contribution</div>
                <div className="widget-configuration-button-container">
                    <Tooltip
                        placement="left"
                        title="This chart shows the difference in your tax deferred investment contribution and your actual reduction in pay."
                    >
                        <button 
                            className="widget-configuration-button widget-configuration-button-info" 
                        >
                            <InfoOutlinedIcon />
                        </button>
                    </Tooltip>
                </div>
            </div>
            {
                graphData.length > 0 &&
                <div className="tax-deferred-content">
                    <h1 className="tax-deferred-sub-title">If you stopped your tax deferred investment of <strong>${graphData[0]["Investment $"].toFixed(0)}</strong>, we estimate your take home pay to increase by <strong>${graphData[0]["Impact $"]}</strong>.</h1>
                    <div className="widget-light-text">Investment vs impact</div>
                    <div className="tax-deferred-number-container">
                        <div className="dashboard-number-and-text">
                            <span>${ graphData[0]["Investment $"]?.toLocaleString(undefined, {maximumFractionDigits: 0}) }</span>
                            Current Investment (Deducted from Gross)
                        </div>
                        <div className="dashboard-number-and-text">
                            <span>${graphData[0]["Impact $"]?.toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
                            Impact to Net Earnings (Take Home Pay)
                        </div>
                    </div>
                </div>
            }
            <div id={ `taxdeferreddiv${graphId}` } style={{ width: "100%", height: "400px", marginLeft: "-20px", marginTop: "30px" }}></div>
        </div>
    )
}