import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import {getStore} from '../../../store'
import { updateResult } from '../../../store/survey/surveySlice'
import { selectCurrentQuestion, selectCurrentQuestionResult, selectGrossPay } from '../../../store/survey/surveySlice'
import { getNumberValue } from '../number/Number'
import SubmitButton from '../submit/SubmitButton'
import './AllowsMultiple.css'
import { MultipleField } from './MultipleField'

export const AllowsMultiple = () => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const [fields, setFields] = useState([])
    const [maxValue, setMaxValue] = useState(0)
    const dispatch = useDispatch()

    const defaultArray = [
        ["0", "Overtime"],
        ["0", "Leave Cash Out"],
        ["0", "Standby Pay"],
    ]

    const handleRemove = () => {
        const temp = [...result.answer]
        if (temp.length === 1) { return }
        temp.pop()
        updateAnswer(temp)
    }

    const handleAdd = () => {
        const temp = [...result.answer]
        if (temp.length === 5) { return }
        const defaultArray = question.answerType[0].map(x => "")
        temp.push(defaultArray)
        updateAnswer(temp)
    }

    const handleFieldInputChange = (val: (string | number)[], index: number) => {
        const temp = [...result.answer]
        temp[index] = val
        updateAnswer(temp)
    }

    function getFieldWithData(index: number, userInput: (string | number)[]) {
        let showMinus = question.allowMultipleAnswers && (result ? index !== 0 && index === result.answer.length - 1 : false)
        let showPlus = question.allowMultipleAnswers && (result ? result.answer.length === index + 1 && result.answer.length < 5 : true)
        return <MultipleField 
            index={index} 
            key={index.toString()} 
            userInput={userInput} 
            handleAdd={handleAdd} 
            handleRemove={handleRemove} 
            showMinus={ showMinus } 
            showPlus={ showPlus }
            handleInputChange={handleFieldInputChange}
        />  
    }

    useEffect(() => {
        const inputElements = document.getElementsByTagName("input")
        const inputElement = inputElements[inputElements.length - question.answerType[0].length]

        if (!question.locked && inputElements.length > 0) {
            inputElement.focus()
        }

        if (question.text.includes("NON PENSIONABLE")) { 
            let grossPay = selectGrossPay(getStore().getState())
            setMaxValue(grossPay)
        }
    }, [question, fields.length])

    useEffect(() => {
        // specific for other non-pensionable pay question
        if (!result && question.text.includes("NON PENSIONABLE")) { 
            updateAnswer(defaultArray)
            return
        }

        // default value if no result yet
        if (!result) { 
            const defaultArray = question.answerType[0].map(x => "")
            updateAnswer([defaultArray])
            return
        }

        setFields(result.answer.map((item: any[], index: number) => {
            return getFieldWithData(index, item)
        }))
        // eslint-disable-next-line
    }, [result])

    const isValidResponse = (arr: any) => {
        var isValid = true
        arr.forEach((e: (number | string)[]) => {
            if (isValid) {
                isValid = (!e.includes("") && !isNaN(parseFloat(e[0].toString())))
            }
        })

        // they can enter nothing for this question as it is just other pay
        if (arr.length === 1 && checkForOne(arr[0], "")) {
            isValid = true
        }

        // if at this point it is not valid then don't check for sum
        if (!isValid) { return isValid }

        // validate it is a reasonable number
        let sum = 0
        arr.forEach((e: (number | string)[]) => {
            sum += getNumberValue(e[0] as number)
        })
        isValid = maxValue === 0 || sum < maxValue

        return isValid
    }

    const updateAnswer = (arr: any) => {
        dispatch(updateResult({
            answer: arr,
            isValid: isValidResponse(arr)
        }))
    }

    const isEmptyValid = () => {
        return result && result.isValid && result.answer.length === 1 && checkForOne(result.answer[0], "")
    }

    const checkForOne = (arr: any[], value: any) => {
        return new Set(arr).size === 1 && arr.includes(value)
    }

    const showMaxWarning = () => {
        if (maxValue === null || !result || maxValue === 0) { return false }

        let sum = 0
        result.answer.forEach((e: (number | string)[]) => {
            sum += getNumberValue(e[0] as number)
        })
        return sum > maxValue
    }

    return (
        <div className="allow-multiple-container">
            { fields }
            {
                showMaxWarning() &&
                <p><em>You must enter a number lower than {maxValue}</em></p>
            }
            { (result && result.isValid && !isEmptyValid()) && <SubmitButton /> }
            { (result && !result.isValid && !isEmptyValid()) && <p className="submit-button-text"><i>Please fill out all fields before continuing.</i></p> }
            { (isEmptyValid()) && 
                <SubmitButton buttonText="Nothing To Report" /> 
            }
        </div>
    )
}
