import { AuthErrors } from '../../../../interfaces'
import './AuthTextfield.css'

export enum AuthTextfieldTypes {
    FirstName = "firstName",
    LastName = "lastName",
    Email = "email",
    SetPassword = "setPass",
    ConfirmPassword = "confirmPass",
    Password = "password"
}

type Props = {
    type: AuthTextfieldTypes,
    value: string,
    onChange: (type: AuthTextfieldTypes, val: string) => void,
    formErrors?: AuthErrors
    disabled?: boolean
}

export const AuthTextfield = ({ type, value, onChange, formErrors, disabled = false }: Props) => {

   const handleInputChange = (val: string) => {
      onChange(type, val)
   }

   const getClassName = () => {
        var className = "create-account-textfield"
        if (!formErrors) { return className }
        var errClass = "create-account-textfield create-account-textfield-error"

        // SIGN UP ERRORS
        if (type === AuthTextfieldTypes.FirstName && formErrors.includes(AuthErrors.InvalidFirstName)) {
            return errClass
        }
        if (type === AuthTextfieldTypes.LastName && formErrors.includes(AuthErrors.InvalidLastName)) {
            return errClass
        }
        if (type === AuthTextfieldTypes.SetPassword && (formErrors.includes(AuthErrors.InvalidPassword) || formErrors.includes(AuthErrors.PasswordMatching))) {
            return errClass
        }
        if (type === AuthTextfieldTypes.ConfirmPassword && (formErrors.includes(AuthErrors.InvalidConfirmPassword) || formErrors.includes(AuthErrors.PasswordMatching))) {
            return errClass
        }

        // LOGIN ERRORS
        if (type === AuthTextfieldTypes.Email && formErrors.includes(AuthErrors.InvalidEmail)) {
            return errClass
        }
        if (type === AuthTextfieldTypes.Password && formErrors.includes(AuthErrors.InvalidPassword)) {
            return errClass
        }

        return className
   }

    const getPlaceholder = () => {
        switch (type) {
            case AuthTextfieldTypes.FirstName:
                return "First Name"
            case AuthTextfieldTypes.LastName:
                return "Last Name"
            case AuthTextfieldTypes.Email:
                return "Email"
            case AuthTextfieldTypes.SetPassword:
                return "Set Password"
            case AuthTextfieldTypes.ConfirmPassword:
                return "Confirm Password"
            case AuthTextfieldTypes.Password:
                return "Password"
            default:
                return ""
        }
    }

    const getAuthType = () => {
        switch (type) {
            case AuthTextfieldTypes.SetPassword:
                return "password"
            case AuthTextfieldTypes.ConfirmPassword:
                return "password"
            case AuthTextfieldTypes.Password:
                return "password"
            default:
                return ""
        }
    }

    return <input 
        value={value} 
        className={ getClassName() } 
        placeholder={ getPlaceholder() } 
        onChange={ e => handleInputChange(e.target.value) }
        type={ getAuthType() }
        disabled={disabled}
    />
}