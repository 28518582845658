import { Box, Typography } from "@mui/material";

function GlobalFooter() {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ my: 10, mx: 5, flex: "2 1" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Have any questions? Contact Us
            <p></p>
            contact@advancedpension.com
          </Typography>
          <Typography>
            Copyright © 2021 Advanced Pension. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default GlobalFooter;
