import { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";

import { resendValidation } from "../../../api/AuthAPI";

const PleaseValidate = () => {
  const [confirmResend, setConfirmResend] = useState(false);
  const resend = async () => {
    await resendValidation();
    setConfirmResend(true);
  };

  const handleClose = () => setConfirmResend(false);

  return (
    <Box
      sx={{
        marginBottom: "0.5rem",
      }}
    >
      <Dialog open={confirmResend} onClose={handleClose}>
        <DialogContent
          sx={{
            fontSize: "1.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            Validation email has been resent!
            <CloseIcon
              onClick={handleClose}
              aria-label="close validation"
              sx={{
                fontSize: "1.25rem",
                marginLeft: "1rem",
                cursor: "pointer",
              }}
            />
          </Box>
          <p></p>
          Please check your email.
        </DialogContent>
      </Dialog>
      <Alert
        severity="warning"
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          '& .MuiAlert-message': {
            width: "100%",
          },
        }}
      >
        <Box>
          Verify your email using the link we sent, then refresh this page.
          <p></p>Haven't received it? Check your spam or junk folder, or request
          a new link.{" "}
          <Link
            onClick={resend}
            aria-label="resend validation"
            sx={{
              cursor: "pointer",
            }}
          >
            Resend validation
          </Link>
        </Box>
      </Alert>
    </Box>
  );
};

export default PleaseValidate;
