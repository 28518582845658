import { Field } from "react-final-form";
import { Select, MenuItem } from "@mui/material";
import { required } from "../../../helpers/ValidationHelpers";
import { US_STATES } from "../../../data/tax_data";

const FormTextField = ({
  inputName,
  currentValue,
}: {
  inputName: string;
  currentValue: string;
}) => (
  <Field
    validate={required}
    name={inputName}
    render={({ input, meta }) => (
      <>
        <Select
          size="small"
          variant="outlined"
          color="secondary"
          defaultValue={currentValue}
          value={input.value}
          sx={{ padding: "0.25rem", verticalAlign: "middle" }}
          onChange={input.onChange}
        >
          {US_STATES.map((state, index) => <MenuItem value={state} key={index}>{state}</MenuItem>)}
        </Select>
        {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
      </>
    )}
  />
);

export default FormTextField;
