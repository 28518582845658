import axios, { AxiosResponse } from "axios"
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"
import { EditReciprocityData } from "../interfaces"

type AdditionalReciprocity = {
  id: number | string,
  salary: number,
};

type ReciprocityDto = 
  | { id: number, additionalReciprocity: AdditionalReciprocity; reciprocity?: boolean }
  | { id: number, additionalReciprocity?: AdditionalReciprocity; reciprocity: boolean }
  | { id: number, additionalReciprocity: AdditionalReciprocity; reciprocity: boolean };

export class PensionApi {
  static async editReciprocity (values: ReciprocityDto[]) {
    try {
      const response: AxiosResponse<EditReciprocityData> = await axios.post<EditReciprocityData>(
        getBackendUrlForPath(API_ROUTE.PENSION_SET_RECIPROCITY),
        values,
        apiDefaultOptions
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}