import { useSelector } from 'react-redux'
import { ClipLoader} from 'react-spinners'
import { selectShowLoadingForSurvey } from '../../../store/survey/surveySlice'
import './SurveyLoader.css'

export const SurveyLoader = () => {
    const showLoading = useSelector(selectShowLoadingForSurvey)

    return (
        <div className="survey-loader-container">
            { showLoading && <ClipLoader size={"25px"} color="#ffffff" loading={true} /> }
        </div> 
    )
}