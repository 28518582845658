import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { authLogout } from '../../../api/AuthAPI';
import './SuperAdminHeader.css';

type Props = {
    withBackButton?: boolean
}

export const SuperAdminHeader = ({ withBackButton = true }: Props) => {
    const handleLogout = () => {
        authLogout().then(() => {
            window.location.reload()
        })
    }

    return (
        <div className="flex-space-between">
            <div>
                <h1 className="org-page-title">Advanced Pension Tracker</h1>
                <h3 className="org-page-subtitle">Super Administrator</h3>
            </div>
            {
                withBackButton &&
                <Button href="/superadmin">
                    <ArrowBackIcon />
                    Back to Admin
                </Button>
            }
            {
                !withBackButton &&
                <>
                    <Button href="/superadmin/newUser" variant="contained">
                        <AddIcon />
                        New User
                    </Button>
                    <Button href="/superadmin/onboard" variant="contained">
                        <AddIcon />
                        Onboard New Organization
                    </Button>
                    <Button onClick={handleLogout} variant="outlined">
                        Logout
                    </Button>
                </>
            }
        </div>
    )
}