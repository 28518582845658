import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  formatDuration,
  intervalToDuration,
  differenceInDays,
  addYears,
  addDays,
} from "date-fns";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { selectRetirementDate } from "../../../store/profile/profileSlice";

export const TimerBar = () => {
  const [timer, setTimer] = useState(null);
  const retirementDate = useSelector(selectRetirementDate);

  useEffect(() => {
    const intervalId = setInterval(handleTimer, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleTimer = () => {
    const difference = intervalToDuration({
      start: new Date(),
      end: retirementDate,
    });
    let yearText = "";
    let dayText = "";
    if (difference.years > 1) yearText = `${difference.years} years, `;
    if (difference.years === 1) yearText = `${difference.years} year, `;
    const dateWithYears = addYears(new Date(), difference.years);

    const dateDifference = differenceInDays(retirementDate, dateWithYears);
    if (dateDifference > 1) dayText = `${dateDifference} days, `;
    if (dateDifference === 1) dayText = `${dateDifference} day, `;

    const dateWithFullDays = addDays(dateWithYears, dateDifference);
    const hourMinDifference = intervalToDuration({
      start: dateWithFullDays,
      end: retirementDate,
    });
    const formattedDiff = formatDuration(hourMinDifference, {
      delimiter: ", ",
    });

    setTimer(`${yearText}${dayText}${formattedDiff}`);
  };

  return (
    <div className="widget-light-text" style={{ fontWeight: "bold" }}>
      <TimerOutlinedIcon />
      <span style={{ marginLeft: "0.5rem" }}>{timer}</span>
    </div>
  );
};
