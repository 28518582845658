import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";

import {
  getStripeCheckoutSession,
} from "../../../api/SubscriptionAPI";
import { useWindowSize } from "../../../hooks";
import {
  selectSubscriptionInfo,
  selectSubscriptionStatus,
  selectLoggedInStatus,
  selectWhitelistStatus,
  selectHasTrialed,
} from "../../../store/auth/authSlice";

import { OverviewHeader } from "./header/OverviewHeader";
import { UserPageRoute } from "../UserPage";
import { ResponsiveAppBar } from "../../home/homeLandingPage";
import ConfettiComponent from "./Confetti";
import SubscriptionPricing from "./SubscriptionPricing";
import Whitelist from "./Whitelist";
import SubscribedUser from "./SubscribedUser";
import SubscribeSuccessModal from "./SubscribeSuccessModal";
import { NewsletterPopup } from "./NewsletterPopup";
import SubscriptionCtaButton from "./SubscriptionCtaButton";
import { SuccessMessages } from "./SubscriptionCtaButton";

export interface SubType {
  PREMIUM_MONTHLY: boolean;
  PREMIUM_ANNUAL: boolean;
}

export interface SuccessMessage {
  title: string,
  successMessage: string,
  body: string,
}

const subscriberFeatures = [
  { text: "Access to all pension numbers" },
  { text: "Monitor current and estimated future pensions" },
  { text: "Adjust for anticipated raises" },
  { text: "Simulate sick leave, holiday, vacation cashouts" },
  { text: "Assess your ability to retire now or in the future" },
  { text: "Explore financial impacts of life changes" },
];

export const Subscription = () => {
  const [subType, setSubType] = useState<SubType>({
    PREMIUM_MONTHLY: false,
    PREMIUM_ANNUAL: true,
  });
  const [showConfetti, setShowConfetti] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const isLoggedIn = useSelector(selectLoggedInStatus);
  const isSubscribed = useSelector(selectSubscriptionStatus);
  const mySub = useSelector(selectSubscriptionInfo);
  const endingSub = mySub && mySub.isSubscribed && mySub.cancelAtPeriodEnd;
  const { width } = useWindowSize();
  const isMobile = width < 900;
  const location = useLocation();
  const isSuccessPage = location.pathname.includes("success");
  const sessionParams = new URLSearchParams(location.search).get("session_id");
  const whitelist = useSelector(selectWhitelistStatus);
  const hasTrialed = useSelector(selectHasTrialed);

  const retrieveSession = async () => {
    const session = await getStripeCheckoutSession(sessionParams);
    if (session?.status === "complete") {
      setShowConfetti(true);
      setSuccessOpen(true);
      setMessageType(SuccessMessages.SUCCESS)
    }
  };

  useEffect(() => {
    if (!isSuccessPage || !sessionParams) return;
    if (isLoggedIn) {
      setShowConfetti(true);
    } else {
      retrieveSession();
    }
  }, []);

  const toggleSub = () =>
    setSubType({
      PREMIUM_MONTHLY: !subType.PREMIUM_MONTHLY,
      // ANNUAL will always be opposite of MONTHLY
      PREMIUM_ANNUAL: subType.PREMIUM_MONTHLY,
    });

  const setMessages = (showMessage: boolean, message: SuccessMessage) => {
    setSuccessOpen(showMessage);
    setMessageType(message)
  }

  return (
    <>
      {isLoggedIn && isMobile && (
        <OverviewHeader activePage={UserPageRoute.Subscribe} />
      )}
      {!isLoggedIn && <ResponsiveAppBar />}
      {!isLoggedIn && !isSuccessPage && <NewsletterPopup />}
      <ConfettiComponent showConfetti={showConfetti} />
      {messageType && (
        <SubscribeSuccessModal
          open={successOpen}
          onClose={() => {
            setSuccessOpen(false);
            setMessageType(null);
          }}
          message={messageType}
        />
      )}
      <Box
        sx={{
          backgroundColor: "var(--dashboard-light-purple)",
          padding: "var(--dashboard-widget-padding)",
          borderRadius: "var(--dashboard-border-radius)",
          textAlign: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Elevate your Retirement
          </Box>
          <Box
            sx={{
              fontWeight: "light",
            }}
          >
            Get full access to projected pension numbers, retirement planning
            and calculators
          </Box>
        </Box>
        <Card
          variant="outlined"
          sx={{
            width: {
              xs: "100%",
              sm: "60%",
              md: "50%",
            },
            alignSelf: "center",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {isLoggedIn && mySub && mySub?.isSubscribed && (
              <SubscribedUser
                ongoingSub={!endingSub}
                expiresAt={mySub.expiresAt}
                isTrialing={mySub.isTrialing}
              />
            )}
            {isLoggedIn && whitelist?.isWhitelisted && (
              <Whitelist isOrg={whitelist.isOrg} />
            )}
            {(!isSubscribed || endingSub) && (
              <SubscriptionPricing
                annual={subType.PREMIUM_ANNUAL}
                toggleSub={toggleSub}
              />
            )}
          </Box>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {subscriberFeatures.map((f, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={f.text} />
              </ListItem>
            ))}
          </List>
          <SubscriptionCtaButton
            isSubscribed={isSubscribed}
            isWhitelisted={whitelist?.isWhitelisted || false}
            endingSub={endingSub}
            isLoggedIn={isLoggedIn}
            hasTrialed={hasTrialed}
            onClick={setMessages}
            subType={subType}
          />
        </Card>
      </Box>
    </>
  );
};
