import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

type Props = {
    open: boolean,
    handleClose: () => void,
    onAction: () => void,
    actionTitle: string,
    dialogTitle: string,
    dialogContent: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmAlert = ({ open, handleClose, onAction, actionTitle, dialogTitle, dialogContent }: Props) => {
    return (
        <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">{ dialogTitle }</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    { dialogContent }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onAction} color="secondary">
                    { actionTitle }
                </Button>
            </DialogActions>
        </Dialog>
    )
}