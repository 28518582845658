import { Autocomplete, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SurveyResult, SurveyAnswerOption, SurveyQuestion } from "../../../interfaces";
import { updateResult } from "../../../store/survey/surveySlice";
import { selectCurrentQuestion, selectCurrentQuestionResult } from "../../../store/survey/surveySlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const OTHER = {
    text: '__OTHER__'
}

export const AutoComplete = ({includeOther = false}) => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult
    const [optionValue, setOptionValue] = useState(null)
    const [textValue, setTextValue] = useState("")
    const [enteringOther, setEnteringOther] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!result || !result.answer) { return }
        let ao = question.answerOptions.find((a) => a.text === result.answer)

        if (ao !== undefined) {
            setOptionValue(ao)
        } else if (question.allowOther) {
            setEnteringOther(true)
            setTextValue(result.answer)
        }
    }, [result, question])

    // Create a wrapper around the autocomplete filter to always show the 'other' option
    const filterOptionsDefault = createFilterOptions();
    const filterOptions = (options: unknown[], { inputValue } : any) => {
        const results = filterOptionsDefault(options, inputValue);

        if (!results.includes(OTHER) && includeOther) {
            results.push(OTHER);
        }

        return results;
    };

    const handleInputChange = (option: SurveyAnswerOption | typeof OTHER) => {
        if (!option) {
            dispatch(updateResult({
                answer: "",
                isValid: false
            }))
            return
        }

        if (option.text === "__OTHER__") {
            dispatch(updateResult({
                answer: "",
                isValid: false
            }))
            setOptionValue(null)
            setTextValue("")
            setEnteringOther(true)
            return
        }

        dispatch(updateResult({
            answer: option.text,
            isValid: option && option.text !== ""
        }))
        setOptionValue(option)
    }

    const handleOtherInputChange = (text: string) => {
        dispatch(updateResult({
            answer: text,
            isValid: text && text !== ""
        }))
        setTextValue(result.answer)
    }

    return (
        <div>
            {
                enteringOther ?
                <TextField
                    autoFocus
                    placeholder="Enter your classification..."
                    variant="outlined"
                    value={textValue}
                    onChange={ e => handleOtherInputChange(e.target.value) }
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { setTextValue(""); setEnteringOther(false); }}
                                edge="start"
                                >
                                <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                :
                <Autocomplete
                    placeholder="Select a classification..."
                    id="combo-box-demo"
                    options={ question.allowOther ? [...question.answerOptions, OTHER] : question.answerOptions}
                    filterOptions={filterOptions}
                    value={optionValue}
                    onChange={ (e, newValue) => handleInputChange(newValue) }
                    getOptionLabel={(option) => option.text}
                    renderOption={(option: {[key: string]: any}) =>
                        option.text === '__OTHER__' ?
                        <Typography color="textSecondary"><i>Other (enter below...)</i></Typography> :
                        option.text
                    }
                    renderInput={(params) => <TextField
                        {...params}
                        label={question.placeholderText}
                        variant="outlined"
                        disabled={ question.locked }
                    />}
                />
            }
        </div>
    )
}