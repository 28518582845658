import axios, { AxiosError }  from "axios"
import { Alert } from "../components/user/overview/Alert";
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"


export const signupNewsletter = async (email: string) => {
  const NEWSLETTER_URL = getBackendUrlForPath(API_ROUTE.EMAIL_SIGNUP)
  try {
    const response = await axios.post(
      NEWSLETTER_URL,
      { email },
      apiDefaultOptions
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return { success: false, error: "Request failed, please try again later." };
      }
    } else {
      return { success: false, error: (error as Error).message };
    }
  }
}