import * as Sentry from "@sentry/react"
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { postCompletedDashboardIntro } from '../../../api/UserAPI'
import { AlertTypes, ProfileData, ServiceStatusValues, UserStatus } from '../../../interfaces'
import { selectCurrentUser, selectCompletedDashboardIntro, selectSubscriptionStatus, selectIsValidated } from '../../../store/auth/authSlice';
import { selectForecastProfile } from '../../../store/forecast/forecastSlice'
import { selectProfileData, selectUserStatus, setWorkingDate } from '../../../store/profile/profileSlice'
import { ProgressBar } from './ProgressBar'
import { OverviewTaxDeferred } from './deferred/OverviewTaxDeferred'
import { OverviewGraph } from './graph/OverviewGraph'
import { OverviewHeader } from './header/OverviewHeader'
import { OverviewAnalysis } from './OverviewAnalysis'
import Loader, { LoaderType } from './loader/Loader'
import { SubscriptionNotification } from './SubscriptionNotification'
import './Overview.css'
import { OverviewRaiseWidget } from './raises/OverviewRaiseWidget'
import { OverviewStatus } from './status/OverviewStatus'
import { OverviewBreakdown } from './OverviewBreakdown'
import { OverviewTaxWidget } from './tax/OverviewTaxWidget'
import { OverviewDaily } from './daily/OverviewDaily'
import { UserPageRoute } from '../UserPage'
import { OverviewCalculator } from './OverviewCalculator'
import { OverviewReciprocity } from "./OverviewReciprocity"
import { Tutorial } from '../tutorial/Tutorial'
import { useAppDispatch } from "../../../store/hooks"
import SubscriptionAlert from "./SubscriptionAlert"
import PleaseValidate from "./PleaseValidate"


const OverviewTabs = {
    SUMMARY: 'Summary',
    TAXES: 'Taxes',
    GROWTH: 'Growth',
    BREAKDOWN: 'Breakdown',
    CALCULATOR: 'Calculator',
    RECIPROCITY: 'Reciprocity',
    NUMBERS: 'In-Depth Analysis',
}

type TabConfig = {
  tabKey: keyof typeof OverviewTabs;
  label: string;
  condition: boolean;
};

const MOBILE_WIDTH = 900;

function Overview() {
    const profileData = useSelector(selectProfileData) as ProfileData
    const forecastData = useSelector(selectForecastProfile) as ProfileData
    const userServiceStatus = useSelector(selectUserStatus) as ServiceStatusValues
    const userData = useSelector(selectCurrentUser);
    const isValidated = useSelector(selectIsValidated);
    const [currentTab, setCurrentTab] = useState(OverviewTabs.SUMMARY);
    const [progress, setProgress] = useState(0);
    const [showIntro, setShowIntro] = useState(false);
    const hasMonthlyData = profileData?.monthly_data;
    const hasTaxImpact = profileData?.tax_impact_on_pay?.length > 0 || false;
    const hasRaisePercentage = !!profileData?.annual_raise_percentage;
    const showTaxDeferred = hasTaxImpact && !isNaN(profileData?.pension_contribution_percentage) &&
        profileData?.pension_contribution_percentage > 0;
    const isMobile = window && window.innerWidth < MOBILE_WIDTH;
    const isSubscribed = useSelector(selectSubscriptionStatus);
    const isSamplePlan = userData.plan_benefit?.retirementBenefitId === 134;
    const hasPreviousPension = !!profileData?.additional_configurations.length;
    const hasCompletedIntro = useSelector(selectCompletedDashboardIntro)
    const dispatch = useAppDispatch();

    const tabConfigs: TabConfig[] = [
      { tabKey: "SUMMARY", label: OverviewTabs.SUMMARY, condition: true },
      { tabKey: "TAXES", label: OverviewTabs.TAXES, condition: true },
      { tabKey: "GROWTH", label: OverviewTabs.GROWTH, condition: true },
      { tabKey: "BREAKDOWN", label: OverviewTabs.BREAKDOWN, condition: true },
      { tabKey: "CALCULATOR", label: OverviewTabs.CALCULATOR, condition: true },
      { tabKey: "RECIPROCITY", label: OverviewTabs.RECIPROCITY, condition: hasPreviousPension },
      { tabKey: "NUMBERS", label: OverviewTabs.NUMBERS, condition: false }
    ];

    useEffect(() => {
        if (!profileData) { return }

        const { userProgress, totalProgress } = profileData.metadata;
        setProgress((userProgress / totalProgress) * 100);
    }, [profileData])

    const handleDateChange = (newIndex: number) => {
        dispatch(setWorkingDate(profileData.monthly_data_keys[newIndex]))
    }

    const callShowIntro = () => {
      setTimeout(() => setShowIntro(!hasCompletedIntro), 1500)
    }

    const handleIntroducingDashboard = async () => {
        setShowIntro(false)

        await postCompletedDashboardIntro()
    }

    useEffect(() => {
      if (!hasCompletedIntro && userServiceStatus === ServiceStatusValues.Loaded) {
          callShowIntro()
        }
    }, [userServiceStatus])

    
    const getData = () => {
      return forecastData !== null ? forecastData : profileData
    }

    if (userServiceStatus !== ServiceStatusValues.Loaded) {
        return (
            <Loader type={LoaderType.Scale} size={50} text="Loading your data..." />
        )
    }

    return (
        <div>
            <OverviewHeader activePage={UserPageRoute.Overview} />
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                <Tutorial open={showIntro} onClose={() => handleIntroducingDashboard()} />
                {isSamplePlan &&
                    <div>
                        <Alert severity="warning" variant="outlined">
                            The below are sample numbers.
                            {' '}
                            This alert will be removed once your retirement system is onboarded.
                        </Alert>
                    </div>
                }
                {!isValidated && <PleaseValidate />}
                {profileData.metadata?.needsMoreInfo.length > 0 &&
                    <Box
                      sx={{
                        "marginBottom": "0.5rem"
                      }}
                    >
                        <Alert severity="warning" variant="outlined">
                            The below are sample numbers.
                            {' '}
                            This alert will be removed once you have input values for:
                          <ul>
                            {profileData.metadata.needsMoreInfo.map((v: string) =>
                            <li>
                              {v.replaceAll("_", " ")}
                            </li>
                            )}
                          </ul>
                        </Alert>
                    </Box>
                }
                {!isSubscribed && <SubscriptionAlert />}
                <div>
                    {progress <= 80 && <ProgressBar progressValue={progress} isMobile={isMobile} />}
                </div>
                <div style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
                {<Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="Tabs navigation"
                        value={currentTab}
                        onChange={(e, newValue) => setCurrentTab(newValue)}
                    >
                        {tabConfigs.map(({ tabKey, label, condition }) =>
                          condition
                            ? <Tab id={tabKey} key={tabKey} label={label} value={label} />
                            : null)}
                    </Tabs>
                }
                </div>
                <div
                    id={`${ forecastData !== null ? "overview-widgets-container-forecast" : ""}`}>
                    <div className={`overview-widgets-container`} style={{ display: 'block'}}>
                        <div className={currentTab === OverviewTabs.SUMMARY ? "grid-column-2" : "hide-widget"}>
                            { hasMonthlyData &&
                            <OverviewStatus
                                isMobile={isMobile}
                                profileData={getData()}
                                onChange={handleDateChange}
                            /> }
                        </div>
                        <div className={currentTab === OverviewTabs.TAXES ? "tax-container" : "hide-widget"}>
                            <OverviewTaxWidget
                                profileData={getData()}
                                hasTaxImpact={hasTaxImpact}
                                isMobile={isMobile}
                            />
                            { showTaxDeferred && <OverviewTaxDeferred profileData={getData()} />}
                        </div>
                        <div className={currentTab === OverviewTabs.GROWTH ? "grid-column-2" : "hide-widget"}>
                            { !isSubscribed && <SubscriptionNotification /> }
                            { isSubscribed && hasMonthlyData && <OverviewDaily profileData={getData()} onChange={handleDateChange} /> }
                            { isSubscribed && hasMonthlyData && <OverviewGraph /> }
                            { isSubscribed && hasRaisePercentage && <OverviewRaiseWidget profileData={getData()} /> }
                        </div>
                        { hasMonthlyData && <div className={currentTab === OverviewTabs.BREAKDOWN ? "grid-column-2" : "hide-widget"}>
                            <OverviewBreakdown
                                isMobile={isMobile}
                            />
                        </div>}
                        { hasMonthlyData && <div className={currentTab === OverviewTabs.CALCULATOR ? "grid-column-2" : "hide-widget"}>
                            <OverviewCalculator
                                isMobile={isMobile}
                                profileData={getData()}
                            />
                        </div>}
                        { hasPreviousPension && <div className={currentTab === OverviewTabs.RECIPROCITY ? "grid-column-2" : "hide-widget"}>
                            <OverviewReciprocity
                            />
                        </div>}
                        { hasMonthlyData && <div className={currentTab === OverviewTabs.NUMBERS ? "grid-column-2" : "hide-widget"}>
                            <OverviewAnalysis
                                isMobile={isMobile}
                            />
                        </div>}
                    </div>
                </div>
            </Sentry.ErrorBoundary>
        </div>
    )
}

export default Overview
