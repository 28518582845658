import FormField from "./FormField";

const AddInvestments = () => {
  return (
    <div
      className="widget-container summary-widget-container"
      style={{ textAlign: "left" }}
    >
      I've also saved
      $<FormField inputName="otherRetirementAccounts" varType="number" />
      for retirement and will save an additional
      $<FormField inputName="otherRetirementInvestments" varType="number" />
      per year. I expect to make
      <FormField inputName="otherRetirementGrowthRate" varType="number" />
      % from my investment savings.
    </div>
  );
};

export default AddInvestments;
