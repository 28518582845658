import Grid2 from '@mui/material/Unstable_Grid2';
import { Item } from '../../super-admin/shared/Item';

export enum DataTableHeaderLabels {
    Interval = "Interval",
    Users = "Users",
    Cumulative = "Cumulative Users"
}

export const DataTableHeader = () => {
  return (
    <Grid2 container className="admin-table-header-container">
      {Object.values(DataTableHeaderLabels).map((label, ind) =>
        <Grid2 key={ind} xs={4}>
          <Item>
            { label }
          </Item>
        </Grid2>
      )}
    </Grid2>
  )
}