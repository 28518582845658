import { AppBar, Box, CircularProgress, Fab, FormControl,
  FormHelperText, Grid, InputLabel, MenuItem, Paper, Portal, Select,
  Tab, Tabs, TextField, Toolbar, Typography, Zoom } from "@mui/material"
import { SuperAdminHeader } from "../header/SuperAdminHeader"
import { Fragment, useEffect, useRef, useState, useCallback } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form, Field } from 'react-final-form'
import { OrgConfigurationsEditor } from "../shared/org-configs/OrgConfigurationsEditor"
import { required, mustBeNumber, minValue, maxValue, composeValidators, fieldParseInt, fieldParseFloat } from  "../../../helpers/ValidationHelpers"
import "./SuperAdminOnboard.css"
import { SuperAdminApi } from "../../../api/SuperAdminAPI"
import { setAlert } from "../../../store/alert/alertSlice"
import { AlertTypes, OnboardingForm } from "../../../interfaces"
import { Prompt, useHistory } from "react-router-dom"
import { createForm, FormApi } from "final-form"
import { useDispatch } from "react-redux"

export const SuperAdminOnboard = () => {

    const [loading, setLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState(0);
    const saveContainer = useRef(null);

    const [form, setForm] = useState<FormApi>()

    const [dirty, setDirty] = useState(false)
    const [valid, setValid] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        const f = createForm({
            onSubmit: () => null,
            initialValues: {},
            mutators: {
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                }
            }
        })

        setForm(f)

        const unsubscribe = f.subscribe(state => {

            if (state.dirty) { window.onbeforeunload = () => true }
            else { window.onbeforeunload = undefined }

            setDirty(state.dirty)
            setValid(state.valid)

        }, {
            dirty: true,
            valid: true
        })

        return function cleanup() {
            window.onbeforeunload = undefined
            unsubscribe()
        }
    }, [])

    const history = useHistory()

    const submit = useCallback((async (formData: OnboardingForm) => {
        setLoading(false)
        try {
            const newOrg = await SuperAdminApi.createOrganization(formData)
            dispatch(setAlert({
              text: "Organization successfully created.",
              type: AlertTypes.Success
            }))
            window.onbeforeunload = undefined
            setDirty(false)
            history.push(`/superadmin/org-edit/${newOrg.id}`)
        } catch (e) {
            dispatch(setAlert({
              text: "Could not create organization. Please try again.",
              type: AlertTypes.Error
            }))
            setLoading(false)
        }
    }), [history])

    if (loading) return <div className="super-admin-page-background">
        <div className="super-admin-page-container" style={{ paddingBottom: "30px" }}>
            <SuperAdminHeader />
            <div>
            <Box sx={{ width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <CircularProgress />
            </Box>
            </div>
        </div>
    </div>

    return (
      <>
        <Prompt
            when={dirty}
            message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="super-admin-page-background">
            <div className="super-admin-page-container">
                <SuperAdminHeader />
                <Paper elevation={1}>
                    <AppBar position="relative">
                        <Toolbar>
                            <Typography variant="h6">
                            Organization Onboarding
                            </Typography>
                        </Toolbar>
                        <Tabs
                          value={currentTab}
                          onChange={(e, value: number) => setCurrentTab(value)}
                          style = {{ backgroundColor: '#FFF' }}
                        >
                            <Tab label="Organization Data" value={0} />
                            <Tab label="Configurations" value={1} />
                        </Tabs>
                        <div ref={saveContainer} style={{ position: "absolute", bottom: "-24px", right: "24px" }}></div>
                    </AppBar>
                    <Box sx={{ mt: 2 }}></Box>
                    {
                        form &&
                    <Form
                        onSubmit={(v: any) => submit(v)}
                        form={form}
                    >
                        {({ handleSubmit }) => (
                            <Fragment>
                                <Portal container={saveContainer.current}>
                                    <Zoom  in={dirty && valid}>
                                        <Fab variant="extended" color="secondary" onClick={handleSubmit}>Finish Onboarding</Fab>
                                    </Zoom>
                                </Portal>
                                <Box sx={{ display: currentTab === 0 ? "block" : "none" }}>
                                    <Box sx={{ px: 3, py: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Field name="name" validate={required}>
                                                {({ input, meta }) => (
                                                    <TextField
                                                        fullWidth variant="outlined" label="Organization Name"
                                                        inputProps={input}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="domain">
                                                {({ input, meta }) => (
                                                    <TextField
                                                        fullWidth variant="outlined" label="Domain"
                                                        inputProps={input}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="retirementSystem" validate={required}>
                                                {({ input, meta }) => (
                                                    <FormControl error={meta.error && meta.touched} variant="outlined" fullWidth>
                                                        <InputLabel>Retirement System Name</InputLabel>
                                                        <Select
                                                            label="Retirement System Name"
                                                            inputProps={input}
                                                        >
                                                            <MenuItem value={"CalPERS"}>CalPERS</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field name="askTierName" parse={(v) => v === 'true' ? true : false} validate={required}>
                                                {({ input, meta }) => (
                                                    <FormControl error={meta.error && meta.touched} variant="outlined" fullWidth>
                                                        <InputLabel>Determine Configuration By</InputLabel>
                                                        <Select
                                                            label="Determine Configuration By"
                                                            inputProps={input}
                                                        >
                                                            <MenuItem value={"false"}>Formula</MenuItem>
                                                            <MenuItem value={"true"}>Tier Name</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{(meta.touched ? meta.error : null) ?? " "}</FormHelperText>
                                                    </FormControl>
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="weeklyHours" parse={fieldParseInt} validate={composeValidators(mustBeNumber, minValue(1), maxValue(168))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Weekly Hours Worked"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="payFrequency" parse={fieldParseInt} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Paychecks Per Year"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="holidayHours" parse={fieldParseFloat} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Holiday Hours"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="uniformPay" parse={fieldParseFloat} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Uniform Pay"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="associationDues" parse={fieldParseFloat} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Association Dues"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="taxDeferredInvestment" parse={fieldParseFloat} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Tax Deferred Investment"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="purchasedServiceTime" parse={fieldParseFloat} validate={composeValidators(mustBeNumber, minValue(1))}>
                                                {({ input, meta }) => (
                                                    <TextField fullWidth variant="outlined" label="Purchased Service Time"
                                                        inputProps={{ type: "number", ...input }}
                                                        error={meta.error && meta.touched}
                                                        helperText={(meta.touched ? meta.error : null) ?? " "}
                                                    />
                                                )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field name="annualRaiseMonth">
                                                {({ input }) => (
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel>Annual Raise Month</InputLabel>
                                                        <Select
                                                            label="Annual Raise Month"
                                                            inputProps={input}
                                                        >
                                                            <MenuItem value={"January"}>January</MenuItem>
                                                            <MenuItem value={"February"}>February</MenuItem>
                                                            <MenuItem value={"March"}>March</MenuItem>
                                                            <MenuItem value={"April"}>April</MenuItem>
                                                            <MenuItem value={"May"}>May</MenuItem>
                                                            <MenuItem value={"June"}>June</MenuItem>
                                                            <MenuItem value={"July"}>July</MenuItem>
                                                            <MenuItem value={"August"}>August</MenuItem>
                                                            <MenuItem value={"September"}>September</MenuItem>
                                                            <MenuItem value={"October"}>October</MenuItem>
                                                            <MenuItem value={"November"}>November</MenuItem>
                                                            <MenuItem value={"December"}>December</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box sx={{ display: currentTab === 2 ? "block" : "none" }}>
                                    <OrgConfigurationsEditor />
                                </Box>
                            </Fragment>
                        )}
                    </Form>
                    }
                </Paper>
            </div>
        </div>
      </>
    )
}