import { Divider , Button } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useState } from "react";

type Props = {
    signUpKey: string
}

function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export const SignUpLinkHeader = ({ signUpKey }: Props) => {
  const [copyText, setCopyText] = useState("Copy Active Link To Clipboard")

  const handleCopyToClipboard = () => {
    setCopyText("Copied")
    copyTextToClipboard(`${window.location.origin}/autojoin/${signUpKey}`)
    setTimeout(() => {
        setCopyText("Copy Active Link To Clipboard") 
    }, 1500);
  }

  return (
    <>
      <Button 
          onClick={handleCopyToClipboard}
          variant="contained"
          sx={{ fontSize: '1.25rem' }}
      >
        <ContentPasteIcon fontSize="small" />
        { copyText }
      </Button>
      <div style={{ margin: '1.25rem 0' }}>
        <strong>Unique Sign Up Link: </strong> 
        <a 
            href={`${window.location.origin}/autojoin/${signUpKey}`}
        >
            {`${window.location.origin}/autojoin/${signUpKey}`}
        </a>
        <p style={{ overflow: 'hidden' }}><strong>Unique Key: </strong>{ signUpKey }</p>
      </div>
      <Divider style={{ marginTop: "10px" }}/>
      <em><p color="gray">This link is to be sent to organization members as it contains a unique connection to your organization. Using it, they can sign up as users.</p></em>
    </>
  )
}