import axios from "axios"
import { apiDefaultOptions, API_ROUTE, getBackendUrlForPath } from "../helpers/APIHelpers"
import { PreSurveyAPIData, SurveyResult } from "../interfaces"

export const postHireDate = async (hireDate: Date) => {
    const body = { hireDate: hireDate.toString() }
    try {
        let response = await axios.post(getBackendUrlForPath(API_ROUTE.SET_HIRE_DATE), body, apiDefaultOptions)
        return response.data as PreSurveyAPIData
    } catch {
        throw new Error("Could not save your hire date. Please try again.")
    }
}

export const postConfiguration = async (answer: SurveyResult) => {
    const body = { ...answer }
    try {
        let response = await axios.post(getBackendUrlForPath(API_ROUTE.SET_PLAN_BENEFIT), body, apiDefaultOptions)
        return response.data
    } catch {
        throw new Error("Could not save your configuration. Please try again.")
    }
}