import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import { isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import { updateResult } from '../../../store/survey/surveySlice';
import { selectCurrentQuestion, selectCurrentQuestionResult, selectDateOfBirth } from '../../../store/survey/surveySlice';
import '../number/Number.css';
import { useWindowSize } from '../../../hooks';

type Props = {
    index?: number,
}

export const NumberDate = ({ index = 0 }: Props) => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const dateOfBirth = useSelector(selectDateOfBirth) as string
    const [minValue, setMinValue] = useState<number>(18)
    const [dateValue, setDateValue] = useState<Date>(new Date())
    const [retireAge, setRetireAge] = useState<number | string>(21);
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 600;

    const calculateAge = (dateOfBirth: string, date: Date) => {
      return Math.round(dayjs(date).diff(new Date(dateOfBirth), "year", true) * 1000) / 1000;
    }

    useEffect(() => {
        if (dateOfBirth && question.text.toLowerCase().includes("like to retire")) {
            let dob = new Date(dateOfBirth)
            let currentDate = new Date()
            const age = dayjs(currentDate).diff(dob, 'year', true) * 1000 / 1000;
            const roundedAge = Math.round(age * 1000) / 1000
            let minimumRetirementAge = Math.max(18, age);
            setMinValue(minimumRetirementAge)
            setRetireAge(roundedAge)
            setDateValue(new Date())
        }

        if (result && result.answer) {
          setDateValue(new Date(result.answer));
          const age = calculateAge(dateOfBirth, result.answer);
          const roundedAge = Math.round(age * 1000) / 1000
          setRetireAge(roundedAge)
        }
    }, [])

    const handleAnswerChange = (val: Date | string, isValid: boolean) => {
      dispatch(updateResult({
          answer: val,
          isValid: isValid
      }))
    }

    const handleInputChange = (val: string) => {
        const num = parseFloat(val)

        // corner case: if val not a number, immediately return
        if (isNaN(num)) {
          setDateValue(null)
          setRetireAge("")
          return handleAnswerChange(null, false)
        }

        const yearsToAdd = Math.floor(num)
        const daysToAdd = (num - yearsToAdd) * 365

        const futureDate = dayjs(new Date(dateOfBirth))
          .add(yearsToAdd, "years")
          .add(daysToAdd, "days")
          .toDate()

        setDateValue(futureDate)

        setRetireAge(num)

        return handleAnswerChange(futureDate.toString(), (!isNaN(num) && num >= minValue))
    }

    const updateNumberInputWithDate = (date: Date | null) => {
        setDateValue(date)

        if (isValid(date) && dateOfBirth) {
          const age = calculateAge(dateOfBirth, date)
          setRetireAge(age)
          handleAnswerChange(date.toISOString(), (!isNaN(age) && age >= minValue))
        } else {
          setDateValue(null)
          setRetireAge("")
          return handleAnswerChange(null, false)
        }
    }

    return (
        <div className="number-container">
            <div className="number-input-container"
              style={{
                ...isMobile && {
                  display: "flex",
                  flexDirection: "column",
                }
              }}
            >
                <div>
                    { question.preFieldDecorator && <span className="number-symbol number-symbol-pre">{ question.preFieldDecorator.flat()[index] }</span> }
                    { question.postFieldDecorator && <span className="number-symbol number-symbol-post">{ question.postFieldDecorator.flat()[index] }</span> }
                    { (!question.postFieldDecorator && !question.preFieldDecorator) && <span className="number-symbol">#</span> }
                    <input
                        type="number"
                        className="number-input"
                        value={ retireAge }
                        onChange={ e => handleInputChange(e.target.value) }
                        readOnly={ question.locked }
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
                    <p>OR ENTER DATE</p>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={ dateValue }
                            onChange={updateNumberInputWithDate}
                            onAccept={updateNumberInputWithDate}
                            disabled={question.locked}
                            minDate={new Date()}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            {
                minValue && result && result.answer <= minValue &&
                <em><p>You must enter a number higher than {Math.round(minValue * 1000) / 1000}</p></em>
            }
        </div>
    )
}