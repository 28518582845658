import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isAfter, isBefore, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SurveyQuestion, SurveyResult } from '../../../interfaces';
import {getStore} from '../../../store';
import { updateResult } from '../../../store/survey/surveySlice';
import { selectCurrentQuestion, selectCurrentQuestionResult, selectHireDate } from '../../../store/survey/surveySlice';
import './DatePicker.css';

/**
 * Format a date to a string
 *
 * @param date
 */
export const formatDate = (date: Date) => {
  if (!isValid(date)) { return date }
  return new Date(date).toISOString()
};

type Props = {
    handleValueChanged?: (val: any, inputIndex: number | undefined) => void,
    index?: number,
    value?: number | string
}

export const NewDatePicker = ({ handleValueChanged, index, value }: Props) => {
  const question = useSelector(selectCurrentQuestion) as SurveyQuestion
  const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
  const [maxDate, setMaxDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date("01/01/1960"))
  const dispatch = useDispatch()

  useEffect(() => {
    const hireDate = selectHireDate(getStore().getState())
    if (question.text.toLowerCase().includes("date of birth") && hireDate) {
      // Date 15 years before hire
      const tempDate = new Date(hireDate)
      tempDate.setFullYear(tempDate.getFullYear() - 15)

      // Date required for user to be 18 years old
      const eighteenDate = new Date()
      eighteenDate.setFullYear(eighteenDate.getFullYear() - 18)

      // Get the lowest of these two dates to set it as the max
      const dates = [tempDate, eighteenDate]
      const min = dates.reduce(function (a, b) { return a < b ? a : b; }); 

      setMaxDate(min)
      setMinDate(null)
    }

  }, [question])

  const handleDateChanged = (date: Date) => {
    if (question.locked) { return }
    updateSurveyWithDate(date)
  }

  const handleDateSelected = (date: Date | null) => {
    updateSurveyWithDate(date)
  }

  const updateSurveyWithDate = (date: Date) => {
    if (handleValueChanged) {
      const strValue = date ? formatDate(date) : null
      handleValueChanged(strValue, index)
      return
    }

    dispatch(updateResult({ 
      answer: (date ? formatDate(date) : null),
      isValid: isValid(date) && !isBefore(date, minDate) && !isAfter(date, maxDate)
    }))
  }

  return (
      <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
              format="MM/dd/yyyy"
              value={ value ? new Date(value) : ((result && result.answer) ? new Date(result.answer) : maxDate) }
              onChange={handleDateChanged}
              onAccept={handleDateSelected}
              disabled={question.locked}
              minDate={minDate}
              maxDate={maxDate}
          />
          </LocalizationProvider>
          {
            isValid( value ? value : ((result && result.answer) ? new Date(result.answer) : null) ) &&
            isBefore(value ? new Date(value) : ((result && result.answer) ? new Date(result.answer) : null), minDate) &&
            <p>Please enter a date after { minDate.toDateString() }</p>
          }
          {
            isValid( value ? value : ((result && result.answer) ? new Date(result.answer) : null) ) &&
            isAfter(value ? new Date(value) : ((result && result.answer) ? new Date(result.answer) : null), maxDate) &&
            (((((new Date()).getFullYear() - (new Date(result.answer)).getFullYear()) >= 18) || !question.text.toLowerCase().includes("date of birth")) ?
            <p>Please enter a date before { maxDate.toDateString() }</p>
            : <p>You must be at least eighteen years old.</p>)
          }
      </div>
  )
}