import { useSelector } from 'react-redux';

import { PensionDataTypes, PreviousPensions } from '../../../interfaces';
import { selectCurrentUser } from '../../../store/auth/authSlice';
import { selectPensionType, selectProfileData } from '../../../store/profile/profileSlice';
import { DetailsHeader } from './PreviousOrgDetails';

interface StatusValues {
    yearValue: string,
    pensionPercentage: number,
    maxPercentage: number,
    currentBenefitFactor: number,
    currentPensionPercentage: number,
    currentPensionGross: string,
    currentPensionNet: string,
    divorcePercentage: number,
    currentVacationCashout: number,
    currentHolidayCashout: number,
    serviceYears: number,
    ePMCPercentage: number,
    isEarlyReduction: boolean,
    earlyReductionFactor: number,
    previousPensions?: PreviousPensions,
    previousPensionPercentage: number,
    yearlyPay: string,
    pensionablePay: string,
    age: number,
    benefit_percentage: number,
    grossPension: string,
    grossPensionNumber: number,
    finalCompensation: string,
    isSocialSecurity: boolean,
}

type Props = {
    isMobile: boolean,
    statusValues: StatusValues,
}

const Detail = {
    SYSTEM: 'Retirement system',
    FORMULA: 'Benefit formula',
    ALLOWANCE: 'Unmodified pension allowance',
}

export const MainOrgDetails = ({ isMobile = true, statusValues }: Props) => {
    const userData = useSelector(selectCurrentUser);
    const profileData = useSelector(selectProfileData);
    const pensionType = useSelector(selectPensionType)

    if (!statusValues || !profileData) {
        return (
            <div></div>
        )
    }

    const formattedEarlyFactor = Math.round(statusValues.earlyReductionFactor * 1000) / 10;
    const formattedServiceYears = Math.round(statusValues.serviceYears * 100) / 100;
    const formattedPensionAmt = `${pensionType === PensionDataTypes.Gross
      ? statusValues.currentPensionGross
      : statusValues.currentPensionNet}`;


    const DetailsInfo = {
        [Detail.SYSTEM]: profileData.retirement_system,
        [Detail.FORMULA]: profileData.retirementBenefitName,
        [Detail.ALLOWANCE]: `$${formattedPensionAmt}`
    }

    return (
        <div
            className="widget-container summary-widget-container"
        >
            <div className="widget-header-container">
               <div className="widget-title">
                    {userData?.organizations?.display_name}
                </div>
                <div
                    style={ isMobile ? {marginTop: '0.25rem'} : {}}
                    className="widget-light-text"
                >
                    Current employer
                </div>
            </div>
            <DetailsHeader details={DetailsInfo} />
            <div style={{textAlign: 'left'}}>
                Final compensation period: {profileData.final_comp_months} months
                <p />
                Final average salary: ${statusValues.pensionablePay}
                <p />
                Benefit factor: {Math.round(statusValues.currentBenefitFactor * 100)}%
                <p />
                {statusValues.isEarlyReduction && (statusValues.earlyReductionFactor < 1) &&
                    <>
                        Early reduction factor: {formattedEarlyFactor}%
                        <p />
                    </>
                }
                Years of service: {formattedServiceYears}
                <p />
                {`Integrated with Social Security: ${statusValues.isSocialSecurity ? 'Yes' : 'No'}`}
                <p />
                {`Unmodified pension allowance = $${statusValues.finalCompensation}
                    * ${Math.round(statusValues.currentBenefitFactor * 100)}%
                    ${statusValues.isEarlyReduction && (statusValues.earlyReductionFactor < 1) ? `* ${formattedEarlyFactor}%` : ''}
                    * ${formattedServiceYears} years
                    = ${formattedPensionAmt}`}
                <p />
                {`You have ${profileData.vacation_hours} vacation hours at
                    ${profileData.metadata.currentVacationCashout === 'user' ? ' a ' : ' an estimated '}
                    cashout rate of
                    $${statusValues.currentVacationCashout} added to your final compensation.`
                }
                <p />
                {`You have ${profileData.holiday_hours} holiday hours at
                    ${profileData.metadata.currentHolidayCashout === 'user' ? ' a ' : ' an estimated '}
                    cashout rate of
                    $${statusValues.currentHolidayCashout} added to your final compensation.`
                }
            </div>
        </div>
    )
}