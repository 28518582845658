import { configureStore } from '@reduxjs/toolkit'
import profileReducer from './profile/profileSlice'
import surveyReducer from './survey/surveySlice'
import authReducer from './auth/authSlice'
import alertReducer from './alert/alertSlice'
import forecastReducer from './forecast/forecastSlice'

const storeRef: {store: any} = {
  store: null,
}

export const startStore = () => {
  storeRef.store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    profile: profileReducer,
    forecast: forecastReducer,
    survey: surveyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
  })
  return storeRef.store;
}

export const getStore = () => storeRef.store;

export type RootState = ReturnType<typeof storeRef.store.getState>;
export type AppDispatch = typeof storeRef.store.dispatch;