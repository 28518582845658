import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { consumeResetPassword } from "../../../../api/AuthAPI";
import { validateResetForm } from "../../../../helpers/FormHelpers";
import { AuthErrors, ResetPasswordFormData } from "../../../../interfaces";
import { AuthSubmit } from "../../shared/submit/AuthSubmit";
import {
  AuthTextfield,
  AuthTextfieldTypes,
} from "../../shared/textfield/AuthTextfield";

type Props = {
  email: string;
  token: string;
  userId: string;
};

export const ResetForm = ({ email, token, userId }: Props) => {
  const [resetForm, setResetForm] = useState<ResetPasswordFormData>({
    newPassword: "",
    confirmPassword: "",
  });
  const [formErrors, setErrors] = useState(null);
  const [formErrorMessages, setErrorMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const handleInputChange = (type: AuthTextfieldTypes, val: string) => {
    var tempData = { ...resetForm };
    switch (type) {
      case AuthTextfieldTypes.SetPassword:
        tempData.newPassword = val;
        break;
      case AuthTextfieldTypes.ConfirmPassword:
        tempData.confirmPassword = val;
        break;
    }

    setResetForm(tempData);
  };

  const handleErrors = (errors: AuthErrors[]) => {
    setErrors(errors);
    setErrorMessages(
      errors.map((e) => (
        <h1 key={e} className="auth-form-error">
          * {e}
        </h1>
      ))
    );
  };

  const handleSubmit = () => {
    const errors = validateResetForm(resetForm);
    if (errors !== null) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    consumeResetPassword(resetForm, token, userId)
      .then((res) => {
        setErrors(null);
        setErrorMessages(null);
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleErrors([AuthErrors.ConsumeError]);
      });
    setTimeout(() => {
      history.push("/login")
    }, 5000)
  };

  return (
    <div className="auth-form-container">
      <div className="auth-form-content">
        <div>
          <h1 className="auth-form-title">Reset Password</h1>
          <div className="auth-form-title-underline"></div>
          <p>Account Email: {email}</p>
        </div>
        {formErrors && formErrorMessages}
        <div>
          <AuthTextfield
            key="set password"
            type={AuthTextfieldTypes.SetPassword}
            formErrors={formErrors}
            value={resetForm.newPassword}
            onChange={handleInputChange}
          />
          <AuthTextfield
            key="confirm pass"
            type={AuthTextfieldTypes.ConfirmPassword}
            formErrors={formErrors}
            value={resetForm.confirmPassword}
            onChange={handleInputChange}
          />
        </div>
        {!success && (
          <AuthSubmit
            title="RESET PASSWORD"
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        )}
        {success && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#32CD32",
              '&:hover': {
                backgroundColor: "#93EB9E",
              }
            }}
            onClick={() => history.push("/login")}
          >
            <CheckCircleOutlineIcon />
            {"  "}
            Success! You will now be redirected
          </Button>)}
      </div>
    </div>
  );
};
