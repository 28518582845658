import { AnimationControls } from "framer-motion"
import { handlePreSurveySubmit } from "../helpers/PreSurveyHelpers"
import { canProceedToNextQuestion } from "../helpers/SurveyHelpers"
import { AlertTypes, SurveyQuestion, SurveyResult } from "../interfaces"
import { getStore } from "../store"
import { setAlert } from "../store/alert/alertSlice"
import { nextQuestion,
  previousQuestion } from "../store/survey/surveySlice"
import { postAnswer, handleFinalQuestion } from "./SurveyAPI"

enum AnimationDirection {
    Forward,
    Backwards
}

export const handleBackPress = async (currentQuestionIndex: number, controls: AnimationControls, dispatch: any) => {
    if (currentQuestionIndex > 0) {
      dispatch(previousQuestion({ questionIndex: currentQuestionIndex }))
      // await changeQuestion([-50, 100], AnimationDirection.Backwards, controls)
    }
}

export const handleNextPress = async (
  currentQuestion: SurveyQuestion,
  result: SurveyResult,
  questionIndex: number,
  surveyData: SurveyQuestion[],
  controls: AnimationControls,
  dispatch: any,
  history: any,
  isSubscribed: boolean = false,
  ) => {
    if (!canProceedToNextQuestion(result)) { return }
    var xVals = [100, -50]

    if (currentQuestion?.isPreSurveyQuestion) {
        // handle pre survey answers
        try {
            await handlePreSurveySubmit(currentQuestion, result)
        } catch (err) {
            showError()
        }
    } else {
        // handle for skip questions
        const answerData = (result && result.answer !== null && result.answer !== "")
            ? result.answer 
            : null

        // post answer to api
        try {
            await postAnswer(currentQuestion.questionId, answerData, currentQuestion)
            if (!surveyData[questionIndex + 1]) handleFinalQuestion(dispatch, history, isSubscribed)
        } catch {
            showError()
        }
    }
    
    dispatch(nextQuestion({ questionIndex }))
    // changeQuestion(xVals, AnimationDirection.Forward, controls)
}

const showError = (err: string = "Can't save question result. Please try again.") => {
    getStore().dispatch(setAlert({text: err, type: AlertTypes.Error}))
}

const changeQuestion = async (xVals: number[], direction: AnimationDirection, controls: AnimationControls) => {
    // question animation
    await animateX(xVals[0], 0, 0.5, controls)
    await animateX(xVals[1], 0, 0, controls)
    switch (direction) {
        case AnimationDirection.Forward:
            break
        case AnimationDirection.Backwards:
            break
    }

    await animateX(0, 1, 0.2, controls)
}

const animateX = async (x: number, opacity: number, duration: number, controls: AnimationControls) => {
    await controls.start({
        x: x + "px",
        opacity: opacity,
        transition: { duration: duration }
    })
}