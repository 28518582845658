import { faEye, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material';
import './CompareCard.css'

type Props = {
    profileWidget: JSX.Element,
    forecastWidget: JSX.Element,
    sideBySide?: boolean
}

export const CompareCard = ({ profileWidget, forecastWidget, sideBySide = true }: Props) => {
    return (
        <div className={`compare-card-container ${!sideBySide ? "compare-card-container-column" : ""}`}>
            <div className="compare-card-item compare-card-margin-right">
                <Tooltip title="These are your CURRENT locked values." placement="top">
                    <div className="compare-icon compare-lock">
                        <FontAwesomeIcon icon={faLock} />
                    </div>
                </Tooltip>
                <div className="compare-card-profile-item-content">
                    {profileWidget}
                </div>
            </div>
            <div className="compare-card-item">
                <Tooltip title="These are your FUTURE forecasted values." placement="top">
                    <div className="compare-eyes compare-icon">
                        <FontAwesomeIcon icon={faEye} />
                    </div>
                </Tooltip>
                <div className="compare-card-item-content compare-card-item-forecast-content">
                    {forecastWidget}
                </div>
            </div>
        </div>
    )
}