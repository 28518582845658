import './Formula.css'
import { useSelector, useDispatch } from 'react-redux'
import { SurveyResult } from '../../../interfaces'
import { updateResult } from '../../../store/survey/surveySlice'
import { selectCurrentQuestionResult } from '../../../store/survey/surveySlice'
import { Number } from '../number/Number'
import { useEffect } from 'react'
import { useState } from 'react'

export const Formula = () => {
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const [inputs, setInputs] = useState<JSX.Element[]>([])
    const dispatch = useDispatch()

    const updateInput = (val: number | string, inputIndex: number | undefined) => {
        let temp = result ? [...result.answer] as (number | string)[] : ["", ""]
        temp[inputIndex!] = val

        dispatch(updateResult({ 
            answer: temp,
            isValid: !temp.includes(NaN) && !temp.includes("")
        }))
    }

    useEffect(() => {
        if (!result || !result.answer) {
            dispatch(updateResult({ 
                answer: ["0", "0"],
                isValid: true
            }))
            return
        }

        setInputs(
            [0, 1].map((v, i) => {
                return <div className="formula-container" key={i.toString()}>
                    <Number 
                        index={ i }
                        handleValueChanged={ updateInput }
                        value={ result.answer[i] }
                    />
                </div>
            })
        )
        // eslint-disable-next-line
    }, [result])

    return (
        <div>
            { inputs }
        </div>
    )
}