import Box from "@mui/material/Box";

const Whitelist = ({ isOrg }: { isOrg: boolean }) => {
  return (
    <Box
      sx={{
        fontWeight: "bolder",
      }}
    >
      <p>Congratulations! 🎉</p>
      <p>
        You are subscribed through{" "}
        {isOrg
          ? "our partnership with your organization"
          : "our individual partners program"}
        .
      </p>
    </Box>
  );
};

export default Whitelist;
