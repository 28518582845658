import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleBackPress, handleNextPress } from '../../../api/AnimationAPI'
import { getQuestionComponent } from '../../../helpers/QuestionHelpers'
import { SurveyQuestion, SurveyQuestionAnswerType, SurveyResult } from '../../../interfaces'
import { selectSubscriptionStatus } from '../../../store/auth/authSlice'
import { selectCurrentQuestion, selectCurrentQuestionIndex, selectCurrentQuestionResult, selectSurveyData } from '../../../store/survey/surveySlice'
import { AnimationContext } from '../../App'
import SubmitButton from '../submit/SubmitButton'
import './Question.css'
import { QuestionHeader } from './QuestionHeader'

export const Question = () => {
    const question = useSelector(selectCurrentQuestion) as SurveyQuestion
    const result = useSelector(selectCurrentQuestionResult) as SurveyResult | null
    const currentQuestionIndex = useSelector(selectCurrentQuestionIndex)
    const surveyData = useSelector(selectSurveyData)
    const animationControls = useContext(AnimationContext)
    const dispatch = useDispatch();
    const history = useHistory();
    const isSubscribed = useSelector(selectSubscriptionStatus)
    
    useEffect(() => {
      const inputElements = document.getElementsByTagName("input")
      const inputElement = inputElements[0]

      if (!question.locked && inputElements.length > 0) {
        inputElement.focus()
      }

      // free ones need diff logic to handle pressing enter on autocomplete
      const onKeyDown = (ev: KeyboardEvent) => {
        // arrow keys should only navigate questions if input isn't active or input value is empty
        if(ev.key === "ArrowRight" && (inputElement !== document.activeElement || inputElement.value === "")) {
          handleNextPress(question, result, currentQuestionIndex, surveyData, animationControls, dispatch, history, isSubscribed)
        } else if (ev.key === "ArrowLeft" && (inputElement !== document.activeElement || inputElement.value === "")) {
          handleBackPress(currentQuestionIndex, animationControls, dispatch)
        } else if (question.answerType[0] !== [SurveyQuestionAnswerType.Free] && ev.key === "Enter") {
          // free questions are different because enter should select autocomplete
          handleNextPress(question, result, currentQuestionIndex, surveyData, animationControls, dispatch, history, isSubscribed)
        }
      }

      document.addEventListener('keydown', onKeyDown);
      return () => {
          document.removeEventListener('keydown', onKeyDown);
      }
    }, [question, animationControls])

    const showSubmitButton = () => {
      return result && result.isValid
    }

    return (
        <div className="question-container">
          <QuestionHeader question={ question } number={ currentQuestionIndex + 1 } />
          <div className="question-content">
            { getQuestionComponent(question) }
            { (showSubmitButton()) && <SubmitButton /> }
          </div>
        </div>
    )
}

export default Question