import React, { useEffect, useState } from 'react'
import { Compare } from './compare/Compare'
import { Menu } from './menu/Menu'
import Overview from './overview/Overview'
import { Settings } from './settings/Settings'
import { Subscription } from './overview/Subscription'
import './UserPage.css'
import { useWindowSize } from '../../hooks'
import VerifyEmail from './overview/VerifyEmail'

const MOBILE_WIDTH = 900;

export enum UserPageRoute {
    Overview = "Overview",
    Account = "Account",
    Compare = "Compare",
    Settings = "Settings",
    Tutorial = "Tutorial",
    Help = "Help",
    Subscribe = "Subscribe",
    Success = "Success",
    Verify = "Verify",
    Logout = "Logout"
}

type Props = {
    page: UserPageRoute
}

export const UserPage: React.FC<Props> = ({ page }) => {
    const [menu, setMenu] = useState(<div></div>)
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < MOBILE_WIDTH

    useEffect(() => {
        setMenu(<Menu activePage={page} />)
    }, [page])

    return (
        <div className="user-page">
            { !isMobile && menu }
            <div className="user-page-content-container">
                { page === UserPageRoute.Overview && <Overview /> }
                { page === UserPageRoute.Settings && <Settings /> }
                { page === UserPageRoute.Subscribe && <Subscription /> }
                { page === UserPageRoute.Compare && <Compare /> }
                { page === UserPageRoute.Verify && <VerifyEmail /> }
            </div>
        </div>
    )
}