import { InviteWelcome } from "../shared/welcome/InviteWelcome";
import { SignUpFormNew } from "./SignUpFormNew";
import "../shared/styles/AuthStyles.css";
import { NewSignUpFormData } from "../../../interfaces";
import { useDispatch } from "react-redux";
import { createAccount } from "../../../store/auth/authSlice";

export const RegistrationPage = () => {
  const dispatch = useDispatch();
  const onSubmit = async (signUpForm: NewSignUpFormData) => {
    await dispatch(createAccount(signUpForm));
  };

  return (
    <div className="auth-page-container">
      <InviteWelcome orgName={""} />
      <SignUpFormNew onSubmit={onSubmit} />
    </div>
  );
};
