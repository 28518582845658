import { Autocomplete, TextField } from "@mui/material";
import { Field } from 'react-final-form';
import { SurveyQuestion } from "../../../interfaces";

const OTHER = {
    text: '__OTHER__'
}

type Props = {
    question: SurveyQuestion,
}

export const AutoCompleteEdit = ({ question } : Props) =>
    <div>
        <Field
            name={question.variable.name}
            component="input"
            initialValue={question.prefill || ""}
        >
        {({input: {onChange, value}, ...rest}) => (
        <Autocomplete
            options={ question.allowOther ? [...question.answerOptions, OTHER] : question.answerOptions}
            getOptionLabel={(option) => option.text ? option.text : option}
            onChange={(e, newValue) => onChange(newValue ? newValue.text : null)}
            value={(value !== "0" && value) ? value : ""}
            style={{ width: 400 }}
            isOptionEqualToValue={(option, value) => option.text ? option.text === value : option === value}
            freeSolo={question.allowOther}
            {...rest}
            renderInput={(Props) => <TextField
                {...Props}
                label={ question.placeholderText}
                variant="outlined"
                disabled={ question.locked }
            />}
        />
        )}
        </Field>
    </div>
