import { TextField, Autocomplete } from "@mui/material";
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from "react-redux";

import { federal_tax_data, state_tax_data } from "../../../../data/tax_data";
import { ProfileData } from '../../../../interfaces';
import { selectSubscriptionStatus } from '../../../../store/auth/authSlice';
import { getPercentage, getNumberValue, getDivisor, formatNumbers } from '../../../../utils/numberDateFormatters';

import './OverviewTaxWidget.css';
import { selectNumbersData, selectTimePeriod } from "../../../../store/profile/profileSlice";

type Props = {
    profileData: ProfileData
    stateName: string
}

export const StateAutocomplete = ({ profileData }: Props) => {
    const [stateName, setStateName] = useState<string>(null)
    const [allStateTaxes, setAllStateTaxes] = useState<{[key: string] : any}>({})
    const numbersData = useSelector(selectNumbersData);
    const isSubscribed = useSelector(selectSubscriptionStatus);
    const annualGross = numbersData?.yearly_gross;
    const timePeriod = useSelector(selectTimePeriod);

    const getFederalTax = (annualGrossPay: number) => {
        let taxData = federal_tax_data
        const taxableGrossPay = annualGrossPay - 12400
        var projected_tax = 0
        taxData.forEach((d) => {
            if ((taxableGrossPay) > d.single.brackets) {
                const topValue = Math.min(d.single.total, taxableGrossPay)
                projected_tax += (topValue - d.single.brackets) * (d.single.rates / 100)
            }
        })

        return projected_tax
    }

    const getStateTax = (currentState: string) => {
        const taxData = state_tax_data[currentState]
        const taxableGrossPay = annualGross - 12400
        var projected_tax = 0
        taxData?.forEach((d) => {
            if ((taxableGrossPay) > d.single.brackets) {
                const topValue = Math.min(d.single.total, taxableGrossPay)
                projected_tax += (topValue - d.single.brackets) * (d.single.rates / 100)
            }
        })
        return projected_tax
    }

    useEffect(() => {
        const federalTax = getFederalTax(annualGross) / getDivisor(timePeriod)
        const medicare = (profileData?.medicare_tax_payment / profileData?.pensionable_pay) * annualGross || 0
        const social = (profileData?.social_security_tax_payment / profileData?.pensionable_pay) * annualGross || 0
        const stateTaxes = Object.keys(state_tax_data).reduce((acc, stateName) => {
            let stateTax = getNumberValue(getStateTax(stateName) / getDivisor(timePeriod));
            let total = getNumberValue(stateTax + federalTax + medicare + social);
            return {...acc, [stateName]: {
                stateName,
                state: formatNumbers(stateTax, isSubscribed),
                statePercent: getPercentage(stateTax, annualGross, isSubscribed),
                total: formatNumbers(total, isSubscribed),
                totalPercent: getPercentage(total, annualGross, isSubscribed),
            }}
        }, {})
        setAllStateTaxes(stateTaxes)
    }, [stateName, timePeriod])

    return (
        <div className="widget-container">
            <div className="widget-header-container">
                <div className="widget-configuration-button-container">
                </div>
            </div>
            <div>
                <Autocomplete
                options={ Object.keys(state_tax_data) }
                getOptionLabel={(option) => option}
                value={stateName}
                onChange={(e, newValue) => setStateName(newValue)}
                renderInput={(Props) => <TextField
                    {...Props}
                    label="What if I moved out of state?"
                    variant="outlined"
                />}
        />
            <div className="state-tax-header-container">
                <div className="state-tax-header">State</div>
                <div className="state-tax-header">Annual state tax</div>
                <div className="state-tax-header">Total tax withholding</div>
            </div>
            {Object.entries(allStateTaxes).map(([k, v], index) => (
                <div
                    key={v.stateName}
                    className={cx({
                    "hide-tax": stateName && stateName !== k,
                },
                    "state-tax-mobile"
                )}>
                    <div style={{flex: "1 1", fontWeight: 500}}>{ v.stateName }</div>
                    <div className="tax-numbers-container">
                        <div style={{fontWeight: 500}}>
                            ${ v.state }
                        </div>
                        <div>{ v.statePercent }%</div>
                    </div>
                    <div className="tax-numbers-container">
                        <div style={{fontWeight: 500}}>
                            ${ v.total }
                        </div>
                        <div>{ v.totalPercent }%</div>
                    </div>
                </div>
                ))
            }
            </div>
        </div>
    )
}