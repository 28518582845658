import { InviteWelcome } from '../shared/welcome/InviteWelcome'
import { ForgotForm } from './form/ForgotForm'
import '../shared/styles/AuthStyles.css'

const Forgot = () => {
    return (
        <div className="auth-page-container">
            <InviteWelcome />
            <ForgotForm />
        </div>
    )
}

export default Forgot;
