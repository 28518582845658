import { postConfiguration, postHireDate } from "../api/PreSurveyAPI";
import { BENEFIT_QUESTION_ID } from "../data/PreSurveyData";
import { PreSurveyAPIData, SurveyApiActions, SurveyAnswerOption, SurveyQuestion, SurveyResult } from "../interfaces";
import { API_ROUTE } from "./APIHelpers";

export const handlePreSurveySubmit = async (currentQuestion: SurveyQuestion, result: SurveyResult) => {
    try {
        switch (currentQuestion.postApiRoute) {
            case API_ROUTE.SET_HIRE_DATE:
                return await handleHireDate(result.answer as string)
            case API_ROUTE.SET_PLAN_BENEFIT:
                return await handleConfiguration(result as SurveyResult, currentQuestion)
            default:
                return
        }
    } catch (e) {
        throw e
    }
}

const handleHireDate = async (dateResult: string) => {
    try {
        const preSurveyResult = await postHireDate(new Date(dateResult))
        let apiData = preSurveyResult as PreSurveyAPIData
        switch (apiData.action) {
            case SurveyApiActions.Error:
                throw new Error("Could not save your hire date. Please try again.")
            case SurveyApiActions.Ask:
                return BENEFIT_QUESTION_ID
        }
    } catch (e) {
        throw e
    }
}

export const setBenefitFormulas = (choices: any[]) => {
    const answerOptions: SurveyAnswerOption[] = choices.map((c, index) => {
        const { name, type, system_name, level, planId, hire_date } = c;
        const startText = hire_date.start
            ? new Date(hire_date.start).toLocaleDateString('en-Us')
            : 'Before';
        const endText = hire_date.end
            ? new Date(hire_date.end).toLocaleDateString('en-Us')
            : 'Present';
        return {
            ...c,
            id: planId,
            text: `[${system_name}] ${name}, ${type}, ${level} (Hire dates: ${startText}-${endText})`
        } as SurveyAnswerOption
    }).sort((a, b) => {
        if (a.system_name < b.system_name) {
          return -1;
        }
        if (a.system_name > b.system_name) {
          return 1;
        }
        return 0;
      })
      return answerOptions
    // getStore().dispatch(setAnswerOptions(answerOptions))
}

const handleConfiguration = async (configuration: SurveyResult, currentQuestion: SurveyQuestion) => {
    try {
        let apiData = await postConfiguration(configuration)
        switch (apiData.action) {
            case SurveyApiActions.Error:
                throw new Error("Could not save your configuration. Please try again.")
            default:
              // hard-coded the date of birth question
                return 14;
        }
    } catch (e) {
        throw e
    }
}