import { FederalTaxBracket, StateTaxData } from "../interfaces";

// as of 10/4/2023 for 2023 tax year (filing 2024)
// https://taxfoundation.org/data/all/state/state-income-tax-rates-2023/

export const federal_tax_data: FederalTaxBracket[] = [
  {
    single: {
      rates: 10,
      brackets: -1,
      total: 11000,
    },
  },
  {
    single: {
      rates: 12,
      brackets: 11001,
      total: 44725,
    },
  },
  {
    single: {
      rates: 22,
      brackets: 44726,
      total: 95375,
    },
  },
  {
    single: {
      rates: 24,
      brackets: 95376,
      total: 182100,
    },
  },
  {
    single: {
      rates: 32,
      brackets: 182101,
      total: 231250,
    },
  },
  {
    single: {
      rates: 35,
      brackets: 231251,
      total: 578125,
    },
  },
  {
    single: {
      rates: 37,
      brackets: 578126,
      total: 1000000000,
    },
  },
];

export const no_tax: { [key: string]: string } = {
  Alabama: 'no-pension-tax',
  Alaska: 'no-state-tax',
  Florida: 'no-state-tax',
  Hawaii: 'no-pension-tax',
  Illinois: 'no-pension-tax',
  Iowa: 'no-pension-tax',
  Mississippi: 'no-pension-tax',
  Nevada: 'no-state-tax',
  'New Hampshire': 'no-state-tax',
  Pennsylvania: 'no-pension-tax',
  'South Dakota': 'no-state-tax',
  Tennessee: 'no-state-tax',
  Texas: 'no-state-tax',
  Washington: 'no-state-tax',
  Wyoming: 'no-state-tax',
};

export const state_tax_data: StateTaxData = {
  Alabama: [
    {
      single: {
        rates: 2.0,
        brackets: -1,
        total: 500,
      },
      married: {
        rates: 2.0,
        brackets: -1,
        total: 1000,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 501,
        total: 3000,
      },
      married: {
        rates: 4.0,
        brackets: 1001,
        total: 6000,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 3001,
        total: 1000000000,
      },
      married: {
        rates: 5.0,
        brackets: 6001,
        total: 1000000000,
      },
    },
  ],
  Arizona: [
    {
      single: {
        rates: 2.5,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 2.5,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Arkansas: [
    {
      single: {
        rates: 2.0,
        brackets: -1,
        total: 4299,
      },
      married: {
        rates: 2.0,
        brackets: -1,
        total: 4299,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 4300,
        total: 8499,
      },
      married: {
        rates: 4.0,
        brackets: 4300,
        total: 8499,
      },
    },
    {
      single: {
        rates: 4.9,
        brackets: 8500,
        total: 1000000000,
      },
      married: {
        rates: 4.9,
        brackets: 8500,
        total: 1000000000,
      },
    },
  ],
  California: [
    {
      single: {
        rates: 1.0,
        brackets: -1,
        total: 10099,
      },
      married: {
        rates: 1.0,
        brackets: -1,
        total: 20198,
      },
    },
    {
      single: {
        rates: 2.0,
        brackets: 10100,
        total: 23942,
      },
      married: {
        rates: 2.0,
        brackets: 20199,
        total: 47884,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 23943,
        total: 37788,
      },
      married: {
        rates: 4.0,
        brackets: 47885,
        total: 75576,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 37789,
        total: 52455,
      },
      married: {
        rates: 6.0,
        brackets: 75577,
        total: 104910,
      },
    },
    {
      single: {
        rates: 8.0,
        brackets: 52456,
        total: 66295,
      },
      married: {
        rates: 8.0,
        brackets: 104911,
        total: 132590,
      },
    },
    {
      single: {
        rates: 9.3,
        brackets: 66296,
        total: 338639,
      },
      married: {
        rates: 9.3,
        brackets: 132591,
        total: 677278,
      },
    },
    {
      single: {
        rates: 10.3,
        brackets: 338640,
        total: 406364,
      },
      married: {
        rates: 10.3,
        brackets: 677279,
        total: 812728,
      },
    },
    {
      single: {
        rates: 11.3,
        brackets: 406365,
        total: 677275,
      },
      married: {
        rates: 11.3,
        brackets: 812729,
        total: 1354550,
      },
    },
    {
      single: {
        rates: 12.3,
        brackets: 677276,
        total: 1000000000,
      },
      married: {
        rates: 12.3,
        brackets: 1354551,
        total: 1000000000,
      },
    },
  ],
  Colorado: [
    {
      single: {
        rates: 4.4,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 4.4,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Connecticut: [
    {
      single: {
        rates: 3.0,
        brackets: -1,
        total: 9999,
      },
      married: {
        rates: 3.0,
        brackets: -1,
        total: 19999,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 10000,
        total: 49999,
      },
      married: {
        rates: 5.0,
        brackets: 20000,
        total: 99999,
      },
    },
    {
      single: {
        rates: 5.5,
        brackets: 50000,
        total: 99999,
      },
      married: {
        rates: 5.5,
        brackets: 100000,
        total: 199999,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 100000,
        total: 199999,
      },
      married: {
        rates: 6.0,
        brackets: 200000,
        total: 399999,
      },
    },
    {
      single: {
        rates: 6.5,
        brackets: 200000,
        total: 249999,
      },
      married: {
        rates: 6.5,
        brackets: 400000,
        total: 499999,
      },
    },
    {
      single: {
        rates: 6.9,
        brackets: 250000,
        total: 499999,
      },
      married: {
        rates: 6.9,
        brackets: 500000,
        total: 999999,
      },
    },
    {
      single: {
        rates: 6.99,
        brackets: 500000,
        total: 1000000000,
      },
      married: {
        rates: 6.99,
        brackets: 1000000,
        total: 1000000000,
      },
    },
  ],
  Delaware: [
    {
      single: {
        rates: 2.2,
        brackets: 1999,
        total: 4999,
      },
      married: {
        rates: 2.2,
        brackets: 1999,
        total: 4999,
      },
    },
    {
      single: {
        rates: 3.9,
        brackets: 4999,
        total: 9999,
      },
      married: {
        rates: 3.9,
        brackets: 4999,
        total: 9999,
      },
    },
    {
      single: {
        rates: 4.8,
        brackets: 9999,
        total: 19999,
      },
      married: {
        rates: 4.8,
        brackets: 9999,
        total: 19999,
      },
    },
    {
      single: {
        rates: 5.2,
        brackets: 19999,
        total: 24999,
      },
      married: {
        rates: 5.2,
        brackets: 19999,
        total: 24999,
      },
    },
    {
      single: {
        rates: 5.55,
        brackets: 24999,
        total: 59999,
      },
      married: {
        rates: 5.55,
        brackets: 24999,
        total: 59999,
      },
    },
    {
      single: {
        rates: 6.6,
        brackets: 59999,
        total: 1000000000,
      },
      married: {
        rates: 6.6,
        brackets: 59999,
        total: 1000000000,
      },
    },
  ],
  Florida: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Georgia: [
    {
      single: {
        rates: 1.0,
        brackets: -1,
        total: 749,
      },
      married: {
        rates: 1.0,
        brackets: -1,
        total: 999,
      },
    },
    {
      single: {
        rates: 2.0,
        brackets: 749,
        total: 2249,
      },
      married: {
        rates: 2.0,
        brackets: 999,
        total: 2999,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 2249,
        total: 3749,
      },
      married: {
        rates: 3.0,
        brackets: 1000,
        total: 4999,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 3749,
        total: 5249,
      },
      married: {
        rates: 4.0,
        brackets: 4999,
        total: 6999,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 5249,
        total: 6999,
      },
      married: {
        rates: 5.0,
        brackets: 6999,
        total: 9999,
      },
    },
    {
      single: {
        rates: 5.75,
        brackets: 6999,
        total: 1000000000,
      },
      married: {
        rates: 5.75,
        brackets: 9999,
        total: 1000000000,
      },
    },
  ],
  Hawaii: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Idaho: [
    {
      single: {
        rates: 5.8,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 5.8,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Illinois: [
    {
      single: {
        rates: 0.00,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.00,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Indiana: [
    {
      single: {
        rates: 3.15,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 3.15,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Iowa: [
    {
      single: {
        rates: 0.0,
        brackets: 10000,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: 10000,
        total: 1000000000,
      },
    },
  ],
  Kansas: [
    {
      single: {
        rates: 3.1,
        brackets: -1,
        total: 14999,
      },
      married: {
        rates: 3.1,
        brackets: -1,
        total: 29999,
      },
    },
    {
      single: {
        rates: 5.25,
        brackets: 14999,
        total: 29999,
      },
      married: {
        rates: 5.25,
        brackets: 29999,
        total: 59999,
      },
    },
    {
      single: {
        rates: 5.7,
        brackets: 29999,
        total: 1000000000,
      },
      married: {
        rates: 5.7,
        brackets: 59999,
        total: 1000000000,
      },
    },
  ],
  Kentucky: [
    {
      single: {
        rates: 4.5,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 4.5,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Louisiana: [
    {
      single: {
        rates: 1.85,
        brackets: -1,
        total: 12499,
      },
      married: {
        rates: 1.85,
        brackets: -1,
        total: 24999,
      },
    },
    {
      single: {
        rates: 3.5,
        brackets: 12499,
        total: 49999,
      },
      married: {
        rates: 3.5,
        brackets: 24999,
        total: 99999,
      },
    },
    {
      single: {
        rates: 4.25,
        brackets: 49999,
        total: 1000000000,
      },
      married: {
        rates: 4.25,
        brackets: 99999,
        total: 1000000000,
      },
    },
  ],
  Maine: [
    {
      single: {
        rates: 5.8,
        brackets: -1,
        total: 24999,
      },
      married: {
        rates: 5.8,
        brackets: -1,
        total: 48999,
      },
    },
    {
      single: {
        rates: 6.75,
        brackets: 24500,
        total: 58049,
      },
      married: {
        rates: 6.75,
        brackets: 49000,
        total: 116099,
      },
    },
    {
      single: {
        rates: 7.15,
        brackets: 58050,
        total: 1000000000,
      },
      married: {
        rates: 7.15,
        brackets: 116100,
        total: 1000000000,
      },
    },
  ],
  Maryland: [
    {
      single: {
        rates: 2.0,
        brackets: -1,
        total: 999,
      },
      married: {
        rates: 2.0,
        brackets: -1,
        total: 999,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 999,
        total: 1999,
      },
      married: {
        rates: 3.0,
        brackets: 999,
        total: 1999,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 1999,
        total: 2999,
      },
      married: {
        rates: 4.0,
        brackets: 1999,
        total: 2999,
      },
    },
    {
      single: {
        rates: 4.75,
        brackets: 2999,
        total: 99999,
      },
      married: {
        rates: 4.75,
        brackets: 2999,
        total: 149999,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 99999,
        total: 124999,
      },
      married: {
        rates: 5.0,
        brackets: 149999,
        total: 174999,
      },
    },
    {
      single: {
        rates: 5.25,
        brackets: 124999,
        total: 149999,
      },
      married: {
        rates: 5.25,
        brackets: 174999,
        total: 224999,
      },
    },
    {
      single: {
        rates: 5.5,
        brackets: 150000,
        total: 224999,
      },
      married: {
        rates: 5.5,
        brackets: 225000,
        total: 299999,
      },
    },
    {
      single: {
        rates: 5.75,
        brackets: 249999,
        total: 1000000000,
      },
      married: {
        rates: 5.75,
        brackets: 299999,
        total: 1000000000,
      },
    },
  ],
  Massachusetts: [
    {
      single: {
        rates: 5.0,
        brackets: -1,
        total: 999999,
      },
      married: {
        rates: 5.0,
        brackets: -1,
        total: 999999,
      },
    },
    {
      single: {
        rates: 9.0,
        brackets: 1000000,
        total: 1000000000,
      },
      married: {
        rates: 9.0,
        brackets: 1000000,
        total: 1000000000,
      },
    },
  ],
  Michigan: [
    {
      single: {
        rates: 4.25,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 4.25,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Minnesota: [
    {
      single: {
        rates: 5.35,
        brackets: -1,
        total: 30069,
      },
      married: {
        rates: 5.35,
        brackets: -1,
        total: 43949,
      },
    },
    {
      single: {
        rates: 6.8,
        brackets: 30070,
        total: 98759,
      },
      married: {
        rates: 6.8,
        brackets: 43950,
        total: 174609,
      },
    },
    {
      single: {
        rates: 7.85,
        brackets: 98760,
        total: 183339,
      },
      married: {
        rates: 7.85,
        brackets: 174610,
        total: 304969,
      },
    },
    {
      single: {
        rates: 9.85,
        brackets: 183340,
        total: 1000000000,
      },
      married: {
        rates: 9.85,
        brackets: 304970,
        total: 1000000000,
      },
    },
  ],
  Mississippi: [
    {
      single: {
        rates: 0.0,
        brackets: 10000,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: 10000,
        total: 1000000000,
      },
    },
  ],
  Missouri: [
    {
      single: {
        rates: 2.0,
        brackets: 1120,
        total: 2241,
      },
      married: {
        rates: 2.0,
        brackets: 1120,
        total: 2241,
      },
    },
    {
      single: {
        rates: 2.5,
        brackets: 2242,
        total: 3362,
      },
      married: {
        rates: 2.5,
        brackets: 2242,
        total: 3362,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 3363,
        total: 4483,
      },
      married: {
        rates: 3.0,
        brackets: 3363,
        total: 4483,
      },
    },
    {
      single: {
        rates: 3.5,
        brackets: 4484,
        total: 5604,
      },
      married: {
        rates: 3.5,
        brackets: 4484,
        total: 5604,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 5605,
        total: 6725,
      },
      married: {
        rates: 4.0,
        brackets: 5605,
        total: 6725,
      },
    },
    {
      single: {
        rates: 4.5,
        brackets: 6726,
        total: 7846,
      },
      married: {
        rates: 4.5,
        brackets: 6726,
        total: 7846,
      },
    },
    {
      single: {
        rates: 4.95,
        brackets: 7847,
        total: 1000000000,
      },
      married: {
        rates: 4.95,
        brackets: 7847,
        total: 1000000000,
      },
    },
  ],
  Montana: [
    {
      single: {
        rates: 1.0,
        brackets: -1,
        total: 3599,
      },
      married: {
        rates: 1.0,
        brackets: -1,
        total: 3599,
      },
    },
    {
      single: {
        rates: 2.0,
        brackets: 3600,
        total: 6299,
      },
      married: {
        rates: 2.0,
        brackets: 3600,
        total: 6299,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 6300,
        total: 9699,
      },
      married: {
        rates: 3.0,
        brackets: 6300,
        total: 9699,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 9700,
        total: 12999,
      },
      married: {
        rates: 4.0,
        brackets: 9700,
        total: 12999,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 13000,
        total: 16799,
      },
      married: {
        rates: 5.0,
        brackets: 13000,
        total: 16799,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 16800,
        total: 21599,
      },
      married: {
        rates: 6.0,
        brackets: 16800,
        total: 21599,
      },
    },
    {
      single: {
        rates: 6.75,
        brackets: 21600,
        total: 1000000000,
      },
      married: {
        rates: 6.75,
        brackets: 21600,
        total: 1000000000,
      },
    },
  ],
  Nebraska: [
    {
      single: {
        rates: 2.46,
        brackets: -1,
        total: 3699,
      },
      married: {
        rates: 2.46,
        brackets: -1,
        total: 7389,
      },
    },
    {
      single: {
        rates: 3.51,
        brackets: 3700,
        total: 22169,
      },
      married: {
        rates: 3.51,
        brackets: 7390,
        total: 44349,
      },
    },
    {
      single: {
        rates: 5.01,
        brackets: 22170,
        total: 35729,
      },
      married: {
        rates: 5.01,
        brackets: 44350,
        total: 71459,
      },
    },
    {
      single: {
        rates: 6.64,
        brackets: 35730,
        total: 1000000000,
      },
      married: {
        rates: 6.64,
        brackets: 71460,
        total: 1000000000,
      },
    },
  ],
  Nevada: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'New Hampshire': [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'New Jersey': [
    {
      single: {
        rates: 1.4,
        brackets: -1,
        total: 19999,
      },
      married: {
        rates: 1.4,
        brackets: -1,
        total: 19999,
      },
    },
    {
      single: {
        rates: 1.75,
        brackets: 19999,
        total: 34999,
      },
      married: {
        rates: 1.75,
        brackets: 19999,
        total: 49999,
      },
    },
    {
      single: {
        rates: 3.5,
        brackets: 34999,
        total: 39999,
      },
      married: {
        rates: 2.45,
        brackets: 49999,
        total: 69999,
      },
    },
    {
      single: {
        rates: 5.525,
        brackets: 39999,
        total: 74999,
      },
      married: {
        rates: 3.5,
        brackets: 69999,
        total: 79999,
      },
    },
    {
      single: {
        rates: 6.37,
        brackets: 74999,
        total: 499999,
      },
      married: {
        rates: 5.525,
        brackets: 79999,
        total: 149999,
      },
    },
    {
      single: {
        rates: 8.97,
        brackets: 499999,
        total: 4999999,
      },
      married: {
        rates: 6.37,
        brackets: 149999,
        total: 499999,
      },
    },
    {
      single: {
        rates: 10.75,
        brackets: 499999,
        total: 1000000000,
      },
      married: {
        rates: 8.97,
        brackets: 499999,
        total: 4999999,
      },
    },
    {
      single: {
        rates: 10.75,
        brackets: 499999,
        total: 1000000000,
      },
      married: {
        rates: 10.75,
        brackets: 4999999,
        total: 1000000000,
      },
    },
  ],
  'New Mexico': [
    {
      single: {
        rates: 1.7,
        brackets: -1,
        total: 5499,
      },
      married: {
        rates: 1.7,
        brackets: -1,
        total: 7999,
      },
    },
    {
      single: {
        rates: 3.2,
        brackets: 5499,
        total: 10999,
      },
      married: {
        rates: 3.2,
        brackets: 7999,
        total: 15999,
      },
    },
    {
      single: {
        rates: 4.7,
        brackets: 10999,
        total: 15999,
      },
      married: {
        rates: 4.7,
        brackets: 15999,
        total: 23999,
      },
    },
    {
      single: {
        rates: 4.9,
        brackets: 15999,
        total: 209999,
      },
      married: {
        rates: 4.9,
        brackets: 23999,
        total: 314999,
      },
    },
    {
      single: {
        rates: 5.9,
        brackets: 210000,
        total: 1000000000,
      },
      married: {
        rates: 5.9,
        brackets: 315000,
        total: 1000000000,
      },
    },
  ],
  'New York': [
    {
      single: {
        rates: 4.0,
        brackets: -1,
        total: 8499,
      },
      married: {
        rates: 4.0,
        brackets: -1,
        total: 17149,
      },
    },
    {
      single: {
        rates: 4.5,
        brackets: 8500,
        total: 11699,
      },
      married: {
        rates: 4.5,
        brackets: 17150,
        total: 23599,
      },
    },
    {
      single: {
        rates: 5.25,
        brackets: 11700,
        total: 13899,
      },
      married: {
        rates: 5.25,
        brackets: 23600,
        total: 27899,
      },
    },
    {
      single: {
        rates: 5.5,
        brackets: 13900,
        total: 80649,
      },
      married: {
        rates: 5.5,
        brackets: 27900,
        total: 161549,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 80650,
        total: 215399,
      },
      married: {
        rates: 6.0,
        brackets: 161550,
        total: 323199,
      },
    },
    {
      single: {
        rates: 6.85,
        brackets: 215400,
        total: 1077549,
      },
      married: {
        rates: 6.85,
        brackets: 323200,
        total: 2155349,
      },
    },
    {
      single: {
        rates: 9.65,
        brackets: 1077550,
        total: 4999999,
      },
      married: {
        rates: 9.65,
        brackets: 2155350,
        total: 4999999,
      },
    },
    {
      single: {
        rates: 10.3,
        brackets: 5000000,
        total: 24999999,
      },
      married: {
        rates: 10.3,
        brackets: 5000000,
        total: 24999999,
      },
    },
    {
      single: {
        rates: 10.9,
        brackets: 25000000,
        total: 1000000000,
      },
      married: {
        rates: 10.9,
        brackets: 25000000,
        total: 1000000000,
      },
    },
  ],
  'North Carolina': [
    {
      single: {
        rates: 4.75,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 4.75,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'North Dakota': [
    {
      single: {
        rates: 1.1,
        brackets: -1,
        total: 41774,
      },
      married: {
        rates: 1.1,
        brackets: -1,
        total: 69699,
      },
    },
    {
      single: {
        rates: 2.04,
        brackets: 41775,
        total: 101049,
      },
      married: {
        rates: 2.04,
        brackets: 69700,
        total: 168449,
      },
    },
    {
      single: {
        rates: 2.27,
        brackets: 101050,
        total: 210824,
      },
      married: {
        rates: 2.27,
        brackets: 168450,
        total: 256649,
      },
    },
    {
      single: {
        rates: 2.64,
        brackets: 210825,
        total: 458349,
      },
      married: {
        rates: 2.64,
        brackets: 256650,
        total: 458349,
      },
    },
    {
      single: {
        rates: 2.9,
        brackets: 458350,
        total: 1000000000,
      },
      married: {
        rates: 2.9,
        brackets: 458350,
        total: 1000000000,
      },
    },
  ],
  Ohio: [
    {
      single: {
        rates: 2.765,
        brackets: 26050,
        total: 46099,
      },
      married: {
        rates: 2.765,
        brackets: 26050,
        total: 46099,
      },
    },
    {
      single: {
        rates: 3.226,
        brackets: 46100,
        total: 92149,
      },
      married: {
        rates: 3.226,
        brackets: 46100,
        total: 92149,
      },
    },
    {
      single: {
        rates: 3.688,
        brackets: 92150,
        total: 115299,
      },
      married: {
        rates: 3.688,
        brackets: 92150,
        total: 115299,
      },
    },
    {
      single: {
        rates: 3.99,
        brackets: 115300,
        total: 1000000000,
      },
      married: {
        rates: 3.99,
        brackets: 115300,
        total: 1000000000,
      },
    },
  ],
  Oklahoma: [
    {
      single: {
        rates: 0.25,
        brackets: -1,
        total: 999,
      },
      married: {
        rates: 0.25,
        brackets: -1,
        total: 1999,
      },
    },
    {
      single: {
        rates: 0.75,
        brackets: 999,
        total: 2499,
      },
      married: {
        rates: 0.75,
        brackets: 1999,
        total: 4999,
      },
    },
    {
      single: {
        rates: 1.75,
        brackets: 2499,
        total: 3749,
      },
      married: {
        rates: 1.75,
        brackets: 4999,
        total: 7499,
      },
    },
    {
      single: {
        rates: 2.75,
        brackets: 3749,
        total: 4899,
      },
      married: {
        rates: 2.75,
        brackets: 7499,
        total: 9799,
      },
    },
    {
      single: {
        rates: 3.75,
        brackets: 4899,
        total: 7199,
      },
      married: {
        rates: 3.75,
        brackets: 9799,
        total: 12199,
      },
    },
    {
      single: {
        rates: 4.75,
        brackets: 7199,
        total: 1000000000,
      },
      married: {
        rates: 4.75,
        brackets: 12199,
        total: 1000000000,
      },
    },
  ],
  Oregon: [
    {
      single: {
        rates: 4.75,
        brackets: -1,
        total: 4049,
      },
      married: {
        rates: 4.75,
        brackets: -1,
        total: 8099,
      },
    },
    {
      single: {
        rates: 6.75,
        brackets: 4050,
        total: 10199,
      },
      married: {
        rates: 6.75,
        brackets: 8100,
        total: 20399,
      },
    },
    {
      single: {
        rates: 8.75,
        brackets: 10200,
        total: 124999,
      },
      married: {
        rates: 8.75,
        brackets: 20400,
        total: 249999,
      },
    },
    {
      single: {
        rates: 9.9,
        brackets: 125000,
        total: 1000000000,
      },
      married: {
        rates: 9.9,
        brackets: 250000,
        total: 1000000000,
      },
    },
  ],
  Pennsylvania: [
    {
      single: {
        rates: 0.00,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.00,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'Rhode Island': [
    {
      single: {
        rates: 3.75,
        brackets: -1,
        total: 68199,
      },
      married: {
        rates: 3.75,
        brackets: -1,
        total: 68199,
      },
    },
    {
      single: {
        rates: 4.75,
        brackets: 68200,
        total: 155049,
      },
      married: {
        rates: 4.75,
        brackets: 68200,
        total: 155049,
      },
    },
    {
      single: {
        rates: 5.99,
        brackets: 155050,
        total: 1000000000,
      },
      married: {
        rates: 5.99,
        brackets: 155050,
        total: 1000000000,
      },
    },
  ],
  'South Carolina': [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 3199,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 3199,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 3200,
        total: 16039,
      },
      married: {
        rates: 3.0,
        brackets: 3200,
        total: 16039,
      },
    },
    {
      single: {
        rates: 6.5,
        brackets: 16040,
        total: 1000000000,
      },
      married: {
        rates: 6.5,
        brackets: 16040,
        total: 1000000000,
      },
    },
  ],
  'South Dakota': [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Tennessee: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Texas: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Utah: [
    {
      single: {
        rates: 4.85,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 4.85,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  Vermont: [
    {
      single: {
        rates: 3.35,
        brackets: -1,
        total: 42149,
      },
      married: {
        rates: 3.35,
        brackets: -1,
        total: 70449,
      },
    },
    {
      single: {
        rates: 6.6,
        brackets: 42150,
        total: 102199,
      },
      married: {
        rates: 6.6,
        brackets: 70450,
        total: 170299,
      },
    },
    {
      single: {
        rates: 7.6,
        brackets: 102200,
        total: 213149,
      },
      married: {
        rates: 7.6,
        brackets: 170300,
        total: 259499,
      },
    },
    {
      single: {
        rates: 8.75,
        brackets: 213150,
        total: 1000000000,
      },
      married: {
        rates: 8.75,
        brackets: 259500,
        total: 1000000000,
      },
    },
  ],
  Virginia: [
    {
      single: {
        rates: 2.0,
        brackets: -1,
        total: 2999,
      },
      married: {
        rates: 2.0,
        brackets: -1,
        total: 2999,
      },
    },
    {
      single: {
        rates: 3.0,
        brackets: 2999,
        total: 4999,
      },
      married: {
        rates: 3.0,
        brackets: 2999,
        total: 4999,
      },
    },
    {
      single: {
        rates: 5.0,
        brackets: 4999,
        total: 16999,
      },
      married: {
        rates: 5.0,
        brackets: 4999,
        total: 16999,
      },
    },
    {
      single: {
        rates: 5.75,
        brackets: 16999,
        total: 1000000000,
      },
      married: {
        rates: 5.75,
        brackets: 16999,
        total: 1000000000,
      },
    },
  ],
  Washington: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'West Virginia': [
    {
      single: {
        rates: 3.0,
        brackets: -1,
        total: 9999,
      },
      married: {
        rates: 3.0,
        brackets: -1,
        total: 9999,
      },
    },
    {
      single: {
        rates: 4.0,
        brackets: 9999,
        total: 24999,
      },
      married: {
        rates: 4.0,
        brackets: 9999,
        total: 24999,
      },
    },
    {
      single: {
        rates: 4.5,
        brackets: 24999,
        total: 39999,
      },
      married: {
        rates: 4.5,
        brackets: 24999,
        total: 39999,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 39999,
        total: 59999,
      },
      married: {
        rates: 6.0,
        brackets: 39999,
        total: 59999,
      },
    },
    {
      single: {
        rates: 6.5,
        brackets: 59999,
        total: 1000000000,
      },
      married: {
        rates: 6.5,
        brackets: 59999,
        total: 1000000000,
      },
    },
  ],
  Wisconsin: [
    {
      single: {
        rates: 3.54,
        brackets: -1,
        total: 13809,
      },
      married: {
        rates: 3.54,
        brackets: -1,
        total: 18419,
      },
    },
    {
      single: {
        rates: 4.65,
        brackets: 13810,
        total: 27629,
      },
      married: {
        rates: 4.65,
        brackets: 18420,
        total: 36839,
      },
    },
    {
      single: {
        rates: 5.3,
        brackets: 27630,
        total: 304169,
      },
      married: {
        rates: 5.3,
        brackets: 36840,
        total: 405549,
      },
    },
    {
      single: {
        rates: 7.65,
        brackets: 304170,
        total: 1000000000,
      },
      married: {
        rates: 7.65,
        brackets: 405550,
        total: 1000000000,
      },
    },
  ],
  Wyoming: [
    {
      single: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
      married: {
        rates: 0.0,
        brackets: -1,
        total: 1000000000,
      },
    },
  ],
  'District of Columbia': [
    {
      single: {
        rates: 4.0,
        brackets: -1,
        total: 9999,
      },
      married: {
        rates: 4.0,
        brackets: -1,
        total: 9999,
      },
    },
    {
      single: {
        rates: 6.0,
        brackets: 9999,
        total: 39999,
      },
      married: {
        rates: 6.0,
        brackets: 9999,
        total: 39999,
      },
    },
    {
      single: {
        rates: 6.5,
        brackets: 39999,
        total: 59999,
      },
      married: {
        rates: 6.5,
        brackets: 39999,
        total: 59999,
      },
    },
    {
      single: {
        rates: 8.5,
        brackets: 59999,
        total: 249999,
      },
      married: {
        rates: 8.5,
        brackets: 59999,
        total: 249999,
      },
    },
    {
      single: {
        rates: 9.25,
        brackets: 249999,
        total: 499999,
      },
      married: {
        rates: 9.25,
        brackets: 249999,
        total: 499999,
      },
    },
    {
      single: {
        rates: 9.75,
        brackets: 499999,
        total: 999999,
      },
      married: {
        rates: 9.75,
        brackets: 499999,
        total: 999999,
      },
    },
    {
      single: {
        rates: 10.75,
        brackets: 999999,
        total: 1000000000,
      },
      married: {
        rates: 10.75,
        brackets: 999999,
        total: 1000000000,
      },
    },
  ],
};

export const US_STATES: string[] = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];
