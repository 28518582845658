import { AppBar, Box, Button, Card, CardActionArea, CardContent,
  Dialog, DialogContent, Grid, Tab, Tabs,
  Toolbar, Typography } from "@mui/material"
import { useForm, useFormState } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import CancelIcon from '@mui/icons-material/Cancel';
import { GridAddIcon } from "@mui/x-data-grid"
import { Fragment, useState, useEffect } from "react"
import { OnChange } from "react-final-form-listeners";
import { PlanBenefit } from "../../../../interfaces";

export const OrgConfigurationsEditor = () => {
  const [editing, setEditing] = useState(false)
  const [currentConfig, setCurrentConfig] = useState<PlanBenefit>(null)
  const [displayValues, setDisplayValues] = useState([])
  const [currentTab, setCurrentTab] = useState(0);
  const form = useForm()
  const formState = useFormState()

  const onSelect = (t: number) => {
      setEditing(true)
      setCurrentConfig(formState.values.configurations[t])
  }

  useEffect(() => {
    if (!currentConfig || !currentConfig.retirementBenefit) return;

    const { retirementBenefit,
      sick_leave,
      final_comp_months,
      holiday_leave,
      vacation_leave,
    } = currentConfig;
    const { retirement_system,
      hire_eligibility,
      max_percent,
      level,
      type,
      scaled,
      benefit_type,
      social_security,
      early_reduction,
    } = retirementBenefit;

    setDisplayValues([
      {title: 'Retirement System', value: retirement_system.name},
      {title: 'Benefit Formula', value: retirementBenefit.name},
      {title: 'Final Compensation Period',
        value: `${final_comp_months || retirementBenefit.final_comp_months} month(s)`
      },
      {title: 'Hire Start',
        value: hire_eligibility.start || 'None'
      },
      {title: 'Hire End',
        value: hire_eligibility.end || 'None'
      },
      {title: 'Max Percent',
        value: `${max_percent}%`
      },
      {title: 'Sick Leave', value: sick_leave ? 'Yes' : 'No'},
      {title: 'Holiday Leave', value: holiday_leave ? 'Yes' : 'No'},
      {title: 'Vacation Leave', value: vacation_leave ? 'Yes' : 'No'},
      {title: 'Level',
        value: level
      },
      {title: 'Type',
        value: type
      },
      {title: 'Scaled',
        value: scaled ? 'Yes' : 'No'
      },
      {title: 'Early Reduction',
        value: early_reduction ? 'Yes' : 'No'
      },
      {title: 'Benefit Type',
        value: benefit_type
      },
      {title: 'Social Security',
        value: social_security ? 'Yes' : 'No'
      },
    ])
  }, [currentConfig])

  const onClose = (event: Event) => {
      if (currentConfig && (!currentConfig.retirementBenefit.name)) {
          event && event.preventDefault()
          return
      }

      setEditing(false)
      setCurrentConfig(null)
      setCurrentTab(0)
  }

  return (
    <>
      <Box sx={{ px: 3, py: 3, mb: 2 }}>
        <Grid container spacing={3}>
          <FieldArray name="configurations" validate={(value: PlanBenefit[]) => (value.length === 0) ? "Please enter at least one configuration." : undefined}>
            {({fields}) => (
              <Fragment>
                <OnChange name="coverageType">
                  {(newType) => {
                    fields.forEach((name) => {
                      form.mutators.setValue(`${name}.coverageType`, newType)
                    })
                  }}
                </OnChange>
                {
                  fields.map((name, index) => {
                    const config = formState.values.configurations[index]
                    const { retirementBenefit } = config;
                    const { retirement_system } = retirementBenefit;

                    return (
                      <Grid item xs={3} key={index}>
                        <Card variant="outlined" style={{ textAlign: "center" }}>
                          <CardActionArea onClick={() => onSelect(index)}>
                            <CardContent>
                              <Typography variant="h5" align="center" component="div">
                                { retirementBenefit.name}
                              </Typography>
                              <Typography variant="subtitle1">
                                { retirement_system.short_name }
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                              <CancelIcon
                                style={{ color: 'purple', cursor: 'pointer' }}
                                onClick={() => fields.remove(index)}
                              />
                        </Card>
                      </Grid>
                    )
                  })
                }
                <Grid item xs={3}>
                  <Button
                      variant="contained"
                      fullWidth
                      disableElevation
                      size="large"
                      color="primary"
                      style={{ height: "100%", minHeight: "148px"}}
                      // onClick={() => fields.push(newConfig(fields.length, formState.values.coverageType))}
                  >
                    <GridAddIcon fontSize="large" />
                  </Button>
                </Grid>
              </Fragment>
            )}
          </FieldArray>
        </Grid>
      </Box>
      <Dialog
          fullWidth
          maxWidth="md"
          open={editing}
          onClose={onClose}
          scroll="paper"
      >
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6">
                  View Configuration - {currentConfig?.retirementBenefit.name}
                </Typography>
                <CancelIcon
                  onClick={() => onClose(null)}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                />
            </Toolbar>
            <Tabs
              sx={{ backgroundColor: 'white' }}
              value={currentTab}
              onChange={(e, value: number) => setCurrentTab(value)}
            >
              <Tab label="Configuration" value={0} />
              <Tab label="Benefit Factors" value={1} />
            </Tabs>
        </AppBar>
        <DialogContent>
            <Box sx={{ px: 3, py: 3, mb: 2 }}>
            {currentTab === 0 &&
              <>
                <Grid container spacing={2}>
                  {displayValues.map((d, i) =>
                    <Fragment key={i}>
                      <Grid item xs={5}>
                      <Typography variant="subtitle2">{d.title}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        {d.value}
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </>
            }
            </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}