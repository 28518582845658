/* Custom utilities to check type of browser, device settings,
and screenwidth 
*/

// Detects if device is a mobile iOS device
export const isMobileIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

// Detects if device is in standalone mode
export const isInStandaloneMode = window.navigator?.standalone;

export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

// Returns boolean if device is a mobile iOS device on Safari
export const checkMobileIosBrowser = () => {
  if (isMobileIos() && !isInStandaloneMode && isSafari) {
    return true;
  }

  return false;
}

// Initialize deferredPrompt for use later to show browser install prompt.
export const notifyUserInstall = () => {
  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (e: any) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can add to home screen
    // btnAdd.style.display = 'block';
  });
}