import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMonthlyPensionData } from '../../../../helpers/OverviewHelpers';
import { GraphDataPoint, SickLeaveData, ProfileData } from '../../../../interfaces';
import { selectProfileData, selectSickLeaveCredit, selectSickLeaveMonthlyIncrease, selectTimePeriod } from '../../../../store/profile/profileSlice';
import { TimePeriodToggle } from "../TimePeriodToggle";
import './OverviewGraph.css';

type Props = {
    graphId?: string
}

export const OverviewGraph = ({ graphId = "" }: Props) => {
  const profileData = useSelector(selectProfileData)
  const [graphData, setGraphData] = useState<GraphDataPoint[]>([])
  const [addSickLeave, setAddSickLeave] = useState(profileData.sick_leave_credit)
  const sickLeaveIncrease = useSelector(selectSickLeaveMonthlyIncrease) as SickLeaveData
  const timePeriod = useSelector(selectTimePeriod);

    useEffect(() => {
        am4core.addLicense("CH272102831");
        let chart = am4core.create(`monthlychartdiv${graphId}`, am4charts.XYChart)

        chart.colors.step = 2;

        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'name'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
        yAxis.numberFormatter.numberFormat = '$#,###'

        var retirementIndex = 0

        function createSeries(value: string, name: string) {
            let series = chart.series.push(new am4charts.StepLineSeries())
            series.hiddenState.transitionDuration = 0
            series.defaultState.transitionDuration = 0
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'name'
            series.name = name
            series.fillOpacity = 1
            series.strokeWidth = 2
            series.cursorTooltipEnabled = true
            series.tooltip.pointerOrientation = "vertical";
            series.tooltip.background.fillOpacity = 0.5;
            series.tooltip.label.padding(12,12,12,12)
            // eslint-disable-next-line
            series.tooltipText = "{name}: {valueY}";

            // gray out data before the retirement index
            series.segments.template.adapter.add("fillOpacity", function(fill, target) {
                if (target.dataItem.index < retirementIndex) { return 0.01 }
            });

            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);
        
            return series;
        }

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = xAxis;

        const timePeriodText = timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1)
        createSeries('gross',  timePeriodText + ' Gross Pension ($)');
        createSeries('net', timePeriodText + ' Net Pension ($)');
        createSeries('early', timePeriodText + ' Early Reduction ($)');

        chart.legend = new am4charts.Legend()
        chart.legend.position = 'top'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 95
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.opacity = 1;

        function arrangeColumns() {

            let series = chart.series.getIndex(0);
        
            let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                let delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    let middle = chart.series.length / 2;
        
                    let newIndex = 0;
                    chart.series.each(function(series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    let visibleCount = newIndex;
                    let newMiddle = visibleCount / 2;
        
                    chart.series.each(function(series) {
                        let trueIndex = chart.series.indexOf(series);
                        let newIndex = series.dummyData;
        
                        let dx = (newIndex - trueIndex + middle - newMiddle) * delta
        
                        series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    })
                }
            }
        }

        chart.data = graphData
    }, [graphData, profileData, timePeriod, graphId])

    useEffect(() => {
        if (!profileData.monthly_data) { return }
        setGraphData(getMonthlyPensionData(profileData, addSickLeave, sickLeaveIncrease, timePeriod))
    }, [profileData, addSickLeave, sickLeaveIncrease, timePeriod])

    if (!graphData || !profileData?.desired_retirement_date) {
        return (
            <div className="overview-graph-container">
            </div>
        )
    }

    return (
        <div 
            className="widget-container widget-container-full" 
        >
            <div className="widget-header-container">
                <div className="widget-title">View Your Pension Growth</div>
                <div className="widget-configuration-button-container">
                    <div className="sick-leave-switch" style={{ marginRight: "10px" }}>
                        <p>Sick Leave</p>
                        <Switch
                            checked={addSickLeave}
                            color="primary"
                            name="checkedA"
                            onChange={ e => setAddSickLeave(e.target.checked)}
                        />
                    </div>
                    <TimePeriodToggle />
                </div>
            </div>
            <div id={`monthlychartdiv${graphId}`} style={{ height: "500px" }}></div>
        </div>
    )
}