import axios from "axios";
import {
  apiDefaultOptions,
  API_ROUTE,
  getBackendUrlForPath,
} from "../helpers/APIHelpers";

export const createSubscription = async (product: string) => {
  const body = {
    product,
  };
  try {
    const response = await axios.post(
      getBackendUrlForPath(API_ROUTE.SUBSCRIPTION_CREATE),
      body,
      apiDefaultOptions
    );
    return response.data;
  } catch {
    throw new Error("Could not save your user data. Please try again.");
  }
};

export const getStripeCheckoutSession = async (sessionId: string) => {
  try {
    const response = await axios.get(
      getBackendUrlForPath(`${API_ROUTE.SUBSCRIPTION_GET_SESSION}/${sessionId}`),
      apiDefaultOptions
    );
    return response.data;
  } catch {
    return null;
  }
};

export const renewSubscription = async (product: string) => {
  const body = {
    product,
  };
  try {
    const response = await axios.post(
      getBackendUrlForPath(API_ROUTE.SUBSCRIPTION_RENEW),
      body,
      apiDefaultOptions
    );
    return response.data;
  } catch {
    throw new Error("Could not renew subscription. Please try again.");
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(API_ROUTE.SUBSCRIPTION_CANCEL),
      {},
      apiDefaultOptions,
    );
    return response.data;
  } catch {
    throw new Error("Could not cancel subscription. Please try again.");
  }
};

export const addWhitelistPromo = async (promoCode: string) => {
  try {
    const response = await axios.post(
      getBackendUrlForPath(API_ROUTE.SUBSCRIPTION_WHITELIST_PROMO),
      { promoCode },
      apiDefaultOptions,
    );
    return response.data;
  } catch {
    throw new Error("Could not cancel subscription. Please try again.");
  }
};
