import {
  Grid,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogTitle,
  Box,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SelectChangeEvent } from "@mui/material/Select";
import { Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import arrayMutators from "final-form-arrays";

import { ProfileData, SurveyQuestion, AllPlans, NewOrganization } from "../../../../interfaces";
import { selectFirstName, selectLastName, selectSubscriptionStatus, selectUserEmail } from "../../../../store/auth/authSlice";
import { selectProfileData } from '../../../../store/profile/profileSlice'
import { getAllQuestions, postAnswers } from "../../../../api/SurveyAPI";
import { getQuestionComponent } from "../../../../helpers/EditDataHelpers";
import { AddNewPension } from "./AddNewPension";
import { ModifyOldPension } from "./ModifyOldPension";
import { openZendesk } from "../../menu/Menu";
import cx from "classnames";
import "./EditMyData.css";

type CategoryItem = {
  name: string;
  list: SurveyQuestion[];
};

type Categories = {
  [key: string]: CategoryItem;
};

type SecondRetirement = {
  id?: number,
  socialSecurity?: boolean,
  retirementBenefitId: number,
  orgId: number,
  serviceYears: number,
  reciprocity: boolean,
};

type Props = {
  open: boolean;
  onClose: () => void;
  isMobile: boolean;
};

const EditMyData = ({ open, onClose, isMobile }: Props) => {
  const profileData = useSelector(selectProfileData) as ProfileData
  const isSubscribed = useSelector(selectSubscriptionStatus);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const fullName = `${firstName} ${lastName}`
  const userEmail = useSelector(selectUserEmail);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [categories, setCategories] = useState<Categories>({});
  const [openCategory, setOpenCategory] = useState("");
  const [allPlans, setAllPlans] = useState<AllPlans>({});
  const [stateOrgs, setStateOrgs] = useState<NewOrganization[]>([]);
  const [removedConfigs, setRemovedConfigs] = useState([]);
  const [updatedConfigs, setUpdatedConfigs] = useState([]);
  const [addConfigs, setAddConfigs] = useState<SecondRetirement[]>([]);

  const handleSubmit = async (values: { [key: string]: any }, api: any) => {
    setLoading(true);
    const dirtyFields = api.getState().dirtyFields;
    const payload: { [key: string]: any } = {};
    for (const key in dirtyFields) {
      payload[key] = values[key];
    }
    if (updatedConfigs.length > 0) {
      payload["secondRetirement"] = updatedConfigs;
    }
    if (removedConfigs.length > 0) {
      payload["removedSecond"] = removedConfigs;
    }
    await postAnswers(payload);
    onClose();
    window.location.reload();
  };

  const getQuestions = async () => {
    const {
      orderedQuestions,
      allUserVariables,
      additionalConfigurations,
      availablePlans,
      allStateOrgs,
    } = await getAllQuestions();
    const categories = orderedQuestions.reduce(
      (acc: Categories, question: SurveyQuestion) => {
        const { category } = question;
        const { text } = category;
        return {
          ...acc,
          [text]: {
            name: text,
            list: [...(acc[text]?.list || []), question],
          },
        };
      },
      {}
    );
    setAllPlans(availablePlans);
    setStateOrgs(allStateOrgs);
    setAddConfigs(additionalConfigurations);
    setCategories(categories);
    setInitialValues(allUserVariables);
    setLoading(false);
  };

  const handleAddSubmit = (values: { [key: string]: any }) => {
    // find if it's a new config that has been added or previously modified
    // old pension
    const configIndex = updatedConfigs.findIndex(
      (config) => (config.id && config.id === values.id) ||
      (config.retirementBenefitId && config.retirementBenefitId === values.retirementBenefitId)
      );
    const previousIndex = addConfigs.findIndex(
      (config) => (config.id && config.id === values.id &&
        (Math.floor(config.serviceYears * 10000) / 10000 !== Math.floor(values.serviceYears * 10000) / 10000  ||
        (values.socialSecurity !== undefined && config.socialSecurity !== values.socialSecurity) ||
        (values.reciprocity !== undefined && config.reciprocity !== values?.reciprocity)))
    );
    if (configIndex > -1) {
      const newConfig = { ...updatedConfigs[configIndex], ...values };
      const newConfigs = updatedConfigs;
      updatedConfigs[configIndex] = newConfig;
      setUpdatedConfigs(newConfigs);
    } else if (previousIndex > -1 || (!values.id && values.retirementBenefitId)) {
      setUpdatedConfigs([...updatedConfigs, values]);
    }
  };

  const handleRemovePension = (id: number) => {
    const remainingConfigs = addConfigs.filter((config) => config.id !== id);
    setAddConfigs(remainingConfigs);
    setRemovedConfigs([...removedConfigs, id]);
  };

  useEffect(() => {
    setLoading(true);
    getQuestions();
  }, []);

  if (loading) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      keepMounted
      onClose={onClose}
      className="forecasting-background-container"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="forecasting-header">
          Customize my data
          <CloseIcon onClick={onClose} />
      </DialogTitle>
      <Box
        px={3}
        py={2}
        className="forecasting-container"
        sx={{
          width: { xs: '100vw', sm: '40vw' }
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="edit-data-category">Category</InputLabel>
          <Select
            value={openCategory}
            onChange={(event: SelectChangeEvent) =>
              setOpenCategory(event.target.value as string)
            }
          >
            {["ALL", ...Object.keys(categories)].map((c, ind) => (
              <MenuItem key={ind} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, form }) => (
            <form id="edit-data" onSubmit={handleSubmit} noValidate>
              <Grid>
                {Object.values(categories).map((category, i: number) => {
                  const isActive = category.name === openCategory || openCategory === "ALL";
                  return (
                    <React.Fragment key={`${i}-${category.name}`}>
                      <div>
                        {category.name !== "Additional Retirement" &&
                          category.list.map((question) => (
                            <Grid
                              key={question.variable.name}
                              className={cx(
                                {
                                  "show-section": isActive,
                                  "hide-section": !isActive,
                                },
                                "question-box"
                              )}
                            >
                              <div>{question.text}</div>
                              {getQuestionComponent(question, form)}
                            </Grid>
                          ))}
                        {category.name === "Additional Retirement" && (
                          <div
                            className={cx(
                              {
                                "show-section": isActive,
                                "hide-section": !isActive,
                              },
                              "question-box"
                            )}
                          >
                            <div>
                              Would you like to add or edit your second
                              retirement data?
                            </div>
                            {addConfigs.map((pension, i) => (
                              <ModifyOldPension
                                key={`${i}-pensionid${pension.id}`}
                                profileData={profileData}
                                previousPension={pension}
                                handleAddSubmit={handleAddSubmit}
                                handleRemovePension={handleRemovePension}
                              />
                            ))}
                            <AddNewPension
                              profileData={profileData}
                              stateOrgs={stateOrgs}
                              allPlans={allPlans}
                              handleAddSubmit={handleAddSubmit}
                            />
                            <div
                              onClick={() => openZendesk(userEmail, fullName)}
                              style={{ cursor: "pointer" }}
                            >
                              Can't find your plan?{" "}
                              <span className="progress-bar-link">
                                Click here
                              </span>{" "}
                              to message us to add it.
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </form>
          )}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-data"
          variant="contained"
          disabled={!isSubscribed}
        >
          Save
        </Button>
      </DialogActions>
    </Drawer>
  );
};

export default EditMyData;
