import './AutoCompleteOption.css'
import { SurveyAnswerOption } from '../../../interfaces'

type Props = {
    answerOption: SurveyAnswerOption,
    isActive: boolean,
    onClick: (text: string) => void
}

const AutoCompleteOption = ({ answerOption, isActive, onClick }: Props) => {
    return (
        <button type="button" className={"auto-complete-option" + (isActive ? ' active' : '')} onClick={ e => onClick(answerOption.text) }>
            { answerOption.text }
        </button>
    )
}

export default AutoCompleteOption