import { AppBar,Box, Grid, Paper, Tab, Tabs, 
  TextField, Toolbar, Typography, CircularProgress,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useEffect, useState, useCallback } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form, Field, FormSpy } from 'react-final-form'
import { useDispatch } from "react-redux"

import { SuperAdminHeader } from "../header/SuperAdminHeader"
import { OrgConfigurationsEditor } from "../shared/org-configs/OrgConfigurationsEditor"
import { required } from "../../../helpers/ValidationHelpers"
import "../onboard/SuperAdminOnboard.css"
import { SuperAdminApi } from "../../../api/SuperAdminAPI"
import { getOrganization } from "../../../api/OrganizationAPI"
import { setAlert } from "../../../store/alert/alertSlice"
import { AlertTypes, NewOrganization, PlanBenefit, BasicForm } from "../../../interfaces"
import { OrgDefaultsEditor } from "./OrgDefaultsEditor"
import { SignUpLink } from "../sign-up-link/SignUpLink"
import { AllUsers } from "../all-users/AllUsers"

type Params = {
    id: string,
}


export const SuperAdminOrgEdit = () => {

  const { id: orgId } = useParams<Params>()

  const [currentTab, setCurrentTab] = useState(0);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const dispatch = useDispatch()

  const tabInfo = [
    {num: 1, component: <OrgConfigurationsEditor />},
    {num: 2, component: <OrgDefaultsEditor />},
    {num: 3, component: <SignUpLink />},
    {num: 4, component: <AllUsers users={usersList} />},
  ]

  const orgInfo = [
    {key: 'state_name'},
    {key: 'org_type'},
    {key: 'county_fips'},
    {key: 'lea_code'},
  ]

  const setupForm = useCallback(async () => {
    const org: NewOrganization = await getOrganization(parseInt(orgId))

    setCurrentOrg(org)
    setUsersList(org.users)

    const retirementSystem = org.orgDetails.find(d => d.name === 'retirement_system')

    const configurations: PlanBenefit[] = org.plan_specific_benefits.map(c => ({
      ...c
    }))

    const initial: BasicForm = {
      orgName: org.display_name,
      retirementSystem: retirementSystem?.value ?? "",
      configurations,
      defaults: org.orgDetails,
    }

    setInitialValues(initial)
    setLoaded(true)
  }, [orgId])

  useEffect(() => {
      setupForm()
  }, [orgId, setupForm])

  useEffect(() => {
      return function cleanup() {
          window.onbeforeunload = undefined
      }
  }, [])

  const saveChanges = useCallback((async (formData: BasicForm) => {
      setLoaded(false)
      try {
          await SuperAdminApi.editOrganizationData(parseFloat(orgId), formData)
          dispatch(setAlert({
            text: "Organization successfully updated.",
            type: AlertTypes.Success
          }))
          setupForm()
      } catch {
          dispatch(setAlert({
            text: "Could not update organization. Please try again.",
            type: AlertTypes.Error
          }))
          setLoaded(true)
      }
  }), [orgId, setupForm])

  if (!loaded) return <div className="super-admin-page-background">
      <div className="super-admin-page-container" style={{ paddingBottom: "30px" }}>
          <SuperAdminHeader />
          <div>
          <Box sx={{ width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CircularProgress />
          </Box>
          </div>
      </div>
  </div>

  return (
      <Form
          validateOnBlur={true}
          onSubmit={(v) => saveChanges(v)}
          initialValues={initialValues}
          mutators={{
              ...arrayMutators,
              setValue: ([field, value, skipTouch], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                  const fieldState = state.fields[field]
                  if (fieldState && !skipTouch) {
                      fieldState.touched = true
                      fieldState.modified = true
                  }
              },
          }}
          subscription={{submitting: true, pristine: true}}
      >
      {({handleSubmit}) => (
        <>
          <FormSpy
              subscription={{ dirty: true }}
              onChange={state => {
                  if (state.dirty) { window.onbeforeunload = () => true }
                  else { window.onbeforeunload = undefined }
              }}
          />
          <div className="super-admin-page-background">
              <div className="super-admin-page-container" style={{ paddingBottom: "30px" }}>
                <SuperAdminHeader />
                  <Paper
                    elevation={3}
                    style={{ overflow: "hidden",
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <AppBar position="relative">
                        <Toolbar variant="dense">
                            <Typography variant="h6" component="div">
                                Editing Organization - { currentOrg.display_name }
                            </Typography>
                        </Toolbar>
                        <Tabs
                          value={currentTab}
                          onChange={(e, value: number) => setCurrentTab(value)}
                          style = {{ backgroundColor: '#FFF' }}
                        >
                            <Tab label="Organization Data" value={0} />
                            <Tab label="Configurations" value={1} />
                            <Tab label="Defaults" value={2} />
                            <Tab label="Signup Link" value={3} />
                            <Tab label="All Users" value={4} />
                        </Tabs>
                    </AppBar>
                    <Box sx={{ display: currentTab === 0 ? "block" : "none", pt: 2 }}>
                        <Box sx={{ px: 3, py: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Field name="orgName" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            fullWidth variant="outlined" label="Organization Name"
                                            inputProps={input}
                                            error={meta.error && meta.touched}
                                            helperText={(meta.touched ? meta.error : null) ?? " "}
                                        />
                                    )}
                                    </Field>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="domain">
                                    {({ input, meta }) => (
                                        <TextField
                                            fullWidth variant="outlined" label="Domain"
                                            inputProps={input}
                                            error={meta.error && meta.touched}
                                            helperText={(meta.touched ? meta.error : null) ?? " "}
                                        />
                                    )}
                                    </Field>
                                </Grid>
                                {orgInfo.map((info, index) =>
                                  <Grid item xs={6} key={index}>
                                    {`${ [info.key] } ${ currentOrg?.[info.key] || 'None' }`}
                                  </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                    {tabInfo.map((tab, i) => (
                      <Box
                        key={i}
                        sx={{ display: currentTab === tab.num ? "block" : "none",
                          pt: 2,
                          height: 0,
                          flex: 1,
                          overflowY: 'scroll'
                        }}
                      >
                        {tab.component}
                      </Box>
                    ))}
                  </Paper>
              </div>
          </div>
        </>
      )}
      </Form>
  )
}