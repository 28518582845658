import { MenuItem, Select } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { memoize } from 'lodash';
import dayjs from 'dayjs';

import { selectMonthlyKeys, selectWorkingDate, setWorkingDate } from '../../../store/profile/profileSlice';

export const getTickerDate = memoize((workingDate: Date) => {
    return `${workingDate.toLocaleString('default', { month: 'short' })} ${workingDate.getFullYear()}`
}, (d) => d.getTime())


export const DateSelect = () => {
  const currentDate = dayjs(useSelector(selectWorkingDate)).format('M/D/YYYY');
  const monthlyKeys = useSelector(selectMonthlyKeys);
  const dispatch = useDispatch();

    return (
        <Select
            value={currentDate}
            onChange={ (e) => dispatch(setWorkingDate(e.target.value))}
            variant="standard"
            className="widget-configuration-button"
            autoWidth
        >
        {
            monthlyKeys.map((k: string, index: number) => (
                <MenuItem
                    key={k}
                    value={k}
                    className="widget-header-select"
                >
                    { getTickerDate(new Date(k)) }
                </MenuItem>
            ))
        }
        </Select>
    )
}