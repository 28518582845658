import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from "react-redux"
import { ForecastChange, ForecastingFormData, FORECASTING_OPTIONS } from "../../../../../interfaces"
import { selectForecastProfile, setForecastFormData } from "../../../../../store/forecast/forecastSlice"
import './ForecastingChanges.css'

type Props = {
    data: ForecastingFormData,
    handleActivate: () => void,
    handleDisable: () => void
}

export const ForecastingChanges = ({ data, handleActivate, handleDisable }: Props) => {
    const forecastProfile = useSelector(selectForecastProfile)
    const dispatch = useDispatch()

    const handleRevertForChange = (change: ForecastChange) => {
        dispatch(setForecastFormData({
            ...data,
            [change.dataKey]: {
                ...data[change.dataKey],
                textfieldValue: null,
                savedValue: null
            }
        }))
    }

    const getChanges = () => {
        if (data === undefined || data === null) { return [] }
        const changes: ForecastChange[] = []
        for (const [key, value] of Object.entries(data)) {
            if (value.savedValue !== null) {
                var beforeVal = value.profileValue
                var afterVal = value.savedValue

                // specific change for purchased service time
                if (key === "purchased_service_time") {
                    beforeVal = `${value.profileValue[0]} mos / ${value.profileValue[1]} yrs`
                    afterVal = `${value.savedValue[0]} mos / ${value.savedValue[1]} yrs`
                }

                changes.push({
                    beforeValue: beforeVal,
                    afterValue: afterVal,
                    title: value.changeTitle,
                    dataKey: key as keyof ForecastingFormData,
                    decorator: FORECASTING_OPTIONS.find((o) => o.dataKey === key).preFieldDecorator
                })
            }
        }
        return changes
    }

    return (
        <>
            {
                getChanges().length > 0 &&
                <div>
                    <Divider />
                    <h3>My Changes</h3>
                </div>
            }
            {
                getChanges().map((c, index) => (
                    <div className="forecasting-change-container" key={index.toString()}>
                        <h4>{ c.title.toUpperCase() }</h4> 
                        <div className="forecasting-change-before-after">
                            <p>Before: {c.beforeValue} {c.decorator ? `(${c.decorator})` : ""}</p>
                            <p>After: {c.afterValue} {c.decorator ? `(${c.decorator})` : ""}</p>
                        </div>
                        <div className="forecasting-change-delete">
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                onClick={ () => handleRevertForChange(c) }
                            />
                        </div>
                    </div>
                ))
            }
            {
                getChanges().length > 0 &&
                <button
                    className="forecasting-activate-button"
                    onClick={ handleActivate }
                >
                    Activate Changes
                </button>
            }
            {
                forecastProfile !== null &&
                <div 
                    className="forecasting-disable-button"
                    onClick={handleDisable}
                >
                    Disable Forecasting
                </div>
            }
        </>
    )
}