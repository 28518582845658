import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDivisor, formatNumbers } from '../../../utils/numberDateFormatters';
import { PensionDataTypes, PreviousPensions, PreviousValues } from '../../../interfaces';
import { selectSubscriptionStatus } from '../../../store/auth/authSlice';
import { selectPensionType, selectTimePeriod, selectNumbersData, selectTaxWithholdings } from '../../../store/profile/profileSlice';
import { MainOrgDetails } from './MainOrgDetails';
import { PreviousOrgDetails } from './PreviousOrgDetails';
import { DateSelect } from './DateSelect';
import { GrossNetToggle } from './GrossNetToggle';
import { TimePeriodToggle } from './TimePeriodToggle';

interface StatusValues {
    yearValue: string,
    pensionPercentage: number,
    maxPercentage: number,
    currentPensionPercentage: number,
    divorcePercentage: number,
    currentPensionGross: string,
    currentPensionNet: string,
    currentVacationCashout: number,
    currentHolidayCashout: number,
    serviceYears: number,
    ePMCPercentage: number,
    currentBenefitFactor: number,
    isEarlyReduction: boolean,
    earlyReductionFactor: number,
    previousPensions?: PreviousPensions,
    previousPensionPercentage: number,
    yearlyPay: string,
    pensionablePay: string,
    age: number,
    benefit_percentage: number,
    grossPension: string,
    grossPensionNumber: number,
    finalCompensation: string,
    isSocialSecurity: boolean,
}

type Props = {
    isCurrent?: boolean
    isMobile: boolean
}

export const OverviewBreakdown = ({ isMobile }: Props) => {
    const timePeriod = useSelector(selectTimePeriod);
    const pensionType = useSelector(selectPensionType);
    const numbersData = useSelector(selectNumbersData);
    const taxWithholdings = useSelector(selectTaxWithholdings);
    const [statusValues, setStatusValues] = useState<StatusValues>(null)
    const isSubscribed = useSelector(selectSubscriptionStatus);

    useEffect(() => {
      if (!numbersData) return

      const setData = () => {
        let data = numbersData;
        const isGrossNumber = pensionType === PensionDataTypes.Gross
        const divisor = getDivisor(timePeriod)

        setStatusValues({
            age: data.age,
            benefit_percentage: data.benefit_percentage,
            maxPercentage: data.max_percentage,
            yearlyPay: isGrossNumber
                ? formatNumbers(data.yearly_gross / divisor, isSubscribed)
                : formatNumbers(data.yearly_net / divisor, isSubscribed),
            pensionablePay: formatNumbers(data.yearly_pensionable / divisor, isSubscribed),
            currentVacationCashout: Math.round(data.currentVacationCashout * 100) / 100,
            currentHolidayCashout: Math.round(data.currentHolidayCashout * 100) / 100,
            currentBenefitFactor: data.currentBenefitFactor,
            currentPensionGross: formatNumbers(data.currentPensionGross, isSubscribed),
            currentPensionNet: formatNumbers(data.currentPensionNet, isSubscribed),
            isEarlyReduction: data.isEarlyReduction,
            earlyReductionFactor: data.earlyReductionFactor,
            serviceYears: data.serviceYears,
            ePMCPercentage: data.ePMCPercentage,
            previousPensions: data.previousPensions,
            pensionPercentage: data.pension_percentage,
            divorcePercentage: data.divorce_percent,
            currentPensionPercentage: data.current_pension_percentage,
            previousPensionPercentage: data.previous_pension_percentage,
            yearValue: (data.service_time.toLocaleString(undefined, { minimumFractionDigits: 2 })),
            grossPension: isGrossNumber
                ? formatNumbers(data.yearly_gross_pension / divisor, isSubscribed)
                : formatNumbers(data.yearly_net_pension / divisor, isSubscribed),
            grossPensionNumber: isGrossNumber
                ? (data.yearly_gross_pension / divisor)
                : (data.yearly_net_pension / divisor),
            finalCompensation: formatNumbers(data.finalCompensation / divisor, isSubscribed),
            isSocialSecurity: data.isSocialSecurity,
        })
      }

      setData()
    }, [timePeriod, numbersData, pensionType])

    if (!statusValues) return <div></div>

    return (
        <div
            className="widget-container summary-widget-container"
        >
            <div className="breakdown-header">
                Total Pension Amount: ${statusValues.grossPension}
                <p />
                See below for details by organization
            </div>
            <div className="widget-header-container">
               <div className="widget-title">
                    Numbers Breakdown
                </div>
                <div className="widget-configuration-button-container">
                    <DateSelect />
                    <GrossNetToggle />
                    <TimePeriodToggle />
                </div>
            </div>
            <div className="summary-section-container">
                <MainOrgDetails
                    isMobile={isMobile}
                    statusValues={statusValues}
                />
                {statusValues.previousPensions.addDetails.map((org: PreviousValues, i: number) => (
                    <PreviousOrgDetails
                        key={i}
                        isMobile={isMobile}
                        values={org}
                        isSubscribed={isSubscribed}
                        taxWithholdings={taxWithholdings}
                    />
                ))}
            </div>
        </div>
    )
}