import { createSlice, createAsyncThunk, Reducer } from "@reduxjs/toolkit"
import type { RootState } from "../storeTypes"
import { SurveyQuestion, SurveyResult, ServiceStatusValues, SurveyResults, PreSurveyProgress, CurrentQuestionData, PreSurveyAPIData } from "../../interfaces"
import { getSurvey } from "../../api/SurveyAPI";
import { getPreviousSurveyResults, sortSurveyQuestions } from "../../helpers/SurveyHelpers";
import { HIRE_DATE_ID, BENEFIT_QUESTION_ID, PreSurveyData } from "../../data/PreSurveyData";
import axios from "axios";
import { getBackendUrlForPath, API_ROUTE } from "../../helpers";
import { SurveyAPIData } from "../../interfaces";

interface SurveySlice {
  status: ServiceStatusValues,
  error: string | null,
  surveyData: SurveyQuestion[],
  currentQuestionData: CurrentQuestionData,
  surveyResults: SurveyResults,
  showLoading: boolean,
}

// Define the initial state using that type
const initialState: SurveySlice = {
  status: ServiceStatusValues.Loading,
  error: null,
  surveyData: [],
  currentQuestionData: null,
  surveyResults: {},
  showLoading: false,
}

interface SurveyThunkArg {
  preSurveyProgress: PreSurveyProgress;
  startFromBeginning: boolean;
}

export const loadSurveyData = createAsyncThunk(
  "survey/fetchSurvey",
  async (payload: SurveyThunkArg) => {
    try {
      const response = await getSurvey(payload.preSurveyProgress, true);
      // const { preSurveyProgress } = payload;
      // const config = { withCredentials: true }
      // const response = await axios.get(getBackendUrlForPath(API_ROUTE.GET_SURVEY), config)

      // const mainSurvey = { ...response.data.finalSurvey } as SurveyAPIData

      // mainSurvey.questions = PreSurveyData.questions.concat(mainSurvey.questions)

      // const benefitIndex = mainSurvey.questions.findIndex(s => s.id === BENEFIT_QUESTION_ID);
      // mainSurvey.questions[benefitIndex].answerOptions = response.data.configurations;

      // return { mainSurvey, preSurveyProgress};
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    updateSurvey(state, action) {
      const { mainSurvey, preSurveyProgress } = action.payload;
      const previousResults: SurveyResults = getPreviousSurveyResults(mainSurvey, preSurveyProgress)
      const currentQuestion = {
        index: 0,
        question: mainSurvey.questions[0],
      }
      console.log(mainSurvey)
      state.status = ServiceStatusValues.Loaded;
      state.surveyData = mainSurvey.questions;
      state.currentQuestionData = currentQuestion;
      state.surveyResults = previousResults;
      state.showLoading = false;
    },
    updateResult(state, action) {
      state.status = ServiceStatusValues.Loaded;
      state.surveyResults[state.currentQuestionData.question.questionId] = action.payload;
    },
    updateResultById(state, action) {
      const questionId = action.payload.questionId;
      state.status = ServiceStatusValues.Loaded;
      state.surveyResults[questionId] = action.payload.result;
    },
    setSurveyLoadingAction(state, action) {
      state.status = ServiceStatusValues.Loaded;
      state.showLoading = action.payload;
    },
    setAnswerOptions(state, action) {
      const questionIndex = state.surveyData.findIndex((q) => q.questionId === BENEFIT_QUESTION_ID)
      const tempSurveyData = [...state.surveyData]
      if (questionIndex && questionIndex >= 0) {
          const updatedQuestion = { ...state.surveyData[questionIndex] }
          updatedQuestion.answerOptions = action.payload
          tempSurveyData[questionIndex] = updatedQuestion
      }
      state.status = ServiceStatusValues.Loaded;
      state.surveyData = tempSurveyData;
    },
    nextQuestion(state, action) {
      const {questionIndex} = action.payload;
      const nextQuestionIndex = questionIndex + 1;
      const nextQuestion = state.surveyData[nextQuestionIndex]

      state.status = ServiceStatusValues.Loaded;
      state.currentQuestionData = {
        question: nextQuestion,
        index: nextQuestionIndex,
      }
    },
    previousQuestion(state, action) {
      const {questionIndex} = action.payload;
      const prevQuestionIndex = questionIndex - 1;
      var prevQuestion = state.surveyData[prevQuestionIndex]
      state.currentQuestionData = {
        question: prevQuestion,
        index: prevQuestionIndex,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSurveyData.fulfilled, (state, action) => {
      // const { mainSurvey, preSurveyProgress } = action.payload;
      // const previousResults: SurveyResults = getPreviousSurveyResults(mainSurvey, preSurveyProgress)
      // const currentQuestion = {
      //   index: 0,
      //   question: mainSurvey.questions[0],
      // }

      // state.status = ServiceStatusValues.Loaded;
      // state.currentQuestionData = currentQuestion;
      // state.surveyResults = previousResults;
      // state.showLoading = false;
    });
    builder.addCase(loadSurveyData.rejected, (state, action) => {
      state.status = ServiceStatusValues.Error;
    });
  },
})

export const { updateSurvey, updateResult, updateResultById, setSurveyLoadingAction, setAnswerOptions, nextQuestion,
  previousQuestion } = surveySlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSurveyLoaded = (state: RootState) => state.survey.status === ServiceStatusValues.Loaded;

export const selectSurveyStatus = (state: RootState) => state.survey.status;

type SelectorFunction<T> = (state: RootState) => T;

const createSurveySelector = <T extends SurveyQuestion | SurveyQuestion[] | number | null>(selector: SelectorFunction<T>, shouldReturnZero: boolean): SelectorFunction<T | null> => {
  return (state: RootState) => {
      if (state.survey.status === ServiceStatusValues.Loaded) {
          return selector(state);
      }
      return shouldReturnZero ? 0 as T : null;
  };
};

const getQuestionIndex: SelectorFunction<number> = (state: RootState) => state.survey.currentQuestionData.index;
const getSurveyLength: SelectorFunction<number> = (state: RootState) => state.survey.surveyData.length;
const getSurveyData: SelectorFunction<SurveyQuestion[] | null> = (state: RootState) => state.survey.surveyData;

export const selectCurrentQuestion: SelectorFunction<SurveyQuestion | null> = (state: RootState) => state.survey.currentQuestionData?.question;
export const selectSurveyLength = createSurveySelector(getSurveyLength, true);
export const selectSurveyData = createSurveySelector(getSurveyData, false);
export const selectCurrentQuestionIndex = createSurveySelector(getQuestionIndex, true);

export const selectSurveyAnswer = (state: RootState, questionId: number): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectAnswerByQuestionKeyword = (state: RootState, keyword: string): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.includes(keyword)).questionId
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectIdByQuestionKeyword = (state: RootState, keyword: string): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.toLowerCase().includes(keyword.toLowerCase())).questionId
      return questionId
  } else {
      return null
  }
}

export const selectDateOfBirth = (state: RootState): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.includes("birth")).questionId
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectHireDate = (state: RootState): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      if (!(HIRE_DATE_ID in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[HIRE_DATE_ID].answer
  } else { return null }
}

export const selectGrossPay = (state: RootState): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.includes("gross pay"))?.questionId
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectNetPay = (state: RootState): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.includes("net pay"))?.questionId
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectFederalTaxes = (state: RootState): any => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      let questionId = state.survey.surveyData.find((q: SurveyQuestion) => q.text.includes("federal taxes"))?.questionId
      if (!(questionId in state.survey.surveyResults)) { return null }
      return state.survey.surveyResults[questionId].answer
  } else { return null }
}

export const selectCurrentQuestionResult = (state: RootState): SurveyResult => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      const qId = state.survey.currentQuestionData.question.questionId
      if (qId in state.survey.surveyResults) {
          return state.survey.surveyResults[qId]
      }
  }
  return null
}

export const selectShowLoadingForSurvey = (state: RootState): boolean => {
  if (state.survey.status === ServiceStatusValues.Loaded) {
      return state.survey.showLoading
  }
  return false
}

export default surveySlice.reducer as Reducer<typeof initialState>;