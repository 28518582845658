import { Field } from "react-final-form";
import { TextField, InputAdornment } from "@mui/material";
import { required, mustBeNumber, minValue, composeValidators } from "../../../helpers/ValidationHelpers";

const FormField = ({
  inputName,
  varType,
}: {
  inputName: string;
  varType: string;
}) => (
  <Field
    validate={composeValidators(required, mustBeNumber, minValue(0))}
    name={inputName}
    render={({ input, meta }) => (
      <>
        <TextField
          size="small"
          variant="outlined"
          color="secondary"
          value={input.value}
          sx={{ padding: "0.25rem", verticalAlign: "middle", width: "100px" }}
          InputProps={{
            inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
            ...(varType === "percent"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }
              : {}),
              ...(varType === "dollar"
              ? {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              : {}),
          }}
          onChange={input.onChange}
        />
        {meta.touched && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
      </>
    )}
  />
);

export default FormField;
