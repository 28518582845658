import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { selectProfileData } from '../../../store/profile/profileSlice';
import { DateSelect } from './DateSelect';
import { GrossNetToggle } from './GrossNetToggle';
import { TimePeriodToggle } from './TimePeriodToggle';
import { format } from 'date-fns';

type Props = {
    isCurrent?: boolean
    isMobile: boolean
}

export const OverviewAnalysis = ({ isMobile }: Props) => {
    const userProfile = useSelector(selectProfileData);
    const {
      groupByDate,
      constants,
      metadata,
      calcVars,
    } = userProfile;

    return (
        <div
            className="widget-container summary-widget-container"
        >
            <div className="widget-header-container">
               <div className="widget-title">
                    Year-by-year Analysis
                </div>
                <div className="widget-configuration-button-container">
                    <DateSelect />
                    <GrossNetToggle />
                    <TimePeriodToggle />
                </div>
            </div>
            <Box>
            {constants.map((el: string) => (
            <Box
              key={el}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                alignItems: 'center',
                paddingY: 1,
              }}
            >
              {calcVars[el]}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 1,
                  alignItems: 'center',
                  paddingY: 1,
                }}
              >
              </Box>
            </Box>
          ))}
            </Box>
          {Object.entries(calcVars).map(([varName, displayName]) => {
            if (constants.find((el: string) => el === varName)) return null;
            return (
            <Box
              key={varName}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                alignItems: 'center',
                paddingY: 1,
              }}
            >
              {displayName}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 1,
                  alignItems: 'center',
                  paddingY: 1,
                }}
              >
                {Object.entries(groupByDate).map(([date, vars]) => {
                  const currentDate = new Date(date);
                  const formattedDate = format(currentDate, 'MM/dd/yyyy');
                  return (
                    <div key={date}>
                      {vars[varName as keyof typeof vars] || 'N/A'}
                      {/* If you want to display formattedDate: */}
                      {/* <span>{formattedDate}</span> */}
                    </div>
                  );
                })}
              </Box>
            </Box>
          )})}
        </div>
    )
}