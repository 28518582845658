import { Button, TextField, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { ProfileData } from "../../../../interfaces";
import { calculateTime, convertTime } from "../../../../utils/numberDateFormatters";

type Props = {
  profileData: ProfileData
  previousPension: { [key: string]: any };
  handleAddSubmit: (values: { [key: string]: any }) => void;
  handleRemovePension: (id: number) => void;
};

export const ModifyOldPension = ({
  profileData,
  handleAddSubmit,
  previousPension,
  handleRemovePension,
}: Props) => {
  const SSIntegration = previousPension.socialSecurity === null
  ? previousPension.retirementBenefit.social_security
  : previousPension.socialSecurity;

  const isEligibleReciprocal = profileData?.peerSystemIds
    ?.some((sys) => sys === previousPension?.retirementBenefit?.retirement_system?.id);

  const Reciprocity = previousPension.reciprocity && isEligibleReciprocal
    ? true
    : false;

  const [serviceDetails, setServiceDetails] = useState({
    serviceYears: convertTime("years", previousPension?.serviceYears) || 0,
    serviceMonths: convertTime("months", previousPension?.serviceYears) || 0,
    socialSecurity: SSIntegration,
    reciprocity: Reciprocity,
  })

  useEffect(() => {
    handleAddSubmit({
      id: previousPension.id,
      serviceYears: calculateTime(serviceDetails.serviceMonths, serviceDetails.serviceYears),
      ...(serviceDetails.socialSecurity !== SSIntegration ? { socialSecurity: serviceDetails.socialSecurity } : {}),
      ...(serviceDetails.reciprocity !== Reciprocity ? { reciprocity: serviceDetails.reciprocity } : {}),
    })
  }, [serviceDetails])

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "1rem 0" }}>
      <div style={{ margin: "0.5rem 0" }}>
        Previous employer information:
        {` `}
        <div style={{ fontWeight: 500 }}>
          {previousPension.org?.display_name || 'No organization specified'}
        </div>
        <div style={{ fontWeight: 500 }}>
          {`${previousPension.retirementBenefit.name}
            (${previousPension.retirementBenefit.retirement_system.short_name})`}
        </div>
      </div>
      <TextField
        style={{ margin: "0.5rem 0" }}
        label="Years"
        type="number"
        defaultValue={serviceDetails.serviceYears}
        onChange={(e) => setServiceDetails({...serviceDetails, serviceYears: +e.target.value})}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ margin: "0.5rem 0" }}
        label="Months"
        type="number"
        defaultValue={serviceDetails.serviceMonths}
        onChange={(e) => setServiceDetails({...serviceDetails, serviceMonths: +e.target.value})}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div>
        <Checkbox
          style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          checked={serviceDetails.socialSecurity || false}
          onChange={(e) => setServiceDetails({...serviceDetails, socialSecurity: e.target.checked})}
        />Social Security
      </div>
      {isEligibleReciprocal && <div>
        <Checkbox
          style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          checked={serviceDetails.reciprocity || false}
          onChange={(e) => setServiceDetails({...serviceDetails, reciprocity: e.target.checked})}
        />Reciprocity
      </div>}
      <Button
        variant="outlined"
        onClick={() => handleRemovePension(previousPension.id)}
      >
        Remove pension
      </Button>
    </div>
  );
};
